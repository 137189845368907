import { Button } from '../../atoms';
import { FormActionsProps } from './FormActions.types';

export const FormActions = (props: FormActionsProps) => {
  const { styleClass, type, primaryCtaText, secondaryCtaText, secondaryCtaAction } = props;

  return (
    <div data-component-name="m-book-FormActions" data-testid="book-FormActions" className={styleClass}>
      <Button type={type} className="m-button-m m-button-primary primary-nobrand" text={primaryCtaText} />
      <a href="#" className="cancel-btn" onClick={e => secondaryCtaAction(e)}>
        <span className="t-font-s">{secondaryCtaText}</span>
      </a>
    </div>
  );
};
