import {
  ReactElement,
  createElement,
  Children,
  isValidElement,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import { useForm, Controller } from 'react-hook-form';

import { FormProps } from './Forms.types';

/**
 * A Form atom is a React Container component in passed react hook form methods to its children component.
 * Form><input id="input" name="input"/></Form>
 * @returns {HTMLFormElement}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Form = forwardRef((props: FormProps, ref: any) => {
  const { children, onSubmitFn, onError, className, mode, reValidateMode, defaultValues, touched, testId } = props;
  const formMethods = useForm({ mode, reValidateMode, defaultValues });
  const {
    register: registerFn,
    handleSubmit,
    formState: { errors: errorsProps },
    getValues,
    watch,
    setValue,
    control,
    trigger,
    resetField,
    setError,
    clearErrors,
    reset,
  } = formMethods;

  useImperativeHandle(ref, () => {
    return {
      register: registerFn,
      handleSubmit,
      formState: { errors: errorsProps },
      getValues,
      watch,
      setValue,
      control,
      trigger,
      resetField,
      setError,
      clearErrors,
      reset,
    };
  });

  useEffect(() => {
    if (typeof touched === 'function') {
      touched(Object.keys(getValues()).some(item => getValues()[item] !== defaultValues[item]));
    }
  }, [watch()]);

  const renderChildrenWithFormMethods = (eachChild: ReactElement<FormProps>) => {
    return eachChild.props.name
      ? createElement(eachChild.type, {
          ...eachChild.props,
          registerFn,
          errorsProps,
          getValues,
          watch,
          setValue,
          control,
          Controller,
          resetField,
          trigger,
          setError,
          clearErrors,
          key: eachChild.props.name,
        })
      : eachChild;
  };
  return (
    <form
      data-component-name="m-book-Forms"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmitFn, onError)}
      className={className}
      data-testid={testId}
    >
      {Children.map(children, child => {
        if (!isValidElement<FormProps>(child)) {
          return child;
        }
        const eachChildren: ReactElement<FormProps> = child;
        return renderChildrenWithFormMethods(eachChildren);
      })}
    </form>
  );
});
