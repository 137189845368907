import {
  HWSLandingPageRedirectionBrands,
  viewHotelWebsiteLink,
  hotelsSublink,
  reviewsSubLink,
  reviewsRedirectionVarValue,
  trcRedirectionVarValue,
  trcRedirectionURL,
  chinaLocale,
  overviewSubLink,
  hardCodedAllLocale,
} from '../../constants/lib/constants';
import { addSubDirectoryPrefix } from '../../utils/src/subDirectoryFunctions';
import { NAVIGATION_URL_CONSTANTS } from '../../lib/organisms/HotelGalleryTitle/config';

/**
 * Custom hook to unify redirection links wherever possible.
 *
 * @param {string} brandCode Brand code of property to determine applicable redirection rule
 * @param {string} marshaCode Marsha code (propertyID) of the property
 * @param {string} propertyName Property name to construct redirection URL
 * @param {string} redirectionType Custom variable to determine redirection type
 * @param {string} locale Current locale of the property (optional arg)
 * @param {string} seoNickName Correctly formatted url substring from UXL
 */

export function useRedirectionRules(
  brandCode?: string,
  marshaCode?: string,
  propertyName?: string,
  redirectionType?: string,
  locale?: string,
  seoNickName?: string | null
) {
  let redirectionURL = '';
  const formedURL =
    marshaCode?.toLowerCase() +
    '-' +
    propertyName
      ?.replace('-', ' ')
      ?.replace(/([~!@#$%^&*()_+=`{}[\]|\\:;'<>,./? ])+/g, '-')
      ?.replace(/^(-)+|(-)+$/g, '')
      ?.toLowerCase();
  if (redirectionType === reviewsRedirectionVarValue) {
    if (brandCode && HWSLandingPageRedirectionBrands.includes(brandCode)) {
      redirectionURL = viewHotelWebsiteLink + (seoNickName ?? formedURL);
    } else {
      if (locale === hardCodedAllLocale?.en) {
        redirectionURL = '/' + locale + hotelsSublink + (seoNickName ?? formedURL) + reviewsSubLink;
      } else {
        redirectionURL =
          addSubDirectoryPrefix(NAVIGATION_URL_CONSTANTS.SM_VIEW_HOTEL_WEBSITE_LINK) + (seoNickName ?? formedURL);
      }
    }
  } else if (redirectionType === trcRedirectionVarValue) {
    locale = locale?.toLowerCase();
    const URLlocale = locale?.toLowerCase() === chinaLocale ? locale : locale?.split('-')[0];
    redirectionURL = trcRedirectionURL + URLlocale + hotelsSublink + (seoNickName ?? formedURL) + overviewSubLink;
  }
  return redirectionURL;
}
