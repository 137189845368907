import { FC } from 'react';

import { Image, Button } from '../../atoms';
import { OffersCardProps } from './OfferCard.types';
import { StyledOffersContainer } from './OfferCard.styles';

/* eslint-disable-next-line */

export const OffersCard: FC<OffersCardProps> = props => {
  const {
    offersId,
    offerCardImage,
    offerCardTitle,
    offerCardDescription,
    ctaLabel,
    ctaLink,
    offerCardDealTag,
    target,
    trackingProperties,
    altText,
  } = props;
  const imageSize = {
    desktop: '270:270',
    tablet: '270:270',
    mobile: '270:270',
  };

  const renditions = offerCardImage?.renditions;
  const dynamic = offerCardImage?.dynamic;

  return (
    <StyledOffersContainer data-component-name="m-book-OffersCard" data-testid="book-OffersCard">
      <div className="card-wrapper">
        <div className="hero-image-wrapper">
          <Image
            url={offerCardImage?.damPath}
            renditions={renditions}
            dynamic={dynamic}
            title={altText || `img-${offersId}`}
            size={imageSize}
            appliedClass={'offer-image'}
          />
          <div className="details-wrapper">
            {offerCardDealTag && (
              <div className="deal-tag-wrapper">
                <span className="icon-tag icon-margin" />
                <span className="deal-label">{offerCardDealTag}</span>
              </div>
            )}
            {offerCardTitle && <h3 className="title">{offerCardTitle}</h3>}
            {offerCardDescription && <p className="description t-font-inverse-s">{offerCardDescription}</p>}
            {ctaLabel && (
              <Button
                isLink={true}
                href={ctaLink}
                className="m-link-tertiary-button icon-color"
                target={target}
                trackingProperties={trackingProperties}
                linkAriaLabel={`${offerCardTitle} ${ctaLabel}`}
              >
                <span className="d-none">{offerCardTitle}</span>
                <span>{ctaLabel}</span>
              </Button>
            )}
            <div className="hero-image-gradient"></div>
          </div>
        </div>
      </div>
    </StyledOffersContainer>
  );
};
