import React, { useEffect } from 'react';
import { ModelManager } from '@adobe/aem-spa-page-model-manager';
import path from 'path';
import '@glidejs/glide/src/assets/sass/glide.core.scss';
import '@glidejs/glide/src/assets/sass/glide.theme.scss';
import '@marriott/global-styles/dist/marriot.global.css';
import '../styles/brands.scss';

import { defineComponentMapping } from '../import-components';
import { useSessionDataStore } from '@marriott/mi-homepage-components-store';

path.resolve('./next.config.js');
declare global {
  interface Window {
    jQuery: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $: any;
    deployedEnvType: string;
  }
}

if (process.env.NODE_ENV !== 'test') {
  ModelManager.initializeAsync();
}

const App = function (props) {
  console.log('App.tsx props', props);
  const { Component, pageProps } = props;
  const { resolvedUrl, model, isAuthorMode } = pageProps;
  const { setSessionData, setHeadersData, setEnvVariablesData } = useSessionDataStore();
  const allowedComponents = model?.cqItems?.root?.[':items']?.responsivegrid?.allowedComponents?.components;
  useEffect(() => {
    setSessionData(pageProps.sessionData);
    setHeadersData(pageProps.headersData);
    setEnvVariablesData({
      submitSearchUrl: process.env.NEXT_PUBLIC_SUBMIT_SEARCH_URL,
      availabilitySearchUrl: process.env.NEXT_PUBLIC_AVAILABILITY_SEARCH_URL,
      googleMapApiKey: process.env.GOOGLE_MAP_API_KEY,
    });
  }, []);
  defineComponentMapping(allowedComponents, resolvedUrl, isAuthorMode, pageProps);
  return <Component {...pageProps} />;
};

export default App;
