import dynamic from 'next/dynamic';
import { EditableComponent } from '@adobe/aem-react-editable-components';

import { CurrentReservationDetailsProps } from './CurrentReservationDetails.types';

export const CurrentReservationDetails = dynamic<CurrentReservationDetailsProps>(() =>
  import('./CurrentReservationDetails').then(module => module.CurrentReservationDetails)
);

export const currentReservationDetailsConfig = {
  emptyLabel: 'currentReservationDetails',
  isEmpty: false,
  resourceType: `mcom-book-spa/components/modifyratelistmenu/currentreservationdetails`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CurrentReservationDetailsEditable = (props: any) => {
  return (
    <EditableComponent config={currentReservationDetailsConfig} {...props}>
      <CurrentReservationDetails {...props} />
    </EditableComponent>
  );
};
