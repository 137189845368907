/* eslint-disable @typescript-eslint/no-explicit-any */
import { RequestProps } from './dataLayer/index.types';
import { getProcessEnvs, addSubDirectoryPrefix } from './helper';
import { URL_CONSTANTS } from '../constants';
import { axiosWrapper } from './axiosClient';

const { NEXT_PUBLIC_AEM_HOST } = getProcessEnvs();
const { ERROR_PAGE_URL } = URL_CONSTANTS;

const redirect = {
  redirect: {
    permanent: false,
    destination: addSubDirectoryPrefix(ERROR_PAGE_URL),
  },
};

/**
 *
 * @param gssp
 * @returns props
 * This function is used to excute the calls before the page gets rendered.
 * session, interceptor, pre-process, page model, datalayer
 */
export function targetPreprocessor(gssp: (preData: any) => { props: any }) {
  return async (context: any) => {
    const {
      resolvedUrl,
      res,
      req,
    }: {
      resolvedUrl: string;
      req: {
        cookies?: { sessionID: string; UserIdToken: string };
        headers: RequestProps;
        query: Record<string, unknown>;
        originalUrl: string;
        url: string;
      };
      res: any;
    } = context;
    try {
      const contextUrl = resolvedUrl ?? req.originalUrl ?? req.url;
      const pageUrl = `${contextUrl?.split('?')?.[0]}`;
      const preData = { experienceSegments: { brandCode: 'string' }, locale: 'string', notFound: false };
      const fragmentModelUrl = pageUrl.replace('.html', '.model.json');
      const baseUrl = `${NEXT_PUBLIC_AEM_HOST}${fragmentModelUrl}`;
      let pageModel = {};
      try {
        const response = await axiosWrapper.get(baseUrl);
        pageModel = response.data;
      } catch (e) {
        throw new Error('Page Model call failed');
      }

      const gsspData = gssp(preData); // Run `getServerSideProps` to get page-specific data

      return {
        model: pageModel,
        serverENV: JSON.stringify(process.env),
        isExpFrag: true,
        ...gsspData.props,
        isIframeCard: false,
      };
    } catch (e) {
      // return redirect;
      res.writeHead(307, { Location: redirect.redirect.destination });
      res.end();
      return res;
    }
  };
}
