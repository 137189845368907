import { StyledSection } from './AdditionalInfo.styles';
import { AdditionalInfoProps } from './AdditionalInfo.types';

export const AdditionalInfo = (props: AdditionalInfoProps) => {
  const { styleClass, additionalChargesAvailable, infoTitle, infoMessage } = props;

  return (
    <StyledSection className={styleClass} data-component-name="m-book-AdditionalInfo" data-testid="book-AdditionalInfo">
      <h4 className="t-font-m t-font-weight-m m-line-height standard">{infoTitle}</h4>
      {additionalChargesAvailable?.map((charge: string, index: number) => (
        <div className="t-font-s" key={index}>
          {charge}
        </div>
      ))}
      <div className="t-font-s">{infoMessage}</div>
    </StyledSection>
  );
};
