import React from 'react';
import ErrorBoundary from './ErrorBoundary';

export const ErrorHOC =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (Component: new () => React.Component<unknown, unknown>, componentName: string) => (props: Record<any, any>) => {
    try {
      return (
        <ErrorBoundary componentName={componentName}>
          <Component {...props} />
        </ErrorBoundary>
      );
    } catch (e) {
      console.log('component level error occured', componentName, e);
      return <div data-component-name="m-ui-library-ErrorHOC"></div>;
    }
  };
