import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledRegionCard = styled.div`
  ${rtl`
    .accordion-wrapper {
      border-top: ${toRem(1)} solid ${baseVariables.color['neutral40']};
    }

    &:last-child {
      .region-card-parent-container {
        margin-bottom: 0 !important;
      }
    }

    .region-card-container {
      .region-card-title {
        line-height: ${baseVariables.font['lineHeightM']};
      }

      .region-card-subtitle {
        line-height: ${baseVariables.font['lineHeightM']};
      }

      .region-card-placeholder {
        .region-card-image {
          width: ${toRem(60)};
          height: ${toRem(60)};
          background-image: url('https://cache.marriott.com/content/dam/marriott-digital/cy/us-canada/hws/h/hpnnb/en_us/logo/internal/assets/cy-hpnnb-grey-image-placeholder-34740.png?imbypass=on');
          border-radius: ${toRem(8)};

          @media ${baseVariables.mediaQuery.md} {
            width: ${toRem(180)};
          }
        }
      }

      .region-card-view-all-link {
        text-decoration: underline;
        line-height: ${baseVariables.font['lineHeightM']};
      }

      
    }
    .accordion__container .accordion__list {
      margin-top:0;

      .no-hotels-error {
        .row {
          width: ${toRem(311)};
          
          @media ${baseVariables.mediaQuery.md} {
           width: ${toRem(413)};
          }
          .m-message-inline {
            padding: ${toRem(13)} ${toRem(20)};
          }

          .m-message-content-wrap {
            align-items: flex-start;
            &:before {
              height: ${toRem(14)};
              width: ${toRem(14)};
              margin-right: ${toRem(11)} !important;
            }
          }
        }
      }
    }
    .accordion__container .accordion__heading 
      .accordion__title {
        margin-bottom:0;
        margin-top: 0;
      }
      .icon-arrow-up:before {
        height: ${toRem(16)};
        width: ${toRem(16)};
      }
  `}
`;
