import { useState, useEffect, FC } from 'react';
import cssClass from 'classnames';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { Image } from '../../atoms';
import { findSavedProperty, handleClickHotelDetails } from './hotelHeaderHelper';
import { BrandedHotelHeaderProps } from './HotelHeader.types';
import { StyledSection } from './HotelHeader.styles';

import { createImageSizes, getWindowSession, addSubDirectoryPrefix, getHotelDetailURL } from '../../utils';
import { getCommaSeparator } from '../../utils';
import { constants, TRACKING_CONSTANT, URL_CONSTANTS, CUSTOM_EVENTS_CONSTANTS } from '../../constants';

const { ON_SHOW_HOTEL_DETAIL } = CUSTOM_EVENTS_CONSTANTS;
const { TARGET_BLANK, HOTEL_DETAILS_ID } = constants;
const { PROPERTY_URL, GOOGLE_MAP_URL, SAVE_PROPERTY_URL, RLM_URL, CACHE_MARRIOTT_URL, ADF_URL } = URL_CONSTANTS;
const {
  HEADER_TILE,
  HEADER_PROPERTY_NAME,
  HEADER_HOTEL_INFO,
  HEADER_ADDRESS_LINK,
  HEADER_PHONE_NO,
  HEADER_REVIEWS,
  INTERNAL_LINK,
} = TRACKING_CONSTANT;

export const BrandedHotelHeader: FC<BrandedHotelHeaderProps> = props => {
  const {
    componentId,
    seoNickname,
    hotelName,
    fileReference,
    latitude,
    longitude,
    hotelAddress,
    phone,
    showReviews,
    suppressReviews,
    stars,
    hotelReviews,
    reviews,
    isLoading,
    hotelDetailsLabel,
    pageType,
    savedLabel,
    saveLabel,
    brandCode,
    propertyId,
    userData,
    showSaveProperty,
    handleClick,
    phoneText,
    starsOutOfText,
    uniqueHotelLogo,
    reviewUrl,
    isMax,
    disableHotelHeaderAndReviewLinks,
    disableHotelReviewLinks,
    enableHQVLink,
    locale,
  } = props;

  const { RLM_PAGE, UPCOMING_RESERVATION_PAGE, HIGH, ADF_PAGE } = constants;
  const isRlmOrUpcomingResPage =
    pageType === RLM_PAGE || pageType === UPCOMING_RESERVATION_PAGE || pageType === ADF_PAGE || pageType === '';
  const [hotelSave, setHotelSave] = useState({ addClass: 'icon-heart-outline', showSavedLabel: false });
  const sessionObject = getWindowSession();
  const isAuthenticated = sessionObject?.authenticated;
  let hotelReviewUrl = reviewUrl && seoNickname ? reviewUrl.replace('{property-name}', seoNickname) : '';
  hotelReviewUrl =
    hotelReviewUrl ||
    addSubDirectoryPrefix(reviewUrl && seoNickname ? reviewUrl.replace('{property-name}', seoNickname) : '');
  let hotelDetailUrl = getHotelDetailURL({ brandCode, locale, marshaCode: propertyId });
  hotelDetailUrl = hotelDetailUrl || addSubDirectoryPrefix(`${PROPERTY_URL}${seoNickname}`);

  /*Generating css clasname from clasname module*/
  const sectionClassName = cssClass('container t-background-color', { hide: isLoading });
  const reviewsClassName = cssClass(
    't-font-weight-r icon-star-fill icon-height t-foreground-color custom_click_track',
    { 'mr-4 mr-lg-3': isRlmOrUpcomingResPage && showSaveProperty },
    { ' disableLinks': disableHotelReviewLinks }
  );

  const propertyDetailsClassName = cssClass('custom_click_track', {
    ' disableLinks': disableHotelHeaderAndReviewLinks,
  });

  const disableHotelLogoClassName = cssClass('t-font-weight-r d-none d-lg-inline-block logo-wrapper', {
    ' disableLinks': disableHotelHeaderAndReviewLinks,
  });

  // const seoPropertyUrl = disableHotelHeaderAndReviewLinks ? '' : `${PROPERTY_URL}${seoNickname}`;
  const seoPropertyUrl = disableHotelHeaderAndReviewLinks ? '' : addSubDirectoryPrefix(hotelDetailUrl);
  const propertyReviewUrl = disableHotelReviewLinks ? '' : addSubDirectoryPrefix(hotelReviewUrl);

  useEffect(() => {
    if (isRlmOrUpcomingResPage && userData?.savedProperties?.length) {
      const { savedProperties } = userData;
      if (findSavedProperty(propertyId, savedProperties) > -1) {
        setHotelSave({ ...hotelSave, addClass: 'icon-heart-fill', showSavedLabel: true });
      } else {
        setHotelSave({ ...hotelSave, addClass: 'icon-heart-outline', showSavedLabel: false });
      }
    }
  }, [userData?.savedProperties]);

  const imageSize = createImageSizes(fileReference?.renditions);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSaveProperty = async (event: any) => {
    if (isAuthenticated && userData?.savedProperties) {
      event.preventDefault();
      const propertyList = userData.savedProperties.map(property => ({
        id: property['id'],
      }));
      handleClick(propertyList, setHotelSave, hotelSave);
    }
  };

  const getReturnToPageURL = () => {
    if (typeof window !== 'undefined' && window?.location?.href?.includes('search/availabilityCalendar')) {
      return ADF_URL;
    } else {
      return RLM_URL;
    }
  };

  return (
    <StyledSection className={sectionClassName} data-component-name={componentId}>
      <a href={seoPropertyUrl} aria-label={hotelName} className={disableHotelLogoClassName} target={TARGET_BLANK}>
        {uniqueHotelLogo ? (
          <Image
            url={`${CACHE_MARRIOTT_URL}${uniqueHotelLogo}`}
            title={hotelName}
            appliedClass={'hotel-logo'}
            fetchpriority={HIGH}
          />
        ) : (
          <Image
            url={fileReference?.damPath}
            title={hotelName}
            size={imageSize}
            renditions={fileReference?.renditions}
            dynamic={fileReference?.dynamic}
            appliedClass={'hotel-logo'}
            fetchpriority={HIGH}
          />
        )}
      </a>
      <div className="property-details">
        <a
          href={seoPropertyUrl}
          target={TARGET_BLANK}
          className={propertyDetailsClassName}
          data-custom_click_track_value={`${HEADER_TILE}|${HEADER_PROPERTY_NAME}|${INTERNAL_LINK}`}
        >
          <h3 className="hotel-name mb-3">{hotelName}</h3>
        </a>
        {hotelName && (
          <>
            {isRlmOrUpcomingResPage && (
              <a
                href={enableHQVLink ? seoPropertyUrl : '#'}
                className="mr-4 mr-lg-3 icon-city icon-height t-font-weight-r t-foreground-color hqvLink custom_click_track"
                data-custom_click_track_value={`${HEADER_TILE}|${HEADER_HOTEL_INFO}|${INTERNAL_LINK}`}
                title={hotelName}
                onClick={e =>
                  handleClickHotelDetails({
                    e: e,
                    id: HOTEL_DETAILS_ID,
                    key: ON_SHOW_HOTEL_DETAIL,
                    hotelReviewUrl: hotelReviewUrl,
                    enableHQVLink: enableHQVLink,
                  })
                }
              >
                <span className="ml-1 d-none d-lg-inline-block option-label">{hotelDetailsLabel}</span>
              </a>
            )}
            <a
              className="mr-4 mr-lg-3 t-font-weight-r icon-location icon-height t-foreground-color custom_click_track"
              target={TARGET_BLANK}
              rel="noopener noreferrer"
              href={`${GOOGLE_MAP_URL}${latitude},${longitude}`}
              aria-label={hotelAddress}
              data-custom_click_track_value={`${HEADER_TILE}|${HEADER_ADDRESS_LINK}|${INTERNAL_LINK}`}
            >
              <p className="ml-1 d-none d-lg-inline-block mb-0 address">{hotelAddress}</p>
            </a>
            {phone && (
              <a
                href={`tel:${phone}`}
                aria-label={phoneText}
                className="mr-4 d-lg-none icon-phone icon-height t-foreground-color custom_click_track"
                data-custom_click_track_value={`${HEADER_TILE}|${HEADER_PHONE_NO}|${INTERNAL_LINK}`}
              >
                <span className="sr-only">{phone}</span>
              </a>
            )}
            {phone && (
              <div className="d-none d-lg-inline-block icon-phone icon-height t-foreground-color">
                <span className="mr-3 ml-1 phone">{phone}</span>
              </div>
            )}
            {showReviews && !suppressReviews && !isMax ? (
              <a
                href={propertyReviewUrl}
                className={reviewsClassName}
                target={TARGET_BLANK}
                data-custom_click_track_value={`${HEADER_TILE}|${HEADER_REVIEWS}|${INTERNAL_LINK}`}
                aria-label={`${stars?.['count']} ${starsOutOfText} ${hotelReviews?.['count']} ${reviews}`}
                data-testid="reviews"
              >
                <span className="ml-1 stars">{getCommaSeparator(stars?.['count'], locale || '')}</span>
                <div className="d-inline-block d-lg-none reviews ml-1">{`(${getCommaSeparator(
                  hotelReviews?.['count'],
                  locale || ''
                )})`}</div>
                <div className="d-none d-lg-inline-block reviews ml-1">{`(${getCommaSeparator(
                  hotelReviews?.['count'],
                  locale || ''
                )} ${reviews})`}</div>
              </a>
            ) : null}
            {isRlmOrUpcomingResPage && showSaveProperty && (
              <a
                className={`d-inline-block icon-height t-font-weight-r t-foreground-color ${hotelSave.addClass}`}
                href={`${SAVE_PROPERTY_URL}?save=${!hotelSave.showSavedLabel}&marshaCode=${propertyId}&returnTo=${getReturnToPageURL()}
                `}
                onClick={handleSaveProperty}
                data-testid="save-property"
              >
                <span className="ml-1 d-none d-lg-inline-block option-label">
                  {hotelSave.showSavedLabel ? savedLabel : saveLabel}
                </span>
              </a>
            )}
          </>
        )}
      </div>
    </StyledSection>
  );
};
