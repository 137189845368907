/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditableComponent } from '@adobe/aem-react-editable-components';
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

const SmHotelGalleryPhotoViewerWrapper = React.lazy(() => import('./HotelGalleryPhotoViewerWrapper'));

interface HotelPhotoGalleryViewerProps {
  model: Record<string, any>;
}

export const HotelGalleryPhotoViewerComponent: React.FC<HotelPhotoGalleryViewerProps> = ({ model }) => {
  const jsonContent = model?.['jsonContent'];
  return (
    <ErrorBoundary fallback={<p>Something went wrong</p>}>
      <Suspense fallback={''}>
        <SmHotelGalleryPhotoViewerWrapper jsonContent={jsonContent} />
      </Suspense>
    </ErrorBoundary>
  );
};

export const HotelGalleryPhotoViewerComponentEditable = (props: any) => {
  return props.cqPath.includes('experiencefragment') ? (
    <HotelGalleryPhotoViewerComponent {...props} />
  ) : (
    <EditableComponent config={{}} {...props}>
      <HotelGalleryPhotoViewerComponent {...props} />
    </EditableComponent>
  );
};
