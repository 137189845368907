import dynamic from 'next/dynamic';
import { ReservationRoomDetailsProps } from './ReservationRoomDetails.types';
import { ReservationRoomDetailsV2Props } from './V2/index.types';

export const ReservationRoomDetails = dynamic<ReservationRoomDetailsProps>(() =>
  import('./ReservationRoomDetails').then(module => module.ReservationRoomDetails)
);

export const ReservationRoomDetailsV2 = dynamic<ReservationRoomDetailsV2Props>(() =>
  import('./V2').then(module => module.ReservationRoomDetailsV2)
);
