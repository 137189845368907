import React, { useContext } from 'react';
import { StyledPaginationSortByComponentDiv } from './index.styles';
import clsx from 'clsx';
import { SearchSummaryComponent } from '../searchResultsSummary';
import { renderedData } from '../../organisms/searchResults';
import { SortByFilterComponent } from '../sortByFilter';

export const PaginationSortByComponent: React.FC<{
  isMobileState: boolean;
  className: string;
  segmentId?: string;
  isLatLongAvailable?: boolean;
}> = ({ isMobileState, className, segmentId, isLatLongAvailable }) => {
  const { isMapViewEnabled } = useContext(renderedData);
  return (
    <StyledPaginationSortByComponentDiv className={clsx('container', className, isMapViewEnabled ? `m-0` : ``)}>
      <div className={clsx('container', 'row-2', isMapViewEnabled ? `pr-3` : `pr-0`)}>
        <SearchSummaryComponent />
        <SortByFilterComponent
          isMobileState={isMobileState}
          segmentId={segmentId}
          isLatLongAvailable={isLatLongAvailable}
        />
      </div>
    </StyledPaginationSortByComponentDiv>
  );
};
