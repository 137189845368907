import { FC } from 'react';

import { BuildInfoProps } from './BuildInfoConsoleLog.types';
import { concatenateWithSeparator } from '../../utils';

export const DEPLOYED_ENV_CONFIG_LABEL = 'Deployed Env Config';
export const JENKINS_BUILD_JOB_LABEL = 'Jenkins Build Job';

/**
 * Display build info in code comment on lower envs.
 * Helps test and dev to know when their code is deployed.
 */
export const BuildInfoConsoleLog: FC<BuildInfoProps> = ({
  deployedEnvType,
  jenkinsDeploymentBuild = '',
  deployedEnvConfig = '',
}) => {
  if (!deployedEnvType || deployedEnvType?.length === 0 || deployedEnvType === 'higher') {
    return null;
  }

  const validDeployedEnvConfig: string =
    deployedEnvConfig && deployedEnvConfig.length > 0 ? `${DEPLOYED_ENV_CONFIG_LABEL}: ${deployedEnvConfig}` : '';
  const validJenkinsInfo: string =
    jenkinsDeploymentBuild && jenkinsDeploymentBuild.length > 0
      ? `${JENKINS_BUILD_JOB_LABEL}: ${jenkinsDeploymentBuild}`
      : '';
  const buildInfoConcat = concatenateWithSeparator([validDeployedEnvConfig, validJenkinsInfo], '; ');
  const buildInfoComment = `console.log('Build Info ==> ${buildInfoConcat}');`;

  return <script type="text/javascript" id="build_info_log" dangerouslySetInnerHTML={{ __html: buildInfoComment }} />;
};
