/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Styled } from './index.styles';
import Accordion from '../Accordion';
import { ComponentProps, TransportationProps, RestrictedCountryListItemProps } from '../HotelOverview.types';
import { serverData } from '../HotelOverview';
import { useQuery } from '@apollo/client';
import { phoenixShopPropertyInfoCall, phoenixShopHQVPropertyInfoCall } from '../../HotelGalleryTitle/HQVQueries';
import { clickTrackingLoc } from '../constants/ApplicationConstants';
import { usePageContext } from '../../../../context/PageContext';
import { overviewMockData } from '../mockdata/overview';
import clsx from 'clsx';
import { useCheckBreakpoint } from '../../../../hooks';
import { isServer } from '@marriott/mi-apollo-client-utils';
// import { useHQVContext } from 'libs/mi-shop-components/src/context';
import { useGetBreakpoint, eventUtil } from '@marriott/mi-ui-library-shop';
import { useHQVContext } from '../../../../context/HQVContext';
import { formatPhoneNumber, logger } from '../../../../utils';
import { MapComponent } from '../MapComponent';
declare global {
  interface Window {
    initMap: any;
    // google: any;
  }
}

export const Location: React.FC<ComponentProps> = () => {
  const { log } = logger({})('HotelOverview/Location');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { jsonContent }: any = useContext(serverData);
  const isViewportL = useCheckBreakpoint('viewportL');
  const isViewportM = useCheckBreakpoint('viewportM');
  const { hqvState } = useHQVContext();

  const { requestId, pageState, currentLocale, isDTT, dttHeaders } = usePageContext();
  const propertyId = hqvState.propertyIDvalue;

  // const propertyId = useMemo(() => {
  //   return pageState.authorMode
  //     ? DEFAULT_FALLBACK_PROPERTY_ID
  //     : resolvedUrl?.split('propertyId=')[1]?.split('&')[0] ?? DEFAULT_FALLBACK_PROPERTY_ID;
  // }, [resolvedUrl, pageState]);
  const reqId = useMemo(() => {
    return pageState.authorMode ? `${Date.now()}` : requestId ?? `${Date.now()}`;
  }, [requestId, pageState]);
  const [_breakpoint, setBreakpoint] = useState<string | undefined>(useGetBreakpoint());
  // Reset breakpoint on window resize.
  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      log.debug('resize, ', useGetBreakpoint());
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setBreakpoint(useGetBreakpoint());
    }

    window.addEventListener('resize', handleResize);

    // Dispatching event to notify the findHotels page that the loader can be hidden
    // flagLocation is set solely for debugging
    if (locationData && Object.keys(locationData).length !== 0) {
      eventUtil.dispatch(`HQVComponentCallCompleted`, { flagLocation: 'locationFlag' });
    }
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const skipQuery = useMemo(() => {
    return !!pageState.authorMode;
  }, [pageState]);

  const queryToBeCalled = isDTT ? phoenixShopHQVPropertyInfoCall : phoenixShopPropertyInfoCall;

  const {
    // loading: locationDataLoading,
    error: locationDataError,
    data: locationDataGraph,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useQuery(queryToBeCalled, {
    fetchPolicy: isServer ? 'network-only' : 'cache-first',
    variables: {
      propertyId: propertyId,
      filter: 'PHONE',
      descriptionsFilter: ['LOCATION'],
    },
    context: {
      headers: {
        // Any header, including x-request-id, can be passed in args with query.
        // If you don't pass it in the authLink will generate a random ID.
        'x-request-id': reqId,
        'accept-language': currentLocale,
        ...dttHeaders,
      },
    },
    skip: skipQuery,
    // onCompleted: data => {
    //   log.debug('phoenixShopHotelLocation complete: ', data);
    // },
  });

  const locationData = useMemo(() => {
    return pageState.authorMode ? overviewMockData.locationContentData : locationDataGraph;
  }, [pageState, locationDataGraph]);

  const airportDetails = locationData?.property.airports;
  const otherTransportationData = locationData?.property.otherTransportation;
  const busTravel: TransportationProps[] = useMemo(() => {
    return otherTransportationData?.filter(
      (transportationForm: TransportationProps) => transportationForm?.type?.code?.indexOf('Bus') >= 0
    );
  }, [otherTransportationData]);
  const carRentals: TransportationProps[] = useMemo(() => {
    return otherTransportationData?.filter(
      (transportationForm: TransportationProps) => transportationForm?.type?.code?.indexOf('Car') >= 0
    );
  }, [otherTransportationData]);
  const subwayTravel: TransportationProps[] = useMemo(() => {
    return otherTransportationData?.filter(
      (transportationForm: TransportationProps) => transportationForm?.type?.code?.indexOf('Subway') >= 0
    );
  }, [otherTransportationData]);
  const trainTravel: TransportationProps[] = useMemo(() => {
    return otherTransportationData?.filter(
      (transportationForm: TransportationProps) => transportationForm?.type?.code?.indexOf('Train') >= 0
    );
  }, [otherTransportationData]);

  const basicInformation = locationData?.property?.basicInformation;
  const contactInformation = locationData?.property?.contactInformation;

  const addressObj = contactInformation?.address;
  const addressLine1 = addressObj?.line1 ?? '';
  const addressCity = addressObj?.city ?? '';
  const addressStateProvince = addressObj?.stateProvince?.description ?? '';
  const addressPostalCode = addressObj?.postalCode ?? '';
  let addressCountry = addressObj?.country?.description ?? '';
  const restrictedCountryList: RestrictedCountryListItemProps[] = jsonContent?.countryListRestricted ?? [];
  const addressCountryCode = addressObj?.country?.code;
  // Reading through list of countries to be hidden and replacing the country with the new country
  restrictedCountryList?.forEach(restrictedCountryItem => {
    if (restrictedCountryItem?.countryItemRestricted === addressCountryCode) {
      addressCountry = restrictedCountryItem?.replacedCountryItem;
    }
  });
  if (
    jsonContent?.overridePropertyCountry &&
    addressObj?.country?.description === jsonContent?.overridePropertyCountryText
  ) {
    addressCountry = '';
  }
  const [isActive, setIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  // Function to handle click of the location component accordians
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const accordionClick = (event: any, value: number): void => {
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      event.preventDefault();
      if (value === activeIndex) {
        setActiveIndex(-1);
      } else {
        setActiveIndex(value);
      }
      setIsActive(false);
    }
  };

  if (locationDataError) {
    return null;
  }

  // function to determine if a comma should be displayed or not
  function addressDelimiterFinder(startString: string, addressArr: string[]) {
    const filteredAddressArr = addressArr.filter(str => str !== '');
    if (startString && filteredAddressArr.length > 0) {
      return ', ';
    }
    return ' ';
  }

  return (
    <Styled>
      {locationData && (
        <div
          id="hqv-location-section"
          className={clsx(
            'color-scheme1',
            'location-box',
            'adjustable-margin-element',
            `${hqvState?.modalOpenState ? 'hqv-modal' : ''}`
          )}
        >
          <div className="location-box__container standard">
            <div className="location-box__col1">
              <h2 className={clsx(isViewportL ? 't-title-s' : 't-subtitle-xl', 'location-heading')}>
                {jsonContent?.locationLabel}
              </h2>
              <div className="location-box__col2-mobile">
                <div className="mobile-map">
                  <div id="location-box__map-box-mobile">
                    <MapComponent currentLocale={currentLocale} basicInformation={basicInformation} />
                  </div>
                </div>
              </div>
              <div className="location-box__desc">
                <div className="hotel-address">
                  <div className="hotel-address-line1">
                    {/* Not using the addressDelimiterFinder since the comma after addressLine1 follows different logic
                    According to FSD-91318, this is what is expected:

                      line1 city, state, country, postal code
                      If there is no country then line1 city, state, postal code
                      if no state then line1 city, country, postal code
                      If no city & country then line1, state, postal code
                      If no postal code , city & country then line1, city */}
                    {addressLine1 +
                      (addressLine1 &&
                      ((addressCity && !addressStateProvince && !addressCountry && !addressPostalCode) ||
                        (!addressCity && addressStateProvince && !addressCountry && addressPostalCode))
                        ? ', '
                        : ' ')}
                  </div>
                  <div className="hotel-address-city-postal">
                    {addressCity +
                      addressDelimiterFinder(addressCity, [addressStateProvince, addressPostalCode, addressCountry])}
                    {addressStateProvince +
                      addressDelimiterFinder(addressStateProvince, [addressPostalCode, addressCountry])}
                    {addressCountry + addressDelimiterFinder(addressCountry, [addressPostalCode])}
                    {addressPostalCode}
                  </div>
                </div>
              </div>
              {contactInformation?.contactNumbers[0]?.phoneNumber?.display &&
                contactInformation?.contactNumbers[0]?.phoneNumber?.original && (
                  <div className="location-box__cn-box">
                    <span className={clsx(isViewportM ? 't-subtitle-m' : 't-label-alt-xs', 'telephone-label')}>
                      {jsonContent?.telephoneLabel}:{' '}
                    </span>
                    <a
                      href={`tel:` + contactInformation?.contactNumbers[0]?.phoneNumber?.original}
                      className={clsx(
                        isViewportM ? 't-subtitle-m' : 't-label-alt-xs',
                        'telephone-link',
                        'custom_click_track'
                      )}
                      {...{ custom_click_track_value: `${clickTrackingLoc}| Location Telephone |internal` }}
                    >
                      <span className="location-box__contactNumber">
                        {formatPhoneNumber(contactInformation?.contactNumbers[0]?.phoneNumber?.display, currentLocale)}
                      </span>
                    </a>
                  </div>
                )}
              <div className="location-box__accordian-container">
                <div className={clsx('col-12', 'p-0', 'accordion', 'm-accordion')} id="accordionExample1">
                  {airportDetails?.map((airport: TransportationProps, index: number) => (
                    <Accordion
                      key={index}
                      accIndex={index}
                      active={index === activeIndex}
                      accordionClick={accordionClick}
                      aeroData={airport}
                    />
                  ))}
                  {(busTravel?.length > 0 ||
                    carRentals?.length > 0 ||
                    subwayTravel?.length > 0 ||
                    trainTravel?.length > 0) && (
                    <div className="accordion-item">
                      <h2 className="accordion-header location-box-header" id="otherTransportationHeading">
                        <button
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#otherTransporation1"
                          aria-expanded={isActive ? 'true' : 'false'}
                          aria-controls="otherTransporation1"
                          tabIndex={0}
                          className={clsx(
                            'accordion-button',
                            'location-box__accordian',
                            'other-transportation',
                            'custom_click_track',
                            isActive ? '' : 'collapsed'
                          )}
                          onClick={(event): void => {
                            if (event.type === 'click') {
                              setActiveIndex(-1);
                              setIsActive(!isActive);
                              event.preventDefault();
                            }
                          }}
                          onKeyDown={(event): void => {
                            if (event.key === 'Enter' || event.keyCode === 13) {
                              setActiveIndex(-1);
                              setIsActive(!isActive);
                              event.preventDefault();
                            }
                          }}
                          {...{
                            custom_click_track_value: `${clickTrackingLoc}| Other Transportation Accordian |internal`,
                          }}
                        >
                          <div className={clsx('m-accordion-title', 'airplane-icon-name', 'accordion-element')}>
                            <span className={clsx('icon-s', 'mr-2', 'icon-shuttle', 'icon-decorative')}></span>
                            <span className={clsx('t-font-m', 'location-box__location-name')}>
                              <p>{jsonContent?.otherTransportationLabel}</p>
                            </span>
                            <span
                              className={clsx(
                                'accordion-arrow-icon',
                                isActive ? 'ml-3 icon-dropdown-up' : 'ml-3 icon-dropdown-down'
                              )}
                            ></span>
                          </div>
                        </button>
                      </h2>

                      {isActive && (
                        <div
                          className={clsx(
                            'color-scheme1',
                            'accordion-collapse',
                            'collapse',
                            'panel',
                            'accordion-content',
                            isActive ? ' show' : ''
                          )}
                          id="otherTransporation1"
                          aria-labelledby="otherTransportationHeading"
                          data-bs-parent="#accordionExample1"
                        >
                          {busTravel.length > 0 && (
                            <div className={clsx('bus-stations', 'new-travel-method', 'standard')}>
                              <p className="t-subtitle-m">{jsonContent?.busStationLabel}</p>
                              {busTravel.map((busStop: TransportationProps) => (
                                <div className={clsx('t-font-s', 'busStop', 'individual-item')}>
                                  <p>{busStop.name}</p>
                                </div>
                              ))}
                            </div>
                          )}
                          {carRentals.length > 0 && (
                            <div className={clsx('car-rentals', 'new-travel-method', 'standard')}>
                              <p className="t-subtitle-m">{jsonContent?.carRentalsLabel}</p>
                              {carRentals.map((rental: TransportationProps) => (
                                <>
                                  <div className={clsx('t-font-s', 'rental', 'individual-item')}>
                                    <p>{rental.name}</p>
                                  </div>
                                  <div className={clsx('individual-item', 'car-phone-number')}>
                                    {rental?.contactInformation?.phones[0] && (
                                      <a href={`tel:` + rental?.contactInformation?.phones[0].replace(/ /g, '')}>
                                        <span>{jsonContent?.phoneLabel}:&nbsp;</span>
                                        <span className="phone-number">
                                          {formatPhoneNumber(
                                            rental?.contactInformation?.phones[0] ?? '',
                                            currentLocale
                                          )}
                                        </span>
                                      </a>
                                    )}
                                  </div>
                                </>
                              ))}
                            </div>
                          )}
                          {subwayTravel.length > 0 && (
                            <div className={clsx('subway-stations', 'new-travel-method', 'standard')}>
                              <p className="t-subtitle-m">{jsonContent?.subwayStationLabel}</p>
                              {subwayTravel.map((subway: TransportationProps) => (
                                <div className={clsx('t-font-s', 'subway', 'individual-item')}>
                                  <p>{subway.name}</p>
                                </div>
                              ))}
                            </div>
                          )}
                          {trainTravel.length > 0 && (
                            <div className={clsx('train-stations', 'new-travel-method', 'standard')}>
                              <p className="t-subtitle-m">{jsonContent?.trainStationLabel}</p>
                              {trainTravel.map((train: TransportationProps) => (
                                <div className={clsx('t-font-s', 'train', 'individual-item')}>
                                  <p>{train.name}</p>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="location-box__col2">
              <div className="desktop-map">
                <div id="location-box__map-box-desktop">
                  <MapComponent currentLocale={currentLocale} basicInformation={basicInformation} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Styled>
  );
};

export default Location;
