// Styles for RegionCardSkeletonLoader go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';

import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledRegionCardSkeletonLoader = styled.div`
  ${rtl`
  .with-header-height{
    height:${toRem(509)};
  } 
  .non-header-height{
    height:${toRem(93)};
  } 
  .closed-state-container{
    width:100%;
    height:${toRem(92)};
    align-items: center;
    background: none;
    width: 100%;
    padding: 0;
    color: inherit;
  }
  .region-item-loader{
    height:${toRem(24)};
  }
  .title-place-loader{
    width: 10%;
    height: ${toRem(34)};
  }

  .region-card-placeholder {
    margin-right:${toRem(32)};
    width: ${toRem(60)};
    height: ${toRem(60)};
    background-image: url('https://cache.marriott.com/content/dam/marriott-digital/cy/us-canada/hws/h/hpnnb/en_us/logo/internal/assets/cy-hpnnb-grey-image-placeholder-34740.png?imbypass=on');
    border-radius: ${toRem(8)};

    @media ${baseVariables.mediaQuery.md} {
      width: ${toRem(180)};
    }
  }
  .skeleton-loader-composite {
    background-color:  ${baseVariables.color['neutral40']};
    color: white;
    -webkit-animation: fadein 2s ease-in alternate infinite;
    -moz-animation: fadein 2s ease-in alternate infinite;
    animation: fadein 2s ease-in alternate infinite;
  }
  @-webkit-keyframes fadein {
    10%   { opacity: 0.3; }
    25%   { opacity: 0.35; }
    50%   { opacity: 0.4; }
    75%   { opacity: 0.5; }
    100% { opacity: 0.6; }
  }
  @-moz-keyframes fadein {
    10%   { opacity: 0.3; }
    25%   { opacity: 0.35; }
    50%   { opacity: 0.4; }
    75%   { opacity: 0.5; }
    100% { opacity: 0.6; }
  }
  @keyframes fadein {
    10%   { opacity: 0.3; }
    25%   { opacity: 0.35; }
    50%   { opacity: 0.4; }
    75%   { opacity: 0.5; }
    100% { opacity: 0.6; }
  }
`}
`;
