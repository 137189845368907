// Styles for MapAndRegionCard go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledMapAndRegionCard = styled.div`
  .hotelDirectoryHeading {
    margin-top: ${toRem(20)};
  }

  .all-filters-container {
    margin-top: ${toRem(16)};
    margin-bottom: ${toRem(20)};
  }

  .all-region-list-container {
    position: relative;

    .map-region-selector-icon {
      text-align: right;

      span.icon-location {
        font-size: ${toRem(19)};
      }
    }

    .map-region-selector-icon-wrapper {
      float: left;
      padding-left: ${toRem(12)};
    }
  }
  .button-label {
    color: ${baseVariables.color['base10']};
    line-height: ${toRem(16)};
    padding: 0 ${toRem(6)} 0 ${toRem(6)};
  }
  .pill-collection {
    overflow: unset;
    .pill {
      &:focus:not(:focus-visible) {
        outline: 0;
      }
      .all-filters-button {
        padding: ${toRem(8)} ${toRem(8)} ${toRem(8)} ${toRem(6)};
      }
    }
  }
  .m-custom-select-block {
    white-space: nowrap;
    max-width: ${toRem(220)};
    @media ${baseVariables.mediaQuery.tablet} {
      max-width: max-content;
    }

    span.icon-dropdown-down,
    span.icon-dropdown-up {
      transform: scale(1.5);
      margin-left: 0;
    }
  }
  .m-custom-select-block > label {
    font-weight: ${baseVariables.font['fontWeightMedium']};
    color: ${baseVariables.color['neutral00']};
    font-size: ${toRem(14)};
    line-height: ${toRem(16)};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
  }

  .hotel-count-loader-container {
    .skeleton-loader-composite {
      background-color: ${baseVariables.color['neutral40']};
      color: white;
      -webkit-animation: fadein 2s ease-in alternate infinite;
      -moz-animation: fadein 2s ease-in alternate infinite;
      animation: fadein 2s ease-in alternate infinite;
      margin-right: ${toRem(4)};
    }
    @-webkit-keyframes fadein {
      10% {
        opacity: 0.3;
      }
      25% {
        opacity: 0.35;
      }
      50% {
        opacity: 0.4;
      }
      75% {
        opacity: 0.5;
      }
      100% {
        opacity: 0.6;
      }
    }
    @-moz-keyframes fadein {
      10% {
        opacity: 0.3;
      }
      25% {
        opacity: 0.35;
      }
      50% {
        opacity: 0.4;
      }
      75% {
        opacity: 0.5;
      }
      100% {
        opacity: 0.6;
      }
    }
    @keyframes fadein {
      10% {
        opacity: 0.3;
      }
      25% {
        opacity: 0.35;
      }
      50% {
        opacity: 0.4;
      }
      75% {
        opacity: 0.5;
      }
      100% {
        opacity: 0.6;
      }
    }
  }
`;
