import styled from 'styled-components';
import { toRem } from '@marriott/mi-ui-library';
import { theme } from '@marriott/mi-ui-library-shop';

export const StyledSkeletonLoaderContainer = styled.div`
  .image-place-loader {
    margin-top: ${toRem(16)};
    margin-left: ${toRem(90)};
    margin-right: ${toRem(90)};
    margin-bottom: ${toRem(16)};
    margin-top: ${toRem(16)};
    height: 100%;
    width: 100%;
  }
  @media ${theme.mediaQuery.allMobiles} {
    .image-place-loader {
      margin-left: ${toRem(16)};
      margin-right: ${toRem(16)};
      height: ${toRem(655)};
      width: -webkit-fill-available;
    }
  }
`;
