import withApollo from 'next-with-apollo';
import { ApolloProvider } from '@apollo/react-hooks';
import { getDataFromTree } from '@apollo/client/react/ssr';

import { useNextMiApolloClient } from '.';
import { OperationSignature } from './types';

export const getWithApolloHOC = (
  operationSignatures: OperationSignature[],
  deployedEnvType: string,
  inMemoryCacheConfig = {}
) => {
  return withApollo(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ initialState }): any => {
      return useNextMiApolloClient(operationSignatures, deployedEnvType, inMemoryCacheConfig, initialState);
    },
    {
      render: ({ Page, props }) => {
        return (
          <ApolloProvider client={props.apollo}>
            <Page {...props} />
          </ApolloProvider>
        );
      },
      getDataFromTree,
    }
  );
};
