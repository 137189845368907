import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledFilterComponentDiv = styled.div`
  ${rtl`
  margin: auto;
  @media only ${theme.mediaQuery.allMobiles}{
    margin-left: auto;
    margin-right:auto;
  }
  font-family: ${theme.fonts['fontFamilySwiss']};
  font-weight: ${theme.fonts['fontWeightMedium']};
  line-height: ${toRem(24)};
  margin-bottom: ${toRem(16)};
  .row-1 {
    display: block;
    overflow-x: auto;
    @media only ${theme.mediaQuery.allMobiles}{
      margin-left: ${toRem(16)};
    }
    @media only ${theme.mediaQuery.largerMobile}{
      margin-left: ${toRem(0)};
    }
  }
  .row-1::-webkit-scrollbar {
    display: none;
  }
  .filter-option-button.active {
    background-color: ${theme.color['close-silver']};
  }

  .row-1-container {
    display: flex;
    align-items: center;
    width: max-content;
  }
  .map-1-container{
    @media only ${theme.mediaQuery.largerMobile}{
    padding-left: ${toRem(8)};
    }
  }

  .filter-option-button {
    margin: ${toRem(0)} ${toRem(8)} ${toRem(0)} ${toRem(0)};
    box-sizing: border-box;
    border: ${toRem(1)} solid ${theme.color['close-silver']};
    border-radius: ${toRem(8)};
    background-color: ${theme.color['white']};
    padding: ${toRem(8)} ${toRem(12)};
    width: max-content;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    flex-direction: row;
    height: 38px;

    @media only ${theme.mediaQuery.allMobiles} {
      margin-right: ${toRem(4)};
    }

    &:hover {
      color: ${theme.color['cod-grey']};
      font-weight: ${theme.fonts['fontWeightRegular']};
    }
    &:focus {
      border: ${toRem(1)} solid ${theme.color['black']} !important;
      color: ${theme.color['cod-grey']};
      font-weight: ${theme.fonts['fontWeightRegular']};
    }
  }

  .filter-divider {
    box-sizing: border-box;
    height: ${toRem(38)};
    width: ${toRem(1)};
    border-left: ${toRem(1)} dashed ${theme.color['close-silver']};
    margin: 0 ${toRem(8)};
  }

  .icon-check {
    margin: 0 ${toRem(2)};
  }
  `}
`;
