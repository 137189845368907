import React from 'react';
import clsx from 'clsx';
import { StyledAlert } from './index.styles';

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface HTMLAttributes<T> {
    custom_click_track_value?: string;
  }
}

const AlertNotification: React.FC<{
  isNonPropertyList?: boolean;
  setLinkClick?: () => void;
  className?: string;
  alertType?: string;
  heading?: string;
  errorMessage?: string;
  children?: React.ReactNode;
  linkText?: string;
  linkClassName?: string;
}> = ({ setLinkClick, className, alertType, heading, children, errorMessage, linkText, linkClassName, ...props }) => {
  const hasLink = linkText && errorMessage?.includes('${AlertLinkTextLabel}');
  const errorMessages = hasLink && errorMessage ? errorMessage.split('${AlertLinkTextLabel}') : [];

  return (
    <StyledAlert>
      <div className={clsx('container', className)}>
        <div className={clsx('row', 'justify-content-center')}>
          <div className={clsx('m-message-inline', 'col-10', alertType)}>
            <div className="m-message-content-wrap">
              <div className="m-message-content">
                {heading ? <div className="t-label-alt-s">{heading}</div> : ''}
                {errorMessage ? (
                  <div className="m-alert-inline-sub-content" id="m-alert-inline-sub-content">
                    {errorMessages ? errorMessages[0] : ''}
                    {errorMessage && !hasLink ? errorMessage : ''}
                    {hasLink ? (
                      <a
                        href="javascript:void(0);"
                        className={clsx('t-font-s', linkClassName)}
                        onClick={(_e): void => {
                          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                          setLinkClick ? setLinkClick() : '';
                        }}
                        {...props}
                      >
                        {linkText}
                      </a>
                    ) : (
                      ''
                    )}
                    {errorMessages[1] ?? ''}
                  </div>
                ) : (
                  ''
                )}
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledAlert>
  );
};
export default AlertNotification;
