import { getEnvProp } from '../helpers';
import { logger } from '@marriott/shared/mi-helper-utils';

export async function getMvpOffersData(resolvedUrl: string, currentLocale: string) {
  const endpoint = getEnvProp('NEXT_PUBLIC_GET_MVPOFFERS_ENDPOINT') || '';
  const { log } = logger({})('getMvpOffersData');
  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        PageURI: resolvedUrl,
        locale: currentLocale?.split('-')?.[1],
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error in mvp offers! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    log.error(`Error fetching MVP offers`, error);
    return Promise.reject(error);
  }
}
