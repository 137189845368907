import React, { FC } from 'react';
import { bannerListArray } from '../../HvmiBannerComponent.type';
import { StyledBannerListItemWrapper } from './index.styles';

export const BannerListComponent: FC<{ bannerList?: Array<bannerListArray> }> = ({ bannerList }) => (
  <StyledBannerListItemWrapper>
    <ul className="hvmi-banner-list pl-0">
      {bannerList?.map((item, index) => (
        <li className="t-label-alt-xs hvmi-banner-list-item" key={index}>
          <span className="icon-success"></span>
          {item?.hvmiBannerPointsText}
        </li>
      ))}
    </ul>
  </StyledBannerListItemWrapper>
);
