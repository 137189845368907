import { TableProps } from './Table.types';
import cssClass from 'classnames';

export const Table = (props: TableProps) => {
  const { data, tableName, styleClass, isMobileView, specialCell, tableContent } = props;

  // to get table header
  const renderTableHeader = () => {
    return (
      <tr>
        {Object.values(tableContent).map((header: string) => {
          return <th key={header}>{header}</th>;
        })}
      </tr>
    );
  };

  // to get table data
  const renderTableData = () => {
    return data.map((rowData: object, index: number) => {
      return (
        <tr key={index}>
          {Object.keys(tableContent).map((header: string) => {
            const value = rowData[header as keyof typeof rowData];
            const valueClassName = cssClass('value', {
              'special-value': value === specialCell,
            });
            return (
              <td key={value} className={valueClassName}>
                {value}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  // to render table content in mobile view
  const mobileViewTableContent = () => {
    return data.map((item: object, index: number) => {
      return (
        <div key={index} className="table-content pb-4">
          {Object.keys(tableContent).map((header: string) => {
            const value = item[header as keyof typeof item];
            const valueClassName = cssClass('value', {
              'special-value': value === specialCell,
            });
            return value ? (
              <div key={value} className="d-flex flex-column pt-4">
                <span className="header pb-2">{tableContent[header]}</span>
                <span className={valueClassName}>{value}</span>
              </div>
            ) : null;
          })}
        </div>
      );
    });
  };

  return (
    <div className={styleClass} data-component-name="m-book-Table" data-testid="book-Table">
      <h2 className="table-name">{tableName}</h2>
      {isMobileView ? (
        mobileViewTableContent()
      ) : (
        <table className="table-content" cellPadding="0">
          <thead className="header">{renderTableHeader()}</thead>
          <tbody>{renderTableData()}</tbody>
        </table>
      )}
    </div>
  );
};
