import { theme } from './theme';

export default `
    [class*="mi-icon-"]:before {
        display: inline-block;
        font-family: ${theme.fontFamily.icon};
        position: relative;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .mi-icon-book:before {
        content: "\\e957";
    }

    .mi-icon-dropdown-down:before {
        content: "\\e932";
    }

    .mi-icon-dropdown-up:before {
        content: "\\e93a";
    }

    .mi-icon-plus:before {
        content: "\\e95e";
    }

    .mi-icon-minus:before {
        content: "\\e951";
    }

    .mi-icon-check:before {
        content: "\\e92c";
    }

    .mi-icon-back-arrow-cropped:before {
        content: "\\e929";
    }

    .mi-icon-right-arrow:before {
        content: "\\e922";
    }

    .mi-icon-arrow-left:before {
        content: "\\e921";
    }

    .mi-icon-arrow-right-cropped:before {
        content: "\\e924";
    }

    .mi-icon-hour-guarantee:before {
        content: "\\e915";
    }
    .mi-icon-location:before {
        content: "\\e900";
    }
    .mi-icon-clock:before {
        content: "\\e901";
    }
    .mi-icon-nearby:before {
        content: "\\e9a5";
    }

    .mi-icon-cancel:before {
        content: "\\e92b";
    }

    .mi-icon-search:before {
        content: "\\e961";
    }

    .mi-icon-close:before {
        content: "\\e92d";
    }

    .mi-icon-filter:before {
        content: "\\e941";
    }

    .mi-icon-phone:before {
        content: "\\e95d";
    }

    .mi-icon-website:before {
        content: "\\e968";
    }

    .mi-icon-city:before {
        content: "\\e986";
    }

    .mi-icon-info:before {
        content: "\\e94a";
    }

    .mi-icon-forward-arrow:before {
        content: "\\e942";
    }

    .mi-icon-arrow-up:before {
        content: "\\e925";
    }

    .mi-icon-arrow-down:before {
        content: "\\e932";
    }

`;
