import { useEffect, useState } from 'react';

import { Button } from '../../atoms';
import { ModalUI } from '../../molecules';
import { ReservationModalProps } from './SaveReservation.types';

import { loadMIVendorForModal, loadModalData } from '../../utils';
import { TRACKING_CONSTANT, URL_CONSTANTS } from '../../constants';
const { INTERNAL_LINK } = TRACKING_CONSTANT;

const overlayParsedOnce = { value: false };

export const SaveReservationAries = ({
  url,
  btnText,
  elementId,
  customCss,
  trackingProperties,
  buttonEnabled,
  isParam,
  setOcjURL,
  showModalOnLoad,
  buttonClass = '',
}: ReservationModalProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [cssLoaded, setCss] = useState<NodeListOf<Element>>();
  const [buttonClick, setButtonClick] = useState<boolean>(false);
  const element = (
    <div className="loading" id={elementId}>
      Loading...
    </div>
  );
  useEffect(() => {
    if (isParam && setOcjURL) {
      setOpenModal(true);
    }
  }, [isParam]);

  useEffect(() => {
    if (openModal) {
      loadModalData(
        overlayParsedOnce,
        url,
        elementId,
        false,
        false,
        setOcjURL && setOcjURL(URL_CONSTANTS.EARN_WHILE_YOU_SLEEP_LINK),
        showModalOnLoad,
        buttonClick
      );
      cssLoaded?.forEach(item => window.$('head').append(item));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  loadMIVendorForModal(overlayParsedOnce);

  const closeModal = () => {
    setOpenModal(false);
    const linksToRemove = document.querySelectorAll('[data-remove="true"]');
    !cssLoaded?.length && setCss(linksToRemove);
    linksToRemove.forEach(item => item.remove());
  };
  const handleOpen = () => {
    setOpenModal(true);
    if (showModalOnLoad) setButtonClick(true);
  };

  return btnText ? (
    <div className={customCss} data-component-name="o-book-SaveReservationAries" data-testid="SaveReservationAries">
      <Button
        text={btnText}
        className={`m-button-s m-button-primary ${buttonEnabled ? '' : 'disabled'} ${buttonClass}`}
        trackingProperties={trackingProperties}
        callback={handleOpen}
        linkType={INTERNAL_LINK}
        testId="saveReservation_Button"
      />
      <ModalUI
        open={openModal}
        onClose={closeModal}
        keepMounted={showModalOnLoad ? buttonClick : true}
        children={element}
        customCss="ocj-modal"
        ariaDescribedby="save-reservation-model"
      />
    </div>
  ) : null;
};
