import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledLayoutContainer = styled.div`
  ${rtl`
    #main-content {
        .aem-container.undefined {
            .search__container {
                padding-top: ${toRem(60)};

                @media only ${theme.mediaQuery.mobileTablet}{
                    padding-top: ${toRem(66)};
                }

                @media only ${theme.mediaQuery.mobileOnly}{
                    padding-top: ${toRem(62)};
                }

                &.map-view-sticky {
                    padding-top: ${toRem(50)};
                }

                &.sticky-search-container {
                    padding-top: 0;
                }
            }
        }
    }
`}
`;
