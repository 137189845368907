import styled from 'styled-components';
import { toRem, theme } from '../../styles';

export const StyledTooltipContainer = styled.span`
  &:before {
    z-index: 51;
  }
  &.tooltip-container:hover {
    // CNWEB-3003 - This is an intentional override of the brand colors for the tooltip.
    &.m-tooltip-top::after {
      z-index: 51;
      background-color: ${theme.colors['base-10']};
      color: ${theme.colors['base-20']};
      max-width: ${toRem(210)};
    }
    &.m-tooltip-top::before {
      border-top-color: ${theme.colors['base-10']};
    }
  }
`;
