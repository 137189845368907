import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import throttle from 'lodash/throttle';

import { canUseDOM, getViewPortValue } from './helper';
import { constants } from '../constants';

export const useScreenViewport = (tabletDimension = constants.BREAKPOINT_DESKTOP) => {
  const [viewport, setViewport] = useState<'desktop' | 'mobile' | 'tablet'>('desktop');
  const viewportRef = useRef(viewport);

  const handleResize = useCallback(
    throttle(() => {
      if (canUseDOM) {
        const newViewport = getViewPortValue(tabletDimension);
        if (newViewport !== viewportRef.current) {
          viewportRef.current = newViewport;
          setViewport(newViewport);
        }
      }
    }, 200),
    []
  );

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const memoizedViewport = useMemo(() => viewport, [viewport]);

  return memoizedViewport;
};
