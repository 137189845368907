import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  height: auto;
  min-height: ${toRem(100)};
  border-radius: ${toRem(14)};
  border: ${toRem(1)} solid ${theme.colors.gallery};
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: ${toRem(30)};
  background-color: ${theme.colors.white};

  &.upcomingRes-upsell {
    margin-bottom: ${toRem(21)};
  }

  @media ${theme.mediaQuery.allMobiles} {
    flex-direction: column;
    height: auto;
    &.upcomingRes-upsell {
      margin-bottom: ${toRem(16)};
    }
  }
  @media ${theme.mediaQuery.minDesktop} {
    margin-right: ${toRem(14)};
  }
`;

export const StyledImage = styled.img`
  border-radius: ${toRem(14)} 0 0 ${toRem(14)};
  width: ${toRem(172)};
  height: auto;
  min-height: ${toRem(172)};
  aspect-ratio: 3/1;
  object-fit: cover;

  @media ${theme.mediaQuery.allTablets} {
    width: ${toRem(172)};
  }

  @media ${theme.mediaQuery.allMobiles} {
    height: ${toRem(187)};
    width: calc(100%);
    border-radius: ${toRem(14)} ${toRem(14)} 0 0;
  }
`;
export const StyledIcon = styled.div`
  border-radius: ${toRem(14)} 0 0 ${toRem(14)};
  width: ${toRem(100)};
  height: auto;
  min-height: ${toRem(100)};
  display: flex;
  justify-content: center;
  align-items: center;
  :before {
    font-size: ${toRem(76)};
  }
  @media ${theme.mediaQuery.allMobiles} {
    display: none;
  }
`;
export const StyledContent = styled.div`
  flex: 1;
  display: flex;
  font-weight: ${theme.fonts['fontWeightMedium']};
  padding: ${toRem(26)} ${toRem(32)} 0 ${toRem(24)};
  flex-direction: column;
  justify-content: space-between;
  .parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media ${theme.mediaQuery.allMobiles} {
      flex-direction: column;
      .child {
        width: 100%;
        display: contents;
        .description {
          margin-bottom: ${toRem(24)};
        }
        section,
        a {
          width: 100%;
          text-align: center;
        }
      }
    }
    @media ${theme.mediaQuery.allTablets} {
      padding-bottom: ${toRem(22)};
      padding-right: ${toRem(21)};
      .sna-btn {
        width: max-content;
      }
    }
  }

  .heading {
    margin-bottom: ${toRem(4)};
    font-size: ${theme.fonts['fontL']};
    line-height: ${theme.fonts['lineHeightL']};
  }

  .description,
  .sub-heading {
    font-weight: normal;
    font-size: ${theme.fonts['fontS']};
    color: ${theme.colors['dark-gray-3']};
    line-height: ${theme.fonts['lineHeightM']};
  }

  .description {
    margin-bottom: ${toRem(0)};
  }

  .upsell-room-details {
    font-size: ${toRem(12)};
    line-height: ${toRem(18)};
    font-family: ${theme.fontFamily.swiss};
    color: ${theme.colors['light-gray-10']};
    border-bottom: ${toRem(1)} solid ${theme.colors['light-gray-10']};
  }

  .bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: ${toRem(24)};
    padding-top: ${toRem(16)};

    .price-block {
      margin-right: ${toRem(10)};

      .price-text {
        font-size: ${theme.fonts['fontXxl']};
      }

      .per-night-text {
        color: ${theme.colors['base-10']};
        font-size: ${theme.fonts['fontXs']};
        line-height: ${theme.fonts['lineHeightXs']};
      }
    }

    .button {
      text-align: center;
      min-height: ${toRem(32)};
      height: auto;
      align-content: center;
      padding-top: 0;
    }
  }

  @media ${theme.mediaQuery.allMobiles} {
    padding: 0;
    padding: ${toRem(24)};

    .bottom-section {
      margin-top: ${toRem(24)};
      .button {
        flex: 1;
        height: auto;
        min-height: ${toRem(32)};
        padding-bottom: ${toRem(6)};
      }
      .price-block {
        margin-top: ${toRem(-8)};
      }
    }
  }
`;
