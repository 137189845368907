/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { inspect } from 'util';
import { RequestProps } from './dataLayer/index.types';
const { INTERCEPTOR_HOST_URL } = process.env;
const { INTERCEPTOR_URL } = process.env;
const { NEXT_PUBLIC_DEFAULT_LANG } = process.env;
export const interceptor = async (req: { headers: RequestProps; originalUrl: string }, resolvedUrl: string) => {
  let interceptorHeader: any = {};
  const { log } = global.loggerInstance('Interceptor');
  const headers: any = {
    Cookie: req.headers['cookie'],
    'X-Host': req?.headers?.['x-host'],
    'X-Akamai-Edgescape': req?.headers?.['x-akamai-edgescape'] ?? null,
    'X-Akamai-Device-Characteristics': req?.headers?.['x-akamai-device-characteristics'] ?? null,
    'Content-Type': 'application/json',
    'accept-language': req?.headers?.['accept-language'] || NEXT_PUBLIC_DEFAULT_LANG,
  };
  log.debug(`[STEP 2] Preprocessor Interceptor headers: ${inspect(headers)}`);
  const url = `${INTERCEPTOR_HOST_URL}${INTERCEPTOR_URL}`;
  log.debug(`[STEP 2] Preprocessor Interceptor API url: ${url}`);
  log.debug(`[STEP 2] Preprocessor Interceptor req.originalUrl: ${req.originalUrl}`);
  log.debug(`[STEP 2] Preprocessor Interceptor resolvedUrl.split('?')[0]: ${resolvedUrl.split('?')[0]}`);
  const originalRequestPath = req.originalUrl ?? resolvedUrl;
  log.debug(`[STEP 2] Preprocessor Interceptor originalRequestPath: ${originalRequestPath}`);
  let response;
  try {
    response = await axios.post(
      url,
      {
        originalRequestPath,
      },
      { headers }
    );
    interceptorHeader = response?.headers;
  } catch (e) {
    log.error(`Interceptor API call failed: ${e}`);
    throw new Error('Interceptor call failed');
  }
  return { response, interceptorHeader };
};
