/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { dataLayerKeys, sessionKeys } from './config';
import { datalayerKeysProps, datalayerProps, sessionDataProps } from './index.types';

export const getServerTargetPayload = (
  datalayer: datalayerProps,
  sessionData: sessionDataProps,
  is_desktop_device: boolean
) => {
  const payload: Record<string, string> = { is_desktop_device };
  dataLayerKeys.forEach((obj: datalayerKeysProps) => {
    if (datalayer?.[obj.key] !== undefined) {
      payload[obj.name] = datalayer?.[obj.key];
    }
  });
  sessionKeys.forEach(obj => {
    if (sessionData?.[obj.key] !== undefined) {
      payload[obj.name] = sessionData?.[obj.key];
    }
  });
  return payload;
};
