import { Label } from '@uiw/react-baidu-map';
import { BaiduMapMarkerProps } from './BaiduMapMarker.types';
import { FC } from 'react';

export const BaiduMapMarker: FC<BaiduMapMarkerProps> = ({
  position,
  label,
  className = '',
  onClick,
  children,
  offset,
  ...props
}) => (
  <Label
    position={position}
    style={{ border: 'none', backgroundColor: 'none', transform: 'translate(-50%, -100%)' }}
    onClick={onClick}
    offset={offset}
  >
    <span className={`m-map-pin ${className}`} {...props}>
      {label}
      {children}
    </span>
  </Label>
);
