import { URL_CONSTANTS, VITAL_UXL_CALLS_COMPONENT } from '../constants';
import { getProcessEnvs } from './helper';

const processEnv = getProcessEnvs();

/**
 * Constructs an error string from the provided error details.
 *
 * @param {Object} params - The parameters for the error string.
 * @param {string} params.sessionId - The unique identifier for the session.
 * @param {string} params.errorSource - The source where the error originated.
 * @param {string} params.errorCode - The specific code representing the error type.
 * @param {string} params.errorComponent - The component where the error occurred.
 * @param {string} params.errorDetail - Additional details about the error.
 * @returns {string} - The constructed error string.
 */
export const constructErrorString = ({
  // sessionId,
  errorSource,
  errorCode,
  errorComponent,
  errorDetail,
}: {
  // sessionId: string; // The unique identifier for the session.
  errorSource: string; // The source where the error originated.
  errorCode: string; // The specific code representing the error type.
  errorComponent: string; // The component where the error occurred.
  errorDetail: string; // Additional details about the error.
}): string => {
  return [errorSource, errorCode, errorComponent, errorDetail].filter(_ => Boolean(_)).join('|');
};

// SESSION_ID|Error Source|Error Code|Error Component|Error Details optional
// SESSION_ID|UXL_ERROR|PhoenixBookHotelHeader|HotelHeader|Error detail(optional)
// SESSION_ID|VALIDATION_ERROR|CreditCardNumber is required|CreditCard|NA
// SESSION_ID|API_ERROR|SubmitReviewDetails|CreditCard|Error details(optional)
// SESSION_ID|COMPONENT_ERROR|HotelHeader|NA|Stack Track as string.

// Define an interface for the error detail object.
export interface bookErrorObjectType {
  errorSource: 'UXL_ERROR' | 'API_ERROR' | 'COMPONENT_ERROR' | 'VALIDATION_ERROR' | 'UNKNOWN_ERROR'; // - The source where the error originated.
  errorCode: string; // - The specific code representing the error type.
  errorComponent: string; // - The component where the error occurred.
  errorDetail: string; // - Additional details about the error.
}

/**
 * Logs application errors to the console based on the provided error details.
 *
 * @param {Object[]} errorDetails - An array of error detail objects.
 * @param {string} errorDetails[].errorSource - The source where the error originated.
 * @param {string} errorDetails[].errorCode - The specific code representing the error type.
 * @param {string} errorDetails[].errorComponent - The component where the error occurred.
 * @param {string} errorDetails[].errorDetail - Additional details about the error.
 */

export const logBookApplicationError = (errorDetails: bookErrorObjectType[]) => {
  const sessionId = window?.sessionDataClient?.sessionToken;

  // Iterate through each error detail object and log appropriate error messages.
  const errorMessages: string[] = [];
  errorDetails?.forEach(_ => {
    const errorMessage = constructErrorString({
      errorSource: _.errorSource,
      errorCode: _.errorCode,
      errorComponent: _.errorComponent,
      errorDetail: _.errorDetail,
    });

    errorMessages.push(errorMessage);
  });
  if (window.dataLayer) {
    window.dataLayer['error_info'] = errorMessages.join(';');
  }
  console.error(`[${sessionId}]: ${errorMessages.join(';')}`);
};

export const getComponentNameFromUXL = (operationName: string) => {
  return VITAL_UXL_CALLS_COMPONENT[operationName] || operationName;
};

/**
 * Retrieves the first error message from a form errors object.
 *
 * @param formErrors - An object where each key is a form field name and its value is an object containing an error message.
 * @returns The error message of the first form field that has an error, or undefined if there are no errors.
 */
export const getFormFirstErrorMessage = (formErrors: Record<string, { message: string }>): string | undefined => {
  return formErrors[Object.keys(formErrors || {})[0]]?.message;
};

/**
 * Represents an error related to logging in an API.
 */
export class LogAPIError {
  /**
   * The source where the error originated.
   */
  errorSource: 'UXL_ERROR' | 'API_ERROR' | 'COMPONENT_ERROR' | 'VALIDATION_ERROR' | 'UNKNOWN_ERROR';

  /**
   * The specific code representing the error type.
   */
  errorCode: string;

  /**
   * The component where the error occurred.
   */
  errorComponent: string;

  /**
   * Additional details about the error.
   */
  errorDetail: string;

  /**
   * Creates an instance of LogAPIError.
   * @param errorDetail - Additional details about the error.
   */
  constructor(errorDetail: string) {
    this.errorDetail = errorDetail;
    this.errorCode = ' ';
    this.errorComponent = ' ';
    this.errorSource = 'API_ERROR';
  }

  /**
   * Logs an error related to RLM navigation.
   * @param modifyFlow - Whether the error occurred in the modify flow.
   */
  logRLMNavError(modifyFlow: boolean) {
    this.errorComponent = 'Select Rate';
    this.errorCode = modifyFlow ? URL_CONSTANTS.MODIFY_SELECT_RATE : URL_CONSTANTS.RLM_NAV_API_URL;
    logBookApplicationError([this]);
  }

  /**
   * Logs an error related to booking now.
   * @param isRrdPage - Whether the error occurred on the RRD page.
   */
  logBookNowError(isRrdPage: boolean) {
    this.errorComponent = 'Booking Acknowledgement';
    this.errorCode = isRrdPage ? processEnv['NEXT_PUBLIC_BOOK_NOW_URL'] : processEnv['NEXT_PUBLIC_GUEST_BOOK_NOW_URL'];
    logBookApplicationError([this]);
  }

  /**
   * Logs an error related to reservation lookup.
   */
  logResLookupError() {
    this.errorComponent = 'LookupReservation';
    this.errorCode = URL_CONSTANTS.SUBMIT_LOOKUP_RESERVATION;
    logBookApplicationError([this]);
  }
}
