import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { baseVariables } from '@marriott/mi-ui-library';

export const StyledRegionItem = styled.div`
  ${rtl`
    .region-item-link {
      line-height: ${baseVariables.font['lineHeightXl']};
      &:hover {
        font-weight: ${baseVariables.font['fontWeightRegular']};
        text-decoration: underline;
      }
    }
  `}
`;
