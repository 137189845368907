import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledMapContainerDiv = styled.div`
  ${rtl`
  &.map-loader-full {
    height: ${toRem(500)};
    background-color: ${theme.color['light-gray-3']};
    width: 100%;

    .loader-container {
      height: 100%;
    }
  }

  .map-width-full {
    width:100vw !important;
  }
  position: relative;
  width:calc(100vw - 24rem);
  @media only ${theme.mediaQuery.tabletMax} {
    width:100vw;
   }
  @media only ${theme.mediaQuery.allMobiles} {
    width:100vw;
  }

  .list-button-container {
    position: fixed;
    top: ${toRem(309)};
    right: ${toRem(16)};
    @media only ${theme.mediaQuery.allTablets} {
      position: absolute;
      top: ${toRem(16)};
      right: ${toRem(32)};
    }
    z-index:1;
    .map-button {
      border-radius: ${toRem(50)};
      height: ${toRem(40)};
      font-size: ${toRem(16)};
      font-weight: ${theme.fonts['fontWeightMedium']};
      box-shadow: ${toRem(0)} ${toRem(5)} ${toRem(16)} rgba(0, 0, 0, 0.5);
      display: flex;
      font-family: ${theme.fontFamily.swiss};
      align-items: center;
      justify-content: center;
      padding: ${toRem(9)} ${toRem(21)};
      background-color: ${theme.color['white']};
      .icon {
        margin-right: ${toRem(6)};
        height: auto;
        width: auto;
        font-size: ${toRem(20)};
        font-style: normal;
      }
      .icon-map {
        margin-right: ${toRem(5)};
      }
      &:focus {
        border: ${toRem(1)} solid ${theme.color['black']};
      }
    }
  }

  .gm-svpc img {
    height: 20px !important;
    box-shadow: 0 0 0 rgb(0 0 0 / 0.2);
  }



  .gm-control-active {
        &[title='Zoom in'] {
    border-radius: 50% !important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    height: ${toRem(30)} !important;
    width: ${toRem(30)} !important;
    margin-left: ${toRem(2)} !important;
    margin-bottom: ${toRem(7)} !important;
        }
    &[title='Zoom out'] {
      border-radius: 50% !important;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    height: ${toRem(30)} !important;
    width: ${toRem(30)} !important;
    margin-left: ${toRem(2)} !important;
    margin-bottom: ${toRem(6)} !important;
      top: ${toRem(2)} !important;
    }
  }

.gm-control-active>img {
    width: ${toRem(12)} !important;
}


 div[data-control-height="81"]>div{
   border-radius: ${toRem(25)} !important;
    width: ${toRem(32)} !important;
    height:${toRem(69)} !important;
     box-shadow: 0 4px 20px 0 rgba(0,0,0,0.6);
 }
  div[data-control-height="81"]>div>div{
height:0px !important
 }
  .gm-svpc {
    border-radius: 50% !important;
    height: ${toRem(30)} !important;
    width: ${toRem(30)} !important;
     box-shadow: 0 4px 20px 0 rgba(0,0,0,0.6);
    background-color: #fff !important;
    margin-top:${toRem(25)};
  }

  .gm-ui-hover-effect {
    display: none !important;
  }

  @media only screen and (max-width: 1000px) {
    .gm-svpc,
    .gm-svpc ~ .gmnoprint,
    .gm-control-active.gm-fullscreen-control {
      display: none;
    }
  }
  @media only ${theme.mediaQuery.allMobiles} {
    .list-button-container{
      position:fixed !important;
      top: unset;
      right:unset;
      width:100%;
      bottom:env(safe-area-inset-bottom);
    }
    .map-button{
      margin-left:auto;
      margin-right:auto;
      margin-bottom:${toRem(24)};
    }
  }
  `}
`;
