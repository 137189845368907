import { FC, useState } from 'react';
import cssClass from 'classnames';

import { ImageCard } from '../ImageCard';
import { OffersCard } from '../OfferCard';
import { config } from './cardConfig';
import { CarouselContainerProps } from './CarouselContainer.types';
import { StyledOfferCardList } from './CarouselContainer.styles';

import { useSplideJS } from '../../utils';
import { constants } from '../../constants';

export const CarouselContainer: FC<CarouselContainerProps> = props => {
  const {
    pageData,
    cardContent,
    previousSlideButtonLabel,
    nextSlideButtonLabel,
    handleImageClick,
    isSingleImage,
    carouselImageSize,
    isImageCarousel,
    destoryOnUnmount,
  } = props;

  const { SLIDE_PREV_BUTTON_LABEL, SLIDE_NEXT_BUTTON_LABEL, ENTER_KEY, HIGH, LOW } = constants;

  const [screenType, setScreenType] = useState<'mobile' | 'tablet' | 'desktop'>('desktop');
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const cardContentLength = cardContent.length;

  useSplideJS({
    setScreenType,
    pageData: { ...pageData, cardContentLength: cardContentLength },
    config,
    setActiveIndex,
    destoryOnUnmount: destoryOnUnmount,
  });

  const paginationArray = [...Array(Math.ceil(cardContent.length / 2))];

  // Triggers slide to next card for extra arrow control added for desktop view only
  const nextSlide = () => {
    document.querySelector<HTMLInputElement>('.splide__arrow--next')?.click();
  };

  // Triggers slide to previous card for extra arrow control added for desktop view only
  const prevSlide = () => {
    document.querySelector<HTMLInputElement>('.splide__arrow--prev')?.click();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onKeyPress = (e: any) => {
    if (e.keyCode === ENTER_KEY && handleImageClick) {
      handleImageClick();
    }
  };

  const imgCarouselClassName = cssClass({
    image_carousel: isImageCarousel,
  });

  return (
    <StyledOfferCardList
      data-component-name="m-book-CarouselContainer"
      data-testid="book-CarouselContainer"
      theme={carouselImageSize}
    >
      <div
        id={`id${pageData.componentId}`}
        className={`${imgCarouselClassName} splide ${isSingleImage ? 'single-image' : ''}`}
      >
        <div className="splide__arrows">
          <button className="splide__arrow splide__arrow--prev">
            <div className="arrow-container d-flex justify-content-center align-items-center">
              <span
                className={
                  (screenType === 'mobile' || screenType === 'tablet') && !isImageCarousel
                    ? 'icon-back-arrow'
                    : 'icon-arrow-left'
                }
              />
            </div>
          </button>
          <button className="splide__arrow splide__arrow--next">
            <div className="arrow-container d-flex justify-content-center align-items-center">
              <span
                className={
                  (screenType === 'mobile' || screenType === 'tablet') && !isImageCarousel
                    ? 'icon-forward-arrow'
                    : 'icon-arrow-right'
                }
              />
            </div>
          </button>
          {screenType === 'desktop' && cardContent?.length > 4 && (
            <div className="custom_splide_arrow">
              <button
                className="custom_button--prev"
                onClick={prevSlide}
                aria-label={previousSlideButtonLabel ?? SLIDE_PREV_BUTTON_LABEL}
                disabled={activeIndex < 1}
              >
                <div className="arrow-container d-flex justify-content-center align-items-center">
                  <span className={'icon-back-arrow'} />
                </div>
              </button>
              <button
                className="custom_button--next"
                onClick={nextSlide}
                aria-label={nextSlideButtonLabel ?? SLIDE_NEXT_BUTTON_LABEL}
                disabled={activeIndex >= cardContent?.length - 4}
              >
                <div className="arrow-container d-flex justify-content-center align-items-center">
                  <span className={'icon-forward-arrow'} />
                </div>
              </button>
            </div>
          )}
        </div>
        <div className="splide__track" tabIndex={0} onKeyDown={onKeyPress} role="button">
          <ul className="splide__list" aria-atomic="true" aria-live="off">
            {cardContent?.map((item, index) => (
              <li className="splide__slide" key={index} onClick={handleImageClick}>
                {isImageCarousel ? (
                  <ImageCard
                    offerCardImage={item?.offerCardImage}
                    offersId={item?.offersId}
                    carouselImageSize={carouselImageSize}
                    fetchpriority={index === 0 ? HIGH : LOW}
                  />
                ) : (
                  <OffersCard
                    offerCardImage={item?.offerCardImage}
                    offerCardTitle={item?.offerCardTitle}
                    offerCardDescription={item?.offerCardDescription}
                    ctaLabel={item?.ctaLabel}
                    ctaLink={item?.ctaLink}
                    offersId={item?.offersId}
                    offerCardDealTag={item?.offerCardDealTag}
                    target={item?.target}
                    trackingProperties={item?.trackingProperties}
                    altText={item?.altText}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
        {screenType === 'mobile' ? (
          <ul
            className="splide__pagination splide__pagination--ltr"
            role="tablist"
            aria-label={config.ARIA_LABEL_SLIDE}
          >
            {paginationArray.map((_val, index) => (
              <li role="presentation" key={index}>
                <button
                  className={`splide__pagination__page ${Math.floor(activeIndex / 2) === index ? 'is-active' : ''}`}
                  type="button"
                  role="tab"
                  aria-controls="splide01-slide01"
                  aria-label={`Go to page ${index + 1}`}
                  aria-selected="true"
                ></button>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </StyledOfferCardList>
  );
};
