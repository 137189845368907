import { FC } from 'react';
import cssClass from 'classnames';
import DOMPurify, { sanitize } from 'isomorphic-dompurify';

import { ContactInformationProps } from './ContactInformation.types';
import { StyledContactInformation } from './contactInformation.styles';
import { useCheckBreakpoint } from '../../utils';

export const ContactInformation: FC<ContactInformationProps> = props => {
  const isViewportL = useCheckBreakpoint('viewportL');
  const isViewportM = useCheckBreakpoint('viewportM');
  const { inModal = true, inCancelModal = false, contactInformationDesktop, contactInformationMobile } = props;
  const containerClassName = cssClass({ 'p-4': inCancelModal });
  const getContactInfomation = () => {
    return isViewportL || isViewportM ? contactInformationDesktop : contactInformationMobile;
  };
  const contactInfo = DOMPurify.sanitize(getContactInfomation() || '', { RETURN_DOM: true });
  if (!contactInfo) return null;
  return (
    <StyledContactInformation
      data-component-name="m-book-ContactInformation"
      data-testid="book-ContactInformation"
      isRrdPage={!inModal}
      className={containerClassName}
    >
      <div dangerouslySetInnerHTML={{ __html: sanitize(contactInfo) }} />
    </StyledContactInformation>
  );
};
