import styled from 'styled-components';
import { toRem } from '../../styles-3.0/utils';
import { theme } from '../../styles-3.0/theme';

const getListContainerHeight = (columnLength: number, itemLength: number): string =>
  `calc(44px * ${Math.ceil(itemLength / columnLength)})`;

export const StyledRecentListDiv = styled.div`
  // margin-left: ${toRem(24)} !important;
  margin-bottom: ${toRem(20)};
  padding: 0 ${toRem(16)};
  @media ${theme.mediaQuery.tablet} {
    padding: 0;
  }
  @media ${theme.mediaQuery.mobileOnly} {
    margin-bottom: ${toRem(12)};
  }
  h3 {
    margin: 0 0 ${toRem(12)} 0;
    color: ${theme.color['dark-gray-13']};
    text-transform: uppercase;
    letter-spacing: ${toRem(1.3)};
    font-weight: 500;
  }
  @media ${theme.mediaQuery.tablet} {
    margin-left: ${toRem(24)};
    margin-right: ${toRem(24)};
  }
  @media ${theme.mediaQuery.desktop} {
    margin-left: ${toRem(24)} !important;
    margin-right: ${toRem(32)};
  }
  @media ${theme.mediaQuery.largeDesktop} {
    margin-left: ${toRem(40)};
    margin-right: ${toRem(40)};
  }
  &.inline-list {
    @media ${theme.mediaQuery.mobileOnly} {
      margin-left: ${toRem(16)};
      margin-right: ${toRem(16)};
      padding: 0;
    }
    ul {
      margin: 0 ${toRem(-10)};
      li {
        display: inline-block;
        width: 100%;
        a {
          padding: ${toRem(12)} 0;
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            white-space: nowrap;
            padding-inline-start: ${toRem(40)};
            &[class^='icon-']::before {
              inset-inline-start: ${toRem(13)};
            }
          }
          @media ${theme.mediaQuery.desktop} {
            padding-inline-end: ${toRem(5)};
          }
        }
        @media ${theme.mediaQuery.tablet} {
          width: calc(100% / 4);
        }
        @media ${theme.mediaQuery.desktop} {
          width: calc(100% / 5);
        }
      }
    }
    &.domestic-dest-list {
      border-bottom: ${toRem(1)} solid ${theme.color['light-gray-6']};
      padding-bottom: ${toRem(15)};
      margin-bottom: ${toRem(24)};
      @media ${theme.mediaQuery.tablet} {
        border-color: ${theme.color['gallery']};
      }
      ul {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: ${({ theme: { itemLength } }): string => getListContainerHeight(2, itemLength)};
        @media ${theme.mediaQuery.tablet} {
          max-height: ${({ theme: { itemLength } }): string => getListContainerHeight(4, itemLength)};
        }
        @media ${theme.mediaQuery.desktop} {
          max-height: ${({ theme: { itemLength } }): string => getListContainerHeight(5, itemLength)};
        }
        li {
          @media ${theme.mediaQuery.mobileOnly} {
            width: calc(100% / 2);
          }
        }
      }
    }
  }
  ul {
    list-style: none;
    margin: 0 ${toRem(-16)};
    @media ${theme.mediaQuery.tablet} {
      margin: 0 ${toRem(-24)};
    }
    @media ${theme.mediaQuery.desktop} {
      margin: 0 ${toRem(-32)};
    }
    @media ${theme.mediaQuery.largeDesktop} {
      margin: 0 ${toRem(-40)};
    }
    padding: 0;
    li {
      a {
        position: relative;
        padding: ${toRem(12)} 0;
        padding-inline-start: ${toRem(40)};
        padding-inline-end: ${toRem(24)};
        @media ${theme.mediaQuery.tablet} {
          padding-inline-start: ${toRem(56)};
        }
        @media ${theme.mediaQuery.desktop} {
          padding: ${toRem(12)} 0;
          padding-inline-start: ${toRem(60)};
          padding-inline-end: ${toRem(32)};
        }
        @media ${theme.mediaQuery.largeDesktop} {
          padding: ${toRem(12)} 0;
          padding-inline-start: ${toRem(68)};
          padding-inline-end: ${toRem(40)};
        }
        display: block;
        text-decoration: none;
        font-family: ${theme.fontFamily.swiss};
        font-size: ${toRem(16)};
        line-height: ${toRem(20)};
        color: ${theme.color['dark-gray-1']};
        span {
          position: relative;
          word-wrap: break-word;
        }
        [class^='icon-']::before {
          width: ${toRem(16)};
          height: ${toRem(16)};
          color: ${theme.color['light-orange-2']};
          position: absolute;
          inset-inline-start: ${toRem(-24)};
          @media ${theme.mediaQuery.tablet} {
            inset-inline-start: ${toRem(-32)};
          }
          @media ${theme.mediaQuery.desktop} {
            inset-inline-start: ${toRem(-27)};
          }
        }
        [class^='icon-'] {
          color: ${theme.color['dark-gray-1']};
        }
        :hover,
        :focus {
          background-color: ${theme.color['light-gray-3']};
          font-weight: ${theme.fonts['fontWeightMedium']};
        }
      }
    }
  }
`;
