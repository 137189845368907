/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { useHotelDirectoryStore } from '../../../modules/store/hotelDirectoryStore';
import { StyledRegionCardWrapper } from './RegionCardsWrapper.styles';
import { HotelDirectoryDataType } from '../HotelDirectoryMap/HotelDirectoryMap.types';
import { useCheckBreakpoint } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { RegionCardSkeletonLoader } from '../../../molecules/RegionCard/RegionCardSkeletonLoader';
import RegionCard from '../../../molecules/RegionCard/RegionCard';

// Component to wrap all the region cards
export const RegionCardsWrapper = ({ setHotelDirectoryCounter, hotelDirectoryCounter, isDataLoaded }: any) => {
  const leisureRegionList = useHotelDirectoryStore(
    (state: { leisureRegionList: any }) => state.leisureRegionList
  ).leisureRegionList;

  const isViewportM = useCheckBreakpoint('viewportM'); // check if viewport is Tablet and above
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const areAllCountsZero = leisureRegionList.every((reg: { count: number }) => reg.count === 0);
    if (areAllCountsZero && !isDataLoaded) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [leisureRegionList, isDataLoaded]);

  // Function creates the markup for each column in the grid
  const createMarkup = () => {
    const results: any = [];
    results.push(
      <div className={clsx('col-12', 'p-0', isViewportM ? '' : 'px-3')}>
        {leisureRegionList?.map((region: HotelDirectoryDataType, index: number) => (
          <RegionCard
            region={region}
            name={region.label}
            code={region.code}
            count={region.count}
            key={region.code + index}
            lat={region.latitude}
            lon={region.longitude}
            customLabel={region.customLabel}
            setHotelDirectoryCounter={setHotelDirectoryCounter}
            hotelDirectoryCounter={hotelDirectoryCounter}
          ></RegionCard>
        ))}
      </div>
    );
    // }
    return results;
  };

  return (
    <StyledRegionCardWrapper>
      {isLoading ? (
        <div className={clsx('region-card-wrapper')}>
          <RegionCardSkeletonLoader type="header-only"></RegionCardSkeletonLoader>
          {[...Array(10)].map(() => (
            <RegionCardSkeletonLoader type=""></RegionCardSkeletonLoader>
          ))}
        </div>
      ) : (
        <div className={clsx('row', 'region-card-wrapper')}>{leisureRegionList?.length ? createMarkup() : ''}</div>
      )}
    </StyledRegionCardWrapper>
  );
};
