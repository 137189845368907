// contains the cards configuration of all variations
export const config = {
  ARIA_LABEL_SLIDE: 'Select a slide to show',
  // configuration for 2 card variation
  layeredSquare_2cards: {
    type: 'slide',
    // number of card shown at a time in different devices
    perPage: {
      mobile: 1,
      tablet: 2,
      desktop: 2,
    },
    // height of the card
    height: {
      mobile: '274px',
      tablet: '193px',
      desktop: '312px',
    },
    // width of card
    width: {
      mobile: '274px',
      tablet: '344px',
      desktop: '554px',
    },
    // gap between two cards
    gap: {
      mobile: '16px',
      tablet: '16px',
      desktop: '18px',
    },
    // width occupied by the carousel
    carousalWidth: {
      mobile: '100%',
      tablet: '100%',
      desktop: '100%',
    },
    // class name that will implement on the title of carousel
    titleClass: 't-title-inverse-s',
    // class name for internal card margin
    marginClass: 'two-card-margin',
    // type of image to be used from data
    imageType: {
      mobile: 'Square',
      tablet: 'Wide',
      desktop: 'Wide',
    },
  },
  // configuration for 1 card variation
  layeredSquare_1cards: {
    type: 'loop',
    // number of card shown at a time in different devices
    perPage: {
      mobile: 1,
      tablet: 1,
      desktop: 1,
    },
    // height of the card
    height: {
      mobile: 'auto',
      tablet: 'auto',
      desktop: 'auto',
    },
    // width of card
    width: {
      mobile: '100%',
      tablet: '100%',
      desktop: '100%',
    },
    // gap between two cards
    gap: {
      mobile: '16px',
      tablet: '16px',
      desktop: '18px',
    },
    // width occupied by the carousel
    carousalWidth: {
      mobile: '100%',
      tablet: '100%',
      desktop: '100%',
    },
    // class name that will implement on the title of carousel
    titleClass: 't-title-inverse-s',
    // class name for internal card margin
    marginClass: 'two-card-margin',
    // type of image to be used from data
    imageType: {
      mobile: 'Wide',
      tablet: 'Wide',
      desktop: 'Wide',
    },
  },
  // configuration for 3 card variation
  layeredSquare_3cards: {
    type: 'slide',
    perPage: {
      mobile: 1,
      tablet: 2,
      desktop: 3,
    },
    height: {
      mobile: '274px',
      tablet: '284px',
      desktop: '365px',
    },
    width: {
      mobile: '274px',
      tablet: '284px',
      desktop: '365px',
    },
    gap: {
      mobile: '16px',
      tablet: '16px',
      desktop: '16px',
    },
    carousalWidth: {
      mobile: '100%',
      tablet: '100%',
      desktop: '100%',
    },
    titleClass: 't-subtitle-inverse-xl',
    marginClass: 'three-card-margin',
    imageType: {
      mobile: 'Square',
      tablet: 'Square',
      desktop: 'Square',
    },
  },
  // configuration for 4 card variation
  layeredSquare_4cards: {
    type: 'slide',
    perPage: {
      mobile: 1,
      tablet: 2,
      desktop: 4,
    },
    height: {
      mobile: '274px',
      tablet: '284px',
      desktop: '270px',
    },
    width: {
      mobile: '274px',
      tablet: '284px',
      desktop: '270px',
    },
    gap: {
      mobile: '15px',
      tablet: '15px',
      desktop: '15px',
    },
    carousalWidth: {
      mobile: '100%',
      tablet: '100%',
      desktop: '100%',
    },
    titleClass: 't-subtitle-inverse-xl',
    marginClass: 'four-card-margin',
    imageType: {
      mobile: 'Square',
      tablet: 'Square',
      desktop: 'Square',
    },
  },
  // configuration for 2 card variation where title is on right of the carousel
  rightAside_layeredSquare_2cards: {
    type: 'slide',
    perPage: {
      mobile: 1,
      tablet: 2,
      desktop: 2,
    },
    height: {
      mobile: '274px',
      tablet: '344px',
      desktop: '365px',
    },
    width: {
      mobile: '275px',
      tablet: '345px',
      desktop: '366px',
    },
    gap: {
      mobile: '15px',
      tablet: '16px',
      desktop: '16px',
    },
    carousalWidth: {
      mobile: '100%',
      tablet: '100%',
      desktop: '764px',
    },
    titleClass: 't-subtitle-inverse-xl',
    marginClass: 'two-card-margin',
    imageType: {
      mobile: 'Square',
      tablet: 'Square',
      desktop: 'Square',
    },
    staticImage: {
      mobile: '/marriottassets/marriott/Stock/stock-woman-sunset-1069700-sq.jpg',
      tablet: '/marriottassets/marriott/Stock/stock-woman-sunset-1069700-sq.jpg',
      desktop: '/marriottassets/marriott/Stock/stock-woman-sunset-1069700-sq.jpg',
    },
  },
};
