import { forwardRef, useEffect } from 'react';
import cssClass from 'classnames';

import { ButtonProps } from './Button.types';
import { StyledContainer } from './Button.styles';

import { getClickTrackValue, trackImpression } from '../../utils';
import { constants } from '../../constants';
/**
 * A Button atom that renders with either text or a combination of text and SVG.
 * <Button label="Click me"></Button>
 * @returns {HTMLButtonElement} <button>Click me</button>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Button = forwardRef((props: ButtonProps, ref?: any) => {
  const {
    type = 'button',
    text,
    style,
    className,
    callback,
    isLink,
    href,
    target,
    rel,
    children,
    trackingProperties,
    linkType = 'Internal',
    linkAriaLabel = '',
    externalArrow = true,
    buttonClassName,
    isDisabled = false,
    testId = '',
    isLoading = false,
    loadingClassName = '',
    isDisabledWhenLoading = false,
  } = props;

  const { COBRAND_NON_POINTS_MATH, TARGET_BLANK } = constants;

  useEffect(() => {
    trackImpression(trackingProperties || {}, text || '');
  }, []);

  const ariaLabel =
    target === TARGET_BLANK ? `Link to ${linkAriaLabel || href} opens in a new window` : `${linkAriaLabel}`;

  /*Generating css classname from classname module*/
  /*"track-completed" class is added to escape from overwrite of clientlib.js */
  const linkClassName = cssClass(`${className} custom_click_track track-completed`, {
    'upside-arrow': externalArrow && target === TARGET_BLANK,
  });
  const btnClassName = cssClass(`${className} custom_click_track track-completed`, { disabled: isDisabled });
  const titleClassName = cssClass({ 'hide-title': isLoading });
  const renderLink = () => {
    return (
      <StyledContainer data-component-name="a-book-Button" data-testid="book-Button" className={buttonClassName}>
        <a
          href={href}
          onClick={callback}
          target={target}
          className={linkClassName}
          rel={rel}
          data-tracking-content-position={trackingProperties?.trackingContentPosition}
          data-tracking-offer-type={trackingProperties?.trackingOfferType}
          data-at-c-ce-var48={trackingProperties?.atCCeVar48}
          data-tracking-description={trackingProperties?.trackingDescription}
          data-tracking-tag={trackingProperties?.trackingTag}
          data-is-click-track={trackingProperties?.clickTrack}
          data-is-impression-track={trackingProperties?.impressionTrack}
          data-is-impression-count={trackingProperties?.impressionCount}
          data-custom_click_track_value={getClickTrackValue(linkType, trackingProperties)}
          data-is-co-brand={trackingProperties?.merchandisingCategory === COBRAND_NON_POINTS_MATH}
          aria-label={ariaLabel}
        >
          {children}
        </a>
      </StyledContainer>
    );
  };
  return isLink ? (
    renderLink()
  ) : (
    <StyledContainer className={buttonClassName}>
      <button
        disabled={isDisabled}
        ref={ref}
        type={type}
        style={style}
        className={`${btnClassName} btn-container`}
        onClick={callback}
        data-testid={testId}
        data-tracking-content-position={trackingProperties?.trackingContentPosition}
        data-tracking-offer-type={trackingProperties?.trackingOfferType}
        data-at-c-ce-var48={trackingProperties?.atCCeVar48}
        data-tracking-description={trackingProperties?.trackingDescription}
        data-tracking-tag={trackingProperties?.trackingTag}
        data-is-click-track={trackingProperties?.clickTrack}
        data-is-impression-track={trackingProperties?.impressionTrack}
        data-is-impression-count={trackingProperties?.impressionCount}
        data-is-co-brand={trackingProperties?.merchandisingCategory === COBRAND_NON_POINTS_MATH}
        data-custom_click_track_value={getClickTrackValue(linkType, trackingProperties)}
        {...(isDisabledWhenLoading &&
          isLoading && { disabled: true, className: `${btnClassName} btn-container disabled` })}
        {...(ariaLabel && { 'aria-label': ariaLabel })}
      >
        <span className={titleClassName}>{text}</span>
        {isLoading && (
          <div>
            <span className={`m-spinner mx-1 ${loadingClassName}`} role="status" />
          </div>
        )}
      </button>
    </StyledContainer>
  );
});
