import { FC, useState, useEffect } from 'react';
import cssClass from 'classnames';

import { Dropdown, Input } from '../../atoms';
import { CustomModal } from '../../molecules';
import { CountryCodeList, SelectOptions, UserMobileNumberProps } from './UserMobileNumber.types';
import { StyledSection, StyledModalDiv } from './UserMobileNumber.styles';
import { SessionMap } from './UserMobileNumber.schema';
import smsCountryCodesGql from './queries/smsCountryCodes.graphql';
import { GI_FIELD_MAX_LENGTH } from '../MemberInformation/helper';

import { useQueryFetch, gql, transformResponse, getWindowSession } from '../../utils';
import { constants, REGEX_CONSTANTS, GQL_OPT_NAME, COUNTRY_MOBILE_NUMBER_MIN_LENGTH } from '../../constants';

const {
  USA_COUNTRY_CODE,
  USA_SMS_CODE,
  USA_COUNTRY_CODE_VALUE,
  US_MOBILE_CODE,
  FR_MOBILE_CODE,
  FR_COUNTRY_CODE,
  DEFAULT,
} = constants;

export const UserMobileNumber: FC<UserMobileNumberProps> = props => {
  const {
    phoneNumber,
    countryCode,
    placeholder,
    description,
    termsAndCondn,
    termsAndCondnOverlay,
    optoutMessage,
    invalidPhoneError,
    className = '',
    initDefaultSMSCountry,
    initDefaultSMSCountryCode,
    handleCountryCodeChange,
    phoneNumberTooltip,
    hideTnc,
    isMobileNumberMandatory = true,
    mobileNumberRegisterOptions,
    countryCodeList,
    ...rest
  } = props;
  let countryDescription = USA_SMS_CODE;
  let countryCodeVal = initDefaultSMSCountryCode || USA_COUNTRY_CODE_VALUE;

  const [isModalOpen, setModal] = useState(false);
  const [countryCodes, setCountryCodes] = useState<SelectOptions[]>([]);
  const [defaultSMSCountry, setDefaultSMSCountry] = useState(USA_COUNTRY_CODE);
  const [countrySelected, setCountrySelected] = useState(defaultSMSCountry);
  const [showOptOutMessage, setShowOptOutMessage] = useState(false);
  const [minLengthMobileNumber, setMinLengthMobileNumber] = useState(10);
  const [maxLengthMobileNumber, setMaxLengthMobileNumber] = useState(10);

  const sessionObject = getWindowSession();
  const { tripsXRequestedByHeader, locale } = transformResponse(sessionObject, SessionMap);

  const GET_COUNTRIES_CODE = gql`
    ${smsCountryCodesGql}
  `;
  const [getCountries, { data }] = useQueryFetch(
    GET_COUNTRIES_CODE,
    GQL_OPT_NAME.smsCountryCodes,
    tripsXRequestedByHeader
  );

  useEffect(() => {
    if (countryCodeList) {
      return;
    }
    getCountries({
      variables: {
        type: 'SMS_COUNTRY_CODES',
      },
    });
  }, [countryCodeList]);

  useEffect(() => {
    const list: CountryCodeList = countryCodeList || data?.smsCountryCodes?.lookups || [];
    setCountryCodes(
      list.map(item => {
        if ((initDefaultSMSCountry || locale)?.toUpperCase()?.match(item.label)) {
          setDefaultSMSCountry(item.label);
        }
        return {
          label: item.description,
          value: item.code,
          code: item.code,
          locale: item.label,
        };
      })
    );
  }, [data, countryCodeList]);

  useEffect(() => {
    defaultSMSCountry && setCountrySelected(defaultSMSCountry);
  }, [defaultSMSCountry]);

  useEffect(() => {
    if (countrySelected) {
      setMinLengthMobileNumber(
        COUNTRY_MOBILE_NUMBER_MIN_LENGTH[countrySelected] || COUNTRY_MOBILE_NUMBER_MIN_LENGTH[DEFAULT]
      );
      setMaxLengthMobileNumber(countrySelected === US_MOBILE_CODE ? 10 : GI_FIELD_MAX_LENGTH.phoneNumber);
      setShowOptOutMessage(countrySelected === FR_MOBILE_CODE || countrySelected === FR_COUNTRY_CODE);
    }
  }, [countrySelected]);

  countrySelected &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    countryCodes?.forEach((dataObj: any) => {
      if (countrySelected === dataObj.locale) {
        countryDescription = dataObj.label;
        countryCodeVal = dataObj.code;
      }
    });
  return (
    <StyledSection data-testid="UserMobileNumber" data-component-name="o-book-UserMobileNumber" className={className}>
      <div className="mobile-number-container">
        <Dropdown
          {...rest}
          id="countryCode"
          className="left-side"
          label={countryCode}
          selectOptions={countryCodes}
          name="countryCode"
          testId="countryCode"
          code={countryCodeVal}
          description={countryDescription}
          isMandatoryField={true}
          varient="outlined"
          placeholder={countryDescription}
          onChange={e => {
            setCountrySelected && setCountrySelected(e.target?.value);
            /*
             * Refer to the implementation of the Dropdown
             * If placeholder is given
             * The first option would be a hidden node only for UI displaying
             */
            const selectedOptionIndex = e.target.selectedIndex - (countryDescription ? 1 : 0);
            handleCountryCodeChange && handleCountryCodeChange(countryCodes[selectedOptionIndex]);
          }}
        />
        <Input
          {...rest}
          validateReset
          className="right-side"
          id="mobileNumber"
          name="mobileNumber"
          testId="mobileNumber"
          type="tel"
          label={phoneNumber}
          isErrorIcon={false}
          varient="outlined"
          isMandatoryField={isMobileNumberMandatory}
          placeholder={placeholder}
          maxLength={rest.mobileNumberMaxLength || maxLengthMobileNumber}
          registerOptions={
            mobileNumberRegisterOptions || {
              pattern: {
                value: RegExp(REGEX_CONSTANTS.NUMBERONLYPATTERN),
                message: invalidPhoneError,
              },
              required: invalidPhoneError,
              minLength: {
                value: minLengthMobileNumber,
                message: invalidPhoneError,
              },
            }
          }
          info={phoneNumberTooltip}
          isInfoIcon={false}
        />
      </div>
      {!hideTnc && (
        <div className={cssClass('tnc-container', { 'with-larger-margin-top': phoneNumberTooltip })}>
          <div className="d-flex align-items-center flex-wrap">
            <div className="text-field">{description}</div>
            <a href="#" className="ctaLink" onClick={() => setModal(true)} data-testid="sms-terms-and-condition">
              {termsAndCondn.ctaText}
            </a>
          </div>
        </div>
      )}
      {optoutMessage && showOptOutMessage ? (
        <div className="optoutmsg-container">
          <div className="d-flex align-items-center flex-wrap">
            <div className="text-field">{optoutMessage}</div>
          </div>
        </div>
      ) : null}
      <CustomModal
        openModal={isModalOpen}
        heading={termsAndCondn.ctaText}
        handleClose={() => setModal(false)}
        optionsModal={false}
        isRoomRequestHeader={true}
        ariaDescribedby="termsAndConditions"
        children={
          <StyledModalDiv
            className="t-font-m modal-description"
            data-rte-editelement
            dangerouslySetInnerHTML={{ __html: termsAndCondnOverlay }}
          />
        }
      />
    </StyledSection>
  );
};
