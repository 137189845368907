import { generateRandomNumber } from '@marriott/shared/mi-helper-utils';

// Unstable code, does not provide fix length answer each time
export const getRandomAlphaString = (length: number) => {
  return 'x'
    .repeat(length)
    .replace(
      /./g,
      _c => 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'[Math.floor(generateRandomNumber() * 62)]
    );
};
