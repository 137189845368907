class CookieUtils {
  static cookies: {
    [key: string]: string;
  };
  static getCookie = (cookieName: string): string => {
    if (!CookieUtils.cookies) {
      CookieUtils.setCookies();
    }
    return CookieUtils.cookies[cookieName];
  };

  static setCookies = (): void => {
    const cookieString = decodeURIComponent(document.cookie);
    CookieUtils.cookies = {};
    cookieString.split(';').forEach(cookie => {
      const [key, value] = cookie.trim().split('=');
      if (key && value) {
        CookieUtils.cookies[key] = value;
      }
    });
  };
}

export default CookieUtils;
