import { useState } from 'react';

import { Checkbox } from '../Checkbox';

import { SendToConcurCheckboxProps } from './SendToConcurCheckbox.types';

export const SendToConcurCheckbox = (props: SendToConcurCheckboxProps) => {
  const { isConcurProfile, sendToConcurMsg = ' ', isRichTextConsent = false, handleSendToConcur } = props;
  const [concurChecked, setConcurChecked] = useState(true);

  const handleConcurClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxVal = event.target.checked;
    setConcurChecked(checkboxVal);
    handleSendToConcur(checkboxVal);
  };

  if (isConcurProfile) {
    return (
      <Checkbox
        {...props}
        id="sendToConcurConsent"
        name="sendToConcurConsent"
        data-component-name="a-book-SendToConcurCheckbox"
        data-testid="book-SendToConcurCheckbox"
        selectOptions={[{ key: sendToConcurMsg, value: `${concurChecked}`, isRichText: isRichTextConsent }]}
        checked={concurChecked}
        handleClick={handleConcurClick}
        className="mb-4"
      />
    );
  }
  return null;
};
