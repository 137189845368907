import React, { createContext, Suspense, useMemo } from 'react';
import { ErrorHandlerAlert } from '../../molecules';
import { Styled } from './HotelOverview.styles';
import { ComponentProps } from './HotelOverview.types';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import Overview from './Overview';
import Location from './Location';

const GOOGLE_MAP_API_KEY = process.env['GOOGLE_MAP_API_KEY'];
const serverDataProps: ComponentProps = {
  propertyOverviewData: {},
  jsonContent: {},
  GOOGLE_MAP_API_KEY: GOOGLE_MAP_API_KEY,
  locationData: {},
  model: {},
};

export const serverData = createContext(serverDataProps);

export const HotelOverviewComponent: React.FC<ComponentProps> = props => {
  const value: ComponentProps = useMemo(() => {
    return { jsonContent: props?.model, GOOGLE_MAP_API_KEY: process.env['GOOGLE_MAP_API_KEY'] };
  }, [props?.model?.jsonContent]);

  return (
    <ErrorHandlerAlert alertMessage={props?.jsonContent?.['componentErrorMessage']}>
      <Suspense fallback={''}>
        <serverData.Provider value={value}>
          <Styled>
            <div className="standard color-scheme1 overview-and-location">
              <Overview />
              <Location />
            </div>
          </Styled>
        </serverData.Provider>
      </Suspense>
    </ErrorHandlerAlert>
  );
};
export const HotelOverviewComponentConfig = {
  emptyLabel: 'HotelOverviewComponent',
  isEmpty: false,
  resourceType: `mi-aem-shop-spa/components/content/hotelgalleryoverview`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HotelOverviewComponentEditable = (props: any) => {
  return props.cqPath.includes('experiencefragment') ? (
    <HotelOverviewComponent {...props} />
  ) : (
    <EditableComponent config={HotelOverviewComponentConfig} {...props}>
      <HotelOverviewComponent {...props} />
    </EditableComponent>
  );
};
