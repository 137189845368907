import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  height: auto;
  min-height: ${toRem(100)};
  border-radius: ${toRem(14)};
  border: ${toRem(1)} solid ${theme.colors.gallery};
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.07);
  margin-bottom: ${toRem(24)};
  background-color: ${theme.colors.white};

  @media ${theme.mediaQuery.allMobiles} {
    flex-direction: column;
    height: auto;
  }
  @media ${theme.mediaQuery.minDesktop} {
    margin-right: ${toRem(14)};
  }
`;

export const StyleImage = styled.div`
  min-height: ${toRem(221)};
  @media ${theme.mediaQuery.tablet} {
    min-height: ${toRem(195)};
    min-width: ${toRem(195)};
    .room-image {
      border-radius: ${toRem(14)} 0 0 ${toRem(14)} !important;
    }
  }
  @media ${theme.mediaQuery.desktop} {
    min-height: ${toRem(285)};
    min-width: ${toRem(507)};
  }
`;

export const StyledContent = styled.div`
  flex: 1;
  display: flex;
  font-weight: ${theme.fonts['fontWeightMedium']};
  padding: ${toRem(26)} ${toRem(32)} 0 ${toRem(24)};
  flex-direction: column;
  justify-content: space-between;
  .parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media ${theme.mediaQuery.allMobiles} {
      flex-direction: column;
      .child {
        width: 100%;
        display: contents;
        .description {
          margin-bottom: ${toRem(24)};
        }
        section,
        a {
          width: 100%;
          text-align: center;
        }
      }
    }
    @media ${theme.mediaQuery.allTablets} {
      padding-bottom: ${toRem(22)};
      padding-right: ${toRem(21)};
    }
  }

  .heading {
    margin-bottom: ${toRem(16)};
    font-size: ${toRem(22)};
    line-height: ${toRem(26)};
  }
  .upsell-room-details {
    font-size: ${toRem(14)};
    line-height: ${toRem(20)};
    color: ${theme.colors['light-gray-10']};
    border-bottom: ${toRem(1)} solid ${theme.colors['light-gray-10']};
  }

  .bottom-section {
    display: flex;
    justify-content: flex-end;

    .price-block {
      margin-right: ${toRem(24)};

      .room-type-text {
        color: ${theme.colors['dark-gray-3']};
        font-size: ${theme.fonts['fontXs']};
        line-height: ${toRem(12)};
        font-weight: 700;
        display: block;
        padding-bottom: ${toRem(4)};
      }

      .price-text {
        font-size: ${theme.fonts['fontXxl']};
      }

      .per-night-text {
        color: ${theme.colors['base-10']};
        font-size: ${theme.fonts['fontXs']};
        line-height: ${theme.fonts['lineHeightXs']};
      }
      .grand-total-text {
        color: ${theme.colors['base-10']};
        font-size: ${theme.fonts['fontXs']};
        line-height: ${theme.fonts['lineHeightS']};
        display: block;
        margin: 0 0 ${toRem(24)} 0;
      }
    }

    .button {
      font-size: ${toRem(16)};
      font-weight: 500;
      padding: ${toRem(12)} ${toRem(16)};
      min-height: ${toRem(40)};
      text-align: center;
      margin-top: ${toRem(16)};
    }
  }

  @media ${theme.mediaQuery.allMobiles} {
    padding: 0;
    padding: ${toRem(24)};

    .bottom-section {
      border-top: ${toRem(1)} solid ${theme.colors['light-gray-3']};
      margin-top: ${toRem(50)};
      padding-top: ${toRem(24)};
      flex-direction: column;
      padding-left: 0;
      padding-bottom: 0;

      .button {
        width: 100%;
        margin: 0;
        font-weight: 500;
        font-size: ${toRem(16)};
        padding: ${toRem(12)};
      }
      .price-block {
        margin-top: 0;
      }
    }
  }
`;
