import { FC } from 'react';
import dynamic from 'next/dynamic';
import Skeleton from 'react-loading-skeleton';
import { isCNLocale } from '@marriott/shared/mi-helper-utils';
import { MapComponentProps } from './MapComponent.types';

const BaiduMapContainer = dynamic(() => import('./BaiduMapContainer'), {
  loading: () => <Skeleton containerTestId="baidu-map-skeleton" height="100%" enableAnimation />,
});

const GoogleMapContainer = dynamic(() => import('./GoogleMapContainer'), {
  loading: () => <Skeleton containerTestId="google-map-skeleton" height="100%" enableAnimation />,
});

export const MapComponent: FC<MapComponentProps> = ({ currentLocale = '', ...props }) => {
  if (isCNLocale(currentLocale)) return <BaiduMapContainer {...props} />;
  return <GoogleMapContainer {...props} />;
};
