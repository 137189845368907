import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem } from '@marriott/mi-ui-library';

export const StyledAllRegionList = styled.div`
  ${rtl`  
    
    .all-region-dropdown {
      position:absolute;
      z-index:1;
      width:${toRem(340)};
    }

    .all-region-dropdown-left {
      left: 0;
    }
    .m-dropdown-content{
      max-height:80vh !important;
    }

    .all-region-dropdown-right {
      right: 0;
    }
    .radio-btn-outline-class {
      .radio-btn-wrapper:first-child {
        .m-radio-button-sm {
          input[type="radio"] + label:before {
            outline: none !important;
          }
        }
      }
    }
  `}
`;
