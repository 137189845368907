import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledPicture = styled.picture`
  .image {
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    max-width: 100%;
  }
`;

export const StyledBannerImageWrapper = styled.div`
  ${rtl`
    height:100%;
    height:${toRem(196)};
    position:relative;
    @media only ${theme.mediaQuery.minDesktop}{
    padding-right: ${toRem(36)};
    }
    @media only ${theme.mediaQuery.allTablets} {
      height:${toRem(208)};
      width: ${toRem(260)};
    }
    @media only ${theme.mediaQuery.mobileOnly} {
      height:${toRem(220)};
      margin-bottom:${toRem(18)};
      padding-right:0;
    }
    .ribbon  {
      position:absolute;
      left:${toRem(17)};
      top:${toRem(17)};
    }
    .icon-wrapper{
      position: absolute;
      bottom: ${toRem(8)};
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @media only ${theme.mediaQuery.minDesktop}{
      padding-right:${toRem(36)};
      }
      span {
        font-size: ${toRem(60)};
        line-height: 1;
        color:${theme.color['white']};
      }
      @media only ${theme.mediaQuery.mobileOnly} {
        padding-right:0;
      }
    }
    .hvmi-banner-image {
      max-width:100%;
      border-radius: ${toRem(6)};
      height:${toRem(196)};
      @media only ${theme.mediaQuery.allTablets} {
        height:${toRem(208)};
      }
      @media only ${theme.mediaQuery.mobileOnly} {
        height:${toRem(220)};
      }
    }
    .scrim-component{
      bottom: 0;
      position:absolute;
      background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.47) 43.68%, rgba(0,0,0,0.62) 100%);
      height:${toRem(98)};
      width:${toRem(240)};
      border-radius: ${toRem(6)};
      @media only ${theme.mediaQuery.allTablets} {
        height:${toRem(112)};
        width:100%;
      }
      @media only ${theme.mediaQuery.mobileOnly} {
        height:${toRem(92)};
        width:100%;

      }
    }
  `}
`;
