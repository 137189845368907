import { FC, useEffect, useState } from 'react';

import { ModalUI } from '../Modal';
import { GlobalPrivacyPolicyProps } from './GlobalPrivacyPolicy.types';
import { StyledSection } from './GlobalPrivacyPolicy.styles';

import { loadModalData } from '../../utils';

export const GlobalPrivacyPolicy: FC<GlobalPrivacyPolicyProps> = ({
  privacyPolicyText,
  privacyPolicyPrimaryCtaText,
  privacyPolicyTarget,
  privacyPolicyPrimaryCtaLink,
}: GlobalPrivacyPolicyProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [cssLoaded, setCss] = useState<NodeListOf<Element>>();
  const privacyStatementId = 'privacy-statement-modal';

  const element = (
    <div className="loading" id={privacyStatementId}>
      Loading...
    </div>
  );

  const closeModal = () => {
    setOpenModal(false);
    const linksToRemove = document.querySelectorAll('[data-remove="true"]');
    !cssLoaded?.length && setCss(linksToRemove);
    linksToRemove.forEach(item => item.remove());
  };

  const loadModal = () => {
    if (openModal) {
      loadModalData({ value: false }, privacyPolicyPrimaryCtaLink, privacyStatementId, false, true);
    }
  };

  useEffect(() => {
    loadModal();
  }, [openModal]);

  const privacyPolicyTextArr = privacyPolicyText?.includes('{link}')
    ? privacyPolicyText?.split('{link}')
    : [privacyPolicyText];

  return privacyPolicyTextArr.length > 0 && privacyPolicyText ? (
    <StyledSection
      data-component-name="m-book-GlobalPrivacyPolicy"
      data-testid="book-GlobalPrivacyPolicy"
      className="standard"
    >
      <p>
        {privacyPolicyTextArr?.[0]}
        <a
          href="#"
          id="global-policy-modal"
          onClick={e => {
            e.preventDefault();
            setOpenModal(true);
          }}
          target={privacyPolicyTarget}
        >
          {privacyPolicyPrimaryCtaText}
        </a>
        {(privacyPolicyTextArr.length > 1 && privacyPolicyTextArr?.slice(1).join(' ')) || ''}
      </p>
      <ModalUI
        open={openModal}
        onClose={closeModal}
        keepMounted={false}
        children={element}
        customCss="privacy-statement-modal legalConsent"
        ariaDescribedby="global-privacy-policy"
      />
    </StyledSection>
  ) : null;
};
