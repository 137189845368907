import { FC, useState, useEffect } from 'react';
import cssClass from 'classnames';

import { InputProps } from './Input.types';
import { StyledInputDiv, StyledError, StyledInfo } from './Input.styles';

import { constants } from '../../constants';

export const Input: FC<InputProps> = ({
  id,
  name,
  label,
  value,
  minLength,
  maxLength,
  className,
  placeholder,
  onChange,
  onKeyPress,
  type = 'text',
  registerFn,
  errorsProps,
  inputClassName,
  isMandatoryField,
  registerOptions = {},
  resetField,
  varient = 'standard',
  validateReset = false,
  setValue = () => null,
  isErrorIcon = true,
  isResetIcon = true,
  checked,
  index,
  onClick,
  readonly,
  trackingValue,
  testId,
  disabled,
  isRichText,
  onFocus,
  onBlur,
  isValid = false,
  showError = false,
  showPwd = false,
  errors = [],
  labelStyle = '',
  info,
  isInfoIcon = true,
}) => {
  const [resetIcon, setResetIcon] = useState<boolean>(false);
  const [inputType, setInputType] = useState<string>(type);
  // let toggleIcon = watch && watch(id) ? 'd-inline-block' : 'd-none';

  useEffect(() => {
    setInputType(type);
  }, [type]);

  let toggleIcon = 'd-inline-block';
  if (!isResetIcon) {
    toggleIcon = 'd-none';
  }
  const passwordType = type === 'password';
  const errorClass =
    errorsProps?.[id] || (passwordType && !isValid && !readonly && showError) || (type === 'text' && showError)
      ? 'is-error'
      : '';
  const isError = varient !== 'outlined' ? errorClass?.concat(' no-outline') : errorClass;
  const errorMessage = errorsProps?.[id]?.message;
  const errorClassName = isErrorIcon ? 'icon-information' : 'information';
  const infoClassName = isInfoIcon ? 'icon-information' : 'information';
  const radioType = type === 'radio';
  const iconClassName = isValid ? 'icon-check' : 'icon-clear';
  const outlineVariant = varient === 'outlined';

  /*Generating css classname from classname module*/
  const resetIconClassName = cssClass(`icon-cancel ${toggleIcon}`, {
    hide: !(!radioType && !disabled && !readonly && resetIcon && !passwordType),
  });
  const pwdShowIconClassName = cssClass({
    'icon-visibility': inputType === constants.PASSWORD,
    'icon-visibility-off': inputType === constants.TEXT,
  });
  const pwdResetIconClassName = cssClass('icon-cancel', {
    [`${iconClassName}`]: passwordType && !readonly && showError,
    hide: !(passwordType && readonly && showError),
  });
  const fieldClassName = cssClass({
    outlined: outlineVariant,
    'no-border': !outlineVariant,
    'read-only-field': readonly,
  });
  const inputBoxClassName = cssClass('input-box', {
    'm-input-field': !radioType && outlineVariant,
  });

  const resetValue = () => {
    if (validateReset) {
      setValue(id, '', { shouldValidate: true });
    } else {
      resetField(id);
    }
  };

  const handleFocusFn = (isFocus: boolean) => {
    if (onFocus && isFocus) {
      onFocus();
    }
    if (onBlur && !isFocus) {
      onBlur();
    }
    setResetIcon(isFocus);
  };
  const changeType = () => {
    inputType === constants.TEXT ? setInputType(constants.PASSWORD) : setInputType(constants.TEXT);
  };

  return (
    <StyledInputDiv data-component-name="a-book-Input" data-testid="book-Input" className={className}>
      <fieldset className={[fieldClassName, isError].join(' ')}>
        {!radioType &&
          (varient === 'outlined' ? (
            <legend id={label} className="outlined-label">
              {label}
            </legend>
          ) : (
            <label htmlFor={id} aria-hidden={true}>
              {label}
            </label>
          ))}
        {radioType && <legend className="hidden-legend">{label}</legend>}
        <div className={inputBoxClassName} aria-live={errorClass ? 'polite' : 'off'}>
          <span className={resetIconClassName} onClick={resetValue} />
          <span className={pwdResetIconClassName} />
          {passwordType && showPwd && <span className={pwdShowIconClassName} onClick={changeType} />}
          <input
            id={id}
            onFocus={() => handleFocusFn(true)}
            key={id}
            type={inputType}
            name={name}
            value={value}
            minLength={minLength}
            maxLength={maxLength}
            onKeyPress={e => onKeyPress && onKeyPress(e)}
            onClick={radioType ? e => onClick && onClick(e, index) : undefined}
            onChange={e => onChange && onChange(e)}
            placeholder={placeholder}
            aria-label={!isRichText ? label : ''}
            data-testid={testId}
            className={[inputClassName, isError].join(' ')}
            data-custom_click_track_value={trackingValue}
            {...(registerFn &&
              registerFn(radioType ? name : id, {
                ...(isMandatoryField && { required: isMandatoryField }),
                ...registerOptions,
                ...(onChange && { onChange }),
              }))}
            checked={checked}
            readOnly={readonly ?? undefined}
            disabled={disabled}
            onWheel={type === 'number' ? e => e.currentTarget?.blur() : () => null}
            aria-describedby={`error${id}`}
            onBlur={() => setTimeout(() => handleFocusFn(false), 500)}
          />
          {radioType &&
            (isRichText ? (
              <label htmlFor={id} dangerouslySetInnerHTML={{ __html: label ?? '' }}>
                {}
              </label>
            ) : (
              <label className={labelStyle} htmlFor={id}>
                {label}
              </label>
            ))}
        </div>
      </fieldset>
      {errors.length && (id === constants.CONFIRM_PASSWORD || !resetIcon)
        ? errors.map(error => (
            <StyledError className={errorClassName} id={`error${id}`}>
              {error}
            </StyledError>
          ))
        : null}
      {errorMessage && (id === constants.CONFIRM_PASSWORD || !passwordType || !resetIcon) && (
        <StyledError className={errorClassName} id={`error${id}`}>
          {errorMessage}
        </StyledError>
      )}
      <div id={`error${id}`}></div>
      {!errors.length && !errorMessage && info && (
        <StyledInfo className={infoClassName} id={`info${id}`}>
          {info}
        </StyledInfo>
      )}
    </StyledInputDiv>
  );
};
