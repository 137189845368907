import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  componentName: string;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public override state: State = {
    hasError: false,
  };
  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  public override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log(
      '%c Error Occured---------------> %s',
      'color: blue; font-weight: bold; font-size: 16px',
      this.props.componentName
    );
    console.log('%c Uncaught error: %s', 'color: blue; font-weight: bold; font-size: 16px', error, errorInfo);
  }
  public override render() {
    if (this.state.hasError) {
      return <div data-component-name="m-book-ErrorBoundary"></div>;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
