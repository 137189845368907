import styled from 'styled-components';
import { toRem, theme } from '../../styles';

export const StyledDiv = styled.div`
  .header {
    display: flex;
    font-size: ${toRem(16)};
    font-weight: ${theme.fonts['fontWeightMedium']};
    letter-spacing: 0;
    line-height: ${toRem(22)};
  }

  .header-text {
    margin: 0 0 0 ${toRem(14)};
  }

  ul {
    padding: 0;
    margin-left: ${toRem(32)};
    margin-bottom: 0;
  }

  .list-item {
    list-style-type: none;
    margin: ${toRem(12)} 0;
    font-size: ${toRem(12)};
    letter-spacing: 0;
    line-height: ${toRem(18)};
    @media ${theme.mediaQuery.desktop} {
      margin: ${toRem(16)} 0;
    }
  }

  .wifi-text {
    color: ${theme.colors['light-gray-10']};
  }
`;
