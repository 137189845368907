import { FC } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { LoadingSkeletonProps } from './LoadingSkeleton.types';
import { StyledSkeletonLines } from './LoadingSkeleton.styles';
export const LoadingSkeleton: FC<LoadingSkeletonProps> = props => {
  const { containerClassName = 'loading-skeleton', customSkeletonConfig = [], isMarginTop = true } = props;
  return (
    <SkeletonTheme>
      {customSkeletonConfig.map((_item, index) => {
        return (
          <StyledSkeletonLines key={`Loading-Skeleton-Container-${index}`} isMarginTop={isMarginTop}>
            <Skeleton key={`Loading-Skeleton-${index}`} {..._item} {...props} containerClassName={containerClassName} />
          </StyledSkeletonLines>
        );
      })}
      {customSkeletonConfig.length === 0 && <Skeleton {...props} containerClassName={containerClassName} />}
    </SkeletonTheme>
  );
};
