import styled from 'styled-components';

import { theme } from '../../styles-3.0/theme';
import { toRem } from '../../styles-3.0/utils';
import { StyledCheckBoxDivProps } from './index.types';

export const StyledCheckBoxDiv = styled.div<StyledCheckBoxDivProps>`
  width: fit-content;
  .checkbox-switch-wrapper {
    display: flex;
    align-items: center;
  }

  .checkbox-switch {
    display: inline-block;
    width: ${toRem(52)};
    height: ${toRem(32)};
    position: relative;
    margin-right: ${toRem(8)};
  }

  .checkbox-switch + input {
    opacity: 0;
  }

  .checkbox-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${theme.color['black']};
    transition: 0.4s;
    border-radius: ${toRem(34)};
    border: ${toRem(1)} solid ${theme.color['light-gray-3']};
  }

  .checkbox-slider:before {
    position: absolute;
    content: '';
    height: ${toRem(28)};
    width: ${toRem(28)};
    left: ${toRem(0.5)};
    top: ${toRem(1)};
    background-color: ${theme.color['light-gray-3']};
    transition: 0.4s;
    border-radius: ${toRem(34)};
    border: none;
  }

  input[type='checkbox']:checked + .checkbox-slider {
    background-color: ${theme.color['light-orange-2']};
    border-color: ${theme.color['light-orange-2']};
  }

  input[type='checkbox']:checked + .checkbox-slider:before {
    background-color: ${theme.color['light-gray-3']};
    transform: translateX(${toRem(19)});
    border-radius: 50%;
    left: ${toRem(2)};
  }
`;
