import { FC } from 'react';

import { TextAreaProps } from './TextArea.types';
import { StyledDescription, StyledDiv, StyledError } from './TextArea.styles';

export const TextArea: FC<TextAreaProps> = ({
  id,
  name,
  label,
  value,
  varient = 'standard',
  minLength,
  maxLength,
  className,
  placeholder,
  registerFn,
  errorsProps,
  inputClassName,
  isMandatoryField,
  description,
  registerOptions = {},
  defaultValue,
}) => {
  const isError = errorsProps?.[id] && 'is-error';
  const errorMessage = errorsProps?.[id]?.message;
  const isOutlined = varient === 'outlined' ? 'outlined' : '';
  const noBorder = varient !== 'outlined' ? 'no-border' : '';

  return (
    <StyledDiv data-component-name="a-book-TextArea" data-testid="book-TextArea" className={className}>
      <fieldset className={[isOutlined, isError, noBorder].join(' ')}>
        {varient === 'outlined' ? (
          <legend id={label} className="outlined-label">
            {label}
          </legend>
        ) : (
          <label htmlFor={id} aria-hidden={true}>
            {label}
          </label>
        )}
        <textarea
          id={id}
          key={id}
          name={name}
          value={value}
          defaultValue={defaultValue}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholder}
          aria-label={label}
          className={[inputClassName, isError].join(' ')}
          {...(registerFn &&
            registerFn(id, {
              ...(isMandatoryField && { required: isMandatoryField }),
              ...(minLength && { minLength: minLength }),
              ...(maxLength && { maxLength: maxLength }),
              ...registerOptions,
            }))}
        />
      </fieldset>
      {description && <StyledDescription>{description}</StyledDescription>}
      {errorMessage && (
        <StyledError className="icon-information" id="error">
          {errorMessage}
        </StyledError>
      )}
    </StyledDiv>
  );
};
