import { toRem } from './utils';
import color from './colors';

export const theme = {
  color,
  mediaQuery: {
    largeDesktop: `(min-width: ${toRem(1440)})`,
    desktop: `(min-width: ${toRem(992)})`,
    mobileTablet: ` screen and (max-width: ${toRem(991)})`,
    tablet: ` screen and (min-width: ${toRem(768)})`,
    tabletOnly: `screen and (min-width: ${toRem(768)}) and (max-width: ${toRem(991)})`,
    largeMobile: ` screen and (min-width: ${toRem(600)})`,
    largerMobile: ` screen and (min-width: ${toRem(650)}) and (max-width: ${toRem(767)})`,
    mobileOnly: ` screen and (max-width: ${toRem(767)})`,
    mobile: ` screen and (min-width: ${toRem(320)} )`,
    mediumMobile: ` screen and (max-width: ${toRem(370)})`,
    smallMobile: ` screen and (max-width: ${toRem(359)})`,
    ie11Only: ` screen and (-ms-high-contrast: none), (-ms-high-contrast: active)`,
    tabletMax: ` screen and (max-width: ${toRem(1199)})`,
    minDesktop: ` screen and (min-width: ${toRem(1200)})`,
  },
  fontFamily: {
    base: 'Swiss721-BT,Helvetica,Arial,sans-serif',
    title: 'Swiss721-BT-Medium, Helvetica,Arial,sans-serif',
    body: 'Swiss721-BT-Regular, Helvetica,Arial,sans-serif',
    heading: 'Swiss-721-Condense,Helvetica,Arial,sans-serif',
    icon: 'MarriottIconsNew',
  },
};
