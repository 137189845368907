import { FC } from 'react';
import clsx from 'clsx';
import { Link } from '@marriott/mi-ui-library';
import { StyledRegionItem } from './RegionItem.styles';
import { RegionItemProps } from './RegionItem.types';
import { formatExitURL } from '../../organisms/MapAndRegionCard/HotelDirectoryMap/HotelDirectoryMapHelper';

// This Component is for each link within the RegionCard component
export const RegionItem: FC<RegionItemProps> = ({
  name,
  count,
  code,
  regionCode,
  isFiltersApplied,
  custom_click_track_value,
}) => {
  return (
    <StyledRegionItem
      data-component-name="m-shop-RegionItem"
      data-testid="shop-RegionItem"
      className={clsx('d-flex', 'align-items-start', 'col-md-3', 'col-6')}
    >
      <Link
        text={`${name} ${count}`}
        linkClassName={clsx('t-font-s', 'region-item-link', 'custom_click_track')}
        linkHref={formatExitURL(code, name, regionCode, isFiltersApplied)}
        ariaLabel={`${name} ${count}`}
        target="_self"
        custom_click_track_value={custom_click_track_value}
      ></Link>
    </StyledRegionItem>
  );
};

export default RegionItem;
