import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledWrapper = styled.div<{ disableBackgroundStyling: boolean }>(
  ({ disableBackgroundStyling = false }) => `
  letter-spacing: 0;
  line-height: ${toRem(18)};
  font-family: ${theme.fontFamily.swiss};
  font-weight: ${theme.fonts['fontWeightRegular']};
  font-size: ${toRem(14)};
  color: ${theme.colors['base-10']};
  border-radius: ${toRem(14)};
  margin-top: ${toRem(32)};
  ${disableBackgroundStyling ? '' : `background-color: ${theme.colors.white};`}
  ${disableBackgroundStyling ? '' : `padding: ${toRem(24)};`}
  &.bg-grey-in-modal {
    background-color: ${theme.colors['light-gray-3']};
    margin-right: ${toRem(32)};
    margin-left: ${toRem(32)};
    @media ${theme.mediaQuery.mobileOnly} {
      margin-top: ${toRem(24)};
      margin-right: ${toRem(16)};
      margin-left: ${toRem(16)};
    }
  }
  p {
    margin: 0;
  }
  .member-icon {
    color: ${theme.colors.carrot};
    margin-right: ${toRem(8)};
    font-size: ${toRem(16)};
  }
  div {
    display: inline-block;
  }
  @media ${theme.mediaQuery.mobileOnly} {
    ${disableBackgroundStyling ? '' : `padding: ${toRem(16)};`}
    font-size: ${toRem(12)};
  }
`
);
