import React from 'react';
import clsx from 'clsx';
import { IncrementProps } from './index.types';
import { StyledIncrementDiv } from './index.styles';
import { ariaConditionalAppend } from '../../styles/utils';

declare module 'react' {
  interface HTMLAttributes<T> {
    custom_click_track_value?: string;
  }
}

const Increment: React.FC<IncrementProps> = props => {
  const {
    incrementText,
    decrementText,
    showCurrentValue,
    incrementHandler,
    decrementHandler,
    currentValue,
    incrementDisable,
    decrementDisable,
    clickTrackingLoc,
    clickTrackValue,
  } = props;

  return (
    <StyledIncrementDiv>
      <button
        type="button"
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick={!decrementDisable ? decrementHandler : (): void => {}}
        className={clsx(decrementDisable ? 'btn-disable' : '', 'custom_click_track')}
        {...ariaConditionalAppend('disabled', true, decrementDisable)}
        custom_click_track_value={`${clickTrackingLoc}| Remove ${clickTrackValue}|internal`}
      >
        <span aria-hidden={decrementDisable ? 'true' : 'false'} className="icon-minus"></span>
        <span className="sr-only">{decrementText}</span>
      </button>
      {showCurrentValue ? <span aria-live="polite">{currentValue}</span> : <span className="empty-separator"></span>}
      <button
        type="button"
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick={!incrementDisable ? incrementHandler : (): void => {}}
        className={clsx(incrementDisable ? 'btn-disable' : '', 'custom_click_track')}
        {...ariaConditionalAppend('disabled', true, incrementDisable)}
        custom_click_track_value={`${clickTrackingLoc}| Add ${clickTrackValue}|internal`}
      >
        <span aria-hidden={incrementDisable ? 'true' : 'false'} className="icon-plus"></span>
        <span className="sr-only">{incrementText}</span>
      </button>
    </StyledIncrementDiv>
  );
};

export default Increment;
