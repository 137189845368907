// this component is for authoring purpose only.
import { iProps } from './NavigationConfigurations.types';
import { StyledComp } from './NavigationConfigurations.style';
import { constants } from '../../constants';

export const NavigationConfigurations = (props: iProps) => {
  const { pageMapping, pageType } = props;
  const { CONFIRMATION_PAGE, GI_PAGE, HYBRID_PAGE, RRD_PAGE, RLM_PAGE } = constants;
  const pageMappingValue = pageMapping.map(data => {
    return (
      <StyledComp>
        <div className="title-container">
          <div className="title">Response Package : </div>
          <div className="description">{data.responsePackage}</div>
        </div>
        <div className="title-container">
          <div className="title">Url To Redirect : </div>
          <div className="description">{data.urlToRedirect}</div>
        </div>
      </StyledComp>
    );
  });

  const getType = (type: string) => {
    switch (type) {
      case RRD_PAGE:
        return 'Review Reservation Details';
      case GI_PAGE:
        return ' Guest Information';
      case CONFIRMATION_PAGE:
        return ' Confirmation';
      case RLM_PAGE:
        return 'Rate List Menu';
      case HYBRID_PAGE:
        return 'Review Guest Information';
      default:
        return '';
    }
  };

  return (
    <StyledComp data-testid="navigationconfigurations" data-component-name="o-book-navigationconfigurations">
      <div className="title-container">
        <div className="title">Page Type : </div>
        <div className="description">{getType(pageType)}</div>
      </div>
      <div className="page-mapping-title">
        <div className="title">Page Mapping :</div>
        <div>{pageMappingValue}</div>
      </div>
    </StyledComp>
  );
};
