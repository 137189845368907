/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react';

import { axiosClient, getProcessEnvs, transformResponse, eventUtil } from '../../utils';
import { constants, URL_CONSTANTS } from '../../constants';
import { HotelDataMap, UniqueHotelLogoMap } from './HotelHeader.schema';
import { HotelHeaderProps } from './HotelHeader.types';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';

const { NEXT_PUBLIC_RECENTLY_VIEWED_CONFIG } = process.env;

const { CTS_LOGO_PAYLOAD, POST } = constants;

export const parseHotelHeaderData = (
  apiResponse: unknown,
  setData: Dispatch<SetStateAction<HotelHeaderProps>>,
  locale: unknown
) => {
  const {
    brandCode,
    hotelName,
    city,
    seoNickname,
    phoneNumbers,
    hotelReviews,
    stars,
    latitude,
    longitude,
    stateProvince,
    country,
    postalCode,
    addressLine1,
    addressLine2,
    addressLine3,
    userData,
    imageUrl,
    dacLogo,
  } = transformResponse(apiResponse, HotelDataMap);

  const mainPhone = phoneNumbers?.find((val: any) => val.type.code === 'phone')?.number;

  setData({
    brandCode: brandCode.toLowerCase() || constants.MARRIOTT_BRAND_CODE,
    hotelName,
    city,
    seoNickname,
    hotelReviews,
    stars,
    latitude,
    longitude,
    phone: mainPhone,
    stateProvince,
    country,
    postalCode,
    addressLine1,
    addressLine2,
    addressLine3,
    isJPLocale: locale === constants.IS_LOCALE_JP,
    suppressReviews: locale === constants.IS_LOCALE_CN,
    showReviews: stars?.count > 0 && hotelReviews?.count > 4,
    userData,
    imageUrl,
    dacLogo,
  });
};

export const findSavedProperty = (propertyId: string, propertyList?: { [id: string]: string }[]): number => {
  if (propertyList?.length) {
    return propertyList?.findIndex(
      (list: { [id: string]: string }) => list?.['id'].toLowerCase() === propertyId.toLowerCase()
    );
  } else return -1;
};

const recentlyViewinitial = [
  {
    config: NEXT_PUBLIC_RECENTLY_VIEWED_CONFIG
      ? JSON.parse(NEXT_PUBLIC_RECENTLY_VIEWED_CONFIG)
      : constants.NEXT_PUBLIC_RECENTLY_VIEWED_CONFIG,
    recentlyViewedProperties: [],
  },
];

const daysDateDifference = (newDate: string, oldDate: string) => {
  const timeDiff = Math.abs(new Date(newDate).getTime() - new Date(oldDate).getTime());
  return Math.ceil(timeDiff / (1000 * 3600 * 24));
};

export const recentlyViewed = (recentlyViewData: any, sessionData: any) => {
  const existingLocalData = JSON.parse(window.localStorage.getItem('miRecentlyViewedProperties')!);
  const miRecentlyViewedProperties = existingLocalData || recentlyViewinitial[0];
  const filteredData = miRecentlyViewedProperties?.recentlyViewedProperties?.filter((item: any) => {
    return (
      item?.marshaCode?.toLowerCase() !== sessionData?.propertyId?.toLowerCase() &&
      daysDateDifference(new Date().toISOString(), item.createdAt) <=
        miRecentlyViewedProperties.config.maxCachedDaysLimit
    );
  });

  miRecentlyViewedProperties?.recentlyViewedProperties?.splice(
    0,
    miRecentlyViewedProperties?.recentlyViewedProperties.length
  );

  miRecentlyViewedProperties?.recentlyViewedProperties.push(...filteredData);
  recentlyViewData.name !== '' && miRecentlyViewedProperties?.recentlyViewedProperties.splice(0, 0, recentlyViewData);
  window.localStorage.setItem('miRecentlyViewedProperties', JSON.stringify(miRecentlyViewedProperties));
};

// get property logo from CTS API
export async function getPropertyLogo(
  propertyId: string,
  setUniqueHotelLogo: Dispatch<SetStateAction<string>>,
  locale: string
) {
  const { NEXT_PUBLIC_PREFIX } = getProcessEnvs();
  const API_URL_PREFIX = NEXT_PUBLIC_PREFIX || useClientEnvVarsStore.getState().envVarsObject['NEXT_PUBLIC_PREFIX'];
  const { NEXT_PUBLIC_DEFAULT_LANG } = getProcessEnvs();
  const url = `${API_URL_PREFIX}${URL_CONSTANTS.CTS_API_URL}`;
  propertyId = propertyId?.toUpperCase();
  const prefix = propertyId?.slice(0, 1);
  const payload = {
    includeLink: true,
    locales: [locale],
    navIds: CTS_LOGO_PAYLOAD.NAVIDS,
    taxonomies: [[`/${CTS_LOGO_PAYLOAD.TAXONOMIES}/${prefix}/${propertyId}`]],
    types: CTS_LOGO_PAYLOAD.TYPES,
  };
  const configs = {
    params: {
      limit: 5,
      defaultLocale: true,
    },
    headers: { 'Accept-Language': locale?.replace('_', '-') || NEXT_PUBLIC_DEFAULT_LANG },
  };

  const result = await axiosClient(POST, url, payload, configs, 'CTS');
  const content = result?.data?.content?.find(({ name = '' }) => name?.includes('logo_L'));
  const { imageUrl = '' } = transformResponse(content, UniqueHotelLogoMap);
  setUniqueHotelLogo(imageUrl);
}
export const handleClickHotelDetails = ({ e, id, key, hotelReviewUrl, enableHQVLink }: any) => {
  !enableHQVLink && e && e.preventDefault();
  eventUtil &&
    eventUtil?.dispatch(key, {
      id,
      hotelReviewUrl,
    });
};
