// Styles for MapAndRegionCardSkeletonLoader go here.
import styled from 'styled-components';
import { toRem } from '@marriott/mi-ui-library';

export const StyledMapAndRegionCardSkeletonLoader = styled.div`
  .loader-container-1 {
    height: ${toRem(500)};
  }
  .loader-container-2 {
    height: ${toRem(1614)};
  }
`;
