export const FindHotelModelPod2 = {
  brandSlug: '',
  brandCSS: '/bin/mi-aem-common-spa/clientlibs/brand-config.{brandCode}.css',
  staticComponentClientLibsCSS: '/etc.clientlibs/mi-aem-shop-spa/clientlibs/clientlib-static-component.min.css',
  enableWebSDK: '',
  dataLayerInitializationScript:
    '/etc.clientlibs/core/wcm/components/commons/datalayer/v1/clientlibs/core.wcm.components.commons.datalayer.v1.js',
  subDirectoryContextPath: '',
  componentsResourceTypes: [
    'mi-aem-shop-spa/components/content/searchform/v1/searchform',
    'mi-aem-homepage-spa/components/xfhomepage',
    'mi-aem-common-spa/components/common/staticwrapper',
    'core/wcm/components/page/v2/page',
    'wcm/foundation/components/responsivegrid',
    'nt:unstructured',
    'mi-aem-shop-spa/components/content/searchresults/v1/searchresults',
    'mi-aem-shop-spa/components/structure/headlessnextjspage',
    'mi-aem-common-spa/components/experiencefragment',
  ],
  dateFormat: '',
  htmlPageItems: [],
  metaNames: [
    {
      name: 'keyword',
      value: '',
    },
    {
      name: 'msapplication-TileColor',
      value: '',
    },
    {
      name: 'msapplication-TileImage',
      value: '',
    },
    {
      name: 'onLoadLightboxURI',
      value: '',
    },
    {
      name: 'description',
      value: '',
    },
    {
      name: 'showLightboxOnLoad',
      value: '',
    },
    {
      name: 'robots',
      value: 'index,follow',
    },
  ],
  language: 'en-US',
  title: 'Find Hotels',
  authorMode: true,
  dataLayerType: 'traditional',
  rtlEnable: false,
  dataLayerStaticElements: {},
  cssClassNames: 'headlessnextjspage remotepagenext page basicpage',
  ':type': 'mi-aem-shop-spa/components/structure/headlessnextjspage',
  dataLayerLaunchScript:
    'https://assets.adobedtm.com/697d0c070f1e/16ad26f0160a/launch-ENaaca933107c74c4eb05514750752e1b8-staging.min.js',
  favIcon: '',
  hreflangList: [],
  ':hierarchyType': 'page',
  currentPagePath: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content',
  dataLayerAttributes: [
    {
      value: 'false',
      key: 'env_is_prod',
    },
    {
      value: 'ram-prod',
      key: 'env_platform',
    },
    {
      value: 'www.marriott.com',
      key: 'env_site_name',
    },
    {
      value: 'www.marriott.com',
      key: 'page_domain_name',
    },
    {
      value: 'en_US',
      key: 'device_language_preferred',
    },
    {
      value: 'https://cache.marriott.com',
      key: 'env_img_path',
    },
    {
      value: 'MR',
      key: 'env_jacket',
    },
    {
      value: 'en_US',
      key: 'site_id',
    },
  ],
  lastModifiedDate: 1726238952367,
  dateOrdinal: '',
  canonical: '',
  localeCurrencySymbol: '',
  ':items': {
    root: {
      ':items': {
        responsivegrid: {
          ':items': {
            searchresults: {
              availabilityLabel: 'Availability',
              amenitiesLabel: 'Amenities',
              loadingLabel: 'Loading',
              guestRatingLabel: 'Guest Rating',
              totalPointsPerStayLabel: 'Total Points / Stay',
              anyDistanceLabel: 'Any Distance',
              recommendedLabel: 'Recommended',
              ':type': 'mi-aem-shop-spa/components/content/searchresults/v1/searchresults',
              pointsSaverLabel: 'PointSavers',
              sortByLabel: 'Sort by',
              anyPointsLabel: 'Any Points',
              defaultLabel: 'Default',
              id: 'searchresults-d069aafe07',
              callToBookLabel: 'Call to book',
              disableTextDescriptor: false,
              removeChildrenMessage: 'Please remove child guest(s) from your search.',
              resortLabel: 'Resort',
              fromDestinationLabel: 'from destination',
              viewRateLabel: 'View Rates',
              selectCurrencySubLabel: 'SHOW PRICE IN',
              reviewsLabel: 'reviews',
              pointBalanceLabel: 'Current Points Balance',
              reviewsShortLabel: 'reviews',
              selectAllLabel: 'Select All',
              brandIconLabel: 'brand icon',
              previousLabel: 'Previous',
              viewAvailabilityLabel: 'View Availability',
              distanceMeasurementUnit: 'miles',
              feeLabel: 'Fee',
              milesLabel: 'miles',
              labelForFiveOrMoreNights: "You're getting a fifth night free for every four you book.",
              previousEEORateLabel: 'was',
              viewMapLabel: 'View Map',
              memberExclusiveLabel: 'Member Exclusive',
              viewHotelDetailsLabel: 'View Hotel Details',
              continueText: 'Continue',
              starsLabel: 'stars of',
              avgPerNightLabel: 'Avg/night',
              ofLabel: 'of',
              applyLabel: 'Apply',
              currencyLabel: '',
              mapLabel: 'Map',
              allFilterLabel: 'All Filters',
              rateUnavailableLabel: 'Rate Unavailable',
              openingSoonLabel: 'Opening Soon',
              pointsLabel: 'Points',
              editToSeeRatesLabel: 'To see rate, edit guests or rooms.',
              newTagLabel: 'NEW',
              hotelTypeLabel: 'Hotel Type',
              monthsShort: 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec',
              dailyDestinationFeeLabel: 'Daily Destination Amenity Fee',
              ratesUnavailableLabel: 'Rates Unavailable',
              newEEORateLabel: 'now',
              dataLayer: {
                'searchresults-d069aafe07': {
                  '@type': 'mi-aem-shop-spa/components/content/searchresults/v1/searchresults',
                  'repo:modifyDate': '2024-09-13T14:49:00Z',
                },
              },
              showAvailableHotelsOnlyLabel: 'Show available hotels only',
              ratesPerNightLabel: '/ Night',
              soldOutLabel: 'Sold Out',
              disableHotelHeaderAndReviewLinks: false,
              filterTaxesLabel: 'Show rates with taxes and all fees',
              adultsOnlyMessageLabel: 'This property accepts adult guests only.',
              unitFromDestinationLabel: 'mi from destination',
              showAvailabilityLabel: 'View Other Dates',
              clearLabel: 'Clear',
              perNightLabel: '/ Night',
              descriptorSearchLabel: 'English',
              priceLabel: 'Price',
              mapViewNightLabel: 'Night',
              hotelsLabel: 'Hotels',
              goToViewHotelDetailsLabel: 'Go To Hotel Details opens in New window',
              goToHotelDetailsLabel: 'Go to Hotel Details',
              anyPriceLabel: 'Any Price',
              pointsPerStayLabel: 'Points / Stay',
              recentlyViewedHeading: 'RECENTLY VIEWED',
              showAvailableHotelLabel: 'View Other Dates',
              fullyRenovatedLabel: 'FULLY RENOVATED',
              recentlyRenovatedLabel: 'RECENTLY RENOVATED',
              nextLabel: 'Next',
              componentErrorMessage:
                'We are currently unable to display the information you requested. Please try again in few moments',
              maximumGuestsExceededLabel: 'This property won’t allow that many guests in one room.',
              surchargeCostsSearchLabel: 'Surcharge for Ordinance Costs',
              filterLabel: 'Filters',
              adultsOnlyLabel: 'ADULTS ONLY',
              dailyResortFeeLabel: 'Daily Resort Fee',
              includesLabel: 'Includes',
              renovatedRoomsLabel: 'RENOVATED ROOM',
              propertyCardDrawerLabel: 'property card drawer',
              editGuestsOrRoomsLabel: 'Edit guests or rooms from your search.',
              fromLabel: 'From',
              selectPriceSubLabel: 'Select Price Per Night',
              showLessLabel: 'Show Less',
              imageUnavailableLabel: 'Image Unavailable',
              labelForFourNights: 'Get a free fifth night for every four you book.',
              taxesAndFeesIncluded: 'Taxes and all fees included',
              destinationAmenityLabel: 'Destination Amenity',
              addListLabel: 'List',
              showingLabel: 'Showing',
              showMoreLabel: 'Show more',
              newHotelLabel: 'NEW HOTEL',
              surchargeTooltipHeadingLabel: 'Surcharge for Ordinance Costs',
              brandsLabel: 'Brands',
              unavailableLabel: 'Unavailable',
              removeChildrenMessageShort: 'Please remove child guest(s).',
              renovatedLobbyLabel: 'RENOVATED LOBBY',
              sortByOptionsADALabel: 'sort by options dropdown opens in a modal window',
              caseSenstiveHotelsLabel: 'hotels',
              unSelectAllLabel: 'Clear All',
            },
            searchform_copy_copy: {
              maxGuestPerRoom: '8',
              cancel: 'Cancel',
              nightCountIncreaseLabel: 'night count increase',
              roomsGuestsHeadShortLabel: 'Rooms & Guests',
              flexibleDates: 'Flexible Dates',
              recentSearchesHeading: 'Recent Searches',
              dateFormat: 'ddd, MMM D',
              destinationSearchResult:
                'There are {x} search results. Keyboard users, use up and down arrows to review and enter to select. Touch device users, explore by touch or with swipe gestures.',
              featuredDestinationsXFPath:
                '/content/experience-fragments/mcom-common/global/en-us/Search/Featured_Destinations/master',
              variation: 'property',
              disableTopDestination: 'false',
              collapseOnPageLoad: false,
              ':type': 'mi-aem-shop-spa/components/content/searchform/v1/searchform',
              children: 'Children',
              disableFloatingBehavior: 'false',
              roomShortLabel: 'Room',
              collapseWhenUserUpdates: false,
              currentLocationText: 'Search from current location below.',
              flexibleTabText: 'Flexible Dates',
              edit: 'Edit',
              specialRatesPoints: 'Special Rates/Points',
              night: 'night',
              maxNumberOfNights: '9',
              enableNewSearch: 'false',
              travelMonthDesktop: 'Which month do you want to travel ?',
              howManyNights: 'How many nights?',
              roomListItems: [
                {
                  roomQuantity: '1',
                },
                {
                  roomQuantity: '2',
                },
                {
                  roomQuantity: '3',
                },
                {
                  roomQuantity: '4-9',
                },
                {
                  roomQuantity: '10-25',
                },
                {
                  roomQuantity: '26+',
                },
              ],
              exactDatesTabText: 'Specific Dates',
              mobileDateFormatLabel: 'DD MMM',
              clearRecentLabel: 'Clear Recents',
              nightCountDecreaseLabel: 'night count decrease',
              flexible: 'Flexible',
              enableOverlayTopWithVideo: 'false',
              reset: 'Reset',
              guest: 'guest',
              childAgeRequired: 'Child {x}: Age (Required)',
              ratesLabel: 'Rates',
              weekdaysShort: 'Sun,Mon,Tue,Wed,Thu,Fri,Sat',
              topDestinationslistItems: [
                {
                  topDestinationField: 'Orlando, Florida',
                },
                {
                  topDestinationField: 'Atlanta, Georgia',
                },
                {
                  topDestinationField: 'New York, NY',
                },
                {
                  topDestinationField: 'Miami, Florida',
                },
                {
                  topDestinationField: 'Nashville, Tennessee',
                },
              ],
              checkInLabel: 'CHECK IN',
              addCorpOrPromoCode: 'Enter Code',
              alternateDateFormat: 'NA',
              enableRedeemPoints: 'false',
              marriottShopPage: 'true',
              dateFormatLanguageLabel: 'en-us',
              myDatesFlexibleLabel: 'My dates are flexible',
              roomsGuestsHead: 'Rooms & Guests',
              localDateFormat: 'MM/DD/YYYY',
              showDatesOnMobile: 'true',
              ratesShopPageHeader: 'SPECIAL RATES',
              applyLabel: 'Apply',
              placeholderTextPopularDestination: 'Popular Destinations',
              selectOneRateLabel: 'Select one rate',
              lessThanLabel: 'Less than 1',
              editField: 'Edit',
              firstDayOfWeek: '0',
              checkOutLabel: 'CHECK OUT',
              monthsShort: 'Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec',
              searchGuestShortLabel: 'Guest',
              selectAMonth: 'Select a month',
              disableRecentlySearched: 'false',
              disableRecentlyViewed: 'false',
              rooms: 'Rooms',
              placeholderTextDesktop: 'Where can we take you?',
              enableFlexibleSearch: 'true',
              yearSearchLabel: 'Year',
              searchViewType: 'defaultview',
              findHotelLabel: 'Update Search',
              ageLabel: 'Age',
              domesticDestinationsXFPath:
                '/content/experience-fragments/mcom-common/global/en-us/Search/Domestic_Destinations/master',
              currentLocationLabel: 'Current Location',
              add: 'Add',
              hideFlexibleDatesCheckbox: 'false',
              datesPlaceholderText: 'Add Date',
              flexibleAlertMessage:
                'Flexible date searches cannot be completed requesting more than 3 rooms. Please revise your search',
              months: 'January,February,March,April,May,June,July,August,September,October,November,December',
              enableAdfSearchForm: false,
              enablePadding: 'true',
              maxGuestsMsg: 'Max: {x} total guests/room',
              weekdays: 'S,M,T,W,T,F,S',
              done: 'Done',
              recentlyViewedHeading: 'RECENTLY VIEWED',
              noResultFound: 'No result found.',
              submitAction: 'default',
              componentErrorMessage:
                'We are currently unable to display the information you requested. Please try again in few moments',
              stayDates: 'Dates',
              adults: 'Adults',
              destination: 'Destination',
              maxChildAge: '17',
              remove: 'Remove',
              ageBasedRatesLabel: 'Age-based rates may be available',
              isDateReversed: false,
              roomsShortLabel: 'Rooms',
              render: 'server',
              orderingRecentlySearchedAndViewed: 'recentSearch',
              specialRatesListItems: [
                {
                  specialRatesCode: 'none',
                  promoCodeEnable: 'false',
                  specialRateslabel: 'Lowest Regular Rate',
                },
                {
                  specialRatesCode: 'aaa',
                  promoCodeEnable: 'false',
                  specialRateslabel: 'AAA/CAA',
                },
                {
                  specialRatesCode: 'S9R',
                  promoCodeEnable: 'false',
                  specialRateslabel: 'Senior Discount',
                },
                {
                  specialRatesCode: 'gov',
                  promoCodeEnable: 'false',
                  specialRateslabel: 'Government & Military',
                },
                {
                  specialRatesCode: 'corp',
                  promoCodeEnable: 'false',
                  specialRateslabel: 'Corp/Promo Code',
                },
              ],
              weekdaysLong: 'Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday',
              hideDestination: 'false',
              usePointsConfirmMessage:
                'Marriott Bonvoy points are not available for use when requesting more than 3 rooms.  Click "OK" to request a special group room rate. Click "Cancel" to revise your search.',
              placeholderTextMobile: 'Where next?',
              clear: 'Clear Dates',
              searchGuestsShortLabel: 'Guests',
              enableOverlap: 'false',
              enableOverlapTop: 'true',
              room: 'Room',
              nights: 'nights',
              guests: 'guests',
              usePointsCertificates: 'Use Points/Awards',
            },
          },
          allowedComponents: {
            components: [
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/availabilitycalendar/v1/availabilitycalendar',
                title: 'Availability Calendar component',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/baselinesearchform/v1/baselinesearchform',
                title: 'Baseline Search Form',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/container',
                title: 'Container',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/experiencefragment',
                title: 'Experience Fragment',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/exploredestinations/v1/exploredestinations',
                title: 'Explore Our Destinations Component',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/homepagesearch/v1/homepagesearch',
                title: 'Homepage Search Form',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/hotelgalleryoverview',
                title: 'Hotel Gallery overview',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/hotelgalleryphotoviewer',
                title: 'Hotel Gallery Photo Viewer',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/hotelgallerytitle',
                title: 'Hotel Gallery Title',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/hotelinformation',
                title: 'Hotel information',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/hvmibanner/v1/hvmibanner',
                title: 'HVMI Banner component',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/mapsubregion/v1/mapsubregion',
                title: 'Map Sub Region Component',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/meetingssearchform/v1/meetingssearchform',
                title: 'Meetings & Events Search Form',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/searchform/v1/searchform',
                title: 'Search Form',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/searchresults/v1/searchresults',
                title: 'Search Results component',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-shop-spa/components/content/tabbedsearchformwrapper/v1/tabbedsearchformwrapper',
                title: 'Tabbed Search Form Wrapper',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mcom-book-spa/components/prebooking/hotelheader',
                title: 'Hotel Header',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-common-spa/components/content/linkbanner',
                title: 'Link Banner',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-common-spa/components/common/staticwrapper',
                title: 'Static Wrapper',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-common-spa/components/content/merchandisingsmallbanner',
                title: 'Merchandising Small Banner',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/wcm/foundation/components/responsivegrid',
                title: 'Layout Container',
              },
              {
                path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/responsivegrid/mi-aem-common-spa/components/experiencefragment',
                title: 'Experience Fragment',
              },
            ],
            applicable: false,
          },
          ':type': 'wcm/foundation/components/responsivegrid',
          ':itemsOrder': ['staticwrapper_copy', 'searchform_copy_copy', 'searchresults', 'experiencefragment_c'],
          columnClassNames: {
            searchresults: 'aem-GridColumn aem-GridColumn--default--12',
            searchform_copy_copy: 'aem-GridColumn aem-GridColumn--default--12',
            experiencefragment_c: 'aem-GridColumn aem-GridColumn--default--12',
            staticwrapper_copy: 'aem-GridColumn aem-GridColumn--default--12',
          },
          gridClassNames: 'aem-Grid aem-Grid--12 aem-Grid--default--12',
          columnCount: 12,
        },
      },
      allowedComponents: {
        components: [
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-shop-spa/components/content/availabilitycalendar/v1/availabilitycalendar',
            title: 'Availability Calendar component',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-shop-spa/components/content/baselinesearchform/v1/baselinesearchform',
            title: 'Baseline Search Form',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-shop-spa/components/container',
            title: 'Container',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-shop-spa/components/experiencefragment',
            title: 'Experience Fragment',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-shop-spa/components/content/exploredestinations/v1/exploredestinations',
            title: 'Explore Our Destinations Component',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-shop-spa/components/content/homepagesearch/v1/homepagesearch',
            title: 'Homepage Search Form',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-shop-spa/components/content/hotelgalleryoverview',
            title: 'Hotel Gallery overview',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-shop-spa/components/content/hotelgalleryphotoviewer',
            title: 'Hotel Gallery Photo Viewer',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-shop-spa/components/content/hotelgallerytitle',
            title: 'Hotel Gallery Title',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-shop-spa/components/content/hotelinformation',
            title: 'Hotel information',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-shop-spa/components/content/hvmibanner/v1/hvmibanner',
            title: 'HVMI Banner component',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-shop-spa/components/content/mapsubregion/v1/mapsubregion',
            title: 'Map Sub Region Component',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-shop-spa/components/content/meetingssearchform/v1/meetingssearchform',
            title: 'Meetings & Events Search Form',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-shop-spa/components/content/searchform/v1/searchform',
            title: 'Search Form',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-shop-spa/components/content/searchresults/v1/searchresults',
            title: 'Search Results component',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-shop-spa/components/content/tabbedsearchformwrapper/v1/tabbedsearchformwrapper',
            title: 'Tabbed Search Form Wrapper',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mcom-book-spa/components/prebooking/hotelheader',
            title: 'Hotel Header',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-common-spa/components/content/linkbanner',
            title: 'Link Banner',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-common-spa/components/common/staticwrapper',
            title: 'Static Wrapper',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-common-spa/components/content/merchandisingsmallbanner',
            title: 'Merchandising Small Banner',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/wcm/foundation/components/responsivegrid',
            title: 'Layout Container',
          },
          {
            path: '/content/marriott-shop-spa/language-masters/na/en-us/findHotels/jcr:content/root/mi-aem-common-spa/components/experiencefragment',
            title: 'Experience Fragment',
          },
        ],
        applicable: false,
      },
      ':type': 'wcm/foundation/components/responsivegrid',
      ':itemsOrder': ['responsivegrid'],
      columnClassNames: {
        responsivegrid: 'aem-GridColumn aem-GridColumn--default--12',
      },
      gridClassNames: 'aem-Grid aem-Grid--12 aem-Grid--default--12',
      columnCount: 12,
    },
  },
  ':itemsOrder': ['root'],
  templateName: 'headless-next-js-template',
  ':path': '/content/marriott-shop-spa/language-masters/na/en-us/findHotels',
  staticComponentClientLibsJS: '/etc.clientlibs/mi-aem-shop-spa/clientlibs/clientlib-static-component.min.js',
  gridCss: '/etc.clientlibs/mi-aem-shop-spa/clientlibs/clientlib-base.css',
  webSdkLaunchScript: 'https://assets.adobedtm.com/697d0c070f1e/f8138fa40779/launch-88b34f6a06e9-development.min.js',
  metaProperties: [
    {
      name: 'og:title',
      value: '',
    },
    {
      name: 'og:description',
      value: '',
    },
    {
      name: 'og:image',
      value: '',
    },
    {
      name: 'og:type',
      value: '',
    },
    {
      name: 'og:site_name',
      value: '',
    },
    {
      name: 'fbAppId',
      value: '',
    },
    {
      name: 'og:url',
      value: '',
    },
  ],
  designPath: '/libs/settings/wcm/designs/default',
};
