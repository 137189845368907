import { inspect } from 'util';
import { DATALAYER_CONSTANT, URL_CONSTANTS } from '../../constants';
import { canUseDOM, isDev, getProcessEnvs, extractValue } from '../helper';
import { logger } from '../logger';
import { DataLayerProps, elmType, indexType, KeysRequest, RequestProps } from './index.types';
import { axiosWrapper } from '../axiosClient';

const { DATALAYER_ENDPOINT_LOCAL } = process.env;

export const getDataLayer = async (
  req: {
    cookies?: { sessionID: string; UserIdToken: string };
    headers: RequestProps;
    query?: Record<string, unknown>;
    url: string;
  },
  sessionData?: object | null
): Promise<DataLayerProps> => {
  // logger initialization
  const { log, pLog } = canUseDOM
    ? logger({ requestID: '', sessionID: '' })('Adobe DataLayer')
    : global.loggerInstance('Datalayer');
  const headers = canUseDOM ? ({} as RequestProps) : req.headers;
  const cookies = canUseDOM ? document.cookie : req.headers.cookie;
  const adobeDataLayer = [];

  // decrypting cookies and merging with header object
  decodeURIComponent(cookies)
    .split(';')
    .forEach(item => {
      const keyValuePair = item.split('=');
      if (keyValuePair.length >= 2) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Unreachable code error
        headers[keyValuePair[0].trim().toLowerCase()] = keyValuePair[1];
      }
    });

  // extracting all the required keys from header
  const {
    sessionid,
    authorization,
    mi_site,
    mi_visitor,
    remembermeflag = false,
    remembermeuserid = '',
    remembermealternateid = '',
    useridtoken,
    localekey = 'en_US',
    sitename = 'marriott.com',
    updatedsearchtype,
    authstatetoken,
  } = headers;
  const ENV_PLATFORM = 'env_platform';

  // extracting data from sessionData
  const marshaCode = extractValue(sessionData, DATALAYER_CONSTANT.sessionDataKeys.marshaCode) || '';
  log.debug(`[${marshaCode}] [DataLayer] marshaCode`);

  const getPageURI = canUseDOM ? window.location.pathname : req['url'] ? req['url'].split('?')[0] : '';

  // creating body for datalayer api call
  const requestBody = JSON.parse(`{
    "sessionToken": "${sessionid}",
    "context": {
      "marshaCode": "${marshaCode}",
      "localeKey": "${localekey}",
      "programFlag": "",
      "siteName": "${sitename}",
      "mobileAuthEnabled": "false",
      "productSiteId": "search",
      "channel": "marriott",
      "pageContent": [],
      "savedPropertiesCount": "2",
      "pageURI": "${getPageURI.replace('.mi', '')}",
      "absolutePageURL": "${getPageURI}",
      "applicationName": "AriesSearch",
      "products": "search",
      "template": "V2",
      "pageURIWithQueryParams": "${req['url'] || ''}",
      "disableChaseApi": "${
        canUseDOM ? getProcessEnvs()['DISABLE_CHASE_API'] ?? 'false' : process.env['DISABLE_CHASE_API'] ?? 'false'
      }",
      "seoQueryParams": ${canUseDOM ? '{}' : req.query ?? '{}'}
    },
    "sourceURI": "${getPageURI}",
    "variation": "${canUseDOM ? '0.1' : req.query?.['variation'] ?? '0.1'}"}`);

  // getting hostname from environment variables
  const host = process.env['NGINX_URL'] || '';
  const authorizationHeader = !authorization ? {} : { authorization };

  const headersKey: Record<string, string | undefined> = {};

  // creating headers for datalayer call
  DATALAYER_CONSTANT.headerKeys.forEach(name => {
    if (name && headers[name as KeysRequest['keys']]) {
      headersKey[name] = headers[name as KeysRequest['keys']];
    }
  });

  const getTimeout = (): number => {
    const timeOutEndPoint = canUseDOM ? getProcessEnvs()['MI_ENDPOINT_TIMEOUT'] : process.env['MI_ENDPOINT_TIMEOUT'];
    let playTimeout = 0;
    if (timeOutEndPoint) {
      playTimeout = +timeOutEndPoint;
    }
    return playTimeout || 22000;
  };

  // modifying headers
  const getHeaders = (): string => {
    let cookieValues = '';
    if (mi_site) {
      cookieValues = cookieValues + ` MI_SITE=${mi_site};`;
    }
    if (mi_visitor) {
      cookieValues = cookieValues + ` MI_VISITOR=${mi_visitor};`;
    }
    if (remembermeuserid) {
      cookieValues = cookieValues + ` RememberMeUserID=${encodeURIComponent(remembermeuserid)};`;
    }
    if (remembermealternateid) {
      cookieValues = cookieValues + ` RememberMeAlternateID=${encodeURIComponent(remembermealternateid)};`;
    }
    if (useridtoken) {
      cookieValues = cookieValues + ` UserIdToken=${encodeURIComponent(useridtoken)};`;
    }
    if (authstatetoken) {
      cookieValues = cookieValues + ` authStateToken=${encodeURIComponent(authstatetoken)};`;
    }
    if (updatedsearchtype) {
      cookieValues = cookieValues + ` updatedSearchType=${updatedsearchtype};`;
    }
    return cookieValues;
  };

  // API call
  let endpoint = canUseDOM
    ? getProcessEnvs()['DATALAYER_ENDPOINT']
    : `${host}${process.env['NEXT_PUBLIC_PREFIX']}${
        req.query?.['endpoint']
          ? req.query['endpoint']
          : process.env['DATALAYER_ENDPOINT'] ?? URL_CONSTANTS.DATALAYER_FALLBACK_URL
      }`;

  //Dev check to run datalyer on local with mock datalayer
  if (isDev) {
    endpoint = DATALAYER_ENDPOINT_LOCAL;
  }
  const perfApiStartTime = new Date().getTime();
  const response = await axiosWrapper
    .post(endpoint, requestBody, {
      timeout: getTimeout(),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Unreachable code error
      headers: {
        // please make sure these coookies are in single line. Else there will be `TypeError [ERR_INVALID_CHAR]`
        cookie: `sessionID=${sessionid}; RememberMeFlag=${remembermeflag}; ${getHeaders()}`,
        'Content-Type': 'application/json',
        ...authorizationHeader,
        ...headersKey,
      },
    })
    .then(({ data, status }) => {
      log.debug(`[${endpoint}] [DataLayer] Aries API Call Success: ${status}`);
      return data.component?.data;
    })
    .catch(err => {
      log.error(
        `[${endpoint}] [DataLayer] Aries API Failed: ${err?.response?.status} [message]: ${err?.response?.data}`
      );
      // throw new Error('Datalayer call failed');
      return {};
    });
  pLog.log(`[${endpoint}] [DataLayer] Aries API Response Time:`, perfApiStartTime, new Date().getTime());

  const dataLayerObj: indexType = {};
  let mvpOffers = '';
  const envProcessURL = canUseDOM ? getProcessEnvs()['PHOENIX_ENV_PLATFORM'] : process.env['PHOENIX_ENV_PLATFORM'];

  /*
  manipulating some of the response data
  */
  if (typeof response?.dataProperties !== 'undefined') {
    response?.dataProperties?.forEach?.((elm: elmType) => {
      if (elm && elm.key && elm.value && elm.value !== 'undefined' && elm.value !== null) {
        dataLayerObj[elm.key] = elm.value;
      }
    });
  } else {
    log.error(`[${endpoint}] [DataLayer] API response dataProperties : ${JSON.stringify(response?.dataProperties)}`);
  }

  // To set platform like "AEM-prod, AEM-stage, AEM-dev"
  if (envProcessURL) {
    dataLayerObj[ENV_PLATFORM] = envProcessURL;
  }

  if (typeof dataLayerObj['mr_nights_to_renew'] === 'string') {
    const nightsToRenew = Number(dataLayerObj['mr_nights_to_renew']);
    if (!isNaN(nightsToRenew)) {
      dataLayerObj['mr_nights_to_renew'] = nightsToRenew;
    }
  }

  if (typeof dataLayerObj['mr_nights_to_next_level'] === 'string') {
    const nightsNextLevel = Number(dataLayerObj['mr_nights_to_next_level']);
    if (!isNaN(nightsNextLevel)) {
      dataLayerObj['mr_nights_to_next_level'] = nightsNextLevel;
    }
  }

  // To identify OTA reservations in check-in flow
  if (typeof dataLayerObj['page_url_query_string'] === 'string') {
    const otaParam = dataLayerObj['page_url_query_string']
      .split('&')
      .filter(val => val.includes('OTA='))
      .join();
    if (otaParam) {
      dataLayerObj['ota_flag'] = otaParam.substring(otaParam.lastIndexOf('=') + 1);
    }
  }

  // Start: manually adding these item if Maken does not provide
  if (typeof dataLayerObj['page_data_layer_ready'] === 'undefined') {
    dataLayerObj['page_data_layer_ready'] = 'false';
  }

  if (typeof dataLayerObj['request_id'] === 'undefined') {
    dataLayerObj['request_id'] = headersKey['x-request-id'] ?? '';
  }
  // End

  if (typeof response?.mvpOfferList !== 'undefined' && response?.mvpOfferList) {
    mvpOffers = JSON.stringify(response.mvpOfferList);
  } else {
    log.error(`[${endpoint}] [DataLayer] API response mvpOfferList Data : ${JSON.stringify(response?.mvpOfferList)}`);
  }

  // logging
  const isLogError = canUseDOM
    ? getProcessEnvs()['LOG_DATA_LAYER'] === 'true'
    : process.env['LOG_DATA_LAYER'] === 'true';

  if (isLogError) {
    log.error(
      `Datalayer endpoint: ${endpoint}, datalayer env. value ${
        canUseDOM ? getProcessEnvs()['DATALAYER_ENDPOINT'] : process.env['DATALAYER_ENDPOINT']
      }`
    );
    log.error(`Datalayer request headers: ${inspect(headers, { breakLength: Infinity })}`);
    log.error(`Datalayer axios cookies: ${getHeaders()}`);
    log.error(`Datalayer axios request headers: ${inspect(headersKey, { breakLength: Infinity })}`);
    log.error(`Datalayer axios post data: ${inspect(requestBody, { breakLength: Infinity })}`);
    if (sessionid !== dataLayerObj['sessionId']) {
      log.error('Session IDs are different');
    } else {
      log.error('Session IDs are same');
    }
    log.error(`Datalayer request sessionID= ${sessionid}, datalayerSessionId= ${dataLayerObj['sessionId']}`);
    if (!useridtoken && dataLayerObj['memState'] === 'authenticated') {
      log.error('User token does not exist but data layer is authenticated');
    }
    log.error(`Datalayer request User ID= ${useridtoken}, memState= ${dataLayerObj['memState']}`);
    if (!useridtoken && dataLayerObj['mr_id_alternate']) {
      log.error('mr_id_alternate found but no user id token');
    }
    log.debug(`Datalayer response: ${inspect(response, { breakLength: Infinity })}`);
  }

  adobeDataLayer.push(dataLayerObj);

  return {
    data: adobeDataLayer,
    mvpOffersData: mvpOffers,
  };
};
