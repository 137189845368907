/* eslint-disable @nx/enforce-module-boundaries */
import { FC } from 'react';
import { ResponsiveGrid } from '@adobe/aem-react-editable-components';

import { PageRendererProps } from './PageRenderer.types';
import { getItemFromPageModel, getProcessEnvs, respGridUtil } from '../../utils';
import { Layout, usePageContext } from '@marriott/mi-book-components/organisms';
import { URL_CONSTANTS } from '../../constants';

const { NEXT_PUBLIC_AEM_PATH } = getProcessEnvs();
const { ITEM_PATH_ROOT, ITEM_PATH_EXP_F } = URL_CONSTANTS;

export const PageRenderer: FC<PageRendererProps> = props => {
  const itemRootPath = props.isExpFragment ? ITEM_PATH_EXP_F : ITEM_PATH_ROOT;
  const responsiveGridModel = getItemFromPageModel(props.data.model, itemRootPath);
  const responsiveGridModelProps = respGridUtil(responsiveGridModel);
  const { metaNames, metaProperties, title, staticComponentClientLibsJS, staticComponentClientLibsCSS } =
    props.data.model;
  const { appliedCssClassNames } = responsiveGridModel;
  const { isRLMPage } = props;
  const { pageState: rlmTabList } = usePageContext(state => state.rlmTabList);
  const pageState = {
    rlmTabList,
  };
  const rlmTabListLoading = pageState?.rlmTabList?.rlmTabListLoading;
  const isRLMPageLoaded = isRLMPage && !rlmTabListLoading;

  return (
    <div data-component-name="m-book-PageRenderer" data-testid="book-PageRenderer">
      <Layout
        metaNames={metaNames}
        metaProps={metaProperties}
        title={title}
        headerFooterJS={staticComponentClientLibsJS}
        headerFooterCSS={staticComponentClientLibsCSS}
        pageIdentifier={appliedCssClassNames}
        model={props.data.model}
        ariaHidden={isRLMPage ? !isRLMPageLoaded : !props.data.isPageLoaded}
        ariaLive={props.data.ariaLive}
      >
        <ResponsiveGrid
          {...responsiveGridModelProps}
          model={responsiveGridModel}
          pagePath={NEXT_PUBLIC_AEM_PATH}
          itemPath={itemRootPath}
        />
      </Layout>
    </div>
  );
};
