import { toRem } from './utils';

// TODO - Remove map once we are done with refactoring.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const colors = {
  merch30: '#1c1c1c',
  merch50: '#ffffff',
  merch60: '#ffb995',
  base10: '#1c1c1c',
  base20: '#ffffff',
  ambassadorSolid: '#231c19',
  neutral20: '#eeeeee',
  neutral30: '#c4c4c4',
  neutral40: '#707070',
  neutral50: '#303030',
};

export default {
  //lightGray: '#b2b2b2',
  gray: '#1c1c1c',
  borderGray: '#5e5d5b',
  //darkGray: '#444054',
  background: '#f4f4f4',
  'base-10': '#1c1c1c',
  'base-20': '#ffffff',
  'dark-gray-1': '#1c1c1c',
  'dark-gray-2': '#231c19',
  'dark-gray-3': '#c4c4c4',
  'dark-gray-4': '#767676',
  // 'dark-gray-5': '#1e1e1e',
  'dark-gray-6': '#979797',
  'dark-gray-7': '#1d1d1d',
  'dark-gray-8': '#868686',
  //'dark-gray-9': '#cccccc',
  'dark-gray-10': '#878787',
  //'dark-gray-11': '#e4e7e7',
  'dark-gray-12': '#303030',
  'dark-gray-13': '#707070',
  'dark-gray-14': '#1C1C1C',
  white: '#ffffff',
  'white-smoke': '#F5F5F5',
  // 'light-gray': '#999999',
  'light-gray-1': '#d2d2d2',
  // 'light-gray-2': '#e6e6e6',
  'light-gray-3': '#eeeeee',
  'light-gray-4': '#1c1c1c',
  'light-gray-5': '#fcfcfc',
  'light-gray-6': '#c4c4c4',
  'light-gray-7': '#dbdbdb',
  'light-gray-9': '#c0c0c0',
  // 'light-orange': '#b84c16',
  'light-orange-2': '#ff9962',
  // 'light-blue': '#3b5998',
  // 'dark-orange': '#b84c16',
  black: '#000000',
  Terracotta: '#df8355',
  silver: '#bababa',
  'close-silver': '#c4c4c4',
  gallery: '#eeeeee',
  alot: '#d8d8d8',
  alabaster: '#f8f8f8',
  zeus: '#231d19',
  merch60: '#ffb995',
  monza: '#d0021b',
  alto: '#d5d5d5',
  salmon: '#ff8d6b',
  'cod-grey': '#1C1C1C',
  'carousel-pink': '#fcebed',
  'dove-gray': '#6C6C6C',
  'mine-shaft': '#303030',
  'fiery-Orange': '#b84c16',
  'rose-of-sharon': '#b94600',
  'outline-blue': '#065AF6',
  'cerise-red': '#e93d76',
  'dark-red': '#d0021b',
  'dove-grey': '#707070',
  'pickled-bluewood': '#2F4256',
  'black-opacity-2': 'rgba(0, 0, 0, 0.2)',
  'cta-box-shadow': `0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07)`,
  'cta-banner-shadow': `0 ${toRem(1)} ${toRem(15)} 0 rgba(0, 0, 0, 0.12)`,
  'container-box-shadow': `0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.1)`,
  'filter-box-shadow': `0 ${toRem(13)} ${toRem(17)} ${toRem(
    -11
  )} rgba(0, 0, 0, 0.1)  inset, 0px 0.4375rem 0.5rem 0rem rgba(0, 0, 0, 0.1)`,
  'non-interactive-accent-color': `var(--non-interactive-accent-color)`,
  'interactive-accent-color': 'var(--interactive-accent-color)',
  'link-text-color': 'var(--link-text-color)',
  'cocoa-brown': '#231C19',
  'peach-orange': '#ffb995',
  'verdun-green': '#477d00',
  'orange-roughy': '#c05721',
  'red-damask': '#dc6f50',
};
