import { FC } from 'react';
import { StyledBannerImageWrapper, StyledPicture } from './index.styles';
import { RenditionProps } from '../../HvmiBannerComponent.type';

// https://cache.marriott.com/marriottassets/marriott/NYCES/nyces-guestroom-0031-hor-feat.jpg?output-quality=90&interpolation=progressive-bilinear&crop=3078:1710;*,*&downsize=513px:*

export const BannerImageComponent: FC<{
  label?: string;
  altText?: string;
  dynamic?: boolean;
  renditions?: Array<RenditionProps>;
  damPath?: string;
  assetPath?: string;
  hvmiBrandLogoIcon?: string;
}> = ({ label, altText, renditions, assetPath, damPath, hvmiBrandLogoIcon }) => {
  const defaultImage = assetPath || damPath || '';
  return (
    <StyledBannerImageWrapper>
      <div className="m-badge-overlay-merch ribbon">{label}</div>
      <StyledPicture>
        {renditions?.map((rendition: RenditionProps, index: number) => {
          const { renditionPath, mediaValue, mediaQuery } = rendition;
          const media = `(${mediaQuery}: ${mediaValue})`;
          return (
            <source
              key={index}
              media={media}
              srcSet={renditionPath || defaultImage}
              data-srcset={renditionPath || defaultImage}
            />
          );
        })}
        <img
          src={defaultImage}
          className="image lazy hvmi-banner-image"
          alt={altText}
          data-src={defaultImage}
          loading="lazy"
        />
      </StyledPicture>
      <div className="scrim-component"></div>
      <div className="icon-wrapper">
        <span className={hvmiBrandLogoIcon || 'portfolio-icon-hvmi'}></span>
      </div>
    </StyledBannerImageWrapper>
  );
};
