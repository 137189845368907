export const HOTEL_VIEW_RATES =
  '/reservation/availabilitySearch.mi?isRateCalendar=true&isSearch=true&propertyCode=$id&currency=$currency&numberOfRooms=$numberOfRooms&numberOfAdults=$numberOfAdults&childrenCount=$childrenCount&childrenAges=$childrenAges&clusterCode=$clusterCode&useRewardsPoints=$useRewardsPoints&fromDate=$fromDate&toDate=$toDate';
export const DATE_FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';
export const DATE_FORMAT_MM_DD_YYYY = 'MM/DD/YYYY';
export const API_TIMEOUT = 2500;
export const STRING_TRUE = 'TRUE';
export const API_DEFAULT_SORT = 'distance';
export const PROPERTIES_LIST_URI =
  process.env['NODE_ENV'] === 'development'
    ? 'https://ram-dev26.ose-dev39-vxbyr.cloud.marriott.com/aries-leisure/v1/destinationHotelsList.comp'
    : '/aries-leisure/v1/destinationHotelsList.comp';
export const PROPERTIES_LIST_URI_RELATIVE = 'aries-leisure/v1/destinationHotelsList';
export const IMAGE_COMPRESSION_PARAMETERS = '?resize=*:250';
export const CHECKBOX_SIZE = 18;
export const VIEW_PORT = 1200;
export const SELECTED_AMENITIES = 'selectedAmenities';
export const SELECTED_BRANDS = 'selectedBrands';
export const SELECTED_PRICE = 'selectedPrice';
export const SELECTED_SORT = 'selectedSort';
export const INITIAL_PRICE = 'initialPrice';
export const RESET_AMENITIES = 'resetAmenities';
export const RESET_BRANDS = 'resetBrands';
export const RESET_SORT = 'resetSort';
export const UPDATE_HOTELS_RESULT = 'updateHotelResults';
export const FILTER_LENGTH_6 = 6;
export const FILTER_LENGTH_5 = 5;
export const TODAYS_DATE = Date.now();
export const TOMORROWS_DATE = Date.now() + 1000 * 60 * 60 * 24;
export const CHILDREN_AGE_BELOW_ONE = 'less than 1';
export const MILESTOMETERS = 1609.344;
export const RADIUS_DEFAULT = '50';
export const TIME_OUT_DELAY = 1000;
export const SEARCH_STATE_COOKIE = 'searchstate';
export const LESS_THAN_LABEL = 'lessthanlabel';
export const ZERO = '0';
export const SEARCH_DETAILS = 'searchDetails';
export const SELECTED_FILTERS = 'selectedFilters';
export const FILTERED_OPTIONS = 'filteredOptions';
export const NONE = 'none';
export const CORP = 'CORP';
export const OFFSET_LIMIT = 40;
export const CLEAR_ALL = 'clearAll';
export const domain = process.env['APP_SERVER'] ?? 'https://www.marriott.com';
export const SELECTED_OFFERS_BRANDS = 'selectedOfferBrands';
export const OFFERS_PROPERTIES_DATA = 'offersPropertiesListData';
export const BREAKPOINT_TABLET = 767; // Tablet / md cutoff
export const BREAKPOINT_DESKTOP = 1023; // Desktop / lg + xl cutoff
export const ZH_TW = 'zh-TW';
export const KO_KR = 'ko-KR';
export const JA_JP = 'ja-JP';
export const LOCALE = 'locale';
export const IS_LOCALE_THAI = 'th-TH';
