import React, { useContext } from 'react';
import {
  StyledSkeletonLoaderDiv,
  StyledSkeletonLoaderContainer,
  StyledSkeletonMapViewLoaderDivForMobile,
  StyledSkeletonMapViewLoaderContainerForMobile,
  StyledSkeletonLoaderFormContainer,
} from './index.styles';
import { SearchFilterContext, SearchResultContext } from '../../index.context';
import { StyledPropertyListContainerDiv } from '../propertyList/index.styles';
import clsx from 'clsx';
import { PaginationSortByComponent } from '../paginationSortBy';
import {
  StyledSearchResultMapContainer,
  StyledSearchResultPropertyContainer,
} from '../../organisms/searchResults/index.styles';
import { StyledMapContainerDiv } from '../mapComponent/index.styles';

export const SkeletonPropertyCardLoaderComponent: React.FC<{
  isViewportL: boolean;
  isInitialLoad?: boolean;
  isSearchForm?: boolean;
  isHotelDirectory?: boolean;
  isMapViewEnabled?: boolean;
  segmentId?: string;
  isLatLongAvailable?: boolean;
}> = ({ isViewportL, isSearchForm, isHotelDirectory, isMapViewEnabled, segmentId, isLatLongAvailable }) => {
  useContext(SearchResultContext);
  useContext(SearchFilterContext);

  const SkeletonLoaderDesktopForMapView = () => {
    return (
      <div className="search-form-container">
        <StyledSearchResultPropertyContainer
          className={clsx(
            'search-results-container',
            'color-scheme1',
            'search-map-view-result-container',
            'active-drawer'
          )}
        >
          <div className={clsx('search-results-container', 'color-scheme1', 'search-map-view-result-container')}>
            {isViewportL && (
              <StyledPropertyListContainerDiv>
                <div>
                  <div
                    className={clsx(
                      'container',
                      'property-card-outer-container',
                      'color-scheme1',
                      'outer-map-view-class'
                    )}
                  >
                    <PaginationSortByComponent
                      isMobileState={true}
                      className="pagination-container"
                      segmentId={segmentId}
                      isLatLongAvailable={isLatLongAvailable}
                    />
                    <div className="container property-card-container  map-view-selected">
                      {[...Array(10)].map(ind => (
                        <StyledSkeletonLoaderDiv key={ind}>
                          <div className="property-card">
                            <div
                              className="skeleton-loader-composite image-place-loader-map"
                              style={{
                                width: '100%',
                                height: '221px',
                                marginBottom: '16px',
                              }}
                            >
                              <img
                                src="https://cache.marriott.com/content/dam/marriott-digital/cy/us-canada/hws/h/hpnnb/en_us/logo/internal/assets/cy-hpnnb-grey-image-placeholder-34740.png?imbypass=on"
                                alt="loading"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                }}
                                className="car-img image-place-loader-map"
                              ></img>
                            </div>
                            <div
                              className="skeleton-loader-composite"
                              style={{
                                width: '90.36%',
                                height: '32px',
                                marginLeft: '16px',
                                marginBottom: '12px',
                              }}
                            ></div>
                            <div
                              className="skeleton-loader-composite "
                              style={{
                                width: '72.89%',
                                height: '16px',
                                marginLeft: '16px',
                                marginBottom: '12px',
                              }}
                            ></div>
                            <div
                              className="skeleton-loader-composite "
                              style={{
                                width: '38.54%',
                                height: '16px',
                                marginBottom: '12px',
                                marginLeft: '16px',
                              }}
                            ></div>
                            <div className="d-flex">
                              <div
                                className="skeleton-loader-composite "
                                style={{
                                  width: '38.54%',
                                  height: '32px',
                                  marginBottom: '10px',
                                  marginLeft: '44px',
                                }}
                              ></div>
                              <div
                                className="skeleton-loader-composite "
                                style={{
                                  width: '38.54%',
                                  height: '32px',
                                  marginBottom: '10px',
                                  marginRight: '16px',
                                  marginLeft: '16px',
                                  borderRadius: '20px',
                                }}
                              ></div>
                            </div>
                          </div>
                        </StyledSkeletonLoaderDiv>
                      ))}
                    </div>
                  </div>
                </div>
              </StyledPropertyListContainerDiv>
            )}
            <StyledSearchResultMapContainer className="map-container">
              <StyledMapContainerDiv>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: '75vh', backgroundColor: '#EEEEEE' }}
                >
                  <div className="m-spinner-m"></div>
                </div>
              </StyledMapContainerDiv>
            </StyledSearchResultMapContainer>
          </div>
        </StyledSearchResultPropertyContainer>
      </div>
    );
  };

  const SkeletonLoaderForHotelDirectory = () => {
    return (
      <div className="search-form-container">
        <StyledSearchResultPropertyContainer
          className={clsx(
            'search-results-container',
            'color-scheme1',
            'search-map-view-result-container',
            'active-drawer'
          )}
        >
          <div className={clsx('search-results-container', 'color-scheme1', 'search-map-view-result-container')}>
            <StyledSearchResultMapContainer className={clsx('map-container', 'map-width-full')}>
              <StyledMapContainerDiv className={clsx('map-loader-full')}>
                <div className={clsx('d-flex', 'justify-content-center', 'align-items-center', 'loader-container')}>
                  <div className="m-spinner-m"></div>
                </div>
              </StyledMapContainerDiv>
            </StyledSearchResultMapContainer>
          </div>
        </StyledSearchResultPropertyContainer>
      </div>
    );
  };

  const SkeletonLoaderForFormContainer = () => {
    return (
      <StyledSkeletonLoaderFormContainer className=" skeleton-loader-container">
        <StyledSkeletonLoaderDiv className="container search-form-div">
          <div
            style={{
              width: '100%',
            }}
          >
            <div
              className="skeleton-loader-composite image-place-loader"
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <img
                src="https://cache.marriott.com/content/dam/marriott-digital/cy/us-canada/hws/h/hpnnb/en_us/logo/internal/assets/cy-hpnnb-grey-image-placeholder-34740.png?imbypass=on"
                alt="loading"
                style={{
                  width: '100%',
                  height: '100%',
                }}
                className="car-img"
              ></img>
            </div>
          </div>
        </StyledSkeletonLoaderDiv>
      </StyledSkeletonLoaderFormContainer>
    );
  };

  const SkeletonLoaderMobileForMapView = () => {
    return (
      <StyledSkeletonMapViewLoaderContainerForMobile>
        {isViewportL && (
          <StyledSearchResultPropertyContainer
            className={clsx(
              'search-results-container',
              'color-scheme1',
              'search-map-view-result-container',
              'active-drawer'
            )}
          >
            <div className={clsx('search-results-container', 'color-scheme1', 'search-map-view-result-container')}>
              <StyledPropertyListContainerDiv>
                <div>
                  <div
                    className={clsx(
                      'container',
                      'property-card-outer-container',
                      'color-scheme1',
                      'outer-map-view-class'
                    )}
                  >
                    <PaginationSortByComponent
                      isMobileState={true}
                      className="pagination-container skeleton-property-card-holder"
                      segmentId={segmentId}
                      isLatLongAvailable={isLatLongAvailable}
                    />
                    <div className="container property-card-container  map-view-selected">
                      {[...Array(10)].map(ind => (
                        <StyledSkeletonLoaderDiv key={ind}>
                          <div className="property-card">
                            <div
                              className="skeleton-loader-composite image-place-loader-map"
                              style={{
                                width: '100%',
                                height: '221px',
                                marginBottom: '16px',
                              }}
                            >
                              <img
                                src="https://cache.marriott.com/content/dam/marriott-digital/cy/us-canada/hws/h/hpnnb/en_us/logo/internal/assets/cy-hpnnb-grey-image-placeholder-34740.png?imbypass=on"
                                alt="loading"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                }}
                                className="car-img image-place-loader-map"
                              ></img>
                            </div>
                            <div
                              className="skeleton-loader-composite"
                              style={{
                                width: '90.36%',
                                height: '32px',
                                marginLeft: '16px',
                                marginBottom: '12px',
                              }}
                            ></div>
                            <div
                              className="skeleton-loader-composite "
                              style={{
                                width: '72.89%',
                                height: '16px',
                                marginLeft: '16px',
                                marginBottom: '12px',
                              }}
                            ></div>
                            <div
                              className="skeleton-loader-composite "
                              style={{
                                width: '38.54%',
                                height: '16px',
                                marginBottom: '12px',
                                marginLeft: '16px',
                              }}
                            ></div>
                            <div className="d-flex">
                              <div
                                className="skeleton-loader-composite "
                                style={{
                                  width: '38.54%',
                                  height: '32px',
                                  marginBottom: '10px',
                                  marginLeft: '44px',
                                }}
                              ></div>
                              <div
                                className="skeleton-loader-composite "
                                style={{
                                  width: '38.54%',
                                  height: '32px',
                                  marginBottom: '10px',
                                  marginRight: '16px',
                                  marginLeft: '16px',
                                  borderRadius: '20px',
                                }}
                              ></div>
                            </div>
                          </div>
                        </StyledSkeletonLoaderDiv>
                      ))}
                    </div>
                  </div>
                </div>
              </StyledPropertyListContainerDiv>
              <StyledSearchResultMapContainer className="map-container">
                <StyledMapContainerDiv>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '75vh', backgroundColor: '#EEEEEE' }}
                  >
                    {/* <div className="m-spinner-m"></div> */}
                    <div
                      className="skeleton-loader-composite image-place-loader-map"
                      style={{
                        width: '100%',
                        height: '221px',
                        marginBottom: '16px',
                      }}
                    >
                      <img
                        src="https://cache.marriott.com/content/dam/marriott-digital/cy/us-canada/hws/h/hpnnb/en_us/logo/internal/assets/cy-hpnnb-grey-image-placeholder-34740.png?imbypass=on"
                        alt="loading"
                        style={{
                          width: '100%',
                          height: '100%',
                        }}
                        className="car-img image-place-loader-map"
                      ></img>
                    </div>
                  </div>
                </StyledMapContainerDiv>
              </StyledSearchResultMapContainer>
            </div>
          </StyledSearchResultPropertyContainer>
        )}
        <div
          className="skeleton-loader-composite image-place-loader"
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <img
            src="https://cache.marriott.com/content/dam/marriott-digital/cy/us-canada/hws/h/hpnnb/en_us/logo/internal/assets/cy-hpnnb-grey-image-placeholder-34740.png?imbypass=on"
            alt="loading"
            style={{
              width: '100%',
              height: '100%',
            }}
            className="car-img"
          ></img>
        </div>
        <StyledSkeletonMapViewLoaderDivForMobile>
          <div className="skeleton-card">
            <StyledSkeletonLoaderDiv>
              <div className="skeleton-loader-composite skeleton-card__image"></div>
              <div className="skeleton-card__properties">
                <div className="skeleton-loader-composite skeleton-card__properties-first"></div>
                <div className="skeleton-loader-composite skeleton-card__properties-second"></div>
                <div className="skeleton-loader-composite skeleton-card__properties-third"></div>
                <div className="d-flex">
                  <div className="skeleton-loader-composite skeleton-card__properties-fourth"></div>
                  <div className="skeleton-loader-composite skeleton-card__properties-fifth"></div>
                </div>
              </div>
            </StyledSkeletonLoaderDiv>
          </div>
        </StyledSkeletonMapViewLoaderDivForMobile>
      </StyledSkeletonMapViewLoaderContainerForMobile>
    );
  };

  const SkeletonLoaderForListView = () => {
    return (
      <StyledSkeletonLoaderContainer className="container skeleton-loader-container">
        {[...Array(40)].map((key, ind) => (
          <StyledSkeletonLoaderDiv key={ind}>
            <div className={`left-side-loader ${key ?? ''}`}>
              <div
                className="skeleton-loader-composite image-place-loader"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <img
                  src="https://cache.marriott.com/content/dam/marriott-digital/cy/us-canada/hws/h/hpnnb/en_us/logo/internal/assets/cy-hpnnb-grey-image-placeholder-34740.png?imbypass=on"
                  alt="loading"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  className="car-img"
                ></img>
              </div>
            </div>
            <div className="right-side-loader">
              <div className="top-right-side-loader">
                <div
                  className="skeleton-loader-composite title-place-loader mb-2"
                  style={{
                    width: '100%',
                    height: '34px',
                  }}
                ></div>
                <div
                  className="skeleton-loader-composite review-place-loader mb-3"
                  style={{
                    width: '100%',
                    height: '20px',
                  }}
                ></div>
                <div
                  className="skeleton-loader-composite desc-place-loader mb-4"
                  style={{
                    width: '100%',
                    height: '90px',
                  }}
                ></div>
                <div
                  className="skeleton-loader-composite view-place-loader mb-2"
                  style={{
                    width: '30%',
                    height: '16px',
                  }}
                ></div>
              </div>
              <div className="bottom-rate-place-loader-container">
                <div
                  className="skeleton-loader-composite rate-place-loader mr-4"
                  style={{
                    width: '25%',
                    height: '42px',
                  }}
                ></div>
                <div
                  className="skeleton-loader-composite rate-place-loader"
                  style={{
                    width: '25%',
                    height: '42px',
                  }}
                ></div>
              </div>
            </div>
          </StyledSkeletonLoaderDiv>
        ))}
      </StyledSkeletonLoaderContainer>
    );
  };

  if (isSearchForm) {
    return <SkeletonLoaderForFormContainer />;
  }
  if (isHotelDirectory) {
    return <SkeletonLoaderForHotelDirectory />;
  }
  if (isMapViewEnabled) {
    if (!isViewportL) {
      return <SkeletonLoaderMobileForMapView />;
    } else {
      return <SkeletonLoaderDesktopForMapView />;
    }
  }
  return <SkeletonLoaderForListView />;
};
