import { numberWithCommas, convertPriceFormat, eventUtil, validateCTSEndpoint } from '../../utils';
import { constants, URL_CONSTANTS } from '../../constants';

import { DataProps, RoomUpgradeImageDataProps } from './RoomUpgradeCard.types';

import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { roomImagesData } from '../../organisms/RoomDetailsModal/helper';

export const getCardData = (props: DataProps) => {
  const { type, roomUpgradeData, title } = props;
  const { ROOM_UPGRADE_TYPE } = constants;

  const roomUpgradeType = type === ROOM_UPGRADE_TYPE;

  let updatedTitle = title;
  let upsellPrice = '';
  let grandTotal = '';

  if (roomUpgradeType) {
    const {
      propertyHeading,
      propertyDescription,
      upsellValue,
      upsellValueDecimalPoint,
      roomPriceValue,
      roomPriceValueDecimalPoint,
      grandTotalValue = 0,
      grandTotalValueDecimalPoint = 0,
    } = roomUpgradeData || {};
    updatedTitle = `${propertyHeading}${propertyDescription ? ', ' + propertyDescription : ''}`;
    const upgradePrice = convertPriceFormat(upsellValue, upsellValueDecimalPoint);
    const roomPrice = convertPriceFormat(roomPriceValue, roomPriceValueDecimalPoint) || 0;
    upsellPrice = upgradePrice ? numberWithCommas(Number((upgradePrice - roomPrice).toFixed(0))) : '0';
    grandTotal = grandTotalValue
      ? numberWithCommas(convertPriceFormat(grandTotalValue, grandTotalValueDecimalPoint)?.toFixed(0))
      : '';
  }

  return {
    updatedTitle,
    upsellPrice,
    grandTotal,
  };
};

export const handleClick = ({
  e,
  id,
  item,
  key,
}: {
  e?: unknown;
  id: string;
  item: Record<string, unknown>;
  key: string;
}) => {
  (e as Event)?.preventDefault();
  eventUtil?.dispatch(key, {
    id,
    data: {
      roomData: item,
    },
  });
};

export const filterRoomUpgradeImages = (
  roomImageData?: RoomUpgradeImageDataProps,
  roomPoolCode?: string,
  imageDomain = URL_CONSTANTS.CACHE_MARRIOTT_URL
) => {
  // variable to validate CTS Image Endpoint based on env variable
  const isCTSImageEndpoint = validateCTSEndpoint(useClientEnvVarsStore.getState().envVarsObject);

  if (isCTSImageEndpoint) {
    if (roomImageData?.hotelPhotoTours && roomPoolCode) {
      return roomImagesData(roomImageData.hotelPhotoTours, isCTSImageEndpoint, roomPoolCode, imageDomain);
    }
  } else {
    const imageNode = roomImageData?.property?.media?.photoGallery?.all;
    if (imageNode?.length && roomPoolCode) {
      return roomImagesData(imageNode, false, roomPoolCode, imageDomain);
    }
  }
  return [];
};
