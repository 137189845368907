import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledRegionCardWrapper = styled.div`
  ${rtl`
        .region-card-wrapper {
            margin: ${toRem(40)} auto;

              @media ${baseVariables.mediaQuery.md} {
                max-width: ${toRem(768)};
              }
              @media ${baseVariables.mediaQuery.lg} {
                max-width: ${toRem(1124)};
              }
        }
    `}
`;
