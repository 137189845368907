import { FC, useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import cssClass from 'classnames';

import { ConfirmationMessage } from '../ConfirmationMessage';
import { HotelCancellationPolicy } from '../HotelCancellationPolicy';
import { HotelHeaderV2 as HotelHeader } from '../HotelHeaderV2';
import { StayDetails } from '../StayDetails';
import { SummaryOfCharges } from '../SummaryOfCharges';
import { RedemptionNotification } from '../RedemptionNotification';
import { ConfirmationMessageProps } from '../ConfirmationMessage/ConfirmationMessage.types';
import { CancellationProps } from '../HotelCancellationPolicy/HotelCancellationPolicy.types';
import { ComponentProps } from '../StayDetails/StayDetails.types';
import { SummaryOfChargesProps } from '../SummaryOfCharges/SummaryOfCharges.types';
import { printScreenProps } from './StayModal.types';
import { RedemptionsAlertProps } from '../RedemptionNotification/RedemptionNotification.types';
import { StyledSection, PrintableSection } from './StayModal.styles';
import { isCNLocale } from '../../utils/helper';
import { ContactInformation } from '../../molecules/ContactInformation';

interface stayDetailsProps {
  confirmationInformation: ConfirmationMessageProps;
  cancellationPolicy: CancellationProps;
  summaryOfChargesProps: SummaryOfChargesProps;
  redemptionNotificationProps: RedemptionsAlertProps;
  stayDetails: ComponentProps;
  printScreen: printScreenProps;
  isHidden?: boolean;
  printBtnClickFlag?: number;
  isInEditor?: boolean;
  disableHotelHeaderAndReviewLinks?: boolean;
  disableHotelReviewLinks?: boolean;
  overridePropertyCountry?: boolean;
  overridePropertyCountryText?: string;
  contactInformationDesktop?: string;
  contactInformationMobile?: string;
  locale?: string;
}

export const StayModal: FC<stayDetailsProps> = ({
  confirmationInformation,
  cancellationPolicy,
  summaryOfChargesProps,
  redemptionNotificationProps,
  stayDetails,
  printScreen,
  isHidden = false,
  printBtnClickFlag,
  isInEditor,
  disableHotelHeaderAndReviewLinks,
  disableHotelReviewLinks = false,
  overridePropertyCountry,
  overridePropertyCountryText,
  contactInformationDesktop,
  contactInformationMobile,
  locale,
}) => {
  const componentRef = useRef<HTMLInputElement>(null);
  const sectionClassName = cssClass({ 'd-none is-aem-hidden': isHidden, 'is-aem-hidden': !isHidden });

  // React Print hook: useReactToPrint
  const printBtnClickHandler = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (isHidden && printBtnClickFlag !== 0) {
      printBtnClickHandler();
    }
  }, [isHidden, printBtnClickFlag, printBtnClickHandler]);

  return (
    <StyledSection className={sectionClassName} data-component-name="o-book-StayModal" data-testid="StayModal">
      <button onClick={printBtnClickHandler}>
        <span className="icon-print"></span>
        <span className="print-text" aria-hidden={true}>
          {printScreen?.text}
        </span>
      </button>
      <span className="sr-only">{printScreen?.text}</span>

      <PrintableSection ref={componentRef}>
        <div className="printable-section standard">
          <HotelHeader
            isInEditor={isInEditor}
            inModal={true}
            disableHotelHeaderAndReviewLinks={disableHotelHeaderAndReviewLinks}
            disableHotelReviewLinks={disableHotelReviewLinks}
            overridePropertyCountry={overridePropertyCountry}
            overridePropertyCountryText={overridePropertyCountryText}
          />
          <RedemptionNotification {...redemptionNotificationProps} />
          <ConfirmationMessage {...confirmationInformation} />
          <StayDetails {...stayDetails} />
          <SummaryOfCharges {...summaryOfChargesProps} inModal={true} />
          <HotelCancellationPolicy {...cancellationPolicy} />
          {isCNLocale(locale) && Boolean(contactInformationDesktop || contactInformationMobile) && (
            <ContactInformation
              contactInformationDesktop={contactInformationDesktop}
              contactInformationMobile={contactInformationMobile}
            />
          )}
        </div>
      </PrintableSection>
    </StyledSection>
  );
};
