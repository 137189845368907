import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledOffersContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }

  .card-wrapper {
    height: ${toRem(270)};
    width: ${toRem(270)};
    display: flex;
    flex-direction: column;

    .hero-image-wrapper {
      height: ${toRem(270)};
      width: ${toRem(270)};
      position: relative;
      background-color: ${theme.colors['dark-gray-13']};
      border-radius: ${toRem(20)};

      .offer-image {
        border-radius: 1.25rem;
        height: 100%;
      }

      .details-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;
        position: absolute;
        bottom: 0;
        padding: ${toRem(18)} ${toRem(14)} ${toRem(18)} ${toRem(24)};

        .title {
          font-weight: ${theme.fonts['fontWeightRegular']};
          font-family: ${theme.fontFamily.swiss};
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-bottom: 0.5rem;
          color: ${theme.colors['base-20']};
          font-size: ${theme.fonts['fontXxl']};
          letter-spacing: 0;
          line-height: ${theme.fonts['lineHeightXxl']};
          text-transform: none;
        }

        .description {
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 0.5rem;
        }
        .icon-color {
          color: ${theme.colors['base-20']};
        }
      }

      .hero-image-gradient {
        width: ${toRem(270)};
        height: ${toRem(270)};
        border-radius: 1.25rem;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        transform: rotate(360deg);
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.54) 46.35%,
          rgba(0, 0, 0, 0.62) 100%
        );
      }
    }
  }

  .deal-tag-wrapper {
    display: inline-flex;
    position: relative;
    color: ${theme.colors['base-20']};

    .deal-label {
      font-size: ${theme.fonts['fontXs']};
      font-weight: ${theme.fonts['fontWeightRegular']};
      letter-spacing: 1.3px;
      line-height: 1rem;
      margin-bottom: 0.75rem;
      margin-left: ${toRem(4)};
    }

    .icon-margin {
      margin-top: ${toRem(-4)};
    }
  }
`;
