import { StyledTooltipContainer } from './PropertyRating.styles';
import { PropertyRatingContainerProps } from './PropertyRating.types';

export const PropertyRatingContainer = ({
  propertyReviewUrl,
  className,
  children,
  disabled,
  target,
  rel,
  title,
  tooltipPosition,
  toolTipContent,
  ...props
}: PropertyRatingContainerProps) => {
  //disabled is a props which we are getting based on locale from parent comp
  //To deteremine locale in this flow we are using isReviewCountHidden function definied in
  //libs\mi-shop-components\src\utils\src\helper.ts
  const RatingsAndReview = () => (
    <a
      href={!disabled ? propertyReviewUrl : undefined}
      className={className}
      target={target}
      data-testid="reviews"
      rel={rel}
      title={!disabled ? title : undefined}
      {...props}
    >
      {children}
    </a>
  );
  return disabled ? (
    <StyledTooltipContainer
      data-testid="reviews-tooltip"
      className={`m-tooltip-${tooltipPosition} tooltip-container`}
      data-content={toolTipContent}
    >
      {RatingsAndReview()}
    </StyledTooltipContainer>
  ) : (
    RatingsAndReview()
  );
};
