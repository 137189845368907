import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledDiv = styled.div`
  background-color: ${theme.colors.lightGray};
  height: 100%;
  width: 100%;

  .imageplaceholder-text {
    text-align: center;
    font-size: ${theme.fonts['fontXxl']};
    font-weight: ${theme.fonts['fontWeightMedium']};
    color: ${theme.colors.black};
    line-height: ${toRem(26)};
  }
`;
