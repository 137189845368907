import { FC } from 'react';
import { WrapperProps } from './index.types';
import DOMPurify from 'isomorphic-dompurify';

export const StaticWrapper: FC<WrapperProps> = props => {
  const sanitizedNode = DOMPurify.sanitize(props?.model?.['referencePath'] || props?.['referencePath'], {
    RETURN_DOM: true,
  });
  const fragmentString = sanitizedNode.innerHTML; // Get the HTML content of the container
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: fragmentString,
      }}
    />
  );
};
