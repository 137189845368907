import React from 'react';
import { Input } from '../../atoms';
import { MI_FIELD_MAX_LENGTH } from '../../organisms/MemberInformation/helper';
import type { RRDUserNameForCNProps, RRDUserNameForNonCNProps, RRDUserNameProps } from './RRDUserName.types';
import { checkFormValidation } from '../../utils/helper';

const RRDUserNameForCN: React.FC<RRDUserNameForCNProps> = props => {
  const {
    lastName,
    firstName,
    latinLastName,
    latinFirstName,
    localizedLastNameRegex,
    localizedFirstNameRegex,
    lastNameRegex,
    firstNameRegex,
    optional,
    errorMessage,
    isRedemption,
    disableFirstNameLastName,
    ...rest
  } = props;

  return (
    <>
      <Input
        {...rest}
        validateReset
        className="left-side"
        id="localizedLastName"
        testId="localizedLastName"
        name="localizedLastName"
        label={lastName}
        varient="outlined"
        isErrorIcon={false}
        placeholder={optional}
        readonly={isRedemption || disableFirstNameLastName}
        registerOptions={{
          pattern: {
            value: checkFormValidation(localizedLastNameRegex || ''),
            message: errorMessage.localizedLastNameInvalidMsg || '',
          },
        }}
      />
      <Input
        {...rest}
        validateReset
        className="right-side"
        id="localizedFirstName"
        testId="localizedFirstName"
        name="localizedFirstName"
        label={firstName}
        varient="outlined"
        isErrorIcon={false}
        placeholder={optional}
        readonly={isRedemption || disableFirstNameLastName}
        registerOptions={{
          pattern: {
            value: checkFormValidation(localizedFirstNameRegex || ''),
            message: errorMessage.localizedFirstNameInvalidMsg || '',
          },
        }}
      />
      <Input
        {...rest}
        validateReset
        className="left-side"
        testId="lastName"
        id="lastName"
        name="lastName"
        readonly={isRedemption || disableFirstNameLastName}
        label={latinLastName}
        varient="outlined"
        isMandatoryField
        maxLength={MI_FIELD_MAX_LENGTH.lastName}
        isErrorIcon={false}
        registerOptions={{
          required: errorMessage.lastName,
          pattern: {
            value: checkFormValidation(lastNameRegex),
            message: errorMessage.lastNameInvalidMsg,
          },
        }}
      />
      <Input
        {...rest}
        validateReset
        className="right-side"
        testId="firstName"
        id="firstName"
        name="firstName"
        readonly={isRedemption || disableFirstNameLastName}
        label={latinFirstName}
        varient="outlined"
        maxLength={MI_FIELD_MAX_LENGTH.firstName}
        isMandatoryField
        isErrorIcon={false}
        registerOptions={{
          required: errorMessage.firstName,
          pattern: {
            value: checkFormValidation(firstNameRegex),
            message: errorMessage.firstNameInvalidMsg,
          },
        }}
      />
    </>
  );
};

const RRDUserNameForNonCN: React.FC<RRDUserNameForNonCNProps> = props => {
  const {
    firstName,
    lastName,
    firstNameRegex,
    lastNameRegex,
    isFirstNameDisabled,
    isLastNameDisabled,
    errorMessage,
    ...rest
  } = props;

  return (
    <>
      <Input
        {...rest}
        validateReset
        className="left-side"
        id="firstName"
        testId="firstName"
        name="firstName"
        readonly={isFirstNameDisabled}
        label={firstName}
        varient="outlined"
        maxLength={MI_FIELD_MAX_LENGTH.firstName}
        isMandatoryField
        isErrorIcon={false}
        registerOptions={{
          required: errorMessage.firstName,
          pattern: {
            value: checkFormValidation(firstNameRegex),
            message: errorMessage.firstNameInvalidMsg,
          },
        }}
      />
      <Input
        {...rest}
        validateReset
        className="right-side"
        id="lastName"
        testId="lastName"
        name="lastName"
        readonly={isLastNameDisabled}
        label={lastName}
        varient="outlined"
        isMandatoryField
        maxLength={MI_FIELD_MAX_LENGTH.lastName}
        isErrorIcon={false}
        registerOptions={{
          required: errorMessage.lastName,
          pattern: {
            value: checkFormValidation(lastNameRegex),
            message: errorMessage.lastNameInvalidMsg,
          },
        }}
      />
    </>
  );
};

export const RRDUserName: React.FC<RRDUserNameProps> = props => {
  const { isCNLocale, ...rest } = props;
  return isCNLocale ? <RRDUserNameForCN {...rest} /> : <RRDUserNameForNonCN {...rest} />;
};
