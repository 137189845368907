import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { theme } from '../../styles-3.0/theme';
import { toRem } from '../../styles-3.0/utils';

export const StyledRadioButtonDiv = styled.div`
  ${rtl`
  display: block;
  position: relative;
  padding: ${toRem(24)} ${toRem(34)} ${toRem(23)} ${toRem(0)};
  border-bottom: ${toRem(1)} solid rgb(238, 238, 238);
  width:fit-content;
  input[type='radio'] + label {
    font-size: ${toRem(18)};
    line-height: ${toRem(20)};
    color: ${theme.color['base-10']};
    padding-left: ${toRem(36)};
    margin-bottom: 0;   
    &:before {
      top: ${toRem(-1)};
    }
    &:after {
      top: ${toRem(6)};
    }
  }
  @media ${theme.mediaQuery.mobileOnly} {
    margin-right: ${toRem(16)};
    margin-left: ${toRem(16)};
  }
`}
`;
