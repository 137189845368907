import { FC } from 'react';

interface MockScripts {
  proxyHost: string;
}

export const MockScripts: FC<MockScripts> = props => {
  const { proxyHost } = props;
  return (
    <>
      <script src={`${proxyHost}/aries/components/mi-vendor/mi-vendor.js`} defer></script>
      <script src={`${proxyHost}/aries/components/mi-core/mi-core.js`} defer></script>
      <script src={`${proxyHost}/aries/components/mi-common/mi-common.js`} defer></script>
      <script src={`${proxyHost}/aries/standards/js/mi-foundation.js`} defer></script>
    </>
  );
};
