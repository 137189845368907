export const pageInitialData = {
  pageLevelErrors: {},
  bookingAcknowledgement: {},
  travelInsuranceWidget: { data: { insuranceAdded: false }, isError: true },
  travelAgent: { data: { isTravelAgent: false } },
  redemptionPanel: {
    data: {
      isRedemption: false,
      isBuyPoints: false,
      isBuyPointConsent: true,
      pointsDeducted: '',
      additionalPointsRequired: '',
      pointCost: '',
    },
  },
  instantCreditCard: {
    isNewCardSaved: false,
    cardType: '',
  },
  memberInformation: {
    country: '',
  },
  inlineEnrollment: {
    joinNow: false,
    signInEnroll: false,
  },
  rlmTabList: {
    selectedTab: '',
    roomDataList: [],
    resortFeeDescription: '',
    rlmTabListLoading: true,
  },
  legalConsent: {
    defaultAPACTitle: '',
  },
  isSpecialRate: true,
  rateCard: {
    showRatesWithTaxes: false,
    isLoading: true,
  },
  showSpecialRateAlert: false,
  showTALoginAlert: false,
  pageLoadData: {
    isPageLoaded: false,
  },
  roomOverview: {
    numberOfRooms: 0,
  },
  cancellationReservationDetails: {
    isFailedCancellation: false,
    isLoading: true,
  },
  modelData: {
    language: 'en',
  },
  additionalRequests: {
    comments: '',
    eta: '',
  },
};
