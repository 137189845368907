/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { logger } from '../../utils/src/logger';

interface PersistentStorage {
  getItem(key: string): string | null;
  setItem(key: string, value: any): void;
}

const { log } = logger({})('useLocalStorage');

class LocalStorage implements PersistentStorage {
  getItem(key: string) {
    const item = localStorage.getItem(key);

    if (item === null) return undefined;

    if (item === 'null') return null;
    if (item === 'undefined') return undefined;

    try {
      return JSON.parse(item);
    } catch (err) {
      log.debug('Error parsing item: ', err);
    }

    return item;
  }
  setItem(key: string, value: any) {
    if (value === undefined) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }
}

class MockStorage implements PersistentStorage {
  getItem() {
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setItem() {}
}

const persistentStorage =
  typeof window !== 'undefined' && window?.localStorage ? new LocalStorage() : new MockStorage();

export function useLocalStorage<ValueType>(key: string, initialValue?: ValueType) {
  const [value, setValue] = useState<ValueType>(() => {
    const valueFromStorage = persistentStorage.getItem(key);

    if (typeof initialValue === 'object' && !Array.isArray(initialValue) && initialValue !== null) {
      return {
        ...initialValue,
        ...valueFromStorage,
      };
    }

    return valueFromStorage || initialValue;
  });

  useEffect(() => {
    persistentStorage.setItem(key, value);
  }, [key, value]);

  return [value, setValue] as const;
}
