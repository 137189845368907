import { SearchActions, setSearchQuery, setSearchResultList } from './store.action';
import { SearchResultDataType, SearchStateStoreDataType } from './store.type';

export const InitialSearchResult: SearchResultDataType = {
  showMapView: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UpdateSearchState = (state: SearchStateStoreDataType, action: any): SearchStateStoreDataType => {
  const { type, payload } = action;
  switch (type) {
    case SearchActions.setQuery:
      return setSearchQuery(state, payload);
    case SearchActions.setFilter:
      return { ...state, filter: payload };
    case SearchActions.setApplyFilter:
    case SearchActions.updateOtherSearchQueryParam:
    case SearchActions.updatePoints:
    case SearchActions.updateTaxesAndAvailability:
    case SearchActions.updateCurrency:
    case SearchActions.updateAmenities:
    case SearchActions.updateActivities:
    case SearchActions.updateBrands:
    case SearchActions.updateTransportationTypes:
    case SearchActions.updateQueryAndOtherSearchQueryParam:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const UpdateSearchResult = (
  state: SearchResultDataType,
  action: { type: SearchActions; payload: SearchResultDataType }
): SearchResultDataType => {
  const { type, payload } = action;
  switch (type) {
    case SearchActions.setSearchResultData:
      return setSearchResultList(state, payload);
    case SearchActions.changeCurrentView:
      return { ...state, showMapView: payload.showMapView };
    case SearchActions.updateMedia:
      return { ...state, media: payload.media };
    default:
      return state;
  }
};
