const fontRootPath = '//cache.marriott.com/aka-fonts/';
const swissBase = 'MarriottDigital/swiss/';

const getFontFace = (
  fontFamily: string,
  fileWoff2: string,
  fileWoff: string,
  fontWeight: number,
  fontBasePath: string
): string => `
    @font-face {
        font-family: ${fontFamily};
        font-style: normal;
        font-weight: ${fontWeight};
        font-display: swap;
        ${
          fileWoff2
            ? `src: url(${fontRootPath}${fontBasePath}${fileWoff2}) format('woff2'), url(${fontRootPath}${fontBasePath}${fileWoff}) format('woff');`
            : `src: url(#{${fontRootPath}}#{${fontBasePath}}#{${fileWoff}}) format('woff');`
        }
        font-display: fallback;
    }
`;

export default `
  ${getFontFace(
    'Swiss-721-Condense',
    'Swiss721BT-BoldCondensed.woff2',
    'Swiss721BT-BoldCondensed.woff',
    700,
    swissBase
  )}
  ${getFontFace(
    'Swiss-721-Condense',
    'Swiss721BT-BlackCondensed.woff2',
    'Swiss721BT-BlackCondensed.woff',
    900,
    swissBase
  )}
  ${getFontFace('Swiss721-BT', 'Swiss721BT-Medium.woff2', 'Swiss721BT-Medium.woff', 500, swissBase)}
  ${getFontFace('Swiss721-BT', 'Swiss721BT-Regular.woff2', 'Swiss721BT-Regular.woff', 400, swissBase)}
  ${getFontFace('Swiss721-BT-Medium', 'Swiss721BT-Medium.woff2', 'Swiss721BT-Medium.woff', 500, swissBase)}
  ${getFontFace('Swiss721-BT-Regular', 'Swiss721BT-Regular.woff2', 'Swiss721BT-Regular.woff', 400, swissBase)}
  ${getFontFace('MarriottIconsNew', 'mi-icons.woff2', 'mi-icons.woff', 500, 'mi-icons/')}
  ${getFontFace('BrandLogoIcons', 'mi-brand-logo-icons.woff2', 'mi-brand-logo-icons.woff', 500, 'mi-brand-logo-icons/')}
`;
