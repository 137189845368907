import styled from 'styled-components';
import { toRem, theme } from '../../styles';

export const StyledDiv = styled.fieldset`
  input[type='checkbox']:checked:focus-visible + label {
    outline: ${toRem(2)} solid ${theme.colors['base-10']};
    outline-offset: ${toRem(2)};
  }
  .checkbox {
    padding: ${toRem(8)} 0;
  }
  @media ${theme.mediaQuery.allMobiles} {
    &.consentCheckClass {
      .checkbox {
        padding-bottom: 0 !important;
      }
    }
  }
  input[type='checkbox'] + label {
    padding: 0 0 0 ${toRem(34)};
    font-size: ${theme.fonts['fontM']};
    font-weight: ${theme.fonts['fontWeightRegular']};
    color: ${theme.colors['base-10']};
    line-height: ${theme.fonts['lineHeightXl']};

    &::before {
      top: ${toRem(2)};
    }

    &::after {
      top: ${toRem(4)};
    }
  }
  input[type='checkbox']:disabled:not([checked]) {
    + label {
      &::before {
      }
      &::after {
        opacity: 0;
      }
    }
  }
  .subTitle {
    margin: 0 0 ${toRem(12)};
    text-transform: none;
    letter-spacing: 0;
    font-size: ${theme.fonts['fontS']};
    color: ${theme.colors['dark-gray-3']};
    line-height: ${theme.fonts['lineHeightM']};
  }
  .title {
    font-size: ${theme.fonts['fontXxl']};
    color: ${theme.colors['base-10']};
    line-height: ${theme.fonts['lineHeightXxl']};
    font-weight: ${theme.fonts['fontWeightMedium']};
  }
  label {
    margin: 0;
  }
  .description {
    font-size: ${theme.fonts['fontXs']};
    color: ${theme.colors['base-10']};
    line-height: ${theme.fonts['lineHeightXs']};
  }
`;

export const StyledError = styled.p`
  color: ${theme.colors.monza};
  font-size: ${theme.fonts['fontS']};
  line-height: ${theme.fonts['lineHeightM']};
  padding-left: ${toRem(30)};
  margin-bottom: 0;
`;
