import { toRem } from '../../styles';
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { theme } from '../../styles-3.0';

export const StyledTooltip = styled.div`
  ${rtl`
    .tooltip-outer-container {
      .tooltip-icon {
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        &:after {
          padding-right: 0;
        }
      }
      .remove-info-icon:{
        :after{
          content:none !important;
        }
      }
      .custom-info-list {
        &:focus {
          background-color: transparent;
          border: 0;
          box-shadow: none;
        }
      }
      .tooltip-container {
        width: ${toRem(355)};
        border-radius: ${toRem(4)};
        padding: ${toRem(16)} ${toRem(10)};
        background-color: ${theme.color['white']} !important;
        box-shadow: 0 4px 20px 0 rgba(0,0,0,0.1);
        position: absolute;
        bottom: 50%;
        z-index: 10;
        text-align: left;
        pointer-events: all;
        cursor: default;
        &.display-tooltip {
          visibility: visible;
        }
        &.hide-tooltip {
          visibility: hidden;
        }
        .tooltip-subheading {
          margin-bottom: ${toRem(4)};
        }
        .tooltip-data-list {
          padding-left: ${toRem(16)};
          margin-bottom: 0;
        }
        .tooltip-tail {
          width: 0;
          height: 0;
          border-left: ${toRem(5)} solid transparent;
          border-right: ${toRem(5)} solid transparent;
          border-top: ${toRem(5)} solid ${theme.color['white']};
          position: absolute;
          bottom: ${toRem(-4)};
          margin: auto;
          left: 48%;
        }
        @media only ${theme.mediaQuery.allMobiles} {
          width: ${toRem(316)};
          right: 7%;
          .tooltip-tail {
            left: 56%;
          }
        }
      }
    }
  `}
`;
