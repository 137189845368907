/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { appendData } from './helper';
import { getDataLayer, RequestProps, getProcessEnvs } from '../../utils';
import { constants } from '../../constants';
import { IProps, DataValuetype, DataLayerTypes } from './AdobeClientDataLayer.types';

const { EVENT_DRIVEN_DATALAYER } = constants;

declare global {
  interface Window {
    // @see libs/shared/mi-headless-utils/src/components/datalayer/datalayer.provider.tsx
    adobeDataLayer: {
      [key: number]: {
        event: string;
        data: Record<string, unknown>;
      };
      version: number;
      push: (event: { event: string; data: Record<string, unknown> }) => void;
      getState: () => Record<string, unknown>;
      addEventListener: (type: string, listener: EventListenerOrEventListenerObject) => void;
      removeEventListener: (type: string, listener: EventListenerOrEventListenerObject) => void;
    };
  }
}

export const appendDataEnv = (staticListItems: DataLayerTypes[], fetchData: any) => {
  if (getProcessEnvs()['DATALAYERTYPE'] === EVENT_DRIVEN_DATALAYER) {
    appendData(staticListItems);
    fetchData();
  }
};

export const AdobeClientDataLayer = (props: IProps) => {
  const { staticListItems } = props;

  const fetchData = async () => {
    const response = await getDataLayer({
      cookies: { sessionID: 'string', UserIdToken: 'string' },
      headers: {} as RequestProps,
      query: {} as Record<string, unknown>,
      url: '',
    });

    const data: DataValuetype = {
      event: 'dynamic:loaded',
      ...response?.data[0],
    };
    // convert to standard format
    window.adobeDataLayer?.push(data as any);
  };

  useEffect(() => {
    appendDataEnv(staticListItems, fetchData);
  }, [staticListItems]);

  return (
    <div className="d-none" data-component-name="o-book-AdobeClientDataLayer">
      ACDL Component
    </div>
  );
};
