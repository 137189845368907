import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledSection = styled.section`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${toRem(30)};
  height: ${toRem(335)};
  max-height: 70vh;

  .icon-success::before {
    font-size: ${toRem(53.33)};
    color: ${theme.colors['merch-green']};
  }

  .icon-information::before {
    font-size: ${toRem(53.33)};
    color: ${theme.colors.monza};
  }

  .title {
    margin-top: ${toRem(28.34)};
    margin-bottom: ${toRem(16)};
    color: ${theme.colors['dark-gray-3']};
    text-align: center;
    font-size: ${theme.fonts['fontL']};
    line-height: ${theme.fonts['lineHeightM']};
    font-weight: ${theme.fonts['fontWeightMedium']};
  }

  .subtitle {
    margin-bottom: ${toRem(0)};
    color: ${theme.colors['base-10']};
    font-weight: normal;
    text-align: center;
    font-size: ${theme.fonts['fontS']};
    line-height: ${theme.fonts['lineHeightM']};
  }
`;
