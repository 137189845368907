/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ErrorBoundary from './ErrorBoundary';
import { logBookApplicationError } from '../../utils/errorLogging';

export const ErrorHOC =
  (Component: new () => React.Component<unknown, unknown>, componentName: string) => (props: Record<any, any>) => {
    try {
      return (
        <ErrorBoundary componentName={componentName}>
          <Component {...props} />
        </ErrorBoundary>
      );
    } catch (e) {
      console.log('component level error occured', componentName, e);
      logBookApplicationError([
        {
          errorSource: 'COMPONENT_ERROR',
          errorComponent: componentName,
          errorCode: componentName,
          errorDetail: String(e),
        },
      ]);
      return <div></div>;
    }
  };

export const ErrorHOCSub = (Component: React.ElementType, componentName: string) => (props: Record<any, any>) => {
  try {
    return (
      <ErrorBoundary componentName={componentName}>
        <Component {...props} />
      </ErrorBoundary>
    );
  } catch (e) {
    console.log('sub component level error occured', componentName, e);
    return <div></div>;
  }
};
