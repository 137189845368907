export const dataLayerKeys = [
  { name: 'brwsrLangcookie_merch', key: 'brwsrLang' },
  { name: 'device_language_preferred', key: 'device_language_preferred' },
  { name: 'env_is_prodenv_is_prod', key: 'env_is_prodenv_is_prod' },
  { name: 'env_platform', key: 'env_platform' },
  { name: 'env_site_id', key: 'env_site_id' },
  { name: 'env_site_name', key: 'env_site_name' },
  { name: 'mr_enrolled', key: 'mr_enrolled' },
  { name: 'mr_nights_to_next_level', key: 'mr_nights_to_next_level' },
  { name: 'mr_prof_address_country', key: 'mr_prof_address_country' },
  { name: 'mr_prof_address_state', key: 'mr_prof_address_state' },
  { name: 'mr_prof_authentication_state', key: 'mr_prof_authentication_state' },
  { name: 'mr_prof_cc_all', key: 'mr_prof_cc_all' },
  { name: 'mr_prof_member_program_level', key: 'mr_prof_member_program_level' },
  { name: 'mr_prof_stay_in_progress', key: 'mr_prof_stay_in_progress' },
  { name: 'mr_prof_upcoming_stay', key: 'mr_prof_upcoming_stay' },
  { name: 'page_url_query_string', key: 'page_url_query_string' },
  { name: 'prop_address_city_state_country', key: 'prop_address_city_state_country' },
  { name: 'prop_address_country_abbr', key: 'prop_address_country_abbr' },
  { name: 'prop_address_lat_long', key: 'prop_address_lat_long' },
  { name: 'prop_brand_code', key: 'prop_brand_code' },
  { name: 'prop_brand_tier', key: 'prop_brand_tier' },
  { name: 'prop_currency_type', key: 'prop_currency_type' },
  { name: 'prop_is_ers', key: 'prop_is_ers' },
  { name: 'prop_marsha_code', key: 'prop_marsha_code' },
  { name: 'prop_name', key: 'prop_name' },
  { name: 'res_rate_is_member_rate', key: 'res_rate_is_member_rate' },
  { name: 'res_rate_program', key: 'res_rate_program' },
  { name: 'res_rooms_per_night', key: 'res_rooms_per_night' },
  { name: 'res_stay_length', key: 'res_stay_length' },
  { name: 'res_stay_nights', key: 'res_room_nights_total' },
  { name: 'search_advance_purchase_days', key: 'search_advance_purchase_days' },
  { name: 'search_date_check_in', key: 'search_date_check_in' },
  { name: 'search_date_check_out', key: 'search_date_check_out' },
  { name: 'search_google_places_destination', key: 'search_google_places_destination' },
  { name: 'search_dates_flexible', key: 'search_dates_flexible' },
  { name: 'search_is_rewards_redemptions', key: 'search_is_rewards_redemption' },
  { name: 'search_multiRate_or_single', key: 'search_multiRate_or_single' },
  { name: 'search_cluster_code', key: 'search_cluster_code' },
];
export const sessionKeys = [
  { name: 'altCustId', key: 'AriesCommon.altCustId' },
  { name: 'prop_hws_tier', key: 'AriesCommon.prop_hws_tier' },
];
