import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem } from '@marriott/mi-ui-library-shop';

export const StyledBannerListItemWrapper = styled.div`
  ${rtl`
    .hvmi-banner-list{
      list-style:none;
      margin-top:${toRem(12)};
      margin-bottom:${toRem(12)};
      .hvmi-banner-list-item {
        margin-bottom:${toRem(8)};
        .icon-success{
          margin-right:${toRem(5)};
          font-size:${toRem(14)};
          line-height:1;
          &:before{
            height:${toRem(13.33)};
            width:${toRem(13.33)};
            position:relative;
            top:${toRem(3)};
          }
        }
      }
    }
  `}
`;
