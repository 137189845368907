import { FC, useEffect, useState } from 'react';
import cssClass from 'classnames';

import { Button } from '../../atoms';
import { ModalCTA } from '../ModalCTA';
import { UpSellMiniCardProps, UpsellCardDetailsProps } from './UpSellMiniCard.types';
import { StyledContent, StyledContainer, StyledImage, StyledIcon } from './UpSellMiniCard.styles';
import { UpsellCard } from './UpsellCard';
import { getUpsellCardData } from './helper';
import { constants, URL_CONSTANTS } from '../../constants';
import { addSubDirectoryPrefix } from '../../utils';

export const UpSellMiniCard: FC<UpSellMiniCardProps> = props => {
  const {
    title,
    type,
    description,
    ctaLink,
    ctaText,
    primaryCtaLink,
    primaryCtaText,
    trackingProperties,
    image,
    requiredSNA,
    availableSNA,
    roomUpgradeData,
    buttonEnabled,
    altText,
    upgradeTo,
    night,
    showDescription,
    pageType,
    target,
    snaStatus,
    upsellPendingTitle,
    upsellPendingCtaText,
    upsellPendingCtaLink,
    upsellPendingDescription,
    upsellSnaConfirmedDescription,
    upsellSnaConfirmedTitle,
    upsellSnaDeniedDescription,
    auth,
    snaTitle,
    snaSignInCTA,
    snaSignInMsg,
    handleUpgradeClick,
  } = props;

  const { ROOM_UPGRADE_TYPE, UPCOMING_RESERVATION_PAGE } = constants;
  const isUpcomingResPage = pageType === UPCOMING_RESERVATION_PAGE;
  const { SIGNIN_ONLY_OVERLAY } = URL_CONSTANTS;

  const [upsellData, setUpsellData] = useState<UpsellCardDetailsProps>({} as UpsellCardDetailsProps);

  useEffect(() => {
    const upsellCardData = getUpsellCardData({
      type,
      snaStatus,
      auth,
      snaTitle,
      snaSignInMsg,
      requiredSNA,
      availableSNA,
      description,
      upsellPendingTitle,
      upsellPendingDescription,
      upsellSnaConfirmedDescription,
      upsellSnaConfirmedTitle,
      upsellSnaDeniedDescription,
      roomUpgradeData,
      title,
      upgradeTo,
    });
    setUpsellData(upsellCardData);
  }, [auth, type]);

  const roomUpgradeType = type === ROOM_UPGRADE_TYPE;

  const btnClassName = cssClass('m-button-s button sna-btn', { disabled: !buttonEnabled });
  const roomDetailsClassName = cssClass('standard', {
    'upsell-room-details d-inline-block': roomUpgradeType,
  });
  const sectionClassName = cssClass(`${type}-container`, {
    'upcomingRes-upsell': isUpcomingResPage,
  });

  const roomName = `${roomUpgradeData.propertyHeading ?? ''}, ${roomUpgradeData.propertyDescription ?? ''}`;
  const roomModalData = { description: roomName, roomDetailsList: [{ roomPoolCode: roomUpgradeData.roomPoolCode }] };
  const updatedCurrency = roomUpgradeData?.upsellCurrency?.getSymbol();

  const renderPriceBlock = () => {
    return (
      <span className="price-block">
        <div className="sub-heading">{roomUpgradeData?.rateName}</div>
        <span className="price-text">+{upsellData.upsellPrice} </span>
        <span className="per-night-text">
          {updatedCurrency} / {night}
        </span>
      </span>
    );
  };

  const signInOnlyOverlay = (customBtnClassname: string, ctaLabel?: string) => {
    return (
      <ModalCTA
        ctaLabel={ctaLabel ?? ''}
        pageType={pageType ?? ''}
        ctaLink={addSubDirectoryPrefix(SIGNIN_ONLY_OVERLAY)}
        customBtnClassname={customBtnClassname}
      />
    );
  };

  return (
    <StyledContainer
      data-component-name="m-book-UpSellMiniCard"
      data-testid="book-UpSellMiniCard"
      className={sectionClassName}
    >
      {upsellData.snaContainer ? (
        <>
          {!upsellData.snaDeniedState ? (
            <StyledIcon className="t-line-height-m icon-suite-night-awards"></StyledIcon>
          ) : (
            ''
          )}
          <StyledContent>
            <div className="parent">
              <div className="child">
                <h3 className="heading standard">{upsellData.snaUpdatedTitle}</h3>
                <h4 className="description standard">
                  {' '}
                  {upsellData.snaUpdatedDescription}
                  {}
                </h4>
              </div>
              {!auth && signInOnlyOverlay('m-button-s button sna-btn', snaSignInCTA?.ctaText)}
              {upsellData.snaPendingState || upsellData.snaRequestedState ? (
                <div className="child">
                  <Button isLink={true} href={upsellPendingCtaLink} className={btnClassName}>
                    {upsellPendingCtaText}
                  </Button>
                </div>
              ) : (
                <> </>
              )}
            </div>
          </StyledContent>
        </>
      ) : (
        upsellData.upsellContainer && (
          <>
            <StyledImage src={image} alt={altText} loading="lazy" />
            <UpsellCard
              updatedTitle={upsellData.updatedTitle}
              showDescription={showDescription}
              updatedDescription={upsellData.updatedDescription}
              roomUpgradeType={roomUpgradeType}
              roomModalData={roomModalData}
              ctaLink={ctaLink}
              ctaText={ctaText}
              primaryCtaLink={primaryCtaLink}
              primaryCtaText={primaryCtaText}
              roomDetailsClassName={roomDetailsClassName}
              btnClassName={btnClassName}
              renderPriceBlock={renderPriceBlock}
              target={target}
              trackingProperties={trackingProperties}
              handleUpgradeClick={handleUpgradeClick}
            />
          </>
        )
      )}
    </StyledContainer>
  );
};
