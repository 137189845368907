import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { theme } from '../../styles/theme';
import { toRem } from '../../styles/utils';

export const StyledTextFieldDiv = styled.div`
  ${rtl`
  input[type='text'] {
    margin: 0;
    font-size: ${toRem(18)};
    line-height: ${toRem(20)};
    border: none;
    outline: none;
    color: ${theme.color['dark-gray-2']};
    box-sizing: border-box;
    padding: ${toRem(6)} ${toRem(48)} ${toRem(13)} 0;
    width: 100%;
    height: ${toRem(41)};
    border-bottom: ${theme.color['light-gray-6']} solid 2px;
    border-radius: 0;
    caret-color: ${theme.color['light-orange-2']};
    &:focus {
      border-bottom: ${theme.color['dark-gray-1']} solid 2px;
    }
  }
  label {
    color: ${theme.color['black']};
    text-transform: uppercase;
    letter-spacing: ${toRem(1.3)};
    display: block;
  }
`}
`;
