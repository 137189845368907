import dynamic from 'next/dynamic';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { RoomDetailsModalProps } from './RoomDetailsModal.types';

export const RoomDetailsModal = dynamic<RoomDetailsModalProps>(() =>
  import('./RoomDetailsModal').then(module => module.RoomDetailsModal)
);

export const RoomDetailsModalConfig = {
  emptyLabel: 'roomdetailsmodal',
  isEmpty: false,
  resourceType: `mcom-book-spa/components/foundation/roomdetails`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RoomDetailsModalEditable = (props: any) => {
  return (
    <EditableComponent config={RoomDetailsModalConfig} {...props}>
      <RoomDetailsModal {...props} />
    </EditableComponent>
  );
};
