import { FC } from 'react';
import cssClass from 'classnames';

import { Title } from '../../atoms';
import { PrefDataTypes, StayDataCard, StayDetailsCardProps } from './StayDetailsCard.types';
import { StyledSection } from './StayDetailsCard.styles';

export const StayDetailsCard: FC<StayDetailsCardProps> = props => {
  const { stayData, loading } = props;
  const {
    stayDates,
    totalForStay,
    room,
    adults,
    roomPreferences,
    rate,
    roomTypeLabel,
    points,
    rooms,
    children,
    guaranteed,
    soldOut,
    requestNoted,
    nonGuaranteed,
    noPreferencesSelected,
    title,
    confirmationNumber: confirmationNumberLabel,
    number,
  } = props;

  const sectionClassName = cssClass({ hide: loading });
  const {
    isRedemption,
    roomType,
    roomCount,
    adultCount,
    childCount,
    rateType,
    startDate,
    endDate,
    totalAmount,
    currency,
    preferenceData,
    totalPoints,
    commissionRate,
    confirmationNumber,
    isGuestViewable,
  } = stayData as StayDataCard;

  const showTotalStay = isGuestViewable || isRedemption;

  const updatedCurrency = currency?.getSymbol();
  return (
    <StyledSection
      data-component-name="m-book-StayDetailsCard"
      data-testid="book-StayDetailsCard"
      className={sectionClassName}
    >
      {Number(number?.slice(-1)) === 1 || !confirmationNumber ? (
        <div className="flex-full-width">
          <Title text={title} type="span" customClassName="subtitle-xxLarge" />
        </div>
      ) : null}

      {/* confirmation number */}
      {confirmationNumber ? (
        <div className="flex-full-width">
          <Title
            text={`${confirmationNumberLabel}: ${confirmationNumber}`}
            type="span"
            customClassName="subtitle-xLarge"
          />
        </div>
      ) : null}

      {/* stay dates */}
      <div className="flex-item-left">
        <span className="item-label">{stayDates}</span>
        <p className="font-m " data-testid="stayDates">
          {startDate + ' - ' + endDate}
        </p>
      </div>
      {/* total for stay */}
      <div className="flex-item-right">
        {showTotalStay ? (
          <>
            <span className="item-label">{totalForStay}</span>
            <p>
              {totalAmount && (
                <>
                  <span className="font-m ">{totalAmount}</span>
                  <span className="currency t-font-xs"> {updatedCurrency?.getSymbol()}</span>
                  {isRedemption && <span className="icon-plus t-font-xs mr-1 ml-1"></span>}
                </>
              )}
              {isRedemption && (
                <>
                  <span className="font-m ">{totalPoints}</span>
                  <span className="currency t-font-xs"> {points}</span>
                </>
              )}
            </p>
          </>
        ) : null}
      </div>
      {/* room type */}
      <div className="flex-item-left">
        <span className="item-label">{roomTypeLabel}</span>
        <p>{roomType}</p>
      </div>
      {/* room count, adults, children */}
      <div className="flex-item-right d-inline-flex">
        <div className="xl-margin-right">
          <span className="item-label">{rooms}</span>
          <p>{roomCount}</p>
        </div>
        <div className="xl-margin-right">
          <span className="item-label">{adults}</span>
          <p>{adultCount}</p>
        </div>
        {childCount ? (
          <div>
            <span className="item-label">{children}</span>
            <p>{childCount}</p>
          </div>
        ) : null}
      </div>
      {/* room preferences */}
      <div className="flex-item-left">
        <span className="item-label room-pref">{roomPreferences}</span>
        {preferenceData &&
          preferenceData.map((data: PrefDataTypes, idx) => {
            return (
              <span key={'room' + idx}>
                {data.noPrefSelected ? (
                  <>
                    {Number(roomCount) === preferenceData.length && Number(roomCount) > 1 && (
                      <span className="room-label item-label">{room + ' ' + data.roomIndex}</span>
                    )}
                    <p className="description">{noPreferencesSelected}</p>
                  </>
                ) : (
                  <>
                    {Number(roomCount) === preferenceData.length && Number(roomCount) > 1 && (
                      <span className="room-label item-label">{room + ' ' + data.roomIndex}</span>
                    )}
                    {data.soldOutPref && (
                      <p className="description">
                        <span className="item-label">{soldOut}:&nbsp;</span>
                        {data.soldOutMsg}.
                      </p>
                    )}
                    {data.guaranteedPref && (
                      <p className="description">
                        <span className="item-label">{guaranteed}:&nbsp;</span>
                        {data.guaranteedMsg}.
                      </p>
                    )}
                    {data.notGuaranteedPref && (
                      <p className="description">
                        <span className="item-label">{nonGuaranteed}:&nbsp;</span>
                        {data.notGuaranteedMsg}.
                      </p>
                    )}
                    {data.requestNotedPref && (
                      <p className="description">
                        <span className="item-label">{requestNoted}:&nbsp;</span>
                        {data.requestNotedMsg}.
                      </p>
                    )}
                  </>
                )}
              </span>
            );
          })}
      </div>
      {/* rate type */}
      <div className="flex-item-right">
        <span className="item-label">{rate}</span>
        <p className="description t-capitalize">
          {rateType}
          <br />
          {commissionRate?.['requestNotedPref'] && `(${commissionRate['requestNotedMsg']})`}
        </p>
      </div>
    </StyledSection>
  );
};
