import { useState, useEffect } from 'react';
import { canUseDOM } from './helper';

export const useScreenWidth = () => {
  const [windowWidth, setWindowWidth] = useState(0);

  const getWidth = () => (canUseDOM ? window.innerWidth : windowWidth);

  const resize = () => setWindowWidth(getWidth());

  useEffect(() => {
    if (canUseDOM) {
      setWindowWidth(getWidth());
      window.addEventListener('resize', resize);
      return () => window.removeEventListener('resize', resize);
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [canUseDOM]);

  return windowWidth;
};
