import React, { FC } from 'react';
import { ComponentProps } from '../HvmiBannerComponent.type';
import { StyledHVMIBanenrWrapper } from './index.styles';
import TitleDescComponent from './TitleDescComponent';
import { BannerListComponent } from './ListComponent';
import { BannerImageComponent } from './ImageComponent';
import clsx from 'clsx';

export const HVMIBannerComponent: FC<ComponentProps> = ({
  hvmiBannerPointsList,
  hvmiTitleText,
  hvmiDescriptionText,
  hvmiCtaLabel,
  hvmiCtaLink,
  hvmiImageLabel,
  hvmiBrandName,
  hvmiImagePath,
  disableHvmiBanner,
  hvmiBrandLogoIcon,
}) => {
  const clickTrackingLoc = 'ad impression Module';
  return (
    <StyledHVMIBanenrWrapper
      className={clsx(
        'color-scheme3 banner-container py-5',
        // if we will get disableHvmiBanner in string, or we will get disableHvmiBanner as a boolean
        disableHvmiBanner ? 'hide-hvmi-banner d-none' : ''
      )}
    >
      <div className="row">
        <div className="col-lg-4 col-md-5 col-12">
          <BannerImageComponent
            label={hvmiImageLabel}
            hvmiBrandLogoIcon={hvmiBrandLogoIcon}
            {...hvmiImagePath}
            altText={hvmiImageLabel + '-hvmi'}
          />
        </div>
        <div className="col-lg-8 col-md-7 col-12">
          <TitleDescComponent bannerDesc={hvmiDescriptionText ?? ''} bannerTitle={hvmiTitleText} />
          <BannerListComponent bannerList={hvmiBannerPointsList} />
          <a
            href={hvmiCtaLink}
            target="_blank"
            rel="noreferrer"
            className="m-button-s m-button-secondary custom-button analytics-click custom_click_track"
            {...{ custom_click_track_value: `${clickTrackingLoc}:${hvmiBrandName}|${hvmiCtaLabel}| ad |internal` }}
          >
            {hvmiCtaLabel}
          </a>
        </div>
      </div>
    </StyledHVMIBanenrWrapper>
  );
};
