import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledSection = styled.div`
  .mobile-number-container {
    display: grid;
    row-gap: ${toRem(32)};
    column-gap: ${toRem(16)};
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 60px;

    .left-side {
      grid-column: 1/6;
    }
    .right-side {
      grid-column: 6/11;
    }
  }

  .optoutmsg-container {
    margin: 0 0 ${toRem(32)};
    .text-field {
      font-size: ${theme.fonts.fontS};
      color: ${theme.colors['base-10']};
      line-height: ${theme.fonts.lineHeightM};
    }
  }
  .tnc-container {
    margin: ${toRem(18)} 0 ${toRem(32)};
    &.with-larger-margin-top {
      margin-top: ${toRem(36)};
    }
    .text-field {
      font-size: ${theme.fonts.fontS};
      color: ${theme.colors['base-10']};
      line-height: ${theme.fonts.lineHeightM};
    }
    .ctaLink {
      font-size: ${theme.fonts.fontS};
      color: ${theme.colors['base-10']};
      line-height: ${theme.fonts.lineHeightM};
      background-color: ${theme.colors.white};
      margin-left: ${toRem(10)};
      padding: 0;
      border-bottom: ${toRem(1)} solid ${theme.colors['base-10']};
    }
    .ctaLink:focus-visible {
      border: 2px solid ${theme.colors['base-10']};
      border-radius: ${toRem(4)};
      padding: ${toRem(1)};
    }
  }

  @media ${theme.mediaQuery.allMobiles} {
    .mobile-number-container {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 0;
      grid-template-rows: repeat(2, 60px);

      .left-side {
        grid-column: 1/10;
      }
      .right-side {
        grid-column: 1/10;
      }
    }
  }
`;

export const StyledModalDiv = styled.div`
  &.modal-description {
    padding: ${toRem(32)};
    background-color: ${theme.colors.white};
    line-height: ${theme.fonts.lineHeightXl};
    border-radius: 0 0 ${toRem(14)} ${toRem(14)};
  }
`;
