import styled from 'styled-components';

import { theme, toRem } from '../../styles';

export const StyledSection = styled('section')<{ isRrdPage: boolean }>(
  ({ isRrdPage }) => `
  display: flex;
  flex: 1;
  padding: ${isRrdPage ? 0 : `${toRem(16)} ${toRem(24)}`};
  box-sizing: border-box;
  border-radius: ${!isRrdPage && toRem(8)};
  background-color: ${!isRrdPage && theme.colors.background};
  font-family: ${theme.fontFamily.swiss};
  margin: auto;
  .title {
    font-size: ${theme.fonts.fontS};
    font-weight: ${theme.fonts.fontWeightMedium};
    line-height: ${theme.fonts.fontXl};
    margin-bottom: ${toRem(11)};
  }
  .description {
    font-size: ${theme.fonts.fontXs};
    font-weight: normal;
    line-height: ${theme.fonts.fontM};
    margin: 0;
  }
  .title-rrd {
    font-size: ${toRem(30)};
    font-weight: ${theme.fonts.fontWeightRegular};
    line-height: ${toRem(32)};
    margin-bottom: 0;
    font-family: ${theme.fontFamily.swiss};
  }
  .description-rrd {
    font-size: ${toRem(16)};
    font-weight: normal;
    line-height: ${theme.fonts.lineHeightL};
    font-family: ${theme.fontFamily.swiss};
    margin-bottom: 0;
  }
`
);
