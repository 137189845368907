/* eslint-disable @typescript-eslint/no-explicit-any */
import { CreateStoreActionType } from './store.types';

/**
 * An External Store with Subscriber mechanism.
 * @store
 * @param    {any}    initialData            initial data for store
 * @param    {(currentState: any, listeners: any, setState: (arg: any) => void)=>Actions}   CustomActionFunction custom actions require to manupulate store.

 * @example  let store = createStore({a: 1}, (currentState, listeners, setState)=>{})
 * 
 * @return   {{getState, subscribe, ...actions}}  store instance.
 */
export function createStore<Type>(initialData: any, actions: CreateStoreActionType): Type {
  let currentState = initialData;
  const listeners = new Set<any>();

  function setState(updatedState: any) {
    currentState = updatedState;
  }

  return {
    getState: () => currentState,
    subscribe: (listener: any) => {
      listeners.add(listener);
      return () => listeners.delete(listener);
    },
    ...actions(currentState, listeners, setState),
  };
}
