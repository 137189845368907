/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useRef } from 'react';
import cssClass from 'classnames';

import { TabProps } from './Tab.types';
import { StyledTabs } from './Tab.styles';

import { constants, TRACKING_CONSTANT } from '../../constants';

const { ENTER_KEY, MODIFY_RATE_TAB, RATE_TAB } = constants;
const { INTERNAL_LINK } = TRACKING_CONSTANT;

export const Tab = (props: TabProps) => {
  const { tabList = [], onTabClick, defaultSelected, isRlmPage } = props;
  const [selected, setSelected] = useState(tabList[0].name);
  const itemsRef = useRef<any>([]);
  const trackingDesc = isRlmPage ? RATE_TAB : MODIFY_RATE_TAB;

  useEffect(() => {
    // Update itemsRef for tabItems
    itemsRef.current = itemsRef.current.slice(0, tabList.length);
  }, [tabList]);

  useEffect(() => {
    if (defaultSelected) {
      setSelected(defaultSelected);
    }
  }, [defaultSelected]);

  const tabClickUpdate = (selectedName: string, index: number) => {
    if (onTabClick) {
      onTabClick(selectedName);
    }
    // Scroll selected item to center
    itemsRef.current[index].scrollIntoView?.({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  const handleClick = (selectedName: string, index: number) => {
    setSelected(selectedName);
    tabClickUpdate(selectedName, index);
  };

  const handlekeyDown = (e: React.KeyboardEvent<HTMLLIElement>, selectedName: string, index: number) => {
    if (e.keyCode === ENTER_KEY) {
      setSelected(selectedName);
      tabClickUpdate(selectedName, index);
    }
  };

  return (
    <StyledTabs data-component-name="a-book-Tab" data-testid="book-Tab">
      {tabList.map((item, index) => (
        <li
          ref={el => (itemsRef.current[index] = el)}
          role="tab"
          tabIndex={0}
          key={item.name}
          data-testid={item.name}
          onClick={() => handleClick(item.name, index)}
          onKeyDown={e => handlekeyDown(e, item.name, index)}
          aria-selected={selected === item.name}
          className={cssClass('custom_click_track', { selected: selected === item.name })}
          data-custom_click_track_value={`${trackingDesc}|${item.label}|${INTERNAL_LINK}`}
        >
          <div className="title">{item.label}</div>
          <div className="subTitle">{item.subtitle}</div>
        </li>
      ))}
    </StyledTabs>
  );
};
