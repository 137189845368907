/* eslint-disable @typescript-eslint/no-explicit-any */
// Imports for external libraries go here.
import React, { FC, useState, useEffect } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { ExploreDestinationsProps, DestinationType, DestionationsListType } from './ExploreDestinations.types';
import { StyledExploreDestinations } from './ExploreDestinations.styles';
import { Accordion, Heading, Link, Types, useCheckBreakpoint, Text, useWindowSize } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { TRACKING_CONST } from '../../modules/constants/HotelDirectory.constants';
import { ExploreDestinationSkeletonLoader } from './ExploreDestinationSkeletonLoader';
import { useHotelDirectoryStore } from '../../modules/store/hotelDirectoryStore';
import { formatNumberByLocale } from '../../utils/src/utils';
import { usePageContext } from '../../context';

// Use named rather than default exports.
export const ExploreDestinations: FC<ExploreDestinationsProps> = (props: ExploreDestinationsProps) => {
  const { model, isDesktop } = props;
  const destinationDetailsObj = model && model.destinationDetails ? JSON.parse(model.destinationDetails) : {};
  const { tags, headingType, size } = Types;
  const { exploreDestinationHeading } = model;
  // const isDesktop = useCheckBreakpoint('viewportL'); // check if viewport is Desktop
  const { HOTEL_DIRECTORY } = TRACKING_CONST;
  const { currentLocale } = usePageContext();
  const [hotelsCountHeading, setHotelsCountHeading] = useState(exploreDestinationHeading);
  const totalNoOfHotelsWorldwide = useHotelDirectoryStore(
    (state: { totalNoOfHotelsWorldwide: any }) => state.totalNoOfHotelsWorldwide
  ).totalNoOfHotelsWorldwide;

  useEffect(() => {
    setHotelsCountHeading(
      exploreDestinationHeading?.replace(
        '{x}',
        formatNumberByLocale(totalNoOfHotelsWorldwide, currentLocale?.replace('_', '-'))
      )
    );
  }, [totalNoOfHotelsWorldwide]);

  /**
   * Function to create markup based on viewport
   * creates 2 columns in case of Desktop, 1 column otherwise
   **/
  const createMarkup = () => {
    // sorting destionations list
    const sortedDestionationsList: DestionationsListType = Object.keys(destinationDetailsObj)
      .sort() // sorts the destination buckets
      .reduce((acc: DestionationsListType, currentValue: string) => {
        acc[currentValue] = destinationDetailsObj[currentValue];
        return acc;
      }, {});

    // If viewport is NOT a desktop, then render all the destinations in a single column
    if (!isDesktop) {
      return (
        <div className={clsx('d-flex', 'flex-column', 'align-self-stretch')}>
          {createAccordionMarkup(sortedDestionationsList)}
        </div>
      );
    }

    // If viewport is a desktop, then render two columns
    const results1: DestionationsListType = {};
    const results2: DestionationsListType = {};
    let count = 0;
    let idx: string;
    for (idx in sortedDestionationsList) {
      if (count >= 5) {
        results2[idx] = sortedDestionationsList[idx];
      } else if (count < 5) {
        results1[idx] = sortedDestionationsList[idx];
      }
      count += 1;
    }

    return (
      <div className={clsx('d-flex')}>
        <div className={clsx('content-1')}>{createAccordionMarkup(results1)}</div>
        <div className={clsx('content-2')}>{createAccordionMarkup(results2)}</div>
      </div>
    );
  };

  /**
   * Function to create markup related to the Accordion
   * which is used at multiple places
   */
  const createAccordionMarkup = (data: DestionationsListType) =>
    Object.entries(data)?.map(([destinationBucket, cities], index) => (
      <div className="destination-item">
        <Accordion
          key={destinationBucket + index}
          headerChildren={
            <Heading
              element={tags.h3}
              variation={headingType.title}
              titleText={destinationBucket}
              customClass={clsx('destination-title')}
            ></Heading>
          }
          id={`destionation-list-${destinationBucket}-${index}`}
          children={
            <div className="d-flex flex-column">
              {cities?.map((city: DestinationType) => (
                <Link
                  key={city.destinationPlace}
                  text={city.destinationPlace}
                  linkHref={city.destinationUrl}
                  ariaLabel={`All hotels in ${city.destinationPlace}`}
                  linkClassName={clsx('destination-item-content-link', 'custom_click_track')}
                  custom_click_track_value={`${HOTEL_DIRECTORY} | ${destinationBucket}: ${city.destinationPlace} | internal`}
                  target="_self"
                ></Link>
              ))}
            </div>
          }
          custom_click_track_closed_value={`${HOTEL_DIRECTORY} | ${destinationBucket}: Close | internal`}
          custom_click_track_open_value={`${HOTEL_DIRECTORY} | ${destinationBucket}: Open | internal`}
          disableAccResize={true}
        ></Accordion>
      </div>
    ));

  return (
    <StyledExploreDestinations
      data-testid="exploredestinations"
      data-component-name="o-shop-exploredestinations"
      className={clsx('standard')}
    >
      <div className={clsx('destinations-container', 'd-flex', 'flex-column', 'flex-wrap', 'container', 'mx-auto')}>
        <div className={clsx('destinations-subheading')}>
          <Text
            copyText={hotelsCountHeading}
            element={tags.div}
            customClass={clsx('t-title-s')}
            fontSize={size.large}
          ></Text>
        </div>
        {createMarkup()}
      </div>
    </StyledExploreDestinations>
  );
};

export const ExploreDestinationsConfig = {
  emptyLabel: 'ExploreDestinationsComponent',
  isEmpty: () => false,
  resourceType: 'mi-aem-shop-spa/components/content/exploredestinations/v1/exploredestinations',
};

export const ExploreDestinationsEditable = (props: ExploreDestinationsProps) => {
  const isDesktop = useCheckBreakpoint('viewportL'); // check if viewport is Desktop
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const { width } = useWindowSize();
  useEffect(() => {
    if (width) {
      setPageLoaded(true);
    }
  }, [width]);
  return (
    <EditableComponent config={ExploreDestinationsConfig} {...props}>
      {pageLoaded ? (
        <ExploreDestinations {...props} isDesktop={isDesktop} />
      ) : (
        <ExploreDestinationSkeletonLoader></ExploreDestinationSkeletonLoader>
      )}
    </EditableComponent>
  );
};
