import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import { theme } from '../../styles/theme';
import { toRem } from '../../styles/utils';

export const SliderWithStyle = withStyles({
  root: {
    color: `${theme.color.white}`,
    width: 'calc(100% - 72px)',
    height: `${toRem(8)}`,
    padding: `${toRem(13)} 0`,
  },
  thumb: {
    height: `${toRem(32)}`,
    width: `${toRem(32)}`,
    backgroundColor: `${theme.color.white}`,
    border: `${toRem(1)} solid ${theme.color['light-gray-6']}`,
    marginTop: `-${toRem(16)}`,
    marginLeft: `-${toRem(16)}`,
    '&:focus, &:hover, &$active': {
      boxShadow: 'none',
    },
  },
  track: {
    height: `${toRem(2)}`,
    backgroundColor: `${theme.color['dark-gray-2']}`,
  },
  rail: {
    height: `${toRem(2)}`,
    backgroundColor: `${theme.color['dark-gray-2']}`,
    opacity: 1,
  },
  valueLabel: {
    backgroundColor: `${theme.color.black}`,
    height: `${toRem(8)}`,
    width: `${toRem(1)}`,
    marginTop: `-${toRem(3)}`,
  },
  active: {},
  markLabel: {
    transform: 'translateX(0)',
    fontFamily: `${theme.fontFamily.title}`,
    '&[data-index="0"]': {
      left: `${toRem(-32)} !important`,
      right: 'unset !important',
    },
    '&[data-index="1"]': {
      left: 'unset !important',
      right: `${toRem(-32)}`,
    },
    color: `${theme.color.black}`,
    top: `${toRem(30)}`,
  },
  mark: {
    backgroundColor: `${theme.color.black}`,
  },
  markActive: {
    backgroundColor: `${theme.color.black}`,
    color: `${theme.color.black}`,
  },
})(Slider);
