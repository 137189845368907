import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledWrapperDiv = styled.div`
  ${rtl`
    
    .heading {
      font-size: ${toRem(22)};
      line-height: ${toRem(28)};
    }
    .m-message-content{
      line-height: ${toRem(22)};
      font-weight: 500;
    }

    .subheading {
      font-size: ${toRem(14)};
      line-height: ${toRem(21)};
    }
    .m-input-text-field > label {
      line-height: ${toRem(12)};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: ${toRem(270)};
    }
    .component-wrapper {
      height: ${toRem(52)};
      width: 100%;

      @media ${baseVariables.mediaQuery.md} {
        max-width: ${toRem(320)};
        padding-right: 0;
      }

      @media ${baseVariables.mediaQuery.lg} {
        max-width: ${toRem(530)};
        padding-right: 0;
        transform: rotate(0);
        z-index: 1;
      }
    }
  `}
`;

export const StyledMeetingsAndEventsForm = styled.div``;
