import { currencyLocales } from '../../constants/lib/constants';

/**
 * Function to take currency as input and generate the symbol for the same.
 * @param currency : selected currency being provided as input
 * @returns the symbol of the particular currency
 */
export const getCurrencySymbol = (currency: string) => {
  const locale = currencyLocales.filter(item => {
    if (item.currency?.toLocaleUpperCase() === currency?.toLocaleUpperCase()) {
      return item;
    }
    return 0;
  });
  const currenySymbol = (0)
    .toLocaleString(locale[0]?.locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
  return currenySymbol?.includes('$') ? '$' : currenySymbol;
};
