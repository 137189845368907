import { FC } from 'react';
import { ErrorHandlerAlert } from '../../molecules';
import { HVMIBannerComponent } from './component';
import { ComponentProps } from './HvmiBannerComponent.type';
import { EditableComponent } from '@adobe/aem-react-editable-components';

const HvmiBanner: FC<ComponentProps> = props => {
  const { id } = props;
  return (
    <ErrorHandlerAlert alertMessage={props?.componentErrorMessage}>
      <div id={id}>
        <div className="color-scheme3 banner-container py-5" id="serp-hvmi-banner-component-container">
          <HVMIBannerComponent {...props?.model} />
        </div>
      </div>
    </ErrorHandlerAlert>
  );
};

//Config for aem editable components
export const HvmiBannerConfig = {
  emptyLabel: 'HvmiBanner',
  isEmpty: false,
  resourceType: 'mi-aem-shop-spa/components/content/hvmibanner/v1/hvmibanner',
};
//Component wrapped with editable component for aem authoring
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HvmiBannerEditable = (props: any) =>
  props.cqPath.includes('experiencefragment') ? (
    <HvmiBanner {...props} />
  ) : (
    <EditableComponent config={HvmiBannerConfig} {...props}>
      <HvmiBanner {...props} />
    </EditableComponent>
  );

export default HvmiBanner;
