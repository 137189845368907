import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledSection = styled.div`
  &.modify-res-msg,
  &.additional-charges-msg {
    margin-bottom: ${toRem(32)};
    padding-left: ${toRem(24)};
    .t-font-m {
      font-size: ${theme.fonts.fontXxl};
      line-height: ${theme.fonts.lineHeightXxl};
    }
    .t-font-s {
      font-size: ${theme.fonts.fontM};
      line-height: ${theme.fonts.lineHeightXl};
    }
  }
  &.additional-charges-msg {
    margin-top: ${toRem(22)};
  }

  @media ${theme.mediaQuery.allMobiles} {
    &.modify-res-msg,
    &.additional-charges-msg {
      padding-left: ${toRem(6)};
    }
    &.additional-charges-msg {
      margin-top: ${toRem(14)};
    }
  }
`;
