import { propertySessionDataType } from '../../context/SessionUpdateContext/index.types';

// action types expeted in HQV Page
export enum HQV_ACTIONS {
  AMENETIES_AVAILABLE = 'AMENETIES-AVAILABLE',
  PHOTOS_AVAILABLE = 'PHOTOS-AVAILABLE',
  PROPERTY_DETAILS_AVAILABLE = 'PROPERTY-DETAILS-AVAILABLE',
  INFORMATION_AVAILABLE = 'INFORMATION-AVAILABLE',
  PROPERTY_IDENTITY = 'PROPERTY_IDENTITY',
  MODAL_OPEN_STATE = 'MODAL_OPEN_STATE',
  PROPERTY_SESSION_DATA = 'PROPERTY_SESSION_DATA',
}

export interface hqvInitialStateType {
  amenetiesAvailable: boolean;
  photosAvailable: boolean;
  propertyDetailsAbailable: boolean;
  informationAvailable: boolean;
  propertyIDvalue: string;
  modalOpenState: boolean;
  propertySessionData: propertySessionDataType;
}

// Initial default state
export const hqvInitialState = {
  amenetiesAvailable: false,
  photosAvailable: false,
  propertyDetailsAbailable: false,
  informationAvailable: false,
  propertyIDvalue: '',
  modalOpenState: false,
  propertySessionData: {},
};

// reducer method
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hqvReducer = (state: hqvInitialStateType, action: { type: HQV_ACTIONS; payload: any }) => {
  switch (action.type) {
    case HQV_ACTIONS.AMENETIES_AVAILABLE: {
      return { ...state, amenetiesAvailable: action.payload };
    }
    case HQV_ACTIONS.PHOTOS_AVAILABLE: {
      return { ...state, photosAvailable: action.payload };
    }
    case HQV_ACTIONS.PROPERTY_DETAILS_AVAILABLE: {
      return { ...state, propertyDetailsAbailable: action.payload };
    }
    case HQV_ACTIONS.INFORMATION_AVAILABLE: {
      return { ...state, informationAvailable: action.payload };
    }
    case HQV_ACTIONS.PROPERTY_IDENTITY: {
      return { ...state, propertyIDvalue: action.payload };
    }
    case HQV_ACTIONS.MODAL_OPEN_STATE: {
      return { ...state, modalOpenState: action.payload };
    }
    case HQV_ACTIONS.PROPERTY_SESSION_DATA: {
      return { ...state, propertySessionData: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};
