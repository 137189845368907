import { createGlobalStyle } from 'styled-components';
import fonts from './fonts.styles';
import icons from './icons.styles';
import cta from './cta.styles';
import typography from './typography.styles';
import { toRem } from './utils';
import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
    ${fonts}
    * {
        box-sizing: border-box;
    }
    html,body {
        border: 0;
        font-size: 100%;
        margin: 0;
        padding: 0;
        vertical-align: baseline;
        scroll-behavior: smooth;
    }
    ${icons}
    ${typography}
    .sr-only {
        position: absolute;
        width: ${toRem(1)};
        height: ${toRem(1)};
        padding: 0;
        margin: ${toRem(-1)};
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }
    ${cta}
    .h-r-center-item {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .custom-scrollbar {
        ::-webkit-scrollbar {
            width: ${toRem(5)};
        }

        ::-webkit-scrollbar-track {
            background: ${theme.color.white};
            border-radius: ${toRem(2)};
            margin: ${toRem(12)} 0;
        }

        ::-webkit-scrollbar-thumb {
            background-color: ${theme.color['light-gray-9']};
            border-radius: ${toRem(2)}
        }

        ::-webkit-scrollbar-thumb:hover{
            background: ${theme.color['dark-gray-10']}
        }
    }
    .MuiAutocomplete-clearIndicator {
        visibility: hidden;
    }
    .MuiAutocomplete-endAdornment {
        position: absolute;
    }
    .blue-outline {
        button:focus,
        input:focus + span {
            @media ${theme.mediaQuery.tablet} {
                outline: ${toRem(2)} solid ${theme.color['outline-blue']};
                outline-offset: ${toRem(10)};
            }
        }
    }
`;
