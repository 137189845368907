import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledHVMIBanenrWrapper = styled.div`
  ${rtl`
  &.hide-hvmi-banner{
    display:none;
  }
    border-top: 1px dashed ${theme.color['dark-gray-6']};
    border-bottom: 1px dashed ${theme.color['dark-gray-6']};
    padding: ${toRem(24)} 0;
    max-width: ${toRem(860)};
    margin:0 auto;
    @media only ${theme.mediaQuery.tabletMax} {
      margin:0 ${toRem(20.5)};
    }
    a.custom-button {
      padding: ${toRem(8)} ${toRem(24)} ${toRem(7)};
      text-align:center;
      height:auto;
      width:auto;
      @media only ${theme.mediaQuery.mobileOnly} {
        margin-top:${toRem(8)};
      }
      :hover{
        color:${theme.color['white']};
      }
    }
  `}
`;
