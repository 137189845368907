import React, { FC } from 'react';
import clsx from 'clsx';

import { MapAndRegionCardSkeletonLoaderProps } from './MapAndRegionCardSkeletonLoader.types';
import { StyledMapAndRegionCardSkeletonLoader } from './MapAndRegionCardSkeletonLoader.styles';
import { RegionCardSkeletonLoader } from '../../../molecules/RegionCard/RegionCardSkeletonLoader';

export const MapAndRegionCardSkeletonLoader: FC<MapAndRegionCardSkeletonLoaderProps> = () => {
  return (
    <StyledMapAndRegionCardSkeletonLoader>
      <div
        style={{ height: '500px' }}
        className={clsx('d-flex', 'justify-content-center', 'align-items-center', 'loader-container-1')}
      >
        <div className="m-spinner-m"></div>
      </div>
      <div
        style={{ height: '1463px' }}
        className={clsx('d-flex', 'justify-content-center', 'align-items-center', 'loader-container-2')}
      >
        <RegionCardSkeletonLoader type=""></RegionCardSkeletonLoader>
      </div>
    </StyledMapAndRegionCardSkeletonLoader>
  );
};
