export const SessionDataMap = {
  'cacheData.data.AriesReservation.propertyId': 'propertyId',
  'cacheData.data.AriesReservation.availabilityRequestVO.lengthOfStay': 'lengthOfStay',
  'cacheData.data.AriesReservation.brandCode': 'brandCode',
};

export const DataMap = {
  roomTypeById: 'roomTypeById',
  property: 'property',
};

export const APIDataMap = {
  'basicInformation?.yearBuilt': 'yearBuilt',
  'basicInformation?.roomRenovation': 'roomRenovation',
  'policies?.smokefree': 'smokefree',
  'policies?.petsAllowed': 'petsAllowed',
  'policies?.petsPolicyDescription': 'petsPolicyDescription',
  'policies?.localizedPetsPolicyDescription': 'localizedPetsPolicyDescription',
  'fitness.edges': 'fitness',
  parking: 'parking',
  attributes: 'attributes',
};

export const ImagesDataMap = {
  'property?.media?.photoGallery?.all': 'imageData',
  hotelPhotoTours: 'hotelPhotoTours',
};
