import { FC } from 'react';

import { TranslationDisclaimerProps } from './TranslationDisclaimer.types';
import { StyledWrapper } from './TranslationDisclaimerTile.styles';

const TranslationDisclaimerTile: FC<TranslationDisclaimerProps> = ({
  disclaimerText,
  classname,
  disableBackgroundStyling = false,
}: TranslationDisclaimerProps) => {
  return disclaimerText ? (
    <StyledWrapper className={classname} disableBackgroundStyling={disableBackgroundStyling}>
      <span dangerouslySetInnerHTML={{ __html: disclaimerText }} />
    </StyledWrapper>
  ) : null;
};

export default TranslationDisclaimerTile;
