import { constants } from '../../../../src/constants';
import { getItemFromPageModel, respGridUtil } from '../../../../src/utils';
import { ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { StyledLayoutContainer } from './index.styles';
import Head from 'next/head';
import Props from './index.type';
const { NEXT_PUBLIC_AEM_PATH } = process.env;
const { ITEM_PATH_HEADER, ITEM_PATH_FOOTER } = constants;

export const Layout = ({
  children,
  title,
  metaNames,
  metaProps,
  headerFooterJS,
  headerFooterCSS,
  pageIdentifier,
  model,
}: Props) => {
  const headerModel = getItemFromPageModel(model, ITEM_PATH_HEADER),
    footerModel = getItemFromPageModel(model, ITEM_PATH_FOOTER),
    headerModelProps = respGridUtil(headerModel),
    footerModelProps = respGridUtil(footerModel);
  return (
    <StyledLayoutContainer>
      <Head>
        <meta charSet="UTF-8" />
        <title>{title}</title>
        <link rel="icon" type="image/x-icon" href={model.favIcon} />
        {metaNames?.map(metaName => (
          <meta key={metaName.name} name={metaName.name} content={metaName.value}></meta>
        ))}
        {metaProps?.map(metaProperty => (
          <meta key={metaProperty.name} property={metaProperty.name} content={metaProperty.value}></meta>
        ))}
        <link rel="canonical" href={model.canonical} />
        <script src={model.launchScript} async></script>
        <script src={model.dataLayerScript} async></script>
        <link rel="preload" href={headerFooterCSS} as="style" />
        <link rel="stylesheet" type="text/css" href={headerFooterCSS} />
      </Head>
      <div className="brand-css" aria-atomic={true}>
        <ResponsiveGrid
          {...headerModelProps}
          model={headerModel}
          pagePath={NEXT_PUBLIC_AEM_PATH}
          itemPath={ITEM_PATH_HEADER}
        />

        <div id="main-content" className={`${model.cssClassNames} ${pageIdentifier}`}>
          {children}
        </div>

        <ResponsiveGrid
          {...footerModelProps}
          model={footerModel}
          pagePath={NEXT_PUBLIC_AEM_PATH}
          itemPath={ITEM_PATH_FOOTER}
        />
        <script src={headerFooterJS} defer></script>
      </div>
    </StyledLayoutContainer>
  );
};
