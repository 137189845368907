import styled from 'styled-components';
import { theme } from '../../styles';

export const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .image-wrapper {
    display: flex;
    flex-direction: column;
    width: ${props => props.theme.desktop?.width};
    height: ${props => props.theme.desktop?.height};
    border-radius: ${props => props.theme.desktop?.imgBorderRadius};

    @media ${theme.mediaQuery.mobileTablet} {
      width: ${props => props.theme.tablet?.width};
      height: ${props => props.theme.tablet?.height};
      border-radius: ${props => props.theme.tablet?.imgBorderRadius};
    }

    @media ${theme.mediaQuery.mobileOnly} {
      width: ${props => props.theme.mobile?.width};
      height: ${props => props.theme.mobile?.height};
      border-radius: ${props => props.theme.mobile?.imgBorderRadius};
    }

    .room-image {
      width: ${props => props.theme.desktop?.width};
      height: ${props => props.theme.desktop?.height};
      border-radius: ${props => props.theme.desktop?.imgBorderRadius};
      @media ${theme.mediaQuery.desktop} {
        background-color: ${props => props.theme.desktop?.imgBackgroundColor};
        object-fit: ${props => props.theme.desktop?.imgObjectFit};
      }

      @media ${theme.mediaQuery.mobileTablet} {
        width: ${props => props.theme.tablet?.width};
        height: ${props => props.theme.tablet?.height};
        border-radius: ${props => props.theme.tablet?.imgBorderRadius};
        background-color: ${props => props.theme.tablet?.imgBackgroundColor};
        object-fit: ${props => props.theme.tablet?.imgObjectFit};
      }

      @media ${theme.mediaQuery.mobileOnly} {
        width: ${props => props.theme.mobile?.width};
        height: ${props => props.theme.mobile?.height};
        border-radius: ${props => props.theme.mobile?.imgBorderRadius};
        background-color: ${props => props.theme.mobile?.imgBackgroundColor};
        object-fit: ${props => props.theme.mobile?.imgObjectFit};
      }
    }
  }
`;
