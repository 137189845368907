export type InlineMessagesProps = {
  title: string;
  messageList?: string[];
  children?: React.ReactNode | React.ReactNode[];
  type: InlineMessagesType;
  severity?: '1' | '2';
  inverse?: boolean;
};

export enum InlineMessagesType {
  Success = 'success',
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
  Emergency = 'emergency',
}
