/* eslint-disable @typescript-eslint/no-explicit-any */
import { bookStore } from './bookStore';
import { usePageContextReturnType } from './PageContext.types';
import { useSyncExternalStore } from 'react';
import { pageStoreComponentID } from './componentsNames';

export { pageStoreComponentID };

/**
 * Hook to subscribe component to store.
 * @store
 * @param    {any}    selectorFunction            selector function to subscribe to particular objects.
 * @example  const{pageState, updateComponentState} = usePageContext(state=>state.componentA)
 *
 * @return   {{pageState, updateComponentState}}
 */

export const usePageContext = (selector = (state: any) => state): usePageContextReturnType => {
  // const [pageState, updateState] = useState(selector(store.getState()));
  // useEffect(() => {
  //   store.subscribe((currentState: any) => updateState(selector(currentState)));
  // }, []);
  const pageState = useSyncExternalStore(
    bookStore.subscribe,
    () => selector(bookStore.getState()),
    () => selector(bookStore.getState()) //Sending same state as snapshot as we are not sending initial state from Server side.
  );
  return {
    pageState,
    updateComponentState: bookStore.updateComponentState,
    updatePageErrors: bookStore.updatePageErrors,
  };
};

//Will remove this when vaidate external store.

// export const PageContext = createContext<any>({});

// export const usePageContext = () => {
//   return useContext(PageContext);
// };
