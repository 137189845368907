/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  phoenixShopDatedSearchByGeoQuery,
  phoenixShopDatedSearchByGeoFilterQuery,
  phoenixShopDatedSearchByDestinationQuery,
  phoenixShopDatedSearchByDestinationFilterQuery,
  phoenixShopSearchPropertiesByDestination,
  phoenixShopSearchPropertiesByGeoLocation,
  phoenixShopSearchPropertiesByDestinationFilter,
  phoenixShopSearchPropertiesByGeoLocationFilter,
  phoenixShopPropertiesMediaGalleryByIDS,
  phoenixShopLowestAvailableRatesByKeywordQuery,
  phoenixShopDatedSearchByLocationSearchInputFilterQuery,
  phoenixShopDatedSearchByLocationSearchInputQuery,
  phoenixShopCustomerToken,
  phoenixShopDatedSearchPropertiesInfoByIDs,
  phoenixShopDatedSearchPropertiesInfoByIDsFilter,
  phoenixShopSearchPropertiesNonDatedAllInfoByIDs,
  phoenixShopSearchPropertiesNonDatedFiltersByIDs,
  phoenixShopConvertCurrencyQuery,
} from '@marriott/mi-shop-graphql';
import { enableHotelList } from './helper';

const defaultQueryOption = {
  fetchPolicy: 'network-only',
};

export function getDTTShopResult(client: any) {
  // create an object containing all the required graphql api including filter
  /****************** return promise from each function ********************** */
  /***** get apollo client instance as client = apolloclient */
  return {
    getFlexibleFilter: function (params: any, destination?: boolean) {
      //get flexible filter list on filter click with search query
      /**** params = <search query var and other custom options for query>
       * destination = <destination // in form true/false>
       * true if we will not get lattitude and longtitude
       */
      return client.query({
        ...defaultQueryOption,
        query:
          enableHotelList() === true
            ? phoenixShopSearchPropertiesNonDatedFiltersByIDs
            : destination
            ? phoenixShopSearchPropertiesByDestinationFilter
            : phoenixShopSearchPropertiesByGeoLocationFilter,
        ...params,
      });
    },
    getFlexiblePropertyInfo: function (params: any, destination?: boolean) {
      //get flexible property info with search query
      /**** params = <search query var and other custom options for query>
       * destination = <destination // in form true/false>
       * true if we will not get lattitude and longtitude
       */
      return client.query({
        ...defaultQueryOption,
        query:
          enableHotelList() === true
            ? phoenixShopSearchPropertiesNonDatedAllInfoByIDs
            : destination
            ? phoenixShopSearchPropertiesByDestination
            : phoenixShopSearchPropertiesByGeoLocation,
        ...params,
      });
    },
    getDatedFilter: function (params: any, destination?: boolean, isStateSearch?: boolean) {
      // get filter list for dated query
      /**** params = <search query var and other custom options for query>
       * destination = <destination // in form true/false>
       * true if we will not get lattitude and longtitude
       */
      const query = isStateSearch
        ? phoenixShopDatedSearchByLocationSearchInputFilterQuery
        : phoenixShopDatedSearchByDestinationFilterQuery;
      return client.query({
        ...defaultQueryOption,
        query:
          enableHotelList() === true
            ? phoenixShopDatedSearchPropertiesInfoByIDsFilter
            : destination
            ? query
            : phoenixShopDatedSearchByGeoFilterQuery,
        ...params,
      });
    },
    getDatedPropertyInfo: function (params: any, destination?: boolean, isStateSearch?: boolean) {
      // get property list for dated query along with price details
      /**** params = <search query var and other custom options for query>
       * destination = <destination // in form true/false>
       * true if we will not get lattitude and longtitude
       */
      const query = isStateSearch
        ? phoenixShopDatedSearchByLocationSearchInputQuery
        : phoenixShopDatedSearchByDestinationQuery;

      const queryOptions = { ...defaultQueryOption, fetchPolicy: 'network-only' };
      return client.query({
        ...queryOptions,
        query:
          enableHotelList() === true
            ? phoenixShopDatedSearchPropertiesInfoByIDs
            : destination
            ? query
            : phoenixShopDatedSearchByGeoQuery,
        ...params,
      });
    },
    getKeywordPropertyInfo: function (params: any) {
      // get property list for dated query based on the keyword
      /**** params = <search query var and other custom options for query>
       */
      return client.query({
        ...defaultQueryOption,
        query: phoenixShopLowestAvailableRatesByKeywordQuery,
        ...params,
      });
    },
    getMedia: function getMediaTour(params: any) {
      // get all media tour with property ids
      /**** params = <search query var and other custom options for query>
       *
       */
      return client.query({
        ...defaultQueryOption,
        query: phoenixShopPropertiesMediaGalleryByIDS,
        ...params,
      });
    },
    getCustomerToken: function getCustomerToken(params: any) {
      // get customer token when userlogin
      /**** params = <query options to fetch customer token>
       */
      return client.query({
        ...defaultQueryOption,
        query: phoenixShopCustomerToken,
        ...params,
      });
    },
    getCurrencyConversionRate: function (params: any) {
      // get currency conversion rate for different currency
      /**** params = <query options to fetch conversion rate>
       */
      return client.query({
        ...defaultQueryOption,
        query: phoenixShopConvertCurrencyQuery,
        ...params,
      });
    },
  };
}
