import dayjs from 'dayjs';
import { canUseDOM } from '../helpers';
import Cookies from 'js-cookie';
import { COOKIE_KEYS } from '../constants';
import { DATA_LAYER_ATTRIBUTES as datalayerAttrList } from '../constants';
import { GlobalPageProps, SessionData } from '../session';

export const getCurrentDateTime = () => {
  return dayjs(Date.now()).format('MM/DD/YY HH:mm:ss');
};

export const getCurrentLocale = (pageProps: GlobalPageProps) => {
  if (canUseDOM) {
    return pageProps?.['currentLocale'] || 'en-US';
  }
  return '';
};

export const propertyResolver = <T extends string | unknown>(value: T) => {
  return value ? value : '';
};

export const getEnvServerId = () => {
  if (Cookies.get(COOKIE_KEYS.miCnSite)) {
    return propertyResolver(Cookies.get(COOKIE_KEYS.miCnSite));
  } else {
    return propertyResolver(Cookies.get(COOKIE_KEYS.miSite));
  }
};

export const getCountryFromLocale = (pageProps: GlobalPageProps) => {
  const currentLocale = getCurrentLocale(pageProps);
  return currentLocale?.split('-')?.[1] || '';
};

export const getEnvSiteId = (pageProps: GlobalPageProps) => {
  const currentCountry = getCountryFromLocale(pageProps);
  if (currentCountry === 'LACA') {
    return 'ES';
  } else return currentCountry;
};

export const getAkamaiLocationAttrs = (headerValue?: string) => {
  if (headerValue?.length) {
    return headerValue.split(',').reduce((acc: Record<string, string>, pair) => {
      const [key, value] = pair.split('=');
      acc[key] = value;
      return acc;
    }, {});
  } else
    return {
      country_code: '',
      lat: '',
      long: '',
      city: '',
      region_code: '',
    };
};

export const getDatalayerAttrList = (pageProps: GlobalPageProps) => {
  if (canUseDOM) {
    return pageProps?.['model']?.['dataLayerStaticElements'] || datalayerAttrList;
  } else {
    return datalayerAttrList;
  }
};

export const getPreviousPageUrl = () => {
  // removing http from referrer
  return canUseDOM ? document.referrer?.replace(/(^\w+:|^)\/\//, '') : '';
};

/**
 *
 * @returns return PersonalizationGUID
 * @description this fucntion first it will fetch Personalization cookie which is encrypted
 * and then if that cookie value contains particular code then
 * we have to decode that value and then
 * from the decoded value we should return a string
 * eg: let string = 'haubsdf;guid:"personalizationCookieValue"', then will return personalizationCookieValue
 */
export const getCookiePersonalizationGuid = () => {
  const personalizationCookie = Cookies.get(COOKIE_KEYS.personalization);
  if (!personalizationCookie) return;
  const decodedValue = decodeURI(personalizationCookie);
  const [, substringAfterGuid] = decodedValue.split('guid');
  const substringAfterColon = substringAfterGuid?.slice(substringAfterGuid?.indexOf(':"') + 2);
  const finalvalue = substringAfterColon?.slice(0, substringAfterColon?.indexOf('"'));
  return propertyResolver(finalvalue);
};

/**
 *
 * @param sessionData
 * @returns promoSpecialOfferId
 * @description if url.pathname == '/search/default' then it will return
 * sessionData?.data?.AriesCommon?.promo_special_offer_id
 * else sessionData?.data?.AriesSearch?.marrOfferId
 */
export const getPromoSpecialOfferId = (sessionData: SessionData) => {
  if (window?.location?.pathname?.replace('.mi', '') == '/search/default') {
    return propertyResolver(sessionData?.data?.AriesCommon?.promo_special_offer_id);
  }
  return propertyResolver(sessionData?.data?.AriesSearch?.searchCriteria?.marrOfferId);
};

/**
 *
 * @param sessionData
 * @returns AriesError object as string
 * @description if session data contans wsErrors
 * then it will construct error that erro object
 */
export const getAriesErrorObj = (sessionData: SessionData): string => {
  const wsErrors = sessionData?.data?.AriesErrors?.wsErrors;
  const API_PREFIX = 'API|302';

  if (wsErrors && wsErrors.length > 0) {
    let errorString = `${API_PREFIX}|${wsErrors[0].componentName}`;
    wsErrors.forEach(errorObj => {
      errorString += `|${getStandardizedAPIEndPoint(errorObj.apiName as string)}_${errorObj.statusCode}`;
      // Adding optional fields to the error string
      if (errorObj.wsErrorCode) errorString += `_${errorObj.wsErrorCode}`;
      if (errorObj.message) errorString += `_${errorObj.message}`;
    });

    return errorString;
  }
  return '';
};

// Function to process the final API endpoint string
const getStandardizedAPIEndPoint = (apiEndpoint: string): string => {
  if (!apiEndpoint) return '';
  let method = '';
  let path = apiEndpoint;
  if (apiEndpoint.includes(' ')) {
    [method, path] = apiEndpoint.split(' ', 2);
  }
  // appending API method in the string if it is present in api name
  const standardizedAPIEndPoint = method ? `${method} ` : '';
  const apiToken = path.split('/').filter(Boolean); // Remove empty segments

  // If the array has fewer than 3 segments, return as-is
  if (apiToken.length < 3) return `${standardizedAPIEndPoint}${path}`;

  // If array is 3 or more, process the dynamic segements in the api name
  return processWSURITokens(standardizedAPIEndPoint, apiToken);
};

/**
 *
 * @param standardizedAPIEndPoint
 * @param apiToken
 * @returns api end point
 * @description it will process apiEndpoint
 * first it will add apiToken[0] to standardapi
 * and then it wil check whether it is in dynamic segment or not
 * and then it will add ID then returns that apiEndpoint
 */
const processWSURITokens = (standardizedAPIEndPoint: string, apiToken: string[]): string => {
  const outlierAPIEndpoint = 'queries';
  const outlierAPIEndpointAfterFirstDynamicIndex = 'consumers';

  standardizedAPIEndPoint += `/${apiToken[0]}`; // Always add the first segment

  for (let i = 1; i < apiToken.length; i++) {
    const segment = apiToken[i];

    // Determine if we are in a dynamic segment context
    const isDynamicSegment =
      (apiToken[1].includes(outlierAPIEndpoint) && !/^[a-zA-Z-]+$/.test(segment)) ||
      (apiToken[1].includes(outlierAPIEndpointAfterFirstDynamicIndex) && i > 2) ||
      i % 2 === 0;

    standardizedAPIEndPoint += `/${isDynamicSegment ? 'ID' : segment}`;
  }
  return standardizedAPIEndPoint;
};

/**
 *
 * @returns pageURLPathbranch
 * @description if pageURI include 'search'
 * then it will return 'search/findHotels.mi'
 * else return pageURI or '/offers'
 */
export const getPageUrlPathBranch = (pageProps: GlobalPageProps) => {
  const pageURI = pageProps?.resolvedUrl;
  if (pageURI?.includes('search')) {
    return '/search/findHotels.mi';
  } else return pageURI ?? '/offers';
};
