import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledModalContent = styled.div`
  padding: ${toRem(15)} ${toRem(32)};
  border-bottom: 1px solid ${theme.colors['close-silver']};
  .body-text {
    margin-top: ${toRem(16)};
  }
`;
export const StyledModalFooter = styled.div`
  padding: ${toRem(15)} ${toRem(32)};
  display: flex;
  flex-direction: row;
  align-items: center;
  a.underlined-btn {
    text-decoration: underline;
  }
  .loader {
    border-color: ${theme.colors.white} !important;
    border-top-color: transparent !important;
  }
`;
