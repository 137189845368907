import styled from 'styled-components';
import { toRem, baseVariables } from '../../styles';

const { mediaQuery } = baseVariables;

export const StyledPillCollection = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: ${toRem(8)};
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media ${mediaQuery.md} {
    overflow: hidden;
    flex-wrap: wrap;
  }

  .pill {
    flex: 0 0 auto;
  }
`;
