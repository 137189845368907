import axios from 'axios';
import { inspect } from 'util';
import { DATALAYER_CONSTANT } from '../../../constants';
import { canUseDOM } from '../..';
import { getProcessEnvs, logger } from '../..';
import { DataLayerProps, elmType, indexType, KeysRequest, RequestProps } from './index.types';
const { DATALAYER_ENDPOINT } = process.env;

const MOCK_SESSION_ID = '47F22F82-AF67-5585-8C53-5AFCE3817816';

export const getDataLayer = async (
  req: {
    cookies?: { sessionID: string; UserIdToken: string };
    headers: RequestProps;
    query?: Record<string, unknown>;
    originalUrl: string;
  },
  resolvedUrl: string,
  locale: string
): Promise<DataLayerProps> => {
  // logger initialization
  const { log } = canUseDOM
    ? logger({ requestID: '', sessionID: '' })('Adobe DataLayer')
    : global.loggerInstance('Datalayer');
  const headers = canUseDOM ? ({} as RequestProps) : req?.headers;
  const cookies = canUseDOM ? document?.cookie : req?.headers?.cookie;
  const adobeDataLayer = [];

  // decrypting cookies and merging with header object
  decodeURIComponent(cookies)
    .split(';')
    .forEach(item => {
      const keyValuePair = item.split('=');
      if (keyValuePair.length >= 2) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Unreachable code error
        headers[keyValuePair[0]?.trim()?.toLowerCase()] = keyValuePair[1];
      }
    });

  // extracting all the required keys from header
  const {
    sessionid,
    useridtoken,
    // authorization,
    mi_site,
    mi_visitor,
    remembermeflag = false,
    remembermeuserid = '',
    remembermealternateid = '',
  } = headers;
  const ENV_PLATFORM = 'env_platform';

  // creating body for datalayer api call
  const getPageURI = resolvedUrl ? resolvedUrl.split('?')[0] : '';
  const absolutePageURL = `${getPageURI}.mi`;
  const localeKeyValue = locale;
  const channelValue = process.env['NEXT_PUBLIC_DATALAYER_SITENAME']?.split('.')[0];
  const requestBody = {
    context: {
      localeKey: localeKeyValue ?? 'en_US',
      programFlag: '',
      siteName: process.env['NEXT_PUBLIC_DATALAYER_SITENAME'],
      mobileAuthEnabled: 'false',
      productSiteId: process.env['NEXT_PUBLIC_DATALAYER_PRODUCT'],
      channel: channelValue,
      pageContent: [],
      pageURI: getPageURI,
      Referer: req?.headers?.referer,
      absolutePageURL: absolutePageURL,
      applicationName: process.env['NEXT_PUBLIC_DATALAYER_APPLICATION'],
      products: process.env['NEXT_PUBLIC_DATALAYER_PRODUCT'],
      template: process.env['NEXT_PUBLIC_DATALAYER_TEMPLATE'],
      seoQueryParams: req.query,
    },
    sessionToken: req?.cookies?.sessionID ?? MOCK_SESSION_ID,
    sourceURI: absolutePageURL,
    variation: process.env['NEXT_PUBLIC_DATALAYER_VARIATION'],
  };

  log.debug(`[STEP 4] datalayer req object: ${inspect(requestBody)}`);

  const headersKey: Record<string, string | undefined> = {};

  // creating headers for datalayer call
  DATALAYER_CONSTANT.headerKeys.forEach(name => {
    if (name && headers[name as KeysRequest['keys']]) {
      headersKey[name] = headers[name as KeysRequest['keys']];
    }
  });

  // modifying headers
  const getHeaders = (): string => {
    let cookieValues = '';
    if (mi_site) {
      cookieValues = cookieValues + ` MI_SITE=${mi_site};`;
    }
    if (mi_visitor) {
      cookieValues = cookieValues + ` MI_VISITOR=${mi_visitor};`;
    }
    if (remembermeuserid) {
      cookieValues = cookieValues + ` RememberMeUserID=${encodeURIComponent(remembermeuserid)};`;
    }
    if (remembermealternateid) {
      cookieValues = cookieValues + ` RememberMeAlternateID=${encodeURIComponent(remembermealternateid)};`;
    }
    if (useridtoken) {
      cookieValues = cookieValues + ` UserIdToken=${encodeURIComponent(useridtoken)};`;
    }
    return cookieValues;
  };

  // API call
  const endpoint = DATALAYER_ENDPOINT ?? '';

  const response = await axios
    .post(endpoint, requestBody, {
      headers: {
        // please make sure these coookies are in single line. Else there will be `TypeError [ERR_INVALID_CHAR]`
        cookie: `sessionID=${sessionid}; RememberMeFlag=${remembermeflag}; ${getHeaders()}`,
        // ...authorizationHeader,
        ...headersKey,
      },
    })
    .then(({ data, status }) => {
      log.debug(`[STEP 4] Datalayer URL ${endpoint} -----> API Call Success: ${status}`);
      return data.component?.data;
    })
    .catch(err => {
      log.error(`[STEP 4] DataLayer API Failed: ${err?.response?.status} [message]: ${err?.response?.data}`);
      // throw new Error('Datalayer call failed');
      return Promise.reject(err);
    });

  const dataLayerObj: indexType = {};
  let mvpOffers = '';
  const envProcessURL = canUseDOM ? getProcessEnvs()['PHOENIX_ENV_PLATFORM'] : process.env['PHOENIX_ENV_PLATFORM'];

  /*
  manipulating some of the response data
  */
  if (typeof response?.dataProperties !== 'undefined') {
    response?.dataProperties?.forEach((elm: elmType) => {
      if (elm.value && elm.value !== 'undefined' && elm.value !== null) {
        dataLayerObj[elm.key] = elm.value;
      }
    });
  }

  // To set platform like "AEM-prod, AEM-stage, AEM-dev"
  if (envProcessURL) {
    dataLayerObj[ENV_PLATFORM] = envProcessURL;
  }

  if (typeof dataLayerObj['mr_nights_to_renew'] === 'string') {
    const nightsToRenew = Number(dataLayerObj['mr_nights_to_renew']);
    if (!isNaN(nightsToRenew)) {
      dataLayerObj['mr_nights_to_renew'] = nightsToRenew;
    }
  }

  if (typeof dataLayerObj['mr_nights_to_next_level'] === 'string') {
    const nightsNextLevel = Number(dataLayerObj['mr_nights_to_next_level']);
    if (!isNaN(nightsNextLevel)) {
      dataLayerObj['mr_nights_to_next_level'] = nightsNextLevel;
    }
  }

  // To identify OTA reservations in check-in flow
  if (typeof dataLayerObj['page_url_query_string'] === 'string') {
    const otaParam = dataLayerObj['page_url_query_string']
      .split('&')
      .filter(val => val.includes('OTA='))
      .join();
    if (otaParam) {
      dataLayerObj['ota_flag'] = otaParam.substring(otaParam.lastIndexOf('=') + 1);
    }
  }

  // Start: manually adding these item if Maken does not provide
  if (typeof dataLayerObj['page_data_layer_ready'] === 'undefined') {
    dataLayerObj['page_data_layer_ready'] = 'false';
  } else {
    dataLayerObj['page_data_layer_ready'] = 'true';
  }

  if (typeof dataLayerObj['request_id'] === 'undefined') {
    dataLayerObj['request_id'] = headersKey['x-request-id'] ?? '';
  }
  // End

  if (typeof response.mvpOfferList !== 'undefined') {
    mvpOffers = JSON.stringify(response.mvpOfferList);
  }

  adobeDataLayer.push(dataLayerObj);

  return {
    data: adobeDataLayer,
    mvpOffersData: mvpOffers,
  };
};
