import dynamic from 'next/dynamic';
import { EditableComponent } from '@adobe/aem-react-editable-components';

import { ComponentProps } from './HotelHeaderV2.types';
import { HotelHeaderLoader } from './HotelHeader';

export const HotelHeaderV2 = dynamic<ComponentProps>(() => import('./HotelHeader'), {
  loading: () => <HotelHeaderLoader inModal={false} />,
  ssr: false,
});

export const HotelHeaderComponentConfig = {
  emptyLabel: 'HotelHeaderComponent',
  isEmpty: false,
  resourceType: 'mcom-book-spa/components/prebooking/hotelheader',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HotelHeaderV2Editable = (props: any) => (
  <EditableComponent config={HotelHeaderComponentConfig} {...props}>
    <HotelHeaderV2 {...props} />
  </EditableComponent>
);
