import { FC } from 'react';

import { Button } from '../../atoms';
import { ConfirmPopupModalProps } from './ConfirmPopupModal.types';
import { StyledModalContent, StyledModalFooter } from './ConfirmPopupModal.styles';
import { CustomModal } from '../CustomModal';

export const ConfirmPopupModal: FC<ConfirmPopupModalProps> = ({
  open = false,
  onClose,
  modalHeading,
  modalBody,
  primaryBtn,
  secondaryBtn,
}) => {
  return (
    <div data-component-name="m-book-ConfirmPopupModal" data-testid="book-ConfirmPopupModal">
      <CustomModal
        openModal={open}
        heading={modalHeading}
        handleClose={onClose}
        optionsModal={false}
        backArrow={false}
        isRRDHeader
      >
        <>
          <StyledModalContent>
            <p className="body-text">{modalBody}</p>
          </StyledModalContent>
          <StyledModalFooter>
            <Button
              text={primaryBtn.text}
              isLink={false}
              callback={primaryBtn.onClick}
              className="m-button m-button-primary button-select standard"
              isDisabled={primaryBtn.isDisabled}
              isLoading={primaryBtn.isLoading}
              loadingClassName="loader"
            />
            <a
              href={secondaryBtn.href}
              onClick={e => {
                if (secondaryBtn.onClick) {
                  e.preventDefault();
                  secondaryBtn.onClick();
                }
              }}
              className="ml-4 text-decoration-underline underlined-btn"
              target={secondaryBtn.target}
            >
              {secondaryBtn.text}
            </a>
          </StyledModalFooter>
        </>
      </CustomModal>
    </div>
  );
};
