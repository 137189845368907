export const SessionDataMap = {
  'cacheData.data.AriesReservation.propertyId': 'propertyId',
  locale: 'locale',
  'cacheData.data.consumerID': 'consumerID',
  'cacheData.data.AriesCommon.memState': 'userState',
  'cacheData.data.AriesCommon.prop_name': 'name',
  'cacheData.data.AriesSearch.searchCriteria.address': 'hotelAddress',
  'cacheData.data.AriesCommon.brndCode': 'brndCode',
  'cacheData.data.AriesReservation.isMax': 'isMax',
};

export const HotelDataMap = {
  'data.property.basicInformation.brand.id': 'brandCode',
  'data.property.basicInformation.name': 'hotelName',
  'data.property.basicInformation.latitude': 'latitude',
  'data.property.basicInformation.longitude': 'longitude',
  'data.property.basicInformation.currency': 'currency',
  'data.property.reviews.numberOfReviews': 'hotelReviews',
  'data.property.reviews.stars': 'stars',
  'data.property.contactInformation.address.line1': 'addressLine1',
  'data.property.contactInformation.address.line2': 'addressLine2',
  'data.property.contactInformation.address.line3': 'addressLine3',
  'data.property.contactInformation.address.city': 'city',
  'data.property.contactInformation.address.stateProvince.description': 'stateProvince',
  'data.property.contactInformation.address.country.description': 'country',
  'data.property.contactInformation.address.postalCode': 'postalCode',
  'data.property.contactInformation.contactNumbers': 'phoneNumbers',
  'data.property.seoNickname': 'seoNickname',
  'data.customer': 'userData',
  'data.property.media.primaryImage.edges[0].node.imageUrls.wideHorizontal': 'imageUrl',
};

export const UniqueHotelLogoMap = {
  'images[0].imageFile': 'imageUrl',
};
