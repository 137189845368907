import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledTitle = styled.div`
  font-family: ${theme.fontFamily.swiss};
  background-color: transparent;

  &.overline-left {
    margin-bottom: ${toRem(10)};
    color: ${theme.colors['dark-gray-3']};
    font-size: ${theme.fonts['fontXs']};
    line-height: ${theme.fonts['lineHeightXs']};
  }

  &.title-xLarge,
  &.title-xxLarge {
    margin-bottom: ${toRem(0)};
    color: ${theme.colors['base-10']};
    font-size: ${toRem(22)};
    line-height: ${toRem(28)};
  }

  &.title-xxLarge {
    line-height: ${toRem(26)};
    margin-bottom: ${toRem(8)};
  }

  &.title-large {
    margin-bottom: ${toRem(0)};
    color: ${theme.colors['base-10']};
    font-size: ${toRem(16)};
    line-height: ${toRem(20)};
  }

  &.subtitle-xLarge {
    margin-bottom: ${toRem(0)};
    color: ${theme.colors['base-10']};
    font-size: ${theme.fonts['fontXxl']};
    line-height: ${theme.fonts['lineHeightXl']};
  }

  &.subtitle-xxLarge {
    margin-bottom: ${toRem(0)};
    color: ${theme.colors['base-10']};
    font-size: ${toRem(30)};
    line-height: ${theme.fonts['lineHeightXxxl']};
  }

  &.hotel-message-title,
  &.cancellation-title {
    color: ${theme.colors['base-10']};
    font-size: ${theme.fonts['fontXxl']};
    line-height: ${theme.fonts['lineHeightXxl']};
  }

  &.hotel-message-title-gi {
    padding-top: ${toRem(32)};

    @media ${theme.mediaQuery.tabletOnly} {
      padding-top: ${toRem(32)};
    }

    @media ${theme.mediaQuery.mobileOnly} {
      padding-top: ${toRem(24)};
    }
  }

  &.rrd-title {
    color: ${theme.colors['base-10']};
    font-size: ${theme.fonts['fontXxl']};
    line-height: ${toRem(28)};
    text-transform: none;
  }

  &.gi-title {
    color: ${theme.colors['base-10']};
    font-size: ${theme.fonts['fontXxl']};
    line-height: ${toRem(36)};
    text-transform: none;
  }

  &.title-margin {
    margin: 0 ${toRem(23)};
  }

  @media ${theme.mediaQuery.tablet} {
    &.title-margin {
      margin: 0 ${toRem(60)};
    }
    &.rrd-title {
      font-size: ${toRem(30)};
      line-height: ${toRem(36)};
    }
    &.gi-title {
      font-size: ${toRem(30)};
      line-height: ${toRem(36)};
    }
  }

  @media ${theme.mediaQuery.desktop} {
    &.title-margin {
      margin: 0 ${toRem(95)};
    }
    &.title-xLarge {
      font-size: ${toRem(30)};
      line-height: ${toRem(36)};
    }
    &.title-large {
      font-size: ${toRem(22)};
      line-height: ${toRem(26)};
    }
    &.title-xxLarge {
      font-size: ${toRem(30)};
      line-height: ${toRem(32)};
      margin-bottom: ${toRem(16)};
    }
  }

  &.gi-title {
    @media ${theme.mediaQuery.minDesktop} {
      margin-top: ${toRem(56)};
    }

    @media ${theme.mediaQuery.tabletOnly} {
      margin-top: ${toRem(32)};
    }

    @media ${theme.mediaQuery.mobileOnly} {
      margin-top: ${toRem(24)};
    }
  }
`;
