import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { useQuery } from '@apollo/client';

import { DistanceProps } from './Distance.types';
import { useGetBreakpoint, getClassForBreakpoint } from '@marriott/mi-ui-library-shop';
import { DEFAULT_FALLBACK_PROPERTY_ID } from '../../../constants';
import { phoenixShopHotelGalleryTitleDistanceOnly } from '../../organisms/HotelGalleryTitle/HQVQueries';
import { isServer } from '@marriott/mi-apollo-client-utils';
import { getCalculatedDistance, logger } from '../../../utils/index';
import { usePageContext } from '../../../context';

export const getDistanceStr = (unitLabel: string, distance: number) => {
  if (unitLabel === 'mi' && distance) {
    return (distance / 1609.344).toFixed(1);
  } else if (unitLabel === 'km' && distance) {
    return (distance / 1000).toFixed(1);
  }
  return null;
};

export const Distance: FC<DistanceProps> = ({
  distanceUnitLabel,
  propertyId,
  requestId,
  latitude,
  longitude,
  startDate,
  endDate,
  model,
  isReviewsSeparatorPresent,
}) => {
  const { log } = logger({})('HotelGalleryTitle/Distance');
  const { currentLocale } = usePageContext();

  log.debug('Distance component: ', latitude, longitude);
  const breakpoint = useGetBreakpoint();
  const calcDistanceUnitLabel = distanceUnitLabel ? distanceUnitLabel : 'mi';
  const calcRequestId = requestId ? requestId : `${Date.now()}`;
  const [distance, setDistance] = useState<string | null>(null);

  const {
    loading,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useQuery(phoenixShopHotelGalleryTitleDistanceOnly, {
    ssr: false,
    fetchPolicy: isServer ? 'network-only' : 'cache-first',
    variables: {
      search: {
        ids: [propertyId ? propertyId : DEFAULT_FALLBACK_PROPERTY_ID],
        latitude: latitude,
        longitude: longitude,
        options: {
          startDate: startDate,
          endDate: endDate,
          includeUnavailableProperties: true,
        },
      },
    },
    context: {
      headers: {
        'x-request-id': calcRequestId,
        'Accept-Language': currentLocale || '',
      },
    },
    onCompleted: data => {
      log.debug('distance query completed: ', data);
      setDistance(
        getCalculatedDistance(
          data?.searchLowestAvailableRatesByPropertyIds?.edges[0]?.node?.distance,
          currentLocale !== 'en-US',
          currentLocale
        )
      );
    },
  });

  if (loading) {
    // TODO: Replace with inline loader.
    return <div></div>;
  }

  return (
    <span>
      {distance && (
        <span className="distance-container">
          {isReviewsSeparatorPresent && <span className="reviews-distance-separator">|</span>}
          <span className="icon-location distance-icon" />
          <span
            className={clsx(
              'title-title-container-list-miles',
              getClassForBreakpoint(breakpoint, 't-font-alt-s', 't-font-alt-s', 't-font-alt-s')
            )}
          >
            {`${distance} ${calcDistanceUnitLabel}`}
          </span>
          <span
            className={clsx(
              'title-container-text',
              getClassForBreakpoint(breakpoint, 't-font-alt-s', 't-font-alt-s', 't-font-alt-s')
            )}
          >
            {model?.fromDestinationLabel}
          </span>
        </span>
      )}
    </span>
  );
};
