import styled from 'styled-components';
import { toRem, theme } from '../../styles';

export const StyledInputDiv = styled.div`
  .hidden-legend {
    display: none;
  }
  .input-box {
    position: relative;
    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='tel'] {
      outline: none;
      border: none;
      border-radius: 0;
      height: ${toRem(43)};
      font-size: ${theme.fonts['fontL']};
      line-height: ${theme.fonts['lineHeightXl']};
      caret-color: ${theme.colors.carrot};
      color: ${theme.colors['dark-gray-2']};
      padding: ${toRem(5)} ${toRem(48)} ${toRem(13.5)} 0;
      border-bottom: ${theme.colors['dark-gray-3']} solid ${toRem(2)};
      &:focus {
        border-bottom: ${theme.colors['dark-gray-2']} solid ${toRem(2)};
      }
      &.is-error.no-outline {
        border-bottom: var(--t-form-error-color) solid ${toRem(3)};
      }
      &::placeholder {
        color: ${theme.colors['dark-gray-3']};
      }
    }
    input {
      :disabled {
        color: ${theme.colors['dark-gray-3']};
      }
      :read-only {
        color: ${theme.colors['dark-gray-3']};
      }
    }
    .icon-cancel {
      z-index: 1;
      cursor: pointer;
      position: absolute;
      right: ${toRem(0)};
      bottom: ${toRem(10)};
      font-size: ${theme.fonts['fontM']};
      color: ${theme.colors.black};
    }
    .icon-visibility,
    .icon-visibility-off {
      cursor: pointer;
    }

    .icon-check {
      color: ${theme.colors['merch-green']};
    }

    .icon-clear {
      color: ${theme.colors.monza};
    }
  }
  label {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: ${toRem(1.3)};
    font-size: ${theme.fonts['fontXs']};
    color: ${theme.colors['dark-gray-3']};
    line-height: ${theme.fonts['lineHeightXs']};
    font-weight: ${theme.fonts['fontWeightMedium']};
    &.text-transform-captitalize {
      text-transform: capitalize !important;
    }
  }
  .outlined {
    border: ${theme.colors['dark-gray-3']} solid ${toRem(1)};
    border-radius: ${toRem(4)};
    position: relative;
    margin-top: ${toRem(12)};
    &:focus-within > .outlined-label {
      color: ${theme.colors['base-10']};
    }
    .input-box {
      .icon-cancel {
        padding-right: ${toRem(10)};
      }
      input {
        border-bottom: 0;
        height: ${toRem(48)};
        font-size: ${theme.fonts['fontM']};
        padding: ${toRem(12)} ${toRem(48)} ${toRem(12)} ${toRem(16)};
        &:focus {
          border: ${theme.colors['base-10']} solid ${toRem(1.5)};
        }
        width: calc(100%);
      }
    }
    &.is-error {
      border: var(--t-form-error-color) solid ${toRem(1)};
      .outlined-label {
        color: var(--t-form-error-color);
      }
      input:focus {
        border: var(--t-form-error-color) solid ${toRem(1.5)};
      }
    }
  }
  .read-only-field {
    border: ${theme.colors['light-gray-6']} solid ${toRem(1)};
    .input-box {
      input {
        color: ${theme.colors['dark-gray-3']} !important;
        pointer-events: none;
      }
    }
    .outlined-label {
      color: ${theme.colors['light-gray-6']};
    }
  }
  .outlined-label {
    width: auto;
    margin: 0;
    padding: ${toRem(6)} ${toRem(8)} ${toRem(6)} ${toRem(6)};
    position: absolute;
    top: ${toRem(-15)};
    left: ${toRem(12)};
    background-color: ${theme.colors.white};
    font-size: ${theme.fonts['fontS']};
    color: ${theme.colors['dark-gray-3']};
    line-height: ${theme.fonts['lineHeightXs']};
    font-weight: ${theme.fonts['fontWeightMedium']};
    z-index: 10;
  }
  input[type='radio']:focus + label:before {
    outline: 1px solid var(--t-brand-fg-color) !important;
    outline-offset: 1px;
  }
`;

export const StyledError = styled.p`
  margin: ${toRem(4)} 0 0;
  color: ${theme.colors.monza};
  font-size: ${theme.fonts['fontXs']};
  line-height: ${theme.fonts['lineHeightXs']};
  &.icon-information::before {
    margin-right: ${toRem(9.3)};
  }
  &.information {
    margin-left: ${toRem(16)};
  }
`;

export const StyledInfo = styled(StyledError)`
  color: ${theme.colors['light-gray-10']};
`;
