/* eslint-disable @typescript-eslint/no-explicit-any */
export function BookActions(currentState: any, listeners: any, setState: (arg: any) => void) {
  return {
    // updateComponentState : This action takes componentName and new state as arguments and update data in the store with particular component id
    updateComponentState: ({ componentName, newState }: { componentName: string; newState: Record<string, any> }) => {
      currentState = { ...currentState, [componentName]: newState };
      setState(currentState);
      listeners.forEach((listener: (arg: any) => null) => {
        listener(currentState);
      });
    },
    // updateComponentState : This action takes componentName and errors array as arguments and update data in the store with particular component id
    updatePageErrors: ({ componentName, errors }: { componentName: string; errors: any }) => {
      currentState = {
        ...currentState,
        pageLevelErrors: {
          ...currentState.pageLevelErrors,
          [componentName]: errors,
        },
      };
      setState(currentState);
      listeners.forEach((listener: (arg: any) => null) => {
        listener(currentState);
      });
      if (errors?.findIndex?.((_: { message: string }) => _.message !== '') !== -1) {
        console.error('Client Side Validation Error, Page State : ', currentState);
      } //Adding client side logger of page state if updatePageErrors invoked.
    },
  };
}
