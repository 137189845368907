import { FC } from 'react';

import { StyledTextFieldDiv } from './index.styles';
import { TextFieldProps } from './index.types';

export const TextField: FC<TextFieldProps> = ({
  className,
  textfieldId,
  textfieldName,
  textfieldLabel,
  maxLength,
  autoFocus,
  value,
  onChange,
  onKeyPress,
  inputClassName,
  ...props
}) => {
  return (
    <StyledTextFieldDiv className={className}>
      <label htmlFor={textfieldId}>
        <span>{textfieldLabel}</span>
      </label>
      <input
        type="text"
        id={textfieldId}
        name={textfieldName}
        onChange={onChange}
        onKeyPress={onKeyPress}
        maxLength={maxLength}
        //TODO
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        value={value}
        className={inputClassName}
        {...props}
      />
    </StyledTextFieldDiv>
  );
};

export default TextField;
