import styled from 'styled-components';
import { toRem, theme } from '../../styles';

export const styles = {
  popper: {
    zIndex: 100,
    left: `${toRem(-28)} !important`,
  },
  root: {
    padding: `${toRem(8)}`,
    borderRadius: `${toRem(14)}`,
    border: `${toRem(1)} solid ${theme.colors.gallery}`,
    boxShadow: `0 ${toRem(4)} ${toRem(20)} 0 ${theme.colors['cta-box-shadow']}`,
  },
};

export const StyledMenuItem = styled.button`
  padding: ${toRem(0)} ${toRem(8)};
  font-size: ${theme.fonts['fontXs']};
  line-height: ${theme.fonts['lineHeightXs']};
  color: ${theme.colors['base-10']};
  background-color: transparent;
  display: none;

  @media ${theme.mediaQuery.mobileOnly} {
    display: block;
  }
`;

export const StyledIcon = styled.span`
  margin-right: ${toRem(8)};
`;

export const StyledDivider = styled.div`
  border-bottom: ${toRem(1)} solid ${theme.colors['close-silver']};
  margin: ${toRem(15)} ${toRem(8)};
`;
