import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem } from '../../styles';

export const StyledAlert = styled.div`
  ${rtl`
  .unexpected-error {
    padding-top:${toRem(32)};
    padding-bottom:${toRem(24)};
  }
  `}
`;
