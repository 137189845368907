import { FC, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { StyledTooltip } from './index.styles';

/*
params: 
  optionalClassName?: to add a class at the outermost parent level for any specific CSS requirements
  tooltipData: any lists that need to be iterated over in a bulleted format
  tooltipMessage?: an optional prompt message that appears before the tooltip icon
  clickTrackingString?: to specify the value captured when a click is triggered
  tooltipParaText?: for any text that is to appear within the tooltip but not in a bulleted format;
  */

export const Tooltip: FC<{
  optionalClassName?: string;
  tooltipData?: string[];
  tooltipMessage?: string;
  clickTrackingString?: string;
  tooltipParaText?: string;
  customTabIdx?: number;
}> = ({ optionalClassName, tooltipData, tooltipMessage, clickTrackingString, tooltipParaText, customTabIdx }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  // function to handle tooltip opening and closing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tooltipHandler = (event: any): void => {
    if (
      (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) &&
      (event?.target?.classList.contains('tooltip-inner-container') ||
        event?.target?.classList.contains('tooltip-icon'))
    ) {
      setIsTooltipOpen(!isTooltipOpen);
    }
  };

  // function to handle tooltip closing when user clicks outside the tooltip
  const clickListener = (event: Event): void => {
    const tooltipElement = tooltipRef.current;
    if (
      !tooltipElement?.contains(event.target as Element) &&
      !tooltipElement?.contains(document.activeElement) &&
      !(
        document.activeElement?.classList.contains('tooltip-icon') ||
        (event?.target as HTMLElement)?.parentElement?.classList.contains('tooltip-icon') ||
        document.activeElement?.classList.contains('tooltip-inner-container') ||
        (event?.target as HTMLElement)?.parentElement?.classList.contains('tooltip-inner-container')
      ) &&
      isTooltipOpen
    ) {
      setIsTooltipOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', clickListener);
    return (): void => {
      document.removeEventListener('click', clickListener);
    };
  });
  return (
    <StyledTooltip>
      <span className={clsx('tooltip-outer-container', optionalClassName)}>
        <span
          tabIndex={customTabIdx ?? 0}
          onClick={(e): void => tooltipHandler(e)}
          onKeyDown={(e): void => tooltipHandler(e)}
          className={clsx('tooltip-inner-container')}
          role="tooltip"
        >
          {tooltipMessage}
          <span
            className={clsx(
              'tooltip-icon',
              'm-link-icon-button-info',
              'analytics-click',
              'custom-info-list',
              'custom_click_track',
              !tooltipData?.length ? 'remove-info-icon' : ''
            )}
            {...{
              custom_click_track_value: `${clickTrackingString}`,
            }}
            tabIndex={-1}
          >
            <div
              className={clsx('tooltip-container', isTooltipOpen ? 'display-tooltip' : 'hide-tooltip')}
              ref={tooltipRef}
            >
              <div className="tooltip-subheading">{tooltipParaText}</div>
              <ul className="tooltip-data-list">
                {tooltipData?.map(tooltipDataItem => (
                  <li className="tooltip-item">{tooltipDataItem}</li>
                ))}
              </ul>
              <div className="tooltip-tail" />
            </div>
          </span>
        </span>
      </span>
    </StyledTooltip>
  );
};

export default Tooltip;
