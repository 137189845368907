/**
 * @author: Divendra Kumar
 * create context for ADF page export it from here as a hook
 * export context and provider from this file
 * this will include state management logic as well for the component
 */

import { createContext, FC, useReducer, useMemo } from 'react';
import { getInitialStateForADFCalendar, setADFCalendarState } from './index.store';
import { ADFPageContextType } from './index.types';

import { logger } from '../../utils/src/logger';
const { log } = logger({})('ADFContextFileInit');

const ADFPageContext = createContext<ADFPageContextType>({
  ADFPageState: getInitialStateForADFCalendar(),
  setADFPageState: () => {
    //TODO: Empty function for initilization purpose
  },
});

export const ADFPageContextProvider: FC<{ children: JSX.Element | JSX.Element[] }> = ({ children }) => {
  /**
   * export provider with the state management logic
   * it is using reducer for complex state management
   * will include this provider as a simple JSX.element
   * eg <ADFPageContextProvider> // will pass children accordingly <ADFPageContextProvider/>
   * now we don't have to provide state logic in component file
   */

  const [ADFPageState, setADFPageState] = useReducer(setADFCalendarState, getInitialStateForADFCalendar());
  const memoContextProviderValue = useMemo(() => {
    return { ADFPageState, setADFPageState };
  }, [ADFPageState]);
  log.debug('ADFContextFileInit: component render');
  return <ADFPageContext.Provider value={memoContextProviderValue}>{children}</ADFPageContext.Provider>;
};
