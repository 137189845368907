/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { pageInitialData } from './pageInitialdata';

export const APP = (props: { Component: any; pageProps: any }) => {
  const { Component, pageProps } = props;
  const [pageState, setPageState] = useState<any>(pageInitialData);

  const updateComponentState = ({
    componentName,
    newState,
  }: {
    componentName: string;
    newState: Record<string, any>;
  }) => {
    setPageState((preState: any) => {
      return { ...preState, [componentName]: newState };
    });
  };

  const updatePageErrors = ({ componentName, errors }: { componentName: string; errors: any }) => {
    setPageState((preState: { pageLevelErrors: any }) => {
      return {
        ...preState,
        pageLevelErrors: {
          ...preState.pageLevelErrors,
          [componentName]: errors,
        },
      };
    });
  };

  return {
    pageState,
    setPageState,
    updateComponentState,
    updatePageErrors,
    pageProps,
    Component,
  };
};
