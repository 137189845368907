import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';
import { baseVariables } from '@marriott/mi-ui-library';

export const Styled = styled.div`
  ${rtl`

.underline-content{
  text-decoration: underline;
}
.logo-background{
  padding:${toRem(16)} ${toRem(20)} ${toRem(24)} ${toRem(20)} !important ;
  display:flex;
  justify-content: center;
  align-items: center;

  &.brand-logo-XE::before {
    font-size: ${toRem(24)};
    margin-top: ${toRem(8)};
  }

  &.brand-logo-MG::before {
    font-size: ${toRem(18)};
    margin-top: ${toRem(8)};
  }

  &.brand-logo-XF::before {
    font-size: ${toRem(24)};
    margin-top: ${toRem(10)};
  }

  &.brand-logo-BA::before {
    font-size: ${toRem(42)};
    margin-top: ${toRem(9)};
    margin-left: ${toRem(2)};
  }

  &.brand-logo-WH::before {
    font-size: ${toRem(14)};
    margin-top: ${toRem(8)};
  }
}
.location-box {
  scroll-margin-top: ${toRem(146)};
  display: block;
  padding: ${toRem(0)} ${toRem(158)} ${toRem(24)};
  font-family: ${theme.fonts['fontFamilySwiss']};
  &__container {
    border-bottom: ${toRem(1)} solid ${theme.color['alto']};
    padding-bottom: ${toRem(30)};
    display: inline-flex;
    width: 100%;
  }

  &__col1 {
    width: 40%;
    display: inline-block;
    .location-heading{
      margin-bottom: ${toRem(18)};
    }
  }

  &__col2 {
    width: 60%;
    display: inline-block;
  }

  #location-box__map-box-mobile {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: ${toRem(10)};
    background-color: ${theme.color['alto']};
  }
  #location-box__map-box-desktop {
    overflow: hidden;
    width: ${toRem(260)};
    margin-top: ${toRem(62)};
    height: ${toRem(260)};
    border-radius: ${toRem(10)};
    background-color: ${theme.color['alto']};
    button:focus{
      outline: ${toRem(1)} solid ${theme.color['black']};
    }
    @media ${baseVariables.mediaQuery.lg} {
      width: ${toRem(332)};
      height: ${toRem(332)};
    }
  }
  .desktop-map {
    display: block;
    border-radius: ${toRem(10)};
    .label-text {
      padding: ${toRem(24)} ${toRem(8)} !important;
      padding-bottom: ${toRem(32)} !important;
      margin: ${toRem(12)} ${toRem(8)};
      text-shadow: none;
      white-space: pre-wrap;
    }
    .icon-status-member-elite {
      text-shadow: none;
      padding: ${toRem(6)} ${toRem(4)} ${toRem(6)} ${toRem(8)};
    }
    .icon-bed{
      &:before{
        color: ${theme.color['white']};
      }
    }
    .arrow-down {
      width: 0;
      height: 0;
      margin: auto;
      border-left: ${toRem(20)} solid transparent;
      border-right: ${toRem(20)} solid transparent;
      border-top: ${toRem(20)} solid #f00;
    }
    .portfolio-icon-edition::before {
      font-size: ${toRem(11)};
      left: ${toRem(2)};
    }
    .portfolio-icon-jw::before {
      font-size: ${toRem(24)};
      left: ${toRem(2)};
      top: ${toRem(21)};
    }
    .portfolio-icon-autograph-collection::before {
      font-size: ${toRem(26)};
      left: ${toRem(2)};
    }
    .portfolio-icon-renaissance::before {
      font-size: ${toRem(33)};
      left: ${toRem(2)};
    }
    .portfolio-icon-delta-hotels::before {
      font-size: ${toRem(40)};
      left: ${toRem(11)};
      top: ${toRem(21)};
    }
    .portfolio-icon-marriott::before {
      font-size: ${toRem(36)};
      left: ${toRem(9)};
      top: ${toRem(19)};
    }
    .portfolio-icon-marriott-executive-apartments::before {
      font-size: ${toRem(26)};
      left: ${toRem(6)};
      top: ${toRem(21)};
    }
    .portfolio-icon-vacation-club::before {
      font-size: ${toRem(47)};
      left: ${toRem(3)};
      top: ${toRem(19)};
    }
    .portfolio-icon-gaylord::before {
      font-size: ${toRem(29)};
      left: ${toRem(4)};
      top: ${toRem(19)};
    }
    .portfolio-icon-ac-hotels::before {
      font-size: ${toRem(39)};
      left: ${toRem(13)};
      top: ${toRem(21)};
    }
    .portfolio-icon-courtyard::before {
      font-size: ${toRem(6)};
      left: ${toRem(2)};
    }
    .portfolio-icon-residence-inn::before {
      font-size: ${toRem(8)};
      left: ${toRem(2)};
      top: ${toRem(28)};
    }
    .portfolio-icon-springhill-suites::before {
      font-size: ${toRem(20)};
      left: ${toRem(2)};
    }
    .portfolio-icon-fairfield-inn::before {
      font-size: ${toRem(13)};
      left: ${toRem(2)};
      top: ${toRem(22)}
    }
    .portfolio-icon-towneplace-suites::before {
      font-size: ${toRem(14)};
      left: ${toRem(2)};
    }
    .portfolio-icon-protea-hotels::before {
      font-size: ${toRem(18)};
      left: ${toRem(3)};
    }
    .portfolio-icon-st-regis::before {
      font-size: ${toRem(34)};
      left: ${toRem(7)};
      top: ${toRem(21)};
    }
    .portfolio-icon-luxury-collection::before {
      font-size: ${toRem(29)};
      left: ${toRem(7)};
    }
    .portfolio-icon-w-hotels::before {
      font-size: ${toRem(33)};
      left: ${toRem(15)};
    }
    .portfolio-icon-westin::before {
      font-size: ${toRem(12)};
      left: ${toRem(6)};
    }
    .portfolio-icon-sheraton::before {
      font-size: ${toRem(8)};
      left: ${toRem(6)};
    }
    .portfolio-icon-le-meridien::before {
      font-size: ${toRem(70)};
      left: ${toRem(2)};
    }
    .portfolio-icon-tribute-portfolio::before {
      font-size: ${toRem(11)};
      left: ${toRem(6)};
    }
    .portfolio-icon-design-hotels::before {
      font-size: ${toRem(10)};
      left: ${toRem(7)};
    }
    .portfolio-icon-aloft::before {
      font-size: ${toRem(24)};
      left: ${toRem(9)};
    }
    .portfolio-icon-four-points::before {
      font-size: ${toRem(24)};
      left: ${toRem(10)};
    }
    .portfolio-icon-element::before {
      top: ${toRem(21)};
      font-size: ${toRem(35)};
      left: ${toRem(10)};
    }
    .portfolio-icon-rcr::before {
      font-size: ${toRem(10)};
      left: ${toRem(2)};
    }
    .portfolio-icon-hotels-and-villas::before {
      font-size: ${toRem(26)};
      left: ${toRem(2)};
    }
    .brand-logo-AR::before{
      font-size: ${toRem(52)};
    }
    .brand-logo-OX::before{
      font-size: ${toRem(20)};
    }
    .brand-logo-PR::before{
      font-size: ${toRem(33)};
    }
    .brand-logo-ER::before{
      font-size: ${toRem(30)};
    }
    .brand-logo-LC::before{
      font-size: ${toRem(28)};
    }
    .brand-logo-RS::before{ 
      font-size: ${toRem(50)};
      margin: ${toRem(8)} 0 0 ${toRem(4)};
    }
    .brand-logo-BR::before{
      font-size: ${toRem(30)};
    }
  }
  .mobile-map {
    display: none;
  }

  &__desc {
    width: 60%;
  }

  &__tel {
    text-decoration: none;
  }

  .telephone-label{
  }

  &__contactNumber {
    text-decoration: underline;
  }

  &__cn-box {
    text-decoration: none;
    margin-top: ${toRem(13)};
    font-size: ${toRem(16)};
    display: block;
  }

  &__accordian {
    cursor: pointer;
    margin: ${toRem(20)};
    margin-left: ${toRem(12)};
    max-width: 96%;
    padding-bottom: ${toRem(10)};
    padding-top: 0;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    &.accordion-button:after {
      display: none;
      position: relative;
      bottom: ${toRem(10)};
    }
    &.accordion-button:focus {
      z-index: 0;
    }
    &.show-border{
      border-bottom: ${toRem(1)} solid ${theme.color['alto']} !important;
    }
    &.other-transportation{
      margin-bottom: 0;
      padding-bottom: ${toRem(7)};
    }
    .airplane-icon-name{
      display: flex;
      flex: 1;
      align-items: center;
      .accordion-arrow-icon::before {
        color: ${theme.color['black']};
        position: absolute;
        top: ${toRem(-10)};
        right: 0;
      }
      .icon-airplane::before{
        font-size: ${toRem(20)};
        margin-right: ${toRem(18)};
        position: relative;
        bottom: ${toRem(4)};
      }
      .icon-shuttle::before{
        font-size: ${toRem(20)};
        margin-right: ${toRem(18)};
        position: relative;
        bottom: 0;
      }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .icon-decorative::before{
          color: ${theme.color['light-orange-2']};
        }
      }
    }
    .icon-dropdown-down,.icon-dropdown-up{
      cursor: pointer;
    }
  }

  &__location-name {
    width: 100%;
    display: inline-block;
  }

  &__accordian-container {
    margin-top: ${toRem(45)};
    .accordion-item {
      border: none !important;
    }
    .location-box__accordian:last-of-type {
      border: none;
    }
    .other-transportation{
      margin-bottom: 0;
      .sub-heading{
        margin-bottom: ${toRem(22)};
      }
    }
  }


  &__col2-mobile {
    display: none;
  }

  .panel {
    padding: 0 ${toRem(18)} 0 ${toRem(13)};
    font-size: ${toRem(14)};
    overflow: hidden;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      a{
        font-weight: ${theme.fonts['fontWeightMedium']};
      }
    }
    a{
      font-weight: ${theme.fonts['fontWeightRegular']};
    }
    .website-link{
      font-weight: ${theme.fonts['fontWeightMedium']};
      .m-link-tertiary-button-external{
        &::after{
          position: relative;
          top: ${toRem(1)};
          text-decoration: none;
        }
      }
      .no-arrow-block{
        font-size: ${toRem(13)};
        height: ${toRem(18.33)};
        display: block;
      }
      &:hover{
        .arrow-block{
          display: block;
          &::before{
            top: ${toRem(2)};
          }
          &::after{
            position: relative;
            top: ${toRem(20)};
          }
        }
        .no-arrow-block{
          display: none;
        }
      }
    }
    .new-travel-method{
      margin-bottom: ${toRem(25)};
      .sub-heading{
        margin-bottom: ${toRem(22)};
      }
      .car-phone-number{
        padding-bottom: ${toRem(10)};
        &:last-of-type{
          padding-bottom: 0;
        }
      }
      p{
        margin-bottom: 0;
      }
    }
  }

  .hide-accordian {
    display: none;
  }

  .show-accordian{
    display: block;
    border-bottom: 0;
  }

  .labels {
    font-size: ${toRem(14)};
    max-width: ${toRem(200)};
    max-height: ${toRem(60)};
    text-shadow: ${toRem(1)} ${toRem(1)} ${theme.color['white']};
    .label-container {
      position: relative;
      bottom: 0;
      transform: translate(-50%, -100%);
      .label-text {
        padding: ${toRem(12)} ${toRem(12)};
        text-shadow: none;
        display: inline-block;
        white-space: pre-wrap;
      }
      .icon-bed{
        &:before{
          color: ${theme.color['white']};
        }
      }
      .arrow-down {
        width: 0;
        height: 0;
        margin: auto;
        border-left: ${toRem(5)} solid transparent;
        border-right: ${toRem(5)} solid transparent;
        border-top: ${toRem(5)} solid #000;
      }
    }
  }
  .location-logo-map-new {
    width: auto;
    height: auto;
    border-radius: ${toRem(10)};
    .icon-bed{
      &::before{
        font-size: ${toRem(22)};
        top: ${toRem(7)};
        left: ${toRem(6)};
      }
    }
  }
}
.space-buffer{
  scroll-margin-top: ${toRem(265)};
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .location-box{
    margin-top: ${toRem(-150)};
    padding-top: ${toRem(150)};
  }
  .space-buffer{
    margin-top: ${toRem(-275)};
    padding-top: ${toRem(275)};
  }
}

.wrap {
  max-width: ${toRem(900)};
  margin: 0 auto;
}
.glide__slide {
  border: ${toRem(1)} solid ${theme.color['black']};
  line-height: ${toRem(100)};
  margin: 0;
  text-align: center;
}

.accordian-row {
  width: 100%;
  display: block;
  text-align: center;
  margin-left: ${toRem(-245)};
}

@media only ${theme.mediaQuery.allTablets} {
  .location-box{
    margin-left: ${toRem(32)};
    margin-right: ${toRem(32)};
    padding: ${toRem(36)} 0 0;
    border-top: ${toRem(1.5)} solid ${theme.color['gallery']};
    .location-box__container{
      justify-content: space-between;
      .location-box__col1{
        width: auto;
        .location-heading{
        }
        .location-box__cn-box{
          font-size: ${toRem(14)};
          margin-top: ${toRem(14)};
        }
        .location-box__accordian-container{
          margin-top: ${toRem(32)};
          .panel{
            font-size: ${toRem(13)};
          }
          .location-box__accordian{
            width: ${toRem(370)};
            height:fit-content;
            .airplane-icon-name{
              .accordion-arrow-icon::before {
                top: ${toRem(-5)};
              }
              .icon-airplane::before {
                bottom: 0;
              }
              .location-box__location-name{
                p{
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
    .location-box__accordian{
      align-items: flex-start;
      margin-left: ${toRem(16)};
      margin-right: 0;
      margin-top: ${toRem(10)};
      margin-bottom: 0;
      padding: 0 0 ${toRem(10)} 0;
      height: fit-content;
      .airplane-icon-name{
      }
    }
  }
}

@media only ${theme.mediaQuery.smallerTablet} {
  .location-box{
    scroll-margin-top: ${toRem(30)};
    .location-box__container{
      .location-box__col1{
        .location-box__accordian-container{
          .location-box__accordian{
            width: ${toRem(291)};
            height:fit-content;
          }
        }
      }
      .location-box__col2{
        width: auto;
        #location-box__map-box-desktop{
          overflow: hidden;
          height: ${toRem(260)};
          width: ${toRem(260)};
          margin-top: ${toRem(71)};
          button:focus{
            outline: ${toRem(1)} solid ${theme.color['black']};
          }
        }
      }
    }
  }
  .space-buffer{
    scroll-margin-top: ${toRem(80)};
  }
}

@media only ${theme.mediaQuery.allMobiles} {
  .location-box {
    scroll-margin-top: ${toRem(35)};
    padding: ${toRem(19)} ${toRem(16)} 0;
    margin-top: ${toRem(-72)};
    .location-box__container{
      .location-box__col1{
        .location-heading{
        }
      }
    }
    .location-box__accordian{
      align-items: flex-start;
      margin-left: ${toRem(16)};
      margin-right: 0;
      margin-top: ${toRem(10)};
      margin-bottom: 0;
      padding: 0 0 ${toRem(10)} 0;
      height: fit-content;
      width: 91%;
      .airplane-icon-name{
        .accordion-arrow-icon::before {
          top: ${toRem(-5)};
        }
        .icon-airplane::before {
          bottom: 0;
        }
        .location-box__location-name{
          p{
            margin: 0;
          }
        }
      }
    }
    &__container {
      display: block;
      .location-box__col1{
        .location-box__cn-box{
          margin-top: ${toRem(14)};
        }
        .location-box__accordian-container{
          margin-top: ${toRem(27)};
        }
      }
    }

    &__location-name {
      width: 80%;
    }

    &__col1 {
      width: 100%;
    }

    &__col2 {
      display: none;
    }

    &__col2-mobile {
      display: block;
      width: 100%;
      height: ${toRem(223)};
      margin-top: ${toRem(-8)};
      margin-bottom: ${toRem(15)};
    }
    .desktop-map {
      display: none;
    }
    .mobile-map {
      display: block;
      #location-box__map-box-mobile {
        overflow: hidden;
        height: ${toRem(223)};
        width: 100%;
        border-radius: ${toRem(10)};
        background-color: ${theme.color['alto']};
        button:focus{
          outline: ${toRem(1)} solid ${theme.color['black']};
        }
      }
      .label-text {
        text-shadow: none;
        display: inline-block;
        white-space: pre-wrap;
        padding: ${toRem(24)} ${toRem(8)} !important;
      padding-bottom: ${toRem(32)} !important;
      }
      .icon-status-member-elite {
        text-shadow: none;
        padding: ${toRem(6)} ${toRem(4)} ${toRem(6)} ${toRem(8)};
      }
      .icon-bed{
        &:before{
          color: ${theme.color['white']};
        }
      }
      .arrow-down {
        width: 0;
        height: 0;
        margin: auto;
        border-left: ${toRem(20)} solid transparent;
        border-right: ${toRem(20)} solid transparent;
        border-top: ${toRem(20)} solid #f00;
      }
      .portfolio-icon-edition::before {
        font-size: ${toRem(11)};
        left: ${toRem(2)};
      }
      .portfolio-icon-jw::before {
        font-size: ${toRem(24)};
        left: ${toRem(2)};
        top: ${toRem(21)};
      }
      .portfolio-icon-autograph-collection::before {
        font-size: ${toRem(26)};
        left: ${toRem(2)};
      }
      .portfolio-icon-renaissance::before {
        font-size: ${toRem(33)};
        left: ${toRem(2)};
      }
      .portfolio-icon-delta-hotels::before {
        font-size: ${toRem(40)};
        left: ${toRem(11)};
        top: ${toRem(21)};
      }
      .portfolio-icon-marriott::before {
        font-size: ${toRem(36)};
        left: ${toRem(9)};
        top: ${toRem(19)};
      }
      .portfolio-icon-marriott-executive-apartments::before {
        font-size: ${toRem(26)};
        left: ${toRem(6)};
        top: ${toRem(21)};
      }
      .portfolio-icon-vacation-club::before {
        font-size: ${toRem(47)};
        left: ${toRem(3)};
        top: ${toRem(19)};
      }
      .portfolio-icon-gaylord::before {
        font-size: ${toRem(29)};
        left: ${toRem(4)};
        top: ${toRem(19)};
      }
      .portfolio-icon-ac-hotels::before {
        font-size: ${toRem(39)};
        left: ${toRem(13)};
        top: ${toRem(21)};
      }
      .portfolio-icon-courtyard::before {
        font-size: ${toRem(6)};
        left: ${toRem(2)};
      }
      .portfolio-icon-residence-inn::before {
        font-size: ${toRem(8)};
        left: ${toRem(2)};
        top: ${toRem(28)};
      }
      .portfolio-icon-springhill-suites::before {
        font-size: ${toRem(20)};
        left: ${toRem(2)};
      }
      .portfolio-icon-fairfield-inn::before {
        font-size: ${toRem(13)};
        left: ${toRem(2)};
        top: ${toRem(22)}
      }
      .portfolio-icon-towneplace-suites::before {
        font-size: ${toRem(14)};
        left: ${toRem(2)};
      }
      .portfolio-icon-protea-hotels::before {
        font-size: ${toRem(18)};
        left: ${toRem(3)};
      }
      .portfolio-icon-st-regis::before {
        font-size: ${toRem(34)};
        left: ${toRem(7)};
        top: ${toRem(21)};
      }
      .portfolio-icon-luxury-collection::before {
        font-size: ${toRem(29)};
        left: ${toRem(7)};
      }
      .portfolio-icon-w-hotels::before {
        font-size: ${toRem(33)};
        left: ${toRem(15)};
      }
      .portfolio-icon-westin::before {
        font-size: ${toRem(12)};
        left: ${toRem(6)};
      }
      .portfolio-icon-sheraton::before {
        font-size: ${toRem(8)};
        left: ${toRem(6)};
      }
      .portfolio-icon-le-meridien::before {
        font-size: ${toRem(70)};
        left: ${toRem(2)};
      }
      .portfolio-icon-tribute-portfolio::before {
        font-size: ${toRem(11)};
        left: ${toRem(6)};
      }
      .portfolio-icon-design-hotels::before {
        font-size: ${toRem(16)};
        left: ${toRem(7)};
      }
      .portfolio-icon-aloft::before {
        font-size: ${toRem(24)};
        left: ${toRem(9)};
      }
      .portfolio-icon-four-points::before {
        font-size: ${toRem(24)};
        left: ${toRem(10)};
      }
      .portfolio-icon-element::before {
        top: ${toRem(21)};
        font-size: ${toRem(35)};
        left: ${toRem(10)};
      }
      .portfolio-icon-rcr::before {
        font-size: ${toRem(10)};
        left: ${toRem(2)};
      }
      .portfolio-icon-hotels-and-villas::before {
        font-size: ${toRem(26)};
        left: ${toRem(2)};
      }
      .brand-logo-SH::before{
        font-size: ${toRem(35)};
        margin-top: ${toRem(-5)};
        padding-bottom: ${toRem(4)};
      }
      .brand-logo-GE::before{
        font-size: ${toRem(39)};
        padding-bottom: ${toRem(4)};
        margin-top: ${toRem(-6)};
      }
      .brand-logo-AR::before{
        font-size: ${toRem(39)};
        padding-bottom: ${toRem(5)};
        margin-top: ${toRem(-5)};
      }
      .brand-logo-AL::before{
        font-size: ${toRem(39)};
        padding-bottom: ${toRem(4)};
        margin-top: ${toRem(-6)};
      }
      .brand-logo-AK::before{
        font-size: ${toRem(35)};
        padding-bottom: ${toRem(4)};
        margin-top: ${toRem(-5)};
      }
      .brand-logo-CY::before{
        font-size: ${toRem(15)};
      }
      .brand-logo-DE::before{
        font-size: ${toRem(59)};
        padding-bottom: ${toRem(6)};
        margin-top: ${toRem(-9)};
      }
      .brand-logo-DS::before{
        font-size: ${toRem(10)};
        margin-top: ${toRem(-3)};
      }
      .brand-logo-EB::before{
        font-size: ${toRem(16)};
        margin-top: ${toRem(-3)};
      }
      .brand-logo-EL::before{
        font-size: ${toRem(40)};
        padding-bottom: ${toRem(4)};
        margin-top: ${toRem(-5)};
      }
      .brand-logo-FI::before{
        font-size: ${toRem(19)};
      }
      .brand-logo-FP::before{
        font-size: ${toRem(33)};
      }
      .brand-logo-JW::before{
        font-size: ${toRem(35)};
        padding-bottom: ${toRem(4)};
        margin-top: ${toRem(-5)};
      }
      .brand-logo-MD::before{
        font-size: ${toRem(16)};
        padding-bottom: ${toRem(4)};
      }
      .brand-logo-ER::before{
        font-size: ${toRem(40)};
        padding-bottom: ${toRem(5)};
        margin-top: ${toRem(-6)};
      }
      .brand-logo-MI::before{
        font-size: ${toRem(23)};
      }
      .brand-logo-MV::before{
        font-size: ${toRem(55)};
        margin-top: ${toRem(-8)};
        padding-bottom: ${toRem(7)};
      }
      .brand-logo-MC::before{
        font-size: ${toRem(33)};
        margin-top: ${toRem(-6)};
        padding-bottom: ${toRem(3)};
      }
      .brand-logo-OX::before{
        font-size: ${toRem(27)};
      }
      .brand-logo-PR::before{
        font-size: ${toRem(47)};
        margin-top: ${toRem(-7)};
        padding-bottom: ${toRem(6)};
      }
      .brand-logo-BR::before{
        font-size: ${toRem(42)};
      }
      .brand-logo-RI::before{
        font-size: ${toRem(20)};
        margin-top: ${toRem(-5)};
      }
      .brand-logo-SI::before{
        font-size: ${toRem(40)};
        margin-top: ${toRem(-3)};
      }
      .brand-logo-XR::before{
        font-size: ${toRem(38)};
        margin-top: ${toRem(-5)};
        padding-bottom: ${toRem(3)};
      }
      .brand-logo-LC::before{
        font-size: ${toRem(43)};
        margin-top: ${toRem(-6)};
        padding-bottom: ${toRem(4)};
      }
      .brand-logo-RS::before{ 
        font-size: ${toRem(50)};
        margin: 0 0 0 ${toRem(4)};
      }
      .brand-logo-RZ::before{
        font-size: ${toRem(53)};
        margin-top: ${toRem(-7)};
        padding-bottom: ${toRem(6)};
      }
      .brand-logo-TS::before{
        font-size: ${toRem(32)};
        margin-top: ${toRem(-4)};
        padding-bottom: ${toRem(3)};
      }
      .brand-logo-TX::before{
        font-size: ${toRem(20)};
        margin-top: ${toRem(-5)};
      }
      .brand-logo-WH::before{
        font-size: ${toRem(12)};
        margin-top: ${toRem(-6)};
        padding-bottom: ${toRem(5)};
      }
      .brand-logo-WI::before{
        font-size: ${toRem(20)};
        margin-top: ${toRem(-5)};
      }
      .brand-logo-XE::before{
        font-size: ${toRem(31.2)};
        margin-top: 0;
      }
      .brand-logo-MG::before{
        font-size: ${toRem(18)};
        margin-top: 0;
      }
      .brand-logo-XF::before {
        font-size: ${toRem(32)};
        margin-top: 0;
      }
      .brand-logo-BA::before{
        font-size: ${toRem(42)};
        margin-top: ${toRem(-1)};
      }
    }
  }

  .overview-box {
    padding: 5% 5% 0;
    max-width: 100%;

    &__container {
      text-align: left;
    }

    &__data {
      max-width: 100%;
      margin: 0;
    }
  }

  .imformation-box {
    padding: 0 5% 0;

    &:last-child {
      padding-bottom: ${toRem(80)};

      .imformation-box__container {
        border-bottom: none;
      }
    }

    .amenties-box {
      width: 100%;
      float: none;
    }

    .amenties-container__row {
      display: block;
      width: 100%;
    }

    &__column {
      float: none;
      width: 100%;
    }

    &__container {
      padding-bottom: ${toRem(15)};
    }

    &__row-ame {
      display: block;
      width: 100%;
      margin-bottom: ${toRem(24)};
    }

    .business-container {
      width: 100%;
    }
  }
}
.hqv-modal {
  &.location-box{
    margin-left: ${toRem(24)};
    margin-right: ${toRem(8)};
    @media only ${theme.mediaQuery.allMobiles} {
      margin:0 ${toRem(16)} 0 ${toRem(16)};
      padding:0;
    }
    padding: ${toRem(36)} 0 0;
    .location-box__container{
      justify-content: space-between;

      @media only ${theme.mediaQuery.allMobiles} {
        padding-top: ${toRem(16)};
      }
      .location-box__col1{
        width: 51%;
        @media only ${theme.mediaQuery.allMobiles} {
          width: 100%;
        }
        .location-heading{
        }
        .location-box__cn-box{
          font-size: ${toRem(14)};
          margin-top: ${toRem(14)};
        }
        .location-box__accordian-container{
          margin-top: ${toRem(32)};
          .panel{
            font-size: ${toRem(13)};
          }
          .location-box__accordian{
            width: ${toRem(380)};
            margin-left: 0;
            @media only ${theme.mediaQuery.allMobiles} {
              width: 100%;
            }
            @media only ${theme.mediaQuery.allTablets} {
              width: ${toRem(330)};
            }
            margin-right: ${toRem(16)};
            height:fit-content;
            .accordion-element {
              width: 100%;
            }
            .airplane-icon-name{
              .accordion-arrow-icon::before {
                top: ${toRem(0)};
              }
              .icon-airplane::before {
                bottom: 0;
              }
              .location-box__location-name{
                p{
                  margin: 0;
                }
                margin-right: 6px;
              }
            }
          }
        }
        .location-box__desc{
          width: 95%;
        }
      }
    }
    .location-box__accordian{
      align-items: flex-start;
      margin-left: ${toRem(16)};
      margin-right: 0;
      margin-top: ${toRem(10)};
      margin-bottom: 0;
      padding: 0 0 ${toRem(10)} 0;
      @media only ${theme.mediaQuery.allMobiles} {
        padding: 0;
        margin:0;
      }
      height: fit-content;
      .accordion-element {
        width: ${toRem(290)};
      }
    }
  }
}
`}
`;
