import React, { FC } from 'react';
import clsx from 'clsx';

import { RegionCardSkeletonLoaderProps } from './RegionCardSkeletonLoader.types';
import { StyledRegionCardSkeletonLoader } from './RegionCardSkeletonLoader.styles';

export const RegionCardSkeletonLoader: FC<RegionCardSkeletonLoaderProps> = ({ type }) => {
  return (
    <StyledRegionCardSkeletonLoader className="container">
      <div
        className={clsx(
          'justify-content-center',
          'align-items-center',
          type === 'header-only' ? 'with-header-height' : 'non-header-height'
        )}
      >
        <div className="closed-state-container d-flex">
          <div className="region-card-placeholder"></div>
          <div className="skeleton-loader-composite title-place-loader mb-2"></div>
        </div>

        {type === 'header-only' ? (
          [...Array(10)].map(() => (
            <div className="open-state-container d-flex">
              <div className="skeleton-loader-composite region-item-loader col-md-3 mr-4 mb-3"></div>
              <div className="skeleton-loader-composite region-item-loader col-md-3 mr-4 mb-3"></div>
              <div className="skeleton-loader-composite region-item-loader col-md-3 mr-4 mb-3"></div>
              <div className="skeleton-loader-composite region-item-loader col-md-3 mr-4 mb-3"></div>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
    </StyledRegionCardSkeletonLoader>
  );
};
