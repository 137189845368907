export const pageStoreComponentID = {
  HOTEL_HEADER: 'hotelHeader',
  TRAVEL_AGENT: 'travelAgent',
  CO_BRAND_CREDIT_CARD: 'coBrandCreditCard',
  SUMMARY_OF_CHARGES: 'summaryOfCharges',
  SESSION: 'session',
  ROOM_REQUESTS: 'roomRequests',
  TRAVEL_INSURANCE_WIDGET: 'travelInsuranceWidget',
  TRAVEL_INSURANCE_CART: 'travelInsuranceCart',
  REDEMPTION_PANEL: 'redemptionPanel',
  CLICK_TO_PAY: 'clickToPay',
  MEMBER_INFORMATION: 'memberInformation',
  BOOKING_ACKNOWLEDGEMENT: 'bookingAcknowledgement',
  RATE_CARD: 'rateCard',
  PAGE_LOAD_DATA: 'pageLoadData',
  REQUIRED_CONSENT_ERROR: 'requiredConsentError',
  OPTIONAL_CONSENT_ERROR: 'optionalConsentError',
  SERVER_SIDE_ERROR: 'serverSideError',
  REDEMPTION_NOT_AVAILABLE_ERROR: 'redemptionNotAvailableError',
  TAGP_SELECT_ERROR: 'tagpSelectError',
  MODIFY_SELECT_ERROR: 'modifySelectError',
  IS_ADULT_ONLY_ERROR: 'isAdultOnlyError',
  IS_GUEST_COUNT_ERROR: 'guestCountError',
  RLM_TAB_LIST: 'rlmTabList',
  CUSTOM_PAYMENT: 'customPayment',
  ROOM_OVERVIEW: 'roomOverview',
  UPSELL_SNA: 'upsellSna',
  UPSELL_SNA_STATUS: 'upsellSnaStatus',
  CANCELLED_RES_DETAILS: 'cancellationReservationDetails',
  MODEL_DATA: 'modelData',
  BOOKNOW_LOADING: 'booknowLoading',
  CURRENT_RES_DETAILS: 'currentReservationDetails',
  IS_MODIFY_RES_FLOW: 'isModifyResFlow',
  ADDITIONAL_REQUESTS: 'additionalRequests',
  HOUSE_KEEPING_DATA: 'houseKeepingData',
  CANCEL_POLICY_DATA: 'cancelPolicyData',
  MODIFY_FLOW_SUCCESS: 'modifyFlowSuccess',
  IS_PRINT_LOADING: 'isPrintLoading',
  RESERVATION_DETAILS: 'reservationDetails',
  IS_CANCEL_OTP_ERROR: 'cancelOTPError',
  IS_AVAILIBILITY_ERROR: 'availabilityError',
  ROOM_INDEX: 'roomIndex',
  CONCUR_DETAILS: 'concurDetails',
};
