/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @author: Mithila Hore
 * create context for shop page that can we used to render static data
 * it will be exported as  a hook
 * we can pass static content to this,
 * we can't update these values
 * if you required to update the value
 * it will be recommended to use zustand or other state managment tool
 */

import { createContext, useContext, FC } from 'react';

const HotelDirectoryStaticDataContext = createContext<any>({}); //TODO: need to add types for the context

export const useHotelDirectoryStaticDataContext = () => {
  /**
   * return context as a hook
   * so dev can directly use it
   */
  return useContext(HotelDirectoryStaticDataContext);
};

export const HotelDirectoryStaticDataContextProvider: FC<{ children: JSX.Element | JSX.Element[]; value: any }> = ({
  children,
  value,
}) => {
  /**
   * return a provider with updated value
   */
  return <HotelDirectoryStaticDataContext.Provider value={value}>{children}</HotelDirectoryStaticDataContext.Provider>;
};
