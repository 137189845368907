import { APIDataMap } from './RoomDetailsModal.schema';
import { transformResponse } from '../../utils';
import { constants, URL_CONSTANTS } from '../../constants';
import { RoomFeatureProps } from '../../molecules/RoomFeature/RoomFeature.types';
import {
  ResponseRoomFeatureType,
  ResponseRoomById,
  ResponseProperty,
  FacilityProps,
  DetailsProps,
  ParkingProps,
  AttributeProps,
  FitnessProps,
} from './RoomDetailsModal.types';
import { filterRoomImages, parseRoomImagesData, parseRoomImagesDataCTS } from '../RateCard/helper';
import { parseHotelPhotos } from '../RoomImageCarousel/helper';

const {
  COMPLIMENTARY,
  HK_LOOKUPID_KEY_FREQUENCY,
  HK_LOOKUPID_KEY_BASELINE,
  HK_PLANB_PARTICIPATION,
  HK_PLANA_PARTICIPATION,
  HK_PLAN_A,
  HK_PLAN_B,
  HOTEL_ICON_CODES,
} = constants;

//This function gets the housekeeping frequency and participation for the property from the facility props received from the response
export const getHouseKeepingDetails = (facilities: FacilityProps[]) => {
  const hotelDetails = { frequency: '', participation: '', planHK: '' };
  facilities?.length &&
    facilities?.forEach((facility: FacilityProps) => {
      const { details } = facility;
      details?.length &&
        details?.forEach((detail: DetailsProps) => {
          const { value, lookupId } = detail;
          if (lookupId === HK_LOOKUPID_KEY_FREQUENCY) hotelDetails.frequency = value;
          else if (lookupId === HK_LOOKUPID_KEY_BASELINE) hotelDetails.participation = value;
        });
    });
  //Checks under which plan the property has opted by using the participation detail
  if (hotelDetails.participation?.toUpperCase() === HK_PLANB_PARTICIPATION) hotelDetails.planHK = HK_PLAN_B;
  else if (hotelDetails.participation?.toUpperCase() === HK_PLANA_PARTICIPATION) hotelDetails.planHK = HK_PLAN_A;
  return hotelDetails;
};

//Parses the api response and extracts only the information needed for deisplaying the room features.
export const parseRoomTypeData = (roomData: ResponseRoomById, wifiInformation: string) => {
  const result: RoomFeatureProps[] = roomData.details.map((item: ResponseRoomFeatureType) => {
    const formattedData = {
      featureName: item.type.description,
      featureCode: item.type.code,
      featureValues: item.localizedDescriptions.length > 0 ? [...item.localizedDescriptions] : [...item.descriptions],
    };
    return formattedData;
  });

  const internetIndex = result.findIndex(item => item.featureName.includes('Internet'));
  if (internetIndex >= 0) {
    result[internetIndex].featureValues.push(wifiInformation);
    result[internetIndex].isWiFiText = true;
  } else {
    result[result.length - 1].featureValues.push(wifiInformation);
    result[result.length - 1].isWiFiText = true;
  }

  return result;
};

//Parses the api response and constructs the hotel related features based on different values from the response
export const parseHotelData = (
  data: ResponseProperty,
  hotelHighlightsLabel: string,
  parkinglabel: string,
  hotelServiceslabel: string,
  smokeFreeText: string,
  fitnessCenterText: string
) => {
  const {
    yearBuilt,
    roomRenovation,
    smokefree,
    petsAllowed,
    petsPolicyDescription,
    localizedPetsPolicyDescription,
    parking,
    attributes,
    fitness,
  } = transformResponse(data, APIDataMap);

  //Constructing the hotel highlights category object
  const hotelHighlights = {
    featureName: hotelHighlightsLabel,
    featureCode: HOTEL_ICON_CODES.HOTEL_HIGHLIGHTS,
    featureValues: [...(smokefree ? [smokeFreeText] : []), yearBuilt, roomRenovation],
  };

  //Retrieving the parking category value and constructing the Parking category object
  const parkingValues = parking.map((item: ParkingProps) => {
    if (item?.description) {
      return item.description;
    } else if (item?.fees?.description?.toUpperCase() === COMPLIMENTARY && item?.type?.code) {
      return `${item.type.code}: ${item.fees.description}`;
    }
    return item?.fees?.description;
  });

  const parkingAndTransportation = {
    featureName: parkinglabel,
    featureCode: HOTEL_ICON_CODES.PARKING,
    featureValues: [...parkingValues],
  };

  //Retrieving the different services and constructing the Hotel Services and Amenities category object
  const serviceValues = attributes?.map((item: AttributeProps) => {
    return item.description;
  });

  const fitnessIndex = fitness.findIndex((item: FitnessProps) => item.node.onProperty === true);

  const servicesAndAmenities = {
    featureName: hotelServiceslabel,
    featureCode: HOTEL_ICON_CODES.HOTEL_SERVICES,
    featureValues: [
      ...(petsAllowed ? [localizedPetsPolicyDescription?.translatedText ?? petsPolicyDescription] : []),
      ...(fitnessIndex > -1 ? [fitnessCenterText] : []),
      ...serviceValues,
    ],
  };

  return [...[hotelHighlights], ...[parkingAndTransportation], ...[servicesAndAmenities]];
};

export const roomImagesData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  imageData: any[],
  isCTS = false,
  roomPoolCode = '',
  imageDomain = URL_CONSTANTS.CACHE_MARRIOTT_URL
) => {
  if (imageData?.length && roomPoolCode) {
    const parsedImagesData = isCTS ? parseRoomImagesDataCTS(imageData) : parseRoomImagesData(imageData, imageDomain);
    const filteredImages = filterRoomImages(parsedImagesData, roomPoolCode);
    return parseHotelPhotos(filteredImages);
  }
  return [];
};
