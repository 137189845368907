import { BorderProps } from './Border.types';
import { Line } from './Border.styles';

export const Border = (props: BorderProps) => {
  const { style, customClassName } = props;

  return (
    <Line
      data-component-name="a-book-Border"
      data-testid="book-Border"
      style={style}
      className={customClassName}
    ></Line>
  );
};
