import { StyledDiv } from './ImagePlaceHolder.styles';
import { photoprops } from './ImagePlaceHolder.types';
export const ImagePlaceHolder = (props: photoprops) => {
  return (
    <StyledDiv
      data-component-name="a-book-ImagePlaceHolder"
      data-testid="book-ImagePlaceHolder"
      className="d-flex justify-content-center align-items-center"
    >
      <p className="imageplaceholder-text">{props.text}</p>
    </StyledDiv>
  );
};
