export const MemberInformationMap = {
  'data.customer.profile.name.givenName': 'firstName',
  'data.customer.profile.name.surname': 'lastName',
  'data.customer.profile.localizedName.givenName': 'localizedFirstName',
  'data.customer.profile.localizedName.surname': 'localizedLastName',
  'data.customer.contactInformation.phones[0].number': 'phone',
  'data.customer.contactInformation.phones[0].country.code': 'phoneCountryCode',
  'data.customer.contactInformation.phones': 'phones',
  'data.customer.contactInformation.emails': 'emails',
  'data.customer.contactInformation.addresses': 'addresses',
  'data.customer.revisionToken': 'revisionToken',
  'data.customer.affiliateConnection.edges[0].node.accountInfo': 'accountInfo',
  'data.customer.affiliateConnection.edges[0].node.affiliate': 'affiliate',
};

export const UpdateMemberInfoMap = {
  'data.updateCustomerContactInformation.revisionToken': 'updateRevisionToken',
  'data.updateCustomerContactInformation.status[0].code': 'statusCode',
};

export const SessionMap = {
  'cacheData.data.AriesReservation.tripsXRequestedByHeader': 'tripsXRequestedByHeader',
  'cacheData.data.consumerID': 'consumerID',
  'cacheData.data.rewardsId': 'rewardsId',
  'cacheData.data.AriesReservation.isRewardsRedemptionFlow': 'isRedemption',
  locale: 'locale',
  authenticated: 'authenticated',
  'cacheData.data.travelAgentIataNumber': 'iataNumber',
  'cacheData.data.travelAgentLastName': 'travelAgentLastName',
  'cacheData.data.travelAgentFirstName': 'travelAgentFirstName',
  'cacheData.data.travelAgentType': 'travelAgentType',
  'cacheData.data.AriesReservation.productRateId': 'productRateCode',
};

export const CountryMap = {
  'data.lookupsByType.lookups': 'countries',
};
