/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-var-requires */
const TargetClient = require('@adobe/target-nodejs-sdk');
const TARGET_CONFIG = {
  client: process.env['TARGET_CLIENT'] || 'TARGET_CLIENT',
  organizationId: process.env['TARGET_ORG_ID'] || 'TARGET_ORG_ID',
};

const targetClient = TargetClient.create(TARGET_CONFIG);

export async function getTargetOffers(req: any) {
  try {
    const response = await targetClient.getOffers({ request: req });
    return response;
  } catch (error: any) {
    throw new Error(`Error fetching target offers: ${error.message}`);
  }
}
