import Accordion from '@material-ui/core/Accordion';
import withStyles from '@material-ui/core/styles/withStyles';
import { theme, toRem } from '../../styles';

export const AccordionWithStyle = withStyles({
  root: {
    color: `${theme.colors.black}`,
    width: '100%',
    fontFamily: `${theme.fontFamily.swiss}`,
    boxShadow: 'none',
    '& .MuiSvgIcon-root': {
      height: `${toRem(20)}`,
    },
    '&:before': {
      display: 'none',
    },
    '& .hide-expand-button': {
      '&.MuiAccordionSummary-root:hover:not(.Mui-disabled)': {
        cursor: 'initial',
      },
      '& .MuiAccordionSummary-expandIcon': {
        display: 'none',
      },
    },
  },
})(Accordion);
