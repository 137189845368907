export const toRem = (pixels: number): string => `${pixels * 0.0625}rem`;

//To append the aria attributes to an element only when condition is met.
export const ariaConditionalAppend = (
  labelType: string,
  labelValue: string | boolean | number,
  condition: boolean
): Record<string, string | boolean | number> => (condition ? { [`aria-${labelType}`]: labelValue } : {});

//To get browser useragent
export const getUserAgentType = (): string => {
  if (typeof window !== 'undefined' && navigator) {
    const userAgentType = navigator.userAgent.toLowerCase();
    if (userAgentType.indexOf('android') > -1 || userAgentType.indexOf('iphone') > -1) {
      return 'mobile';
    } else {
      return 'desktop';
    }
  }
  return 'desktop';
};
