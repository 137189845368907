export const jsonContent = {
  checkInLabel: 'checkInLabel',
  amenitiesLabel: 'amenitiesLabel',
  generalInformationLabel: 'generalInformationLabel',
  parkingLabel: 'parkingLabel',
  perStayLabel: 'perStayLabel',
  isHotelInformationSectionDisabled: false,
  isAmenitiesSectionDisabled: false,
  isPropertyDetailsSectionDisabled: true,
  noSmokeFreePolicyText: 'noSmokeFreePolicyText',
  accessibilityInformationLabel: 'accessibilityInformationLabel',
  seeAllAmenitiesLabel: 'seeAllAmenitiesLabel',
  accessibilityLabel: 'accessibilityLabel',
  mockGeneralInformationLabel: 'mockGeneralInformationLabel',
  nonRefundableFeeLabel: 'nonRefundableFeeLabel',
  petsAllowedText: 'petsAllowedText',
  hotelInformationLabel: 'hotelInformationLabel',
  petPolicyLabel: 'petPolicyLabel',
  propertyDetailsLabel: 'propertyDetailsLabel',
  contactHotelLabel: 'contactHotelLabel',
  hotelInfoListItems: [
    {
      hotelInfoLink: '#',
      hotelInfoText: 'hoteInfoText1',
    },
    {
      hotelInfoLink: '#',
      hotelInfoText: 'hotelInfoText2',
    },
  ],
  seeFewerAmenitiesLabel: 'seeFewerAmenitiesLabel',
  petsNotAllowedLabel: 'petsNotAllowedLabel',
  checkOutLabel: 'checkOutLabel',
  moreInformationLabel: 'moreInformationLabel',
  validKeyAmenitiesListArray: [
    { amenitiesTagId: 'airport-shuttle', amenitiesTagTitle: 'Airport-Shuttle' },
    { amenitiesTagId: 'spa', amenitiesTagTitle: 'SPA' },
    { amenitiesTagId: 'golf', amenitiesTagTitle: 'Golf' },
    { amenitiesTagId: 'fitness-center', amenitiesTagTitle: 'Fitness-center' },
    { amenitiesTagId: 'pool', amenitiesTagTitle: 'Pool' },
    { amenitiesTagId: 'kitchen', amenitiesTagTitle: 'Kitchen' },
    { amenitiesTagId: 'pet-friendly', amenitiesTagTitle: 'Pets-welcome' },
    { amenitiesTagId: 'free-internet', amenitiesTagTitle: 'Free-high-speed-internet' },
    { amenitiesTagId: 'breakfast', amenitiesTagTitle: 'Free-breakfast' },
    { amenitiesTagId: 'meeting-space', amenitiesTagTitle: 'Meeting-Space' },
    { amenitiesTagId: 'parking', amenitiesTagTitle: 'Parking' },
  ],
};
