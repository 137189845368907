import React, { useEffect, useState, useReducer } from 'react';
import dynamic from 'next/dynamic';
import { ComponentProps, PageProps } from './index.types';
import { eventUtil } from '@marriott/mi-ui-library-shop';
import { SkeletonPropertyCardLoaderComponent } from '../../SearchResults/component/molecules/skeletonPropertyCardLoader';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { HQVContext } from '../../../../context/HQVContext';
import { hqvInitialState, hqvReducer } from '../../../../reducers/HQV';
import { StyledFormContainer } from './SearchForm.styles';

const SearchFormComponent = dynamic(() => import('./SearchFormComponent'));

const SearchForm: React.FC<ComponentProps> = pageProps => {
  const [loadForm, setLoadForm] = useState(false);
  const [state, dispatch] = useReducer(hqvReducer, hqvInitialState);

  const [searchLoadForms, setSearchLoadForms] = useState(false);
  useEffect(() => {
    eventUtil.on('loadSearchForm', res => {
      setLoadForm(res);
    });
  }, []);

  const setSearchLoadForm = () => {
    setSearchLoadForms(true);
  };

  return (
    <StyledFormContainer
      className={
        (pageProps as unknown as PageProps)?.enableAdfSearchForm ||
        (pageProps as unknown as PageProps)?.cqType?.includes('mi-aem-shop-spa')
          ? ''
          : 'serp-search-form'
      }
    >
      {!searchLoadForms && <SkeletonPropertyCardLoaderComponent isViewportL={true} isSearchForm={true} />}
      {loadForm &&
        ((pageProps as unknown as PageProps)?.enableAdfSearchForm ? (
          <HQVContext.Provider value={{ hqvState: state, hqvDispatch: dispatch }}>
            <SearchFormComponent pageProps={pageProps as unknown as PageProps} setSearchLoadForm={setSearchLoadForm} />
          </HQVContext.Provider>
        ) : (
          <SearchFormComponent pageProps={pageProps as unknown as PageProps} setSearchLoadForm={setSearchLoadForm} />
        ))}
    </StyledFormContainer>
  );
};

export default SearchForm;
//Config for aem editable components
export const SearchFormConfig = {
  emptyLabel: 'SearchForm',
  isEmpty: false,
  //tobe changed
  resourceType: `mi-aem-shop-spa/components/content/searchform/v1/searchform`,
};
//Component wrapped with editable component for aem authoring
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SearchFormComponentEditable = (props: any) =>
  props.cqPath.includes('experiencefragment') ? (
    <SearchForm {...props} />
  ) : (
    <EditableComponent config={SearchFormConfig} {...props}>
      <SearchForm {...props} />
    </EditableComponent>
  );
