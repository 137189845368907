import { FC } from 'react';

import { StatusProps } from './StatusPopup.types';
import { StyledSection } from './StatusPopup.styles';

import { constants } from '../../constants';

export const StatusPopup: FC<StatusProps> = props => {
  const { SUCCESS } = constants;
  const { type, title, subTitle } = props;
  const iconName = type === SUCCESS ? 'icon-success' : 'icon-information';

  return (
    <StyledSection data-component-name="a-book-StatusPopup" data-testid="book-StatusPopup">
      <span className={iconName} />
      <h2 className="title standard">{title}</h2>
      <h3 className="subtitle standard">{subTitle}</h3>
    </StyledSection>
  );
};
