import { FC } from 'react';

import { StyledCheckBoxDiv } from './index.styles';
import { CheckBoxProps } from './index.types';

export const CheckBox: FC<CheckBoxProps> = ({
  variation = 'simple',
  className,
  size = 20,
  checkboxId,
  checkboxName,
  checkboxLabel,
  checked,
  children,
  value,
  color = 'black',
  inputClassName,
  setTabIndex,
  onChange,
  ariaLabelledbyID,
  onKeyDown,
  ariaLabel,
  ...props
}) => {
  const mode = color === 'black' ? 'black-shade' : 'grey-shade';
  return (
    <StyledCheckBoxDiv size={size} className={className} mode={mode} tabIndex={setTabIndex}>
      {variation === 'simple' ? (
        <>
          <input
            type="checkbox"
            id={checkboxId}
            name={checkboxName}
            onChange={onChange}
            onKeyDown={onKeyDown}
            checked={checked}
            value={value}
            className={inputClassName}
            role="checkbox"
            aria-labelledby={ariaLabelledbyID}
            aria-checked={checked}
            data-testid="checkbox"
            {...props}
          ></input>
          <label htmlFor={checkboxId} id={ariaLabelledbyID} onKeyDown={onKeyDown}>
            {checkboxLabel}
            {children}
          </label>
        </>
      ) : (
        <label className="checkbox-switch-wrapper" htmlFor={checkboxId} tabIndex={-1}>
          <span
            className="checkbox-switch"
            tabIndex={0}
            onKeyDown={onKeyDown}
            onChange={onChange}
            role="switch"
            aria-checked={checked}
          >
            <input
              type="checkbox"
              id={checkboxId}
              name={checkboxName}
              checked={checked}
              onChange={onChange}
              value={value}
              data-testid="checkbox"
              {...props}
              className={inputClassName}
              tabIndex={-1}
              role="switch"
              aria-checked={checked}
              aria-label={ariaLabel}
            />
            <span className="checkbox-slider" tabIndex={-1}></span>
          </span>
          {children}
        </label>
      )}
    </StyledCheckBoxDiv>
  );
};

export default CheckBox;
