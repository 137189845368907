// Styles for __name__ go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledAvailabilityCalendarDiv = styled.div`
  box-shadow: none;
  padding: 0 ${toRem(16)};
  background: ${baseVariables.color['neutral10']};

  .tab-wrapper {
    border-radius: 0 !important;

    @media only ${baseVariables.mediaQuery.md} {
      border-radius: ${toRem(12)} !important;
    }

    @media only ${baseVariables.mediaQuery.lg} {
      border-radius: ${toRem(12)} !important;
    }
  }
  .tab-desc {
    margin-top: ${toRem(-3)}!important;
  }
  .tab-wrapper {
    padding-bottom: 0;
    padding-top: ${toRem(16)};
    @media only ${baseVariables.mediaQuery.md} {
      padding-top: ${toRem(24)};
      background: ${baseVariables.color['base20']};
    }

    @media only ${baseVariables.mediaQuery.lg} {
      padding-top: ${toRem(24)};
    }
  }

  .adf-error-messages > div {
    width: 100%;
    @media only ${baseVariables.mediaQuery.md} {
      width: fit-content;
    }
  }
  .adf-error-messages .m-message-inline {
    padding: ${toRem(13)} ${toRem(16)} ${toRem(13)} ${toRem(19)};
    margin: ${toRem(16)} ${toRem(0)} ${toRem(16)} ${toRem(16)};
    @media only ${baseVariables.mediaQuery.md} {
      margin-left: ${toRem(0)};
      padding: ${toRem(13)} ${toRem(23)};
      margin-bottom: ${toRem(24)};
      margin-top: ${toRem(0)};
    }
  }

  .availability-tab-wrapper {
    margin: ${toRem(0)} ${toRem(16)};
    @media only ${baseVariables.mediaQuery.md} {
      margin: ${toRem(0)} ${toRem(32)};
    }
  }
  .points-enabled {
    padding-top: 0 !important;
  }
  .legal-helper-text {
    padding: ${toRem(16)} !important;
    margin: 0 auto !important;

    @media only ${baseVariables.mediaQuery.md} {
      margin: ${toRem(24)} !important;
    }
    @media only ${baseVariables.mediaQuery.lg} {
      padding: 0 ${toRem(6)} !important;
      margin: ${toRem(24)} auto !important;
    }
  }
  .points-legal-helper-text {
    margin: ${toRem(24)} auto !important;
    padding-right: 0;
    @media only ${baseVariables.mediaQuery.md} {
      margin-top: 0 !important;
    }
  }
`;
