/* eslint-disable @typescript-eslint/no-explicit-any */
import fetch from 'cross-fetch';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  useLazyQuery,
  useMutation,
  DocumentNode,
  TypedDocumentNode,
  OperationVariables,
  gql,
  ApolloLink,
  ErrorPolicy,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { split } from 'apollo-link';

import { CUSTOM_EVENTS_CONSTANTS, VITAL_UXL_CALLS, GQL_OPT_NAME, constants } from '../constants';
import { canUseDOM, getProcessEnvs, isDev } from './helper';
import { eventUtil } from './eventUtil';
import { getComponentNameFromUXL, logBookApplicationError } from './errorLogging';

const processenv = getProcessEnvs();
const { NEXT_PUBLIC_GRAPHQL_URL, NEXT_PUBLIC_PREFIX, PROXY_HOST } = processenv;

//gql is to be used in components
export { gql };

declare global {
  interface Window {
    graphqlLanguage: {
      graphqlLanguage: string;
    };
  }
}

//modifying the query headers
const queryHeaders = {
  headers: {
    'accept-language': canUseDOM
      ? window.graphqlLanguage?.graphqlLanguage || process.env['NEXT_PUBLIC_DEFAULT_LANG']
      : process.env['NEXT_PUBLIC_DEFAULT_LANG'],
    'graphql-force-safelisting': [true, constants.TRUE].includes(
      processenv?.['NEXT_PUBLIC_GRAPHQL_FORCE_SAFELISTING'] || ''
    ),
    'apollographql-client-name': 'phoenix_book',
    'apollographql-client-version': 1,
  },
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const batchHttpLink = new BatchHttpLink({ fetch, ...queryHeaders });
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const httpLink = new HttpLink({ fetch, ...queryHeaders });

//this is used to log the errors occured while fetching the query.
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  if (networkError) {
    console.log(`[GraphQL Network error]: ${networkError}`);
  }
});

const link = split(
  operation => operation.getContext()['isBatchEnabled'] === true,
  batchHttpLink as any, // otherwise, batching is fine
  httpLink as any // if the context is true -- debatch
) as unknown as ApolloLink;

//initializing the apollo client
export const apolloClient = new ApolloClient({
  link: errorLink.concat(link),
  cache: new InMemoryCache(),
});

//method used to set the locale specific languageTag using setContext
export const setGraphqlHeaders = (languageTag?: string) => {
  const updatedLink = setContext((_, previousContext) => ({
    headers: {
      ...previousContext['headers'],
      'accept-language': languageTag || process.env['NEXT_PUBLIC_DEFAULT_LANG'],
    },
  })) as unknown as ApolloLink;
  apolloClient.setLink(updatedLink.concat(errorLink.concat(link)));
};

const constructUri = (operationName: string) => {
  if (isDev) {
    return `${PROXY_HOST}${NEXT_PUBLIC_PREFIX}${NEXT_PUBLIC_GRAPHQL_URL}/${operationName}`;
  }
  return `${NEXT_PUBLIC_PREFIX}${NEXT_PUBLIC_GRAPHQL_URL}/${operationName}`;
};

//custom hook is used to fetch the graphql data
export const useQueryFetch = (
  COMP_GQL: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  operationName: string,
  tripsXRequestedByHeader?: string | undefined,
  isBatching = false,
  errorPolicy: ErrorPolicy | undefined = 'none',
  additionalHeaders: Record<string, string | boolean> | undefined = {},
  onErrorCustom?: (() => void) | undefined,
  onCompletedCustom?: (() => void) | undefined
) => {
  // const operationSign = GQL_OPT_SIGN[operationName as keyof typeof GQL_OPT_SIGN];
  return useLazyQuery(COMP_GQL, {
    context: {
      isBatchEnabled: isBatching,
      headers: {
        'graphql-operation-name': `${operationName}`,
        // 'graphql-operation-signature': `${operationSign}`, // Setting the operation signature from the renderer HOC where operationSignatures is passed
        'x-requested-by': tripsXRequestedByHeader ?? '',
        'x-request-id': '', //Setting x-request-id empty to override NextMiApolloClient x-request-id logic
        ...queryHeaders.headers,
        ...additionalHeaders,
      },
      uri: constructUri(operationName),
    },
    fetchPolicy: 'no-cache',
    errorPolicy: errorPolicy,
    onError: e => {
      const componentName = getComponentNameFromUXL(operationName);
      if (VITAL_UXL_CALLS.includes(operationName)) {
        logBookApplicationError([
          { errorSource: 'UXL_ERROR', errorCode: operationName, errorComponent: componentName, errorDetail: e.message },
        ]);
        if (operationName === GQL_OPT_NAME.propertyPaymentInfo || operationName === GQL_OPT_NAME.customerPaymentInfo) {
          window.parent.postMessage({ graphQlError: true }, window.location.origin);
        } else {
          eventUtil.dispatch(CUSTOM_EVENTS_CONSTANTS.ON_GRAPHQL_ERROR, {});
        }
      }
      onErrorCustom?.();
    },
    onCompleted: () => {
      onCompletedCustom?.();
    },
  });
};

//custom mutation hook is used to fetch the graphql data
export const useMutationFetch = (
  COMP_GQL: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  operationName: string,
  tripsXRequestedByHeader?: string | undefined,
  additionalHeaders: Record<string, string | boolean> | undefined = {},
  errorPolicy: ErrorPolicy | undefined = 'none'
) => {
  // const operationSign = GQL_OPT_SIGN[operationName as keyof typeof GQL_OPT_SIGN];
  return useMutation(COMP_GQL, {
    context: {
      headers: {
        'graphql-operation-name': `${operationName}`,
        // 'graphql-operation-signature': `${operationSign}`,  // Setting the operation signature from the renderer HOC where operationSignatures is passed
        'x-requested-by': tripsXRequestedByHeader ?? '',
        'x-request-id': '', //Setting x-request-id empty to override NextMiApolloClient x-request-id logic
        ...queryHeaders.headers,
        ...additionalHeaders,
      },
      uri: constructUri(operationName),
    },
    errorPolicy: errorPolicy,
    onError: e => {
      const componentName = getComponentNameFromUXL(operationName);
      logBookApplicationError([
        { errorSource: 'UXL_ERROR', errorCode: operationName, errorComponent: componentName, errorDetail: e.message },
      ]);
      if (VITAL_UXL_CALLS.includes(operationName)) {
        eventUtil.dispatch(CUSTOM_EVENTS_CONSTANTS.ON_GRAPHQL_ERROR, {});
      }
    },
  });
};
