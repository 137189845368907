/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '@marriott/mi-ui-library-shop';
import { jsonContent as mockLabels } from './mock/jsonContent';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { ErrorHandlerAlert } from '../../molecules';

const HotelInformation = React.lazy(() => import('./HotelInformation'));
interface HotelInformationProps {
  model: Record<string, any>;
  isAuthorMode?: boolean;
}

const HotelInformationComponent: React.FC<HotelInformationProps> & { renderCallServerSide: () => void } = ({
  model,
  isAuthorMode,
}) => {
  const jsonContent: any = { ...mockLabels, ...model };
  const updatedTheme = {
    ...theme,
    // TODO Check how this data will come up in next architecture.
    // dir: initialState?.pageProps?.dirRTL === 'true' ? 'rtl' : '',
    dir: '',
  };

  const renderComponent = () => {
    return (
      <ErrorHandlerAlert alertMessage={jsonContent?.['componentErrorMessage']}>
        <Suspense fallback={''}>
          <ThemeProvider theme={updatedTheme}>
            <HotelInformation jsonContent={jsonContent} isAuthorMode={isAuthorMode} />
          </ThemeProvider>
        </Suspense>
      </ErrorHandlerAlert>
    );
  };
  return renderComponent();
};

export const HotelInformationComponentConfig = {
  emptyLabel: 'HotelInformationComponent',
  isEmpty: false,
  resourceType: `mi-aem-shop-spa/components/content/hotelinformation`,
};

export const HotelInformationComponentEditable = (props: any) => {
  return props.cqPath.includes('experiencefragment') ? (
    <HotelInformationComponent {...props} />
  ) : (
    <EditableComponent config={{}} {...props}>
      <HotelInformationComponent {...props} />
    </EditableComponent>
  );
};

export default HotelInformationComponent;

HotelInformationComponent.renderCallServerSide = () => {
  return {
    customQueryData: 'this is test string',
  };
};
