/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-webpack-loader-syntax */
import { toRem } from './utils';
import color from './colors';
import brand from './brand';

// NEXT JS will only allow importing of SCSS variables from node_nodules if the file has .module.scss name
// normal file imports are not permitted due to global nature of styles and possible conflicts.
import viewportBreakpoints from '@marriott/global-styles/dist/scss/base/variables/_mediaQueries.module.scss';

const fonts = {
  fontFamilySwiss: 'Swiss-721',
  fontSwissCondensed: 'SwissCondensed',
  fontMiIcons: 'MiIcons',
  fontBrandLogoIcons: 'BrandLogoIcons', // !default; /*Brand icons with "by Marriott". These brand Icons are used in uni nav brand portfolio card, search results, brand filters,favs page*/
  fontPortfolioLogoIcons: 'PortfolioLogoIcons', // !default; /*These font icons are ONLY used in the brand ribbon.*/
  fontFamilyVideoIcons: 'VideoIcons', //  !default;
  fontFamilyPl: 'UniversNextW1G',
  fontFamilyAr: 'JannaLT',
  /* Font size variations
   *********************** */
  fontXs: '0.75rem',
  // $font-xs: 0.75rem !default;
  // $font-s: 0.875rem !default;
  fontS: '0.875rem',
  // $font-m: 1rem !default;
  fontM: '1rem',
  // $font-l: 1.125rem !default;
  fontL: '1.125rem',
  // $font-xl: 1.25rem !default;
  fontXl: '1.25rem',
  // $font-xxl: 1.375rem !default;
  fontXxl: '1.375rem',
  // $font-xxxl: 1.5rem !default;
  fontXxxl: '1.5rem',
  // $font-1x: 2.125rem !default;
  font1x: '2.125rem',
  // $font-2x: 2.25rem !default;
  font2x: '2.25rem',
  // $font-3x: 3.75rem !default;
  font3x: '3.75rem',
  // $font-4x: 5.625rem !default;
  font4x: '.625rem',

  /* Line Height palette
   **************** */
  // $line-height-xs: 1rem !default;
  lineHeightXs: '1rem',
  // $line-height-s: 1.125rem !default;
  lineHeightS: '1.125rem',
  // $line-height-m: 1.25rem !default;
  lineHeightM: '1.25rem',
  // $line-height-l: 1.375rem !default;
  lineHeightL: '1.375rem',
  // $line-height-xl: 1.5rem !default;
  lineHeightXl: '1.5rem',
  // $line-height-xxl: 1.625rem !default;
  lineHeightXxl: '1.625rem',
  // $line-height-xxxl: 2.375rem !default;
  lineHeightXxxl: '2.375rem',
  // $line-height-1x: 2.75rem !default;
  lineHeight1x: '2.75rem',
  // $line-height-2x: 4rem !default;
  lineHeight2x: '4rem',

  /* Font weight variations
   ************************* */
  // $font-weight-regular: 500 !default;
  fontWeightRegular: '500',
  // $font-weight-medium: 700 !default;
  fontWeightMedium: '700',
  // $font-weight-bold: 900 !default;
  fontWeightBold: '900',

  /* Font Style variation
   ************************* */
  // $font-style-italic: italic !default;
  fontStyleItalic: 'italic',
};

export const theme = {
  color,
  mediaQuery: {
    largeDesktop: `(min-width: ${viewportBreakpoints['viewportXl']})`,
    desktop: `(min-width: ${viewportBreakpoints['viewportL']})`,
    largeTablet: ` screen and (max-width: ${toRem(1024)})`,
    mobileTablet: ` screen and (max-width: ${toRem(991)})`,
    tablet: ` screen and (min-width: ${viewportBreakpoints['viewportM']})`,
    largeMobile: ` screen and (min-width: ${toRem(600)})`,
    largerMobile: ` screen and (min-width: ${toRem(650)}) and (max-width: ${toRem(767)})`,
    // On windows machine its was breaking on 767 to fix that added extra 0.5
    mobileOnly: ` screen and (max-width: ${toRem(767.5)})`,
    mobile: ` screen and (min-width: ${toRem(320)} )`,
    smallMobile: ` screen and (max-width: ${toRem(359)})`,
    ie11Only: ` screen and (-ms-high-contrast: none), (-ms-high-contrast: active)`,
    tabletMax: ` screen and (max-width: ${toRem(1199)})`,
    minDesktop: ` screen and (min-width: ${toRem(1200)})`,
    allTablets: ` screen and (min-width: ${toRem(768)}) and (max-width: ${toRem(1025)}) `,
    allMobiles: ` screen and (max-width: ${toRem(767)}) `,
    smallerTablet: ` screen and (min-width: ${toRem(768)}) and (max-width: ${toRem(1000)}) `,
    largeScreen: ` screen and (min-width: ${toRem(1400)})`,
  },
  fontFamily: {
    icon: 'MarriottIconsNew',
    swiss: fonts['fontFamilySwiss'],
    swissCondensed: fonts['fontSwissCondensed'],
    title: 'Swiss721-BT-Medium, Helvetica,Arial,sans-serif',
  },
  fonts,
  brand,
};
