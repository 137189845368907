import styled from 'styled-components';
import { toRem } from '../../styles';

export const StyledTooltipContainer = styled.span`
  &:before {
    z-index: 51;
  }
  &.tooltip-container:hover {
    &:after {
      z-index: 51;
      max-width: ${toRem(210)};
    }
    cursor: pointer;
  }
`;
