import { FC } from 'react';
import clsx from 'clsx';
import { getEventKeyTypes } from '@marriott/mi-ui-library';
import { StyledPaginationContainer } from './index.styles';
import { PaginationPanelProps } from './index.types';
import { useTranslation } from 'react-i18next';

import { usePagination, DOTS } from './usePagination';

declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface HTMLAttributes<T> {
    // extends React's HTMLAttributes
    custom_click_track_value?: string;
  }
}
const PaginationPanel: FC<PaginationPanelProps> = ({
  onPageChange,
  totalCount,
  currentPage,
  pageSize,
  className,
  classList,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageSize,
  });
  const { t } = useTranslation();

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = (): void => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = (): void => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange && paginationRange[paginationRange.length - 1];
  const clickTrackingLoc = 'Pagination';
  return (
    <StyledPaginationContainer
      className={clsx('shop-pagination-container', className ?? className, classList?.container)}
    >
      <ul className={`shop-pagination ${classList?.listParent}`} role="list">
        <li>
          <a
            className={clsx(
              'shop-pagination-arrow',
              'shop-pagination-prev',
              't-font-s',
              currentPage === 1 ? 'disabled' : '',
              'custom_click_track'
            )}
            onClick={(event): void => {
              event.preventDefault();
              onPrevious();
            }}
            onKeyDown={(event): void => {
              // event.preventDefault();
              if (getEventKeyTypes(event)?.isEnterKey) {
                onPrevious();
              }
            }}
            // TODO: Code refactor is required to add anchor tags inside the li tags, to support accessibility
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            href="#"
            tabIndex={currentPage === 1 ? -1 : 0}
            aria-label={t('PrevPage')}
            {...{ custom_click_track_value: `${clickTrackingLoc}| PrevPage Option clicked |internal` }}
          >
            <div className="icon-arrow-left" />
          </a>
        </li>
        {paginationRange?.map((pageNumber, ind: number) =>
          pageNumber.toString() === DOTS ? (
            <li key={`${ind}-list`} className="shop-pagination-item shop-pagination-dots" role="presentation">
              &#8230;
            </li>
          ) : (
            <li>
              <a
                className={clsx(
                  'shop-pagination-item',
                  classList?.listItem,
                  pageNumber === currentPage ? 'active t-label-s' : 't-font-s',
                  'custom_click_track'
                )}
                key={`${ind}-list`}
                // TODO: Code refactor is required to add anchor tags inside the li tags, to support accessibility
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                role="button"
                href="#"
                tabIndex={0}
                aria-label={`${pageNumber}`}
                {...{ custom_click_track_value: `${clickTrackingLoc}| Page ${pageNumber} clicked |internal` }}
                onClick={(event): void => {
                  event.preventDefault();
                  onPageChange(pageNumber);
                }}
                onKeyDown={(event): void => {
                  // event.preventDefault();
                  if (getEventKeyTypes(event)?.isEnterKey) {
                    onPageChange(pageNumber);
                  }
                }}
              >
                {pageNumber}
              </a>
            </li>
          )
        )}
        <li>
          <a
            className={clsx(
              'shop-pagination-arrow',
              'shop-pagination-next',
              't-font-s',
              currentPage === lastPage ? 'disabled' : '',
              'custom_click_track'
            )}
            onClick={(event): void => {
              event.preventDefault();
              onNext();
            }}
            onKeyDown={(event): void => {
              // event.preventDefault();
              if (getEventKeyTypes(event)?.isEnterKey) {
                onNext();
              }
            }}
            // TODO: Code refactor is required to add anchor tags inside the li tags, to support accessibility
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
            role="button"
            href="#"
            tabIndex={currentPage === lastPage ? -1 : 0}
            aria-label={t('NextPage')}
            {...{ custom_click_track_value: `${clickTrackingLoc}| NextPage Option clicked |internal` }}
          >
            <div className="icon-arrow-right" />
          </a>
        </li>
      </ul>
    </StyledPaginationContainer>
  );
};

export default PaginationPanel;
