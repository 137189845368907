import { GoogleMap } from '@react-google-maps/api';
import React, { ReactNode } from 'react';

export const GoogleMapComponent: React.FC<{
  childrenVal?: ReactNode | undefined;
  clickOutside?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapRef?: any;
  zoomValue: number;
  centreValue: google.maps.LatLng | google.maps.LatLngLiteral | undefined;
  options?: google.maps.MapOptions | undefined;
  onLoadEvent?: ((map: google.maps.Map) => void | Promise<void>) | undefined;
}> = ({ childrenVal, clickOutside, mapRef, zoomValue, centreValue, options, onLoadEvent }) => {
  return (
    <GoogleMap
      children={childrenVal}
      onClick={clickOutside}
      onDragStart={clickOutside}
      ref={mapRef}
      zoom={zoomValue}
      center={centreValue}
      mapContainerStyle={{ height: '100%', width: '100%' }}
      options={options}
      onLoad={onLoadEvent}
    ></GoogleMap>
  );
};
