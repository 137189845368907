import axios, { AxiosRequestConfig, AxiosResponse, AxiosRequestHeaders } from 'axios';
import { ApiResponse, PayloadConstructor } from './serverTarget.types';
import { defaultUrl, defaultHeaders } from './constant';

export const getTargetExpSegment = async (
  url: string = defaultUrl,
  headers: AxiosRequestHeaders = {},
  createReqObj: PayloadConstructor | object | null = null, // Allow function or object or null
  queryParams: object = {}
): Promise<ApiResponse> => {
  try {
    const defaultPayload = createDefaultPayload();
    let payload = defaultPayload;
    if (createReqObj) {
      if (typeof createReqObj === 'function') {
        payload = createReqObj(defaultPayload);
      } else if (typeof createReqObj === 'object') {
        payload = createReqObj;
      }
    }

    const mergedHeaders: AxiosRequestHeaders = {
      ...defaultHeaders,
      ...headers,
    };

    const config: AxiosRequestConfig = {
      headers: mergedHeaders,
      params: queryParams,
    };
    const response: AxiosResponse = await axios.post(url, payload, config);
    return { data: response.data };
  } catch (error) {
    return { data: null };
  }
};

/* 
  Please do not make any changes to this structure.If you need any changes to the structure you can do it inside the application from which this utility is getting called.
*/
export const createDefaultPayload = (): object => {
  return {
    context: {
      userAgent: 'test',
      timeOffsetInMinutes: 0,
      channel: 'web',
      browser: {
        host: process.env['NEXT_PUBLIC_TARGET_BASE_URL'],
        webGLRenderer: null,
      },

      address: {
        url: '',
        referringUrl: null,
      },
    },

    id: {
      tntId: process.env['NEXT_PUBLIC_TNT_ID'],
    },

    property: {
      token: process.env['NEXT_PUBLIC_PROPERTY_TOKEN'],
    },

    experienceCloud: {
      analytics: {
        supplementalDataId: process.env['NEXT_PUBLIC_SUPPLEMENTAL_DATA_ID'],
        trackingServer: 'metrics.marriott.com',
        trackingServerSecure: 'smetrics.marriott.com',
        logging: 'server_side',
      },
    },

    execute: {
      mboxes: null,
    },
  };
};
