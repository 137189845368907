import { FC } from 'react';
import { ErrorHandlerAlert } from '../../molecules';

interface ComponentProps {
  id?: string;
  componentErrorMessage?: string;
}

const MerchBanner: FC<ComponentProps> = props => {
  const { id } = props;
  return (
    <ErrorHandlerAlert alertMessage={props?.componentErrorMessage}>
      <div id={id}>
        <div id="serp-merch-banner-component-container"></div>
      </div>
    </ErrorHandlerAlert>
  );
};
export default MerchBanner;
