import React from 'react';
import { Checkbox } from '../../atoms';
import { UserMobileNumber } from '../../organisms';
import type { SMSProps } from './SMS.types';

export const SMS: React.FC<SMSProps> = props => {
  const { smsNote, ...rest } = props;

  const sendSMS = rest?.watch && rest.watch('sendSMS');

  return (
    <>
      <div data-component-name="m-book-SMS" data-testid="book-SMS" className="checkbox-container">
        <div className="d-flex align-items-center">
          <Checkbox
            {...rest}
            id="sendSMS"
            testId="sendSMS"
            name="sendSMS"
            selectOptions={[
              {
                key: smsNote,
                value: '',
              },
            ]}
          />
        </div>
      </div>
      {sendSMS && <UserMobileNumber {...rest} name="mobileNumber" />}
    </>
  );
};
