import moment, { Moment } from 'moment';

export type DateObjectType = Moment;

const DATE_FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';

export const getFormattedDate = (date: string, format = DATE_FORMAT_YEAR_MONTH_DAY): DateObjectType => {
  return moment(moment(date).format(format));
};

export const getDurationBetweenDates = (startDate: string, endDate: string): number => {
  return moment.duration(getFormattedDate(endDate).diff(getFormattedDate(startDate), 'days'), 'days').asDays();
};

export const DiffBtDates = (val: string): number => {
  // Input Format should be YYYY-DD-MM
  const date1 = moment(new Date(val));
  const date2 = moment(new Date(new Date().toISOString().substr(0, 10)));
  const diffDays = moment.duration(date1.diff(date2)).asDays();
  return diffDays;
};

export const getDateFormatted = (date?: DateObjectType, format?: string): string => {
  return date?.format(format ?? 'MM/DD/YYYY') ?? '';
};

export const getMPOFormattedDate = (): { currMPODate: string; nextMPODate: string } => {
  const dateObj = {
    currMPODate: getDateFormatted(moment(new Date()), DATE_FORMAT_YEAR_MONTH_DAY).toString(),
    nextMPODate: getDateFormatted(moment(new Date()).add(1, 'days'), DATE_FORMAT_YEAR_MONTH_DAY).toString(),
  };
  return dateObj;
};
