import cssClass from 'classnames';
import { Button } from '../../atoms';
import { constants, CUSTOM_EVENTS_CONSTANTS, TRACKING_CONSTANT } from '../../constants';
import { handleClick } from '../../organisms/RateCard/helper';
import { UpsellCardProps } from './UpSellMiniCard.types';
import { StyledContent } from './UpSellMiniCard.styles';

export const UpsellCard = (props: UpsellCardProps) => {
  const {
    updatedTitle,
    showDescription,
    updatedDescription,
    roomUpgradeType,
    roomModalData,
    roomDetailsClassName,
    ctaLink,
    ctaText,
    primaryCtaLink,
    primaryCtaText,
    trackingProperties,
    btnClassName,
    renderPriceBlock,
    target = '_blank',
    handleUpgradeClick,
  } = props;

  const { ROOM_DETAILS_ID } = constants;
  const { ON_SHOW_ROOM_DETAIL } = CUSTOM_EVENTS_CONSTANTS;
  const bottomSectionClass = cssClass('bottom-section', { 'flex-row-reverse': !roomUpgradeType });

  return (
    <StyledContent>
      <div className="top-section">
        <h3 className="heading standard">{updatedTitle}</h3>
        {showDescription && updatedDescription && <h4 className="description standard">{updatedDescription}</h4>}
        {roomUpgradeType ? (
          <a
            href="#"
            onClick={e => handleClick({ e: e, id: ROOM_DETAILS_ID, item: roomModalData, key: ON_SHOW_ROOM_DETAIL })}
            className={roomDetailsClassName}
          >
            {ctaText}
          </a>
        ) : (
          <a href={ctaLink} target={target} className="m-link-tertiary-button standard">
            {ctaText}
          </a>
        )}
      </div>
      <div className={bottomSectionClass}>
        {roomUpgradeType && renderPriceBlock()}
        <Button
          isLink={true}
          href={primaryCtaLink}
          className={btnClassName}
          trackingProperties={trackingProperties}
          linkType={TRACKING_CONSTANT.INTERNAL_LINK}
          callback={e => handleUpgradeClick?.(e, primaryCtaLink)}
        >
          {primaryCtaText}
        </Button>
      </div>
    </StyledContent>
  );
};
