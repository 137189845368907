import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledTitleWrapper = styled.div`
  ${rtl`
    .hvmi-banner-title {
      @media only ${theme.mediaQuery.mobileOnly} {
       font-size:${toRem(18)};
      }
    }
    .hvmi-banner-desc {
      padding-top:${toRem(12)} !important;
    }
  `}
`;
