import React, { useCallback, useEffect, useState, FC } from 'react';
import ReactPlayerLoader from '@brightcove/react-player-loader';

import { VideoProps } from './Video.types';

interface PlayerInstance {
  paused: () => boolean;
  pause: () => void;
}

export const Video: FC<VideoProps> = props => {
  const {
    id,
    playerId = 'default',
    accountId,
    videoId,
    poster,
    clickTrack,
    custom_click_track_value,
    ariaLabel,
    options,
    videoMediaQuery,
    onError,
  } = props;

  const [error, setError] = useState(false);
  const [player, setPlayer] = useState<PlayerInstance | null>(null);

  const handleResize = useCallback(() => {
    if (!videoMediaQuery) {
      return;
    }
    const mediaQuery = window.matchMedia(videoMediaQuery);
    // Pause the player if the screen width other than the provided mediaquery
    if (!mediaQuery.matches && player && !player.paused()) {
      player.pause();
    }
  }, [player, videoMediaQuery]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [player]);

  useEffect(() => {
    if (error && onError) {
      onError();
    }
  }, [error, onError]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const attachAnalyticsAttr = (success: any) => {
    const { ref } = success;
    if (!ref) return;

    ref.on('error', () => {
      setError(true);
    });

    if (clickTrack) {
      const playControlButton = ref.el_.querySelector('.vjs-play-control');
      if (playControlButton) {
        playControlButton.setAttribute('data-custom_click_track_value', custom_click_track_value);
        playControlButton.classList.add('custom_click_track');
      }
    }

    // Set the player instance when it's ready
    setPlayer(ref);
  };

  return (
    <ReactPlayerLoader
      id={id}
      accountId={accountId}
      videoId={videoId}
      playerId={playerId}
      poster={poster}
      onSuccess={attachAnalyticsAttr}
      onFailure={() => setError(true)}
      options={{
        responsive: true,
        autoplay: options.autoplay,
        muted: options.muted,
        preload: 'none',
        aspectRatio: options.aspectRatio,
        loop: options.loop,
        src: options.src,
        userActions: {
          doubleClick: false,
        },
      }}
      aria-label={ariaLabel}
    />
  );
};
