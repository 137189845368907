/* eslint-disable @typescript-eslint/no-var-requires */
export const phoenixShopHotelAmenities = require('./lib/phoenixShopHotelAmenities.graphql');
export const phoenixShopHQVPhotogalleryCall = require('./lib/phoenixShopHQVPhotogalleryCall.graphql');

// Distance only client-side query
export const phoenixShopHotelGalleryTitleDistanceOnly = require('./lib/phoenixShopHotelGalleryTitleDistanceOnly.graphql');
// Rates only client-side query
export const phoenixShopHotelGalleryTitleRateOnly = require('./lib/phoenixShopHotelGalleryTitleRateOnly.graphql');

// consolidated basic property info for hqv title, location, and overview
export const phoenixShopPropertyInfoCall = require('./lib/phoenixShopPropertyInfoCall.graphql');

export const phoenixShopHQVRateOnly = require('./lib/phoenixShopHQVRateOnly.graphql');

export const phoenixShopHQVPropertyInfoCall = require('./lib/phoenixShopHQVPropertyInfoCall.graphql');
