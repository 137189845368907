import React, { FC } from 'react';
import clsx from 'clsx';

import { StyledExploreDestinationSkeletonLoader } from './ExploreDestinationSkeletonLoader.styles';
import { ExploreDestinationSkeletonLoaderProps } from './ExploreDestinationSkeletonLoader.types';

// Use named rather than default exports.
export const ExploreDestinationSkeletonLoader: FC<ExploreDestinationSkeletonLoaderProps> = () => {
  return (
    <StyledExploreDestinationSkeletonLoader>
      <div
        style={{ height: '408px' }}
        className={clsx('d-flex', 'justify-content-center', 'align-items-center', 'loader-container')}
      >
        <div className="m-spinner-m"></div>
      </div>
    </StyledExploreDestinationSkeletonLoader>
  );
};
