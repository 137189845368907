import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledComp = styled.div`
  .title {
    font-size: ${theme.fonts['fontM']};
    font-weight: ${theme.fonts['fontWeightMedium']};
    line-height: ${theme.fonts['fontXl']};
    margin-bottom: ${toRem(11)};
  }
  .title-container {
    display: flex;
    margin-left: ${toRem(100)};
  }
  .page-mapping-title {
    font-size: ${toRem(16)};
    line-height: ${toRem(20)};
    margin-left: ${toRem(100)};
  }
  .description {
    text-transform: capitalize;
    font-weight: ${theme.fonts['fontWeightBold']};
    padding-left: ${toRem(18)};
  }
`;
