/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataLayerTypes } from './AdobeClientDataLayer.types';

import { canUseDOM } from '../../utils';

export const appendData = (staticData: DataLayerTypes[]) => {
  if (canUseDOM) {
    window.adobeDataLayer = window.adobeDataLayer || ([] as any);
    const acdlObj: Record<string, unknown> = {
      event: 'Initial:loaded',
    };
    staticData.forEach((item: { propertyName: string; propertyValue: string }) => {
      acdlObj[item.propertyName] = item.propertyValue;
    });
    // should use standard event and data object @see libs/shared/mi-headless-utils/src/components/datalayer/datalayer.context.ts
    window.adobeDataLayer.push(acdlObj as any);
  }
};
