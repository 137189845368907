import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { theme } from '../../styles/theme';
import { toRem } from '../../styles/utils';

export const StyledIncrementDiv = styled.div`
  ${rtl`
  display: flex;
  align-items: center;
  button {
    border-radius: 50%;
    width: ${toRem(32)};
    height: ${toRem(32)};
    background: transparent;
    cursor: pointer;
    border: ${toRem(1)} solid ${theme.color['light-gray-6']};
    &.btn-disable {
      cursor: not-allowed;
      span:nth-child(1):before {
        color: ${theme.color['light-gray-6']};
      }
    }
    [class^='icon-'] {
      color: ${theme.color['dark-gray-1']};
    }
    span:nth-child(1) {
      font-size: ${toRem(24)};
      display: flex;

      /* for IE 11 fix */
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &:focus {
      box-shadow: 0 0 ${toRem(1)} ${toRem(1)} ${theme.color['black-opacity-2']};
      outline-offset: 0;
      outline-style: solid;
    }
  }
  > span {
    color: ${theme.color['dark-gray-2']};
    font-size: ${toRem(24)};
    font-weight: 500;
    letter-spacing: -${toRem(0.11)};
    line-height: ${toRem(29)};
    text-align: center;
    display: inline-block;
    margin: 0 ${toRem(20)} ${toRem(2)} ${toRem(20)};
  }
  .empty-separator {
    margin-left: ${toRem(10)};
    margin-right: ${toRem(10)};
  }
`}
`;
