import { useCheckBreakpoint } from './useCheckBreakpoint';
import { useState } from 'react';

/**
 * To allow better autocompletion and feedback to developers we've hardcoded the keys for available
 * breakpoints in this hook. If breakpoints are updated the hook would have to be updated as well.
 */

interface ClassMappings {
  viewportXS: string;
  viewportS: string;
  viewportM: string;
  viewportL: string;
  viewportXl: string;
}

export const useConditionalViewportClasses = (classMapping: ClassMappings): string => {
  const [mappingResponse, setMappingResponse] = useState({
    viewportXS: '',
    viewportS: '',
    viewportM: '',
    viewportL: '',
    viewportXl: '',
  });

  const viewportXS = useCheckBreakpoint('viewportXS');
  const viewportS = useCheckBreakpoint('viewportS');
  const viewportM = useCheckBreakpoint('viewportM');
  const viewportL = useCheckBreakpoint('viewportL');
  const viewportXl = useCheckBreakpoint('viewportXl');

  if (viewportXS) {
    setMappingResponse({ ...mappingResponse, viewportXS: classMapping.viewportXS });
  } else {
    setMappingResponse({ ...mappingResponse, viewportXS: '' });
  }

  if (viewportS) {
    setMappingResponse({ ...mappingResponse, viewportS: classMapping.viewportS });
  } else {
    setMappingResponse({ ...mappingResponse, viewportS: '' });
  }

  if (viewportM) {
    setMappingResponse({ ...mappingResponse, viewportM: classMapping.viewportM });
  } else {
    setMappingResponse({ ...mappingResponse, viewportM: '' });
  }

  if (viewportL) {
    setMappingResponse({ ...mappingResponse, viewportL: classMapping.viewportL });
  } else {
    setMappingResponse({ ...mappingResponse, viewportL: '' });
  }

  if (viewportXl) {
    setMappingResponse({ ...mappingResponse, viewportXl: classMapping.viewportXl });
  } else {
    setMappingResponse({ ...mappingResponse, viewportXl: '' });
  }

  return Object.values(mappingResponse).join(' ');
};
