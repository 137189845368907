import { ADFContextStateType, ADFContextDispatcherPayloadType } from './index.types';
import { ADFCalendarStateAction } from '../../constants';
import { DateObjectType, getDateFormatted, DATE_FORMAT_YEAR_MONTH_DAY } from '@marriott/mi-ui-library';
/**
 * @author: Divendra Kumar
 * Create a store function to update the state on ADF page
 * it will consume adf action file from constant file
 * having state management function and will update the state for ADF page
 */

export const setADFCalendarState = (
  state: ADFContextStateType,
  action: ADFContextDispatcherPayloadType
): ADFContextStateType => {
  /**
   * Update calendar state depend on diffrent action type
   * it will update the required data on page state level
   */
  const { type, payload } = action;
  switch (type) {
    // add required custom action
    case ADFCalendarStateAction.setContextState:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const getInitialStateForADFCalendar = (): ADFContextStateType => {
  /**
   * set initial state for ADF page/ Calendar
   */
  return {
    // TODO: add required data for page render
    fromDate: null,
    toDate: null,
  };
};

export const setDatesForADF = (
  startDate: DateObjectType | null,
  endDate: DateObjectType | null
): ADFContextStateType => {
  return {
    fromDate: startDate,
    toDate: endDate,
    startDate: startDate ? getDateFormatted(startDate, DATE_FORMAT_YEAR_MONTH_DAY) : null,
    endDate: endDate ? getDateFormatted(endDate, DATE_FORMAT_YEAR_MONTH_DAY) : null,
  };
};
