import { Dropdown, Input } from '../../atoms';
import { MobileNumberProps } from './MobileNumber.types';
import { StyledDiv } from './MobileNumber.styles';

import { constants } from '../../constants';
import { checkFormValidation } from '../../utils/helper';

export const MobileNumber = (props: MobileNumberProps) => {
  const { MOBILE_NUMBER, COUNTRY_CODE, US, DEFAULT_DIALING_CODE } = constants;
  const {
    countryCode,
    mobilePhoneNumber,
    dialingCodes,
    validations,
    placeholder,
    errorMessage,
    required,
    defaultCountry,
  } = props;

  const inputPlaceholder = placeholder.replace(/-/g, ' - ');
  const { label = US, value = DEFAULT_DIALING_CODE } = defaultCountry;
  return (
    <StyledDiv data-component-name="m-book-MobileNumber" data-testid="book-MobileNumber">
      <Dropdown
        {...props}
        id={COUNTRY_CODE}
        className="flex-1"
        label={countryCode}
        placeholder={label}
        code={value}
        description={label}
        selectOptions={dialingCodes}
        isMandatoryField={true}
        name={countryCode}
        registerOptions={{
          required: required,
        }}
      />
      <Input
        {...props}
        id={MOBILE_NUMBER}
        className="flex-1"
        isMandatoryField={true}
        name={mobilePhoneNumber}
        label={mobilePhoneNumber}
        placeholder={inputPlaceholder}
        registerOptions={{
          required: required,
          pattern: {
            value: checkFormValidation(validations),
            message: errorMessage,
          },
        }}
      />
    </StyledDiv>
  );
};
