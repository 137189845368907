import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledDiv = styled.div`
  display: flex;
  gap: ${toRem(16)};
  margin: ${toRem(24)} 0;

  .flex-1 {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  @media ${theme.mediaQuery.allMobiles} {
    display: block;
    & > :first-child {
      margin-bottom: ${toRem(24)};
    }
  }
`;
