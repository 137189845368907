/* eslint-disable @typescript-eslint/no-explicit-any */
import { DATALAYER_CONSTANT } from '../../../../../../mi-shop-components/src/constants';
import { KeysRequest, getProcessEnvs } from '../../../../../../mi-shop-components/src/utils';
import Axios from 'axios';
import { logger } from '../../../../utils';
import Cookies from 'js-cookie';

const { log } = logger({})('HotelGalleryTitle/helper');
const { NEXT_PUBLIC_DEFAULT_LANG } = getProcessEnvs();

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>;
  }
}

function updateDataLayerObjForHQV(propertyId: string | undefined, datalayerProperties: any) {
  let pathType = '';
  if (Cookies.get('newSearch')) {
    pathType = datalayerProperties?.NEXT_PUBLIC_PATHTYPE_NEW ?? '';
  } else {
    pathType = datalayerProperties?.NEXT_PUBLIC_PATHTYPE_LEGACY ?? '';
  }

  const dataLayer = window?.dataLayer;
  if (dataLayer) {
    dataLayer['search_res_path_type'] = pathType;
    dataLayer['search_list_records_on_page'] = '1';
    dataLayer['search_multiRate_or_single'] = datalayerProperties?.NEXT_PUBLIC_SEARCH_SINGLE_RATE ?? '';
    dataLayer['search_list_setting_records_per_page'] = 40;
    dataLayer['search_list_marshas_on_page'] = propertyId;
    dataLayer['search_list_sort_types_available'] = localStorage.getItem('sortByOptions') ?? '';
  }
}

export async function callDataLayer(
  resolvedUrl: any,
  sessionData: any,
  headersData?: any,
  query?: any,
  propertyId?: string,
  brandCode?: any,
  datalayerParams?: any,
  datalayerProperties?: any
): Promise<any> {
  const getPageURI = resolvedUrl ? resolvedUrl.split('?')[0] : '';
  const MOCK_SESSION_ID = '825167AA-9F54-5B1B-95C0-53EEC4E22391';
  const absolutePageURL = `${getPageURI}.mi`;
  const localeKeyValue =
    headersData &&
    Object.keys(headersData)?.length > 0 &&
    headersData['accept-language']?.split(',')[0]?.replace('-', '_');
  const channelValue = datalayerParams?.NEXT_PUBLIC_DATALAYER_SITENAME?.split('.')[0];
  const datalayerUrl = datalayerParams?.MI_DATALAYER_APP_URL || '';

  const getHeaders = (): string => {
    let cookieValues = '';
    if (headersData?.mi_site) {
      cookieValues = cookieValues + ` MI_SITE=${headersData?.mi_site};`;
    }
    if (headersData?.mi_visitor) {
      cookieValues = cookieValues + ` MI_VISITOR=${headersData?.mi_visitor};`;
    }
    if (headersData?.remembermeuserid) {
      cookieValues = cookieValues + ` RememberMeUserID=${encodeURIComponent(headersData?.remembermeuserid)};`;
    }
    if (headersData?.remembermealternateid) {
      cookieValues = cookieValues + ` RememberMeAlternateID=${encodeURIComponent(headersData?.remembermealternateid)};`;
    }
    if (headersData?.useridtoken) {
      cookieValues = cookieValues + ` UserIdToken=${encodeURIComponent(headersData?.useridtoken)};`;
    }
    return cookieValues;
  };

  const headersKey: Record<string, string | undefined> = {};

  // creating headers for datalayer call
  if (headersData && Object.keys(headersData).length > 0) {
    DATALAYER_CONSTANT.headerKeys.forEach(name => {
      if (name && headersData[name as KeysRequest['keys']]) {
        headersKey[name] = headersData[name as KeysRequest['keys']];
      }
    });
  }

  const reqBody = {
    context: {
      localeKey: localeKeyValue ?? 'en_US',
      programFlag: '',
      siteName: datalayerParams?.NEXT_PUBLIC_DATALAYER_SITENAME,
      mobileAuthEnabled: 'false',
      productSiteId: datalayerParams?.NEXT_PUBLIC_DATALAYER_PRODUCT,
      channel: channelValue,
      pageContent: [],
      pageURI: getPageURI,
      referer: headersData?.referer,
      absolutePageURL: absolutePageURL,
      applicationName: datalayerParams?.NEXT_PUBLIC_DATALAYER_APPLICATION,
      products: datalayerParams?.NEXT_PUBLIC_DATALAYER_PRODUCT,
      template: datalayerParams?.NEXT_PUBLIC_DATALAYER_TEMPLATE,
      seoQueryParams: query,
      marshaCode: propertyId,
      propertyId: propertyId,
      brandCode: brandCode,
    },
    sessionToken:
      sessionData?.key ??
      sessionData?.data?.sessionToken ??
      sessionData?.cacheData?.key ??
      sessionData?.cacheData?.data?.sessionToken ??
      MOCK_SESSION_ID,
    sourceURI: absolutePageURL,
    variation: datalayerParams?.NEXT_PUBLIC_DATALAYER_VARIATION,
  };

  const dataLayer = await Axios.post(datalayerUrl, reqBody, {
    headers: {
      // please make sure these coookies are in single line. Else there will be `TypeError [ERR_INVALID_CHAR]`
      cookie: `sessionID=${sessionData?.key}; RememberMeFlag=${headersData?.remembermeflag}; ${getHeaders()}`,
      ...headersKey,
    },
  });

  log.debug(`${headersKey}, 'headersKey headersKey`);
  log.debug(`==========datalayerUrl==========: ${datalayerUrl}`);
  log.debug(`==========reqBody==========: ${JSON.stringify(reqBody)}`);
  log.debug(`==========response==========: ${JSON.stringify(dataLayer)}`);

  const datalayerResponse = dataLayer?.data?.component?.data?.dataProperties;
  if (dataLayer?.data?.component?.data?.dataProperties?.length && window?.dataLayer) {
    for (const [key] of Object.entries(window.dataLayer)) {
      datalayerResponse.forEach((entry: any) => {
        if (window?.dataLayer) {
          if (key === entry.key) {
            window.dataLayer[key] = entry.value;
          } else {
            window.dataLayer[entry.key] = entry.value;
          }
        }
      });
    }
  }

  updateDataLayerObjForHQV(propertyId, datalayerProperties);
}
