import { FC } from 'react';

import { Tile, TranslationDisclaimerProps } from './TranslationDisclaimer.types';
import CongratulationsTile from './CongratulationsTile';
import TranslationDisclaimerTile from './TranslationDisclaimerTile';

export const TranslationDisclaimer: FC<TranslationDisclaimerProps> = (props: TranslationDisclaimerProps) => {
  switch (props.componentType) {
    case Tile.CongratulationsTile:
      return (
        <div data-component-name="m-book-TranslationDisclaimer" data-testid="book-TranslationDisclaimer">
          <CongratulationsTile {...props} />
        </div>
      );
    default:
      return (
        <div data-component-name="m-book-TranslationDisclaimer" data-testid="book-TranslationDisclaimer">
          <TranslationDisclaimerTile {...props} />
        </div>
      );
  }
};
