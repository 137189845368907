import dynamic from 'next/dynamic';
import { ReservationDetailsProps } from './ReservationDetails.types';
import { ReservationDetailsTypes } from './V2/index.types';

export const ReservationDetails = dynamic<ReservationDetailsProps>(() =>
  import('./ReservationDetails').then(module => module.ReservationDetails)
);

export const ReservationDetailsV2 = dynamic<ReservationDetailsTypes>(() =>
  import('./V2').then(module => module.ReservationDetailsV2)
);
