import { FC } from 'react';

import { ModalCTA } from '../../molecules';
import { TranslationDisclaimerProps } from './TranslationDisclaimer.types';
import { StyledWrapper } from './CongratulationsTile.styles';
import { addSubDirectoryPrefix, getWindowSession, transformResponse } from '../../utils';
import { SessionMap } from './CongratulationsTile.schema';
import { constants, URL_CONSTANTS } from '../../constants';

function extractText(htmlString: string = '') {
  const [beforeLink = '', rest = ''] = htmlString.split(/<a[^>]*>/);
  const [linkText = '', afterLink = ''] = rest.split(/<\/a>/);

  return {
    beforeLinkText: beforeLink.replace('<p>', ''),
    linkText: linkText.trim(),
    afterLinkText: afterLink.replace('</p>', ''),
  };
}

const CongratulationsTile: FC<TranslationDisclaimerProps> = ({
  disclaimerText,
  classname,
  disableBackgroundStyling = false,
}: TranslationDisclaimerProps) => {
  const { beforeLinkText = '', linkText = '', afterLinkText = '' } = extractText(disclaimerText);
  const sessionObject = getWindowSession();
  const { isMemberRate } = transformResponse(sessionObject, SessionMap);

  if (!isMemberRate || !disclaimerText) {
    return null;
  }

  return (
    <StyledWrapper className={classname} disableBackgroundStyling={disableBackgroundStyling}>
      <span className="ml-1 icon-member-rates1x member-icon" />
      {beforeLinkText}
      <ModalCTA
        ctaLabel={linkText}
        ctaLink={addSubDirectoryPrefix(URL_CONSTANTS.RES_SIGNIN_OVERLAY)}
        pageType={constants.HYBRID_PAGE}
        disableSignInAutoLoad={true}
      />
      {afterLinkText}
    </StyledWrapper>
  );
};

export default CongratulationsTile;
