import { FC } from 'react';

import { Button, Image } from '../../atoms';
import { SessionDataMap } from './MiniCard.schema';
import { bannerLinkProps, MiniCardProps } from './MiniCard.types';
import { StyledComp, StyledImageContainer, StyledSection } from './MiniCard.styles';
import cssClass from 'classnames';

import { createImageSizes, getWindowSession, transformResponse, useScreenWidth } from '../../utils';
import { constants, TRACKING_CONSTANT } from '../../constants';

const { BONVOY_BANNER, MINICARD, REDEMPTION_ALERT, CHECK_IN_BANNER, TRAVEL_INSURANCE_BANNER, MAX_VIEWPORT_MOBILE } =
  constants;

const BannerLink = ({ ctaLink, type, target, trackingProperties, linkAriaLabel }: bannerLinkProps) => {
  const iconName = type === BONVOY_BANNER ? 'm-link-tertiary-button' : 'icon-arrow-right';
  return ctaLink ? (
    type === BONVOY_BANNER ? (
      <span className={iconName + ' link'} />
    ) : (
      <Button
        isLink={true}
        href={ctaLink}
        className={iconName + ' link'}
        target={target}
        trackingProperties={trackingProperties}
        linkAriaLabel={linkAriaLabel}
      >
        <span>{type}</span>
      </Button>
    )
  ) : null;
};

export const MiniCard: FC<MiniCardProps> = ({
  type,
  target,
  ctaLink,
  altText,
  fileReferenceDynamic,
  cardTitle,
  cardDescription,
  cardCTA,
  linkAriaLabel,
  trackingProperties,
  linkType = '',
  noPwdEnroll,
  tagLine,
}: MiniCardProps) => {
  const sessionObject = getWindowSession();
  const { isMaxProperty } = transformResponse(sessionObject, SessionDataMap);
  const renditions = fileReferenceDynamic?.renditions;
  const imageSize = createImageSizes(renditions);
  const isredemptionAlert = type === REDEMPTION_ALERT;
  const isCtaRequired = type === CHECK_IN_BANNER || type === TRAVEL_INSURANCE_BANNER || noPwdEnroll;
  const isMobileViewPort = useScreenWidth() < MAX_VIEWPORT_MOBILE;
  const btnSectionClassName = cssClass({
    'standard m-button-m button': type === CHECK_IN_BANNER,
    'standard m-button-s button': noPwdEnroll,
    'm-button-secondary button': type === TRAVEL_INSURANCE_BANNER,
  });

  const titleWrapper = cssClass('title-wrapper', {
    'ml-5': type === TRAVEL_INSURANCE_BANNER && !isMobileViewPort,
    'wo-pwd': noPwdEnroll,
  });
  const compClassName = cssClass(type, { 'no-pwd-view': noPwdEnroll });
  const renderContent = () => {
    return (
      <StyledComp data-component-name="m-book-MiniCard" data-testid="book-MiniCard" className={compClassName}>
        <StyledSection className="flex-1">
          {isredemptionAlert ? (
            <span className="icon-free-nights icon-extra-free-nights"></span>
          ) : (
            <StyledImageContainer className={type}>
              <Image
                url={fileReferenceDynamic?.damPath}
                renditions={fileReferenceDynamic?.renditions}
                dynamic={fileReferenceDynamic?.dynamic}
                title={altText}
                size={imageSize}
                appliedClass={'banner-image'}
              />
            </StyledImageContainer>
          )}
          <div className={titleWrapper}>
            {noPwdEnroll && <p className="tagline">{tagLine}</p>}
            {cardTitle && <h2 className="t-title-xs heading standard">{cardTitle}</h2>}
            <p className="description standard">{cardDescription}</p>
          </div>
        </StyledSection>
        {isCtaRequired && (
          <div className="btn-section">
            <Button
              isLink={true}
              href={cardCTA?.ctaLink}
              className={btnSectionClassName}
              target={cardCTA?.target}
              trackingProperties={cardCTA?.trackingProperties}
              linkAriaLabel={cardCTA?.ctaText}
              linkType={linkType}
            >
              {cardCTA?.ctaText}
            </Button>
          </div>
        )}
        {!noPwdEnroll ? (
          <BannerLink
            type={type}
            ctaLink={type === MINICARD || type === BONVOY_BANNER ? cardCTA?.ctaLink : ctaLink}
            target={type === MINICARD || type === BONVOY_BANNER ? cardCTA?.target : target}
            trackingProperties={cardCTA?.trackingProperties ?? trackingProperties}
            linkAriaLabel={linkAriaLabel}
          />
        ) : null}
      </StyledComp>
    );
  };

  if (isMaxProperty && type === BONVOY_BANNER) return null;

  return type === BONVOY_BANNER ? (
    <Button
      isLink={true}
      buttonClassName={'d-block'}
      href={cardCTA?.ctaLink}
      target={cardCTA?.target}
      trackingProperties={cardCTA?.trackingProperties ?? trackingProperties}
      externalArrow={false}
      linkType={TRACKING_CONSTANT.INTERNAL_LINK}
    >
      {renderContent()}
    </Button>
  ) : (
    renderContent()
  );
};
