// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// eslint-disable-next-line import/no-webpack-loader-syntax
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import breakpoints from '@marriott/global-styles/dist/scss/base/variables/_mediaQueries.module.scss';
import { useWindowSize } from './useWindowSize';

// parsing breakpoints fetched from global styles and sending as int values
const APPLICATION_BREAKPOINTS = (() => {
  const parsed: { [key: string]: number } = {};
  Object.keys(breakpoints).forEach(key => {
    parsed[key] = parseInt(breakpoints[key].replace('px', ''), 10);
  });
  return parsed;
})();

type keys = keyof typeof APPLICATION_BREAKPOINTS;

export function useCheckBreakpoint(viewportSize: keys) {
  const [isGreater, setIsGreater] = useState(false);
  const { width } = useWindowSize();

  const targetViewportSize = APPLICATION_BREAKPOINTS[viewportSize] || 0;

  useEffect(() => {
    if (width && width >= targetViewportSize) {
      setIsGreater(true);
    } else {
      setIsGreater(false);
    }
  }, [width, targetViewportSize]);

  return isGreater;
}
