export const hotelDirectoryMockUXL = {
  data: {
    searchPropertiesByDestination: {
      __typename: 'PropertyDestinationSearchConnection',
      facets: [
        {
          __typename: 'PropertySearchFacet',
          buckets: [
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'africa',
              count: 148,
              label: 'Africa',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'asia',
              count: 1193,
              label: 'Asia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'australia-pi',
              count: 49,
              label: 'Australia & Pacific Islands',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'canada',
              count: 282,
              label: 'Canada',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'caribbean',
              count: 98,
              label: 'Caribbean',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'central-america',
              count: 46,
              label: 'Central America',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'europe',
              count: 874,
              label: 'Europe',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'mexico',
              count: 293,
              label: 'Mexico',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'middle-east',
              count: 175,
              label: 'Middle East',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'south-america',
              count: 94,
              label: 'South America',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'united-states',
              count: 5938,
              label: 'United States',
            },
          ],
          type: {
            __typename: 'PropertySearchFacetTypes',
            code: 'leisure-region',
            count: 11,
            enumCode: 'LEISURE_REGIONS',
            label: 'Leisure Region',
          },
        },
        {
          __typename: 'PropertySearchFacet',
          buckets: [
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'all-suites',
              count: 248,
              label: 'All Suites',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'business-center',
              count: 7723,
              label: 'Business center',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'ev-charging',
              count: 2054,
              label: 'EV Charging',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'fitness-center',
              count: 8779,
              label: 'Fitness center',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'breakfast',
              count: 3699,
              label: 'Free breakfast',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'free-internet',
              count: 8397,
              label: 'Free high-speed internet',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'internet',
              count: 9117,
              label: 'High-speed Internet',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'kitchen',
              count: 2032,
              label: 'Kitchen',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'kitchenette',
              count: 1461,
              label: 'Kitchenette',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'pet-friendly',
              count: 4452,
              label: 'Pets welcome',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'plug-in-panel',
              count: 2527,
              label: 'Plug-in panel',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'pool',
              count: 6911,
              label: 'Pool',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'new-lobby',
              count: 6,
              label: 'Renovated Lobby',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'restaurant-on-site',
              count: 6579,
              label: 'Restaurant on-site',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'room-service',
              count: 3197,
              label: 'Room service',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'whirlpool-in-room',
              count: 730,
              label: 'Whirlpool in Room',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'whirlpool-on-site',
              count: 2079,
              label: 'Whirlpool on Site',
            },
          ],
          type: {
            __typename: 'PropertySearchFacetTypes',
            code: 'amenities',
            count: 17,
            enumCode: 'AMENITIES',
            label: 'Amenities',
          },
        },
        {
          __typename: 'PropertySearchFacet',
          buckets: [
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AR',
              count: 251,
              label: 'AC Hotels',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AL',
              count: 240,
              label: 'Aloft Hotels',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'BA',
              count: 1,
              label: 'Apartments by Marriott Bonvoy',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AK',
              count: 336,
              label: 'Autograph Collection',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'XE',
              count: 155,
              label: 'City Express by Marriott',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'CY',
              count: 1355,
              label: 'Courtyard',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'DE',
              count: 141,
              label: 'Delta Hotels',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'DS',
              count: 153,
              label: 'Design Hotels™',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'EB',
              count: 20,
              label: 'EDITION',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'EL',
              count: 113,
              label: 'Element Hotels',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'FI',
              count: 1358,
              label: 'Fairfield Inn & Suites',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'FP',
              count: 345,
              label: 'Four Points by Sheraton',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'XF',
              count: 19,
              label: 'Four Points Express by Sheraton',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'GE',
              count: 7,
              label: 'Gaylord Hotels',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'JW',
              count: 129,
              label: 'JW Marriott',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MD',
              count: 119,
              label: 'Le Méridien',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'ER',
              count: 44,
              label: 'Marriott Executive Apartments',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MC',
              count: 674,
              label: 'Marriott Hotels & Resorts',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MV',
              count: 85,
              label: 'Marriott Vacation Club',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MG',
              count: 12,
              label: 'MGM Collection with Marriott Bonvoy',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'OX',
              count: 164,
              label: 'Moxy Hotels',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'PR',
              count: 64,
              label: 'Protea Hotels',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'BR',
              count: 180,
              label: 'Renaissance Hotels',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'RI',
              count: 939,
              label: 'Residence Inn',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'SI',
              count: 450,
              label: 'Sheraton',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'SH',
              count: 574,
              label: 'SpringHill Suites',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'XR',
              count: 67,
              label: 'St. Regis',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'LC',
              count: 125,
              label: 'The Luxury Collection',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'RZ',
              count: 130,
              label: 'The Ritz-Carlton',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'TS',
              count: 543,
              label: 'TownePlace Suites',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'TX',
              count: 147,
              label: 'Tribute Portfolio',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'WH',
              count: 73,
              label: 'W Hotels',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'WI',
              count: 262,
              label: 'Westin Hotels & Resorts',
            },
          ],
          type: {
            __typename: 'PropertySearchFacetTypes',
            code: 'brands',
            count: 33,
            enumCode: 'BRANDS',
            label: 'Brands',
          },
        },
        {
          __typename: 'PropertySearchFacet',
          buckets: [
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'convention-center',
              count: 2,
              label: 'Convention center',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'extended-stay',
              count: 1634,
              label: 'Extended stay',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'new',
              count: 573,
              label: 'New hotel',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'resort',
              count: 656,
              label: 'Resort',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'timeshare',
              count: 94,
              label: 'Timeshare',
            },
          ],
          type: {
            __typename: 'PropertySearchFacetTypes',
            code: 'property-types',
            count: 5,
            enumCode: 'PROPERTY_TYPES',
            label: 'Hotel Type',
          },
        },
        {
          __typename: 'PropertySearchFacet',
          buckets: [
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: "Ivory Coast (Cote D'Ivoire)",
              count: 1,
              label: null,
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'Moldova',
              count: 1,
              label: null,
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AL',
              count: 1,
              label: 'Albania',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'DZ',
              count: 8,
              label: 'Algeria',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AO',
              count: 1,
              label: 'Angola',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AQ',
              count: 13,
              label: 'Antarctica',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AG',
              count: 2,
              label: 'Antigua and Barbuda',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AR',
              count: 12,
              label: 'Argentina',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AM',
              count: 4,
              label: 'Armenia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AW',
              count: 7,
              label: 'Aruba',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AU',
              count: 34,
              label: 'Australia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AT',
              count: 21,
              label: 'Austria',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AZ',
              count: 7,
              label: 'Azerbaijan',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'BS',
              count: 5,
              label: 'Bahamas',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'BH',
              count: 5,
              label: 'Bahrain',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'BD',
              count: 4,
              label: 'Bangladesh',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'BB',
              count: 8,
              label: 'Barbados',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'BY',
              count: 1,
              label: 'Belarus',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'BE',
              count: 15,
              label: 'Belgium',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'BZ',
              count: 1,
              label: 'Belize',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'BM',
              count: 2,
              label: 'Bermuda',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'BT',
              count: 2,
              label: 'Bhutan',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'BO',
              count: 2,
              label: 'Bolivia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'BA',
              count: 4,
              label: 'Bosnia and Herzegovina',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'BW',
              count: 1,
              label: 'Botswana',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'BR',
              count: 14,
              label: 'Brazil',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'BG',
              count: 4,
              label: 'Bulgaria',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'KH',
              count: 4,
              label: 'Cambodia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'CA',
              count: 282,
              label: 'Canada',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'KY',
              count: 3,
              label: 'Cayman Islands',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'CL',
              count: 14,
              label: 'Chile',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'CN',
              count: 566,
              label: 'China',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'CO',
              count: 25,
              label: 'Colombia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'CR',
              count: 21,
              label: 'Costa Rica',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'HR',
              count: 6,
              label: 'Croatia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'CW',
              count: 3,
              label: 'Curacao',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'CY',
              count: 2,
              label: 'Cyprus',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'CZ',
              count: 8,
              label: 'Czech Republic',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'DK',
              count: 6,
              label: 'Denmark',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'DJ',
              count: 1,
              label: 'Djibouti',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'DO',
              count: 24,
              label: 'Dominican Republic',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'EC',
              count: 6,
              label: 'Ecuador',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'EG',
              count: 17,
              label: 'Egypt',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'SV',
              count: 3,
              label: 'El Salvador',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'EE',
              count: 1,
              label: 'Estonia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'ET',
              count: 2,
              label: 'Ethiopia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'FJ',
              count: 5,
              label: 'Fiji',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'FI',
              count: 3,
              label: 'Finland',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'FR',
              count: 73,
              label: 'France',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'PF',
              count: 2,
              label: 'French Polynesia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'GE',
              count: 12,
              label: 'Georgia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'DE',
              count: 122,
              label: 'Germany',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'GH',
              count: 2,
              label: 'Ghana',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'GR',
              count: 40,
              label: 'Greece',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'GD',
              count: 2,
              label: 'Grenada',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'GU',
              count: 1,
              label: 'Guam',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'GT',
              count: 3,
              label: 'Guatemala',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'GY',
              count: 1,
              label: 'Guyana',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'HT',
              count: 1,
              label: 'Haiti',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'HN',
              count: 1,
              label: 'Honduras',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'HK',
              count: 16,
              label: 'Hong Kong',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'HU',
              count: 9,
              label: 'Hungary',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'IS',
              count: 5,
              label: 'Iceland',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'IN',
              count: 152,
              label: 'India',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'ID',
              count: 77,
              label: 'Indonesia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'IE',
              count: 9,
              label: 'Ireland',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'IL',
              count: 6,
              label: 'Israel',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'IT',
              count: 74,
              label: 'Italy',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'JM',
              count: 7,
              label: 'Jamaica',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'JP',
              count: 111,
              label: 'Japan',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'JO',
              count: 9,
              label: 'Jordan',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'KZ',
              count: 5,
              label: 'Kazakhstan',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'KE',
              count: 7,
              label: 'Kenya',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'XK',
              count: 2,
              label: 'Kosovo',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'KW',
              count: 7,
              label: 'Kuwait',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'KG',
              count: 1,
              label: 'Kyrgyzstan',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'LV',
              count: 1,
              label: 'Latvia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'LB',
              count: 1,
              label: 'Lebanon',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'LT',
              count: 3,
              label: 'Lithuania',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'LU',
              count: 2,
              label: 'Luxembourg',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MO',
              count: 2,
              label: 'Macau',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MK',
              count: 1,
              label: 'Macedonia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MW',
              count: 1,
              label: 'Malawi',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MY',
              count: 53,
              label: 'Malaysia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MV',
              count: 9,
              label: 'Maldives',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MT',
              count: 4,
              label: 'Malta',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MU',
              count: 4,
              label: 'Mauritius',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MX',
              count: 293,
              label: 'Mexico',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MC',
              count: 1,
              label: 'Monaco',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'ME',
              count: 1,
              label: 'Montenegro',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MA',
              count: 10,
              label: 'Morocco',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'NA',
              count: 5,
              label: 'Namibia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'NP',
              count: 3,
              label: 'Nepal',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'NL',
              count: 27,
              label: 'Netherlands',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'NC',
              count: 3,
              label: 'New Caledonia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'NZ',
              count: 2,
              label: 'New Zealand',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'NG',
              count: 9,
              label: 'Nigeria',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'NO',
              count: 3,
              label: 'Norway',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'OM',
              count: 6,
              label: 'Oman',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'PK',
              count: 3,
              label: 'Pakistan',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'PA',
              count: 17,
              label: 'Panama',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'PG',
              count: 1,
              label: 'Papua New Guinea',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'PY',
              count: 2,
              label: 'Paraguay',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'PE',
              count: 11,
              label: 'Peru',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'PH',
              count: 10,
              label: 'Philippines',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'PL',
              count: 24,
              label: 'Poland',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'PT',
              count: 23,
              label: 'Portugal',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'PR',
              count: 18,
              label: 'Puerto Rico',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'QA',
              count: 21,
              label: 'Qatar',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'RO',
              count: 6,
              label: 'Romania',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'RW',
              count: 2,
              label: 'Rwanda',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'KN',
              count: 2,
              label: 'Saint Kitts and Nevis',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'LC',
              count: 2,
              label: 'Saint Lucia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'WS',
              count: 2,
              label: 'Samoa',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'SA',
              count: 42,
              label: 'Saudi Arabia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'SN',
              count: 2,
              label: 'Senegal',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'RS',
              count: 4,
              label: 'Serbia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'SC',
              count: 2,
              label: 'Seychelles',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'SG',
              count: 17,
              label: 'Singapore',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'SX',
              count: 1,
              label: 'Sint Maarten',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'SK',
              count: 3,
              label: 'Slovak Republic',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'SI',
              count: 1,
              label: 'Slovenia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'ZA',
              count: 47,
              label: 'South Africa',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'KR',
              count: 37,
              label: 'South Korea',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'ES',
              count: 112,
              label: 'Spain',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'LK',
              count: 5,
              label: 'Sri Lanka',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'SR',
              count: 1,
              label: 'Suriname',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'SE',
              count: 9,
              label: 'Sweden',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'CH',
              count: 29,
              label: 'Switzerland',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'TW',
              count: 26,
              label: 'Taiwan',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'TH',
              count: 63,
              label: 'Thailand',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'TT',
              count: 2,
              label: 'Trinidad and Tobago',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'TN',
              count: 3,
              label: 'Tunisia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'TC',
              count: 3,
              label: 'Turks and Caicos Islands',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'TR',
              count: 54,
              label: 'Türkiye',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'UG',
              count: 5,
              label: 'Uganda',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AE',
              count: 78,
              label: 'United Arab Emirates',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'GB',
              count: 131,
              label: 'United Kingdom',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'TZ',
              count: 9,
              label: 'United Republic of Tanzania',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'UY',
              count: 2,
              label: 'Uruguay',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'US',
              count: 5940,
              label: 'USA',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'UZ',
              count: 1,
              label: 'Uzbekistan',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'VE',
              count: 4,
              label: 'Venezuela',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'VN',
              count: 23,
              label: 'Vietnam',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'VI',
              count: 6,
              label: 'Virgin Islands (US)',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'ZM',
              count: 10,
              label: 'Zambia',
            },
          ],
          type: {
            __typename: 'PropertySearchFacetTypes',
            code: 'countries',
            count: 146,
            enumCode: 'COUNTRIES',
            label: 'Countries / Regions',
          },
        },
        {
          __typename: 'PropertySearchFacet',
          buckets: [
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'beach',
              count: 337,
              label: 'Beach',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'casino',
              count: 74,
              label: 'Casino',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'family',
              count: 1151,
              label: 'Family travel',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'spa',
              count: 1484,
              label: 'Full service spa',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'golf',
              count: 162,
              label: 'Golf',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'ski',
              count: 26,
              label: 'Ski/snowboard',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'tennis',
              count: 421,
              label: 'Tennis',
            },
          ],
          type: {
            __typename: 'PropertySearchFacetTypes',
            code: 'activities-on-site',
            count: 7,
            enumCode: 'ACTIVITIES',
            label: 'Activities',
          },
        },
        {
          __typename: 'PropertySearchFacet',
          buckets: [
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'airport-shuttle',
              count: 1349,
              label: 'Airport shuttle',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'car-rental-desk',
              count: 451,
              label: 'Car rental desk',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'parking',
              count: 8819,
              label: 'Parking',
            },
          ],
          type: {
            __typename: 'PropertySearchFacetTypes',
            code: 'transportation-types',
            count: 3,
            enumCode: 'TRANSPORTATION_TYPES',
            label: 'Transportation',
          },
        },
        {
          __typename: 'PropertySearchFacet',
          buckets: [
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AL',
              count: 98,
              label: 'Alabama',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AK',
              count: 12,
              label: 'Alaska',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AZ',
              count: 135,
              label: 'Arizona',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'AR',
              count: 48,
              label: 'Arkansas',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'CA',
              count: 582,
              label: 'California',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'CO',
              count: 169,
              label: 'Colorado',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'CT',
              count: 47,
              label: 'Connecticut',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'DE',
              count: 18,
              label: 'Delaware',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'DC',
              count: 32,
              label: 'District Of Columbia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'FL',
              count: 534,
              label: 'Florida',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'GA',
              count: 237,
              label: 'Georgia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'HI',
              count: 41,
              label: 'Hawaii',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'ID',
              count: 29,
              label: 'Idaho',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'IL',
              count: 158,
              label: 'Illinois',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'IN',
              count: 122,
              label: 'Indiana',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'IA',
              count: 55,
              label: 'Iowa',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'KS',
              count: 50,
              label: 'Kansas',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'KY',
              count: 82,
              label: 'Kentucky',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'LA',
              count: 90,
              label: 'Louisiana',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'ME',
              count: 20,
              label: 'Maine',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MD',
              count: 107,
              label: 'Maryland',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MA',
              count: 130,
              label: 'Massachusetts',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MI',
              count: 155,
              label: 'Michigan',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MN',
              count: 94,
              label: 'Minnesota',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MS',
              count: 43,
              label: 'Mississippi',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MO',
              count: 107,
              label: 'Missouri',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'MT',
              count: 27,
              label: 'Montana',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'NE',
              count: 43,
              label: 'Nebraska',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'NV',
              count: 53,
              label: 'Nevada',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'NH',
              count: 29,
              label: 'New Hampshire',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'NJ',
              count: 111,
              label: 'New Jersey',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'NM',
              count: 42,
              label: 'New Mexico',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'NY',
              count: 231,
              label: 'New York',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'NC',
              count: 220,
              label: 'North Carolina',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'ND',
              count: 14,
              label: 'North Dakota',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'OH',
              count: 201,
              label: 'Ohio',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'OK',
              count: 71,
              label: 'Oklahoma',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'OR',
              count: 59,
              label: 'Oregon',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'PA',
              count: 201,
              label: 'Pennsylvania',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'RI',
              count: 15,
              label: 'Rhode Island',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'SC',
              count: 123,
              label: 'South Carolina',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'SD',
              count: 17,
              label: 'South Dakota',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'TN',
              count: 164,
              label: 'Tennessee',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'TX',
              count: 623,
              label: 'Texas',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'UT',
              count: 75,
              label: 'Utah',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'VT',
              count: 8,
              label: 'Vermont',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'VA',
              count: 204,
              label: 'Virginia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'WA',
              count: 95,
              label: 'Washington',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'WV',
              count: 26,
              label: 'West Virginia',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'WI',
              count: 79,
              label: 'Wisconsin',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'WY',
              count: 14,
              label: 'Wyoming',
            },
          ],
          type: {
            __typename: 'PropertySearchFacetTypes',
            code: 'states',
            count: 51,
            enumCode: 'STATES',
            label: 'States / Provinces',
          },
        },
        {
          __typename: 'PropertySearchFacet',
          buckets: [
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'certified-meeting-planner',
              count: 1984,
              label: 'Certified meeting planner',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'convention-resort-network',
              count: 51,
              label: 'Convention & Resort Network',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'marriott-certified-wedding-planner',
              count: 877,
              label: 'Marriott-certified wedding planner',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'meeting-space',
              count: 7615,
              label: 'Meeting event space',
            },
            {
              __typename: 'PropertySearchTermFacetBucket',
              code: 'meeting-services-app',
              count: 1001,
              label: 'Meeting Services App',
            },
          ],
          type: {
            __typename: 'PropertySearchFacetTypes',
            code: 'meetings-and-events',
            count: 5,
            enumCode: 'MEETINGS_EVENTS',
            label: 'Events',
          },
        },
      ],
      total: 9199,
    },
  },
};
