import AlertNotification from '../Alerts';
import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { logger } from '../../utils';

export const ErrorHandlerAlert: FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: JSX.Element | any;
  alertMessage?: string;
  isNonPropertyList?: boolean;
  sessionToken?: string;
}> = ({ children, alertMessage, isNonPropertyList = true, sessionToken }) => {
  const { log } = logger({})('ErrorRedirect');
  const componentName = children['name'] || children['displayName'] || children?._owner?.elementType?.name;
  log.debug(children);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function ErrorFallback() {
    return (
      <div role="alert">
        <AlertNotification
          errorMessage={alertMessage}
          isNonPropertyList={isNonPropertyList}
          className={'unexpected-error'}
          // alertType={'warning'}
        />
      </div>
    );
  }

  const errorHandler = (error: Error, info: { componentStack: string }) => {
    console.log('Inside ErrorHandler---------');
    console.log(`${componentName || 'Component'} errored  with error: ${error}`);
    if (!sessionToken) {
      log.debug(`${componentName || 'Component'} errored  with error: ${error}`);
      log.debug(`${componentName || 'Component'} errorHandler componentStack  ${info.componentStack}`);
    } else {
      log.debug(`${componentName || 'Component'} errored out for sessionId ${sessionToken} with error: ${error}`);
      log.debug(
        `${componentName || 'Component'} errorHandler componentStack for sessionId ${sessionToken}: ${
          info.componentStack
        }`
      );
    }
  };

  return (
    <div data-component-name="m-book-ErrorHandlerAlert" data-testid="book-ErrorHandlerAlert">
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          log.debug('need to reset the error');
        }}
        onError={errorHandler}
      >
        {children}
      </ErrorBoundary>
    </div>
  );
};
