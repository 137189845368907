/* eslint-disable @typescript-eslint/no-explicit-any */
import { SetStateAction } from 'react';

import {
  HotelPhotoToursType,
  ImagesType,
  RoomTagsType,
  RoomTagsValueType,
  RoomImagesType,
  TabListDataType,
  RoomDetailListType,
  ImageData,
  ImageDataEdges,
} from './RateCard.types';

import {
  addSubDirectoryPrefix,
  eventUtil,
  getCurrentPageUrlParams,
  getUserCountryCodeFromAkamai,
  transformResponse,
  objectToQueryString,
} from '../../utils';
import { constants, URL_CONSTANTS, CUSTOM_EVENTS_CONSTANTS } from '../../constants';
import { SessionDataMap } from './RateCard.schema';

function capitalizeFirstLetter(string: string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export const getFirstRateDescription = (rateDescription: string) => {
  // FSD-75355 Rates description change
  return capitalizeFirstLetter(rateDescription);
};

export const handleClick = ({ e, id, item, key }: any) => {
  e?.preventDefault();
  eventUtil?.dispatch(key, {
    id,
    data: {
      roomData: item,
    },
  });
};

export const findWithAttrRoomPoolCode = (roomDataList: any, roomPoolCode: any, selectedTab: any) => {
  for (let i = 0; i < roomDataList?.length; i += 1) {
    if (roomDataList[i].roomPoolCode === roomPoolCode && roomDataList[i].rateType === selectedTab) {
      roomDataList[i].isCurrentlySelected = true;
      return i;
    }
  }
  return -1;
};

export const getSelectedPrice = (roomDataList: any, rateCode: any) => {
  const selectedRate = roomDataList?.filter((item: any) => item.rateCode === rateCode);
  return selectedRate ? selectedRate.price : '';
};

export const getRoomDetails = (roomData: any, hideMemberRatesFlag: boolean) => {
  const rooomDetailList = roomData?.roomDetailsList ?? [];
  if (hideMemberRatesFlag) {
    return rooomDetailList?.filter((items: { membersOnlyRate: boolean }) => !items.membersOnlyRate);
  }
  return rooomDetailList;
};

export const hideMemberRatesFn = (roomData: any, hideMemberRatesFlag: boolean) => {
  return hideMemberRatesFlag && roomData?.roomDetailsList?.length === 1 && roomData?.hasMemberRate;
};

const findRateCodeIndex = (currentlySelected: any, rateCode: string) => {
  for (let i = 0; i < currentlySelected?.length; i++) {
    if (currentlySelected[i]?.rateCode === rateCode) {
      return i;
    }
  }
  return -1;
};

export const getRoomDetailsList = (currentlySelected: any, rateCode: string) => {
  const selectedRoom = currentlySelected?.roomDetailsList;
  if (selectedRoom?.length === 1) {
    return;
  } else {
    const index = findRateCodeIndex(selectedRoom, rateCode);
    selectedRoom?.unshift(selectedRoom?.splice(index, 1)[0]);
    selectedRoom?.splice(1);
  }
};

export const setSelectedRoom = (
  propertyName: any,
  findWithAttr: any,
  setCurrentSelectedRoom: any,
  roomDataList: any,
  selectedTab: any
) => {
  if (findWithAttr(roomDataList, propertyName, selectedTab) === -1) {
    setCurrentSelectedRoom(false);
  } else {
    setCurrentSelectedRoom(true);
  }
};

export const getModifyRlmCurrentRoomTab = (roomDataList: any, rateCode: any) => {
  for (let i = 0; i < roomDataList?.length; i += 1) {
    for (let j = 0; j < roomDataList[i]?.roomDetailsList?.length; j += 1) {
      if (roomDataList[i].roomDetailsList[j].rateCode === rateCode) {
        return roomDataList[i].roomDetailsList[j].rateType;
      }
    }
  }
  return '';
};

// Calculate Idle time and showing Session Timeout modal
export const idleTimer = (setShowTimeOutModal: { (value: SetStateAction<boolean>): void; (arg0: boolean): void }) => {
  let timeOutAction: NodeJS.Timeout;

  const resetTimer = () => {
    clearTimeout(timeOutAction);
    timeOutAction = setTimeout(logout, constants.FIFTEEN_MINUTES_TIMER); // open Session Timeout modal after 15 mins.
    timeOutAction = setTimeout(redirect, constants.THIRTY_MINUTES_TIMER); // redirect to expiredSession page after 30 mins.
  };

  document.onmousemove = resetTimer; // catches mouse movements
  document.onclick = resetTimer; // catches mouse clicks
  document.onscroll = resetTimer; // catches scrolling
  document.onkeydown = resetTimer; //catches keyboard actions
  document.ontouchstart = resetTimer; // catches touchscreen

  const logout = () => {
    setShowTimeOutModal(true);
  };

  const redirect = () => {
    window.location.assign(addSubDirectoryPrefix(URL_CONSTANTS.EXPIRED_SESSION_URL));
  };

  resetTimer();
};

export const parseRoomImagesData = (imageData: ImageData[], imageDomain = URL_CONSTANTS.CACHE_MARRIOTT_URL) => {
  const result: RoomImagesType[] = [];
  imageData?.forEach(({ edges }: ImageData) => {
    edges?.forEach(({ node }: ImageDataEdges) => {
      const imageFile = node?.imageUrls?.wideHorizontal;
      const roomPools = node?.roomPoolCodes?.length ? node.roomPoolCodes : [];
      const roomTypes = node?.roomTypeCodes?.length ? node.roomTypeCodes : [];
      if (imageFile && (roomPools?.length || roomTypes?.length)) {
        const extractedTags = [...roomPools, ...roomTypes];
        result.push({
          altText: node.caption,
          metadata: [{ imageFile: `${imageDomain}${imageFile}` }],
          roomTags: createRoomTagMap(extractedTags),
        });
      }
    });
  });
  return result;
};

export const parseRoomImagesDataCTS = (imageData: HotelPhotoToursType[]) => {
  let result: RoomImagesType[] = [];
  imageData.length &&
    imageData?.forEach((item: HotelPhotoToursType) => {
      const modifiedImageData = item?.images?.map((image: ImagesType) => {
        if (image?.roomTags?.length > 0) {
          let extractedTags: string[] = [];
          image?.roomTags?.forEach((tag: RoomTagsType) => {
            const roomPools = tag.roomPools?.length ? tag.roomPools.map((pool: RoomTagsValueType) => pool.id) : [];
            const roomTypes = tag.roomTypes?.length ? tag.roomTypes.map((type: RoomTagsValueType) => type.id) : [];
            extractedTags = [...roomPools, ...roomTypes];
          });
          return {
            altText: image.captions?.title,
            metadata: [...image.metadata],
            roomTags: createRoomTagMap(extractedTags),
          };
        } else {
          return null;
        }
      });
      const filteredImageData = modifiedImageData.filter(itemValue => itemValue !== null) as RoomImagesType[];
      result = [...result, ...filteredImageData];
    });
  return result;
};

export const filterRoomImages = (imageArr: RoomImagesType[], roomPoolCode: string) => {
  if (!roomPoolCode) return [];
  const poolCode = roomPoolCode.toUpperCase();
  return imageArr.filter((image: RoomImagesType) => image.roomTags[poolCode] === poolCode);
};

const createRoomTagMap = (tags: string[]) => {
  const roomTagMap: Record<string, string> = {};
  tags.forEach((tag: string) => {
    roomTagMap[tag.toUpperCase()] = tag.toUpperCase();
  });
  return roomTagMap;
};

export const getInitialState = (): RoomImagesType[] => {
  return [
    {
      initialState: true,
      altText: '',
      metadata: [
        {
          imageFile: '',
        },
      ],
      roomTags: {},
    },
  ];
};

export const sortRedemptionRoomDataList = (roomDataList: TabListDataType[]) => {
  let sortedRoomDataList = [];

  // sort roomType details within each room - in points ascending order
  sortedRoomDataList = roomDataList?.map((item: TabListDataType) => {
    if (item.roomDetailsList.length > 1) {
      item.roomDetailsList.sort((roomType1: RoomDetailListType, roomType2: RoomDetailListType) => {
        return roomType1.totalPoints - roomType2.totalPoints;
      });
    }
    return item;
  });

  //sort rooms by each room's first roomType's points in ascending order
  //if points are equal then sort by cash in ascending order
  if (sortedRoomDataList) {
    sortedRoomDataList = sortedRoomDataList.sort((room1: TabListDataType, room2: TabListDataType) => {
      const r1 = room1.roomDetailsList[0];
      const r2 = room2.roomDetailsList[0];

      if (r1.totalPoints === r2.totalPoints) {
        return r1.price || 0 - r2.price || 0;
      } else return r1.totalPoints - r2.totalPoints;
    });
  }

  return sortedRoomDataList;
};

const getIsRoomType = ({ attributes = [] }: TabListDataType) => {
  let isSuite = false;
  attributes.forEach((item: { accommodationCategory: { code: string } }) => {
    if (constants.SUITES_ROOM_CODES.includes(item?.accommodationCategory?.code)) {
      isSuite = true;
    }
  });
  return !isSuite;
};

const sortDataByNumMatches = (roomData: TabListDataType[], isDefaultRoomList: boolean) => {
  const groupedData = roomData.reduce((data: Record<string | number, TabListDataType[]>, current: TabListDataType) => {
    const descriptionObj = isDefaultRoomList ? { description: current.roomName } : {};
    const currentRoom = { ...current, ...descriptionObj };
    const description = currentRoom.description;

    if (data[description]) {
      data[description].push(current);
    } else {
      data[description] = [current];
    }
    return data;
  }, {});
  const response: TabListDataType[] = [];
  const result = Object.values(groupedData);
  result.forEach((item: TabListDataType[]) => {
    response.push(...item);
  });
  return response;
};

const sortDataByRoomType = (roomData: TabListDataType[]) => {
  return roomData.sort((room1: TabListDataType, room2: TabListDataType) => {
    const room1Type = getIsRoomType(room1);
    const room2Type = getIsRoomType(room2);
    if (room1Type && !room2Type) {
      return -1;
    }
    return 0;
  });
};

const sortData = (
  roomData: TabListDataType[],
  memberRates: Record<string, string[]>,
  regularRates: Record<string, string[]>
) => {
  const groupedData = roomData.reduce((data: Record<string | number, TabListDataType[]>, current: TabListDataType) => {
    const { price } = current.roomDetailsList[0];

    if (data[price]) {
      data[price].push(current);
    } else {
      data[price] = [current];
    }
    return data;
  }, {});

  // Convert the grouped data into an array of subarrays
  const response: TabListDataType[] = [];
  const result = Object.values(groupedData);
  result.forEach((item: TabListDataType[]) => {
    const sortedData = sortDataByRoomType(item);
    response.push(...sortedData);
  });
  response.forEach((item: TabListDataType) => {
    item.memberRateIds = item?.productIdKey ? memberRates?.[item?.productIdKey] : [];
    item.regularRateIds = item?.productIdKey ? regularRates?.[item?.productIdKey] : [];
  });
  return response;
};

const getRoomDescWithPrice = (roomData: TabListDataType) => {
  let priceConcat = '';
  roomData.roomDetailsList.forEach(item => {
    priceConcat = priceConcat + String(item.price);
  });
  return roomData.description + priceConcat;
};

export const getUniqueRoomData = (roomData: TabListDataType[], isDefaultRoomList = false) => {
  let initialAttributeCount: Record<string, number> = {};
  let memberRateId: Record<string, string[]> = {};
  let regularRateId: Record<string, string[]> = {};
  const sortedRoomData = sortDataByNumMatches(roomData, isDefaultRoomList);
  const uniqueData = sortedRoomData.reduce((data: TabListDataType[], current: TabListDataType) => {
    if (!current.ersProduct) {
      data.push(current);
      return data;
    }
    let hasMoreRoomAvailableIndex = -1;
    const descriptionObj = isDefaultRoomList ? { description: current.roomName } : {};
    const currentRoom = { ...current, ...descriptionObj };
    const currentAttributeCount = currentRoom?.attributeList?.length || 0;
    if (!initialAttributeCount?.[currentRoom.description]) {
      initialAttributeCount = { [currentRoom.description]: currentAttributeCount };
    }
    const currentRoomDescWithPrice = getRoomDescWithPrice(currentRoom);
    if (memberRateId?.[currentRoomDescWithPrice]) {
      memberRateId?.[currentRoomDescWithPrice].push(currentRoom.commonRepresentativeProductIds[0]);
    } else {
      memberRateId = { ...memberRateId, [currentRoomDescWithPrice]: [currentRoom.commonRepresentativeProductIds[0]] };
    }

    if (currentRoom.commonRepresentativeProductIds.length > 1) {
      if (regularRateId?.[currentRoomDescWithPrice]) {
        regularRateId?.[currentRoomDescWithPrice].push(currentRoom.commonRepresentativeProductIds[1]);
      } else {
        regularRateId = {
          ...regularRateId,
          [currentRoomDescWithPrice]: [currentRoom.commonRepresentativeProductIds[1]],
        };
      }
    }

    const existingData = data.find((room: TabListDataType, index: number) => {
      const room1 = room.roomDetailsList.length < currentRoom.roomDetailsList.length ? room : currentRoom;
      const room2 = room.roomDetailsList.length < currentRoom.roomDetailsList.length ? currentRoom : room;
      if (currentRoom.description === room.description) {
        if (currentAttributeCount < initialAttributeCount?.[currentRoom.description]) {
          initialAttributeCount[currentRoom.description] = currentAttributeCount;
          hasMoreRoomAvailableIndex = index;
        }
      }
      return room1.roomDetailsList.every(item =>
        room2.roomDetailsList.some(
          currentItem => room2.description === room1.description && currentItem.price === item.price
        )
      );
    });

    if (!existingData) {
      currentRoom.productIdKey = currentRoomDescWithPrice;
      data.push(currentRoom);
    } else if (hasMoreRoomAvailableIndex > -1) {
      data.splice(hasMoreRoomAvailableIndex, 1);
      currentRoom.productIdKey = currentRoomDescWithPrice;
      data.push(currentRoom);
    }
    return data;
  }, []);
  const sortedData = sortData([...uniqueData], memberRateId, regularRateId);
  return sortedData;
};

export const handleChangeModalTrigger = (item: any, val: string) => {
  eventUtil &&
    eventUtil?.dispatch(CUSTOM_EVENTS_CONSTANTS.ON_SHOW_CONFIRM_CHANGES, {
      id: constants.CONFIRM_CHANGES_MODAL,
      data: { ...item, cartId: val },
    });
};

/**
 * Determines whether to show full prices based on various conditions.
 *
 * @param {Record<string, string | number | boolean>} sessionObject - The user's session data.
 * @returns {boolean} - True if full prices should be shown, false otherwise.
 */
export const getShowFullPriceFlag = (sessionObject: Record<string, string | number | boolean>) => {
  // Constants and session data extraction
  const { ACTIVE_SESSION_COUNTRY_FULL_PRICE, TRUE, FALSE, SHOW_TAX_DEFAULT_LOACALES } = constants;
  const { showFullPrice, locale } = transformResponse(sessionObject, SessionDataMap);

  // Get URL parameters and user's country code
  const urlSearchParams = getCurrentPageUrlParams();
  const activeSessionUserCountryCode = getUserCountryCodeFromAkamai();

  // Check each condition and return true if any condition is met
  if (
    !SHOW_TAX_DEFAULT_LOACALES.includes(locale) &&
    urlSearchParams['showFullPrice'] === FALSE &&
    !ACTIVE_SESSION_COUNTRY_FULL_PRICE.includes(activeSessionUserCountryCode)
  ) {
    return false;
  }

  //EN-GB , JP & NL Default Show Taxes & Fees for all scenarios
  if (
    SHOW_TAX_DEFAULT_LOACALES.includes(locale) ||
    showFullPrice === TRUE ||
    urlSearchParams['showFullPrice'] === TRUE ||
    ACTIVE_SESSION_COUNTRY_FULL_PRICE.includes(activeSessionUserCountryCode)
  ) {
    return true;
  }

  // If none of the conditions are met, return false
  return false;
};

export const getRoomPoolUrl = ({ marshaCode = '', roomPoolCode = '', selectedTabName = '', productId = '' }) => {
  // /reservation/ersViewRoomPool.mi?marshaCode=NYCMQ&roomPoolCode=genr&displayRoomPoolList=Y&src=ratelist&selectedTabName=standard&productId=TllDTVF8Uk1PQ3xHRU5SfDIwMjMtMTEtMTl8MjAyMy0xMS0yMHwyN2RkYWM3Zi00OWZkLTRlOWItOTI1Ni00NDE1MzkxOTdiMGI
  const queryParamsObj = {
    marshaCode: marshaCode,
    roomPoolCode: roomPoolCode,
    displayRoomPoolList: 'Y', //This is constant query params. Same in ARIES.
    src: 'ratelist', //This is constant query params. Same in ARIES.
    selectedTabName: selectedTabName,
    productId: productId,
  };
  const roomPoolURL = addSubDirectoryPrefix(
    URL_CONSTANTS.ERS_ROOM_POOL_URL + '?' + objectToQueryString(queryParamsObj)
  );
  return roomPoolURL;
};
