import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledHotelDirectoryMap = styled.div`
  ${rtl`  
    .m-message-inline{
      padding-top: ${toRem(12)};
      padding-bottom: ${toRem(12)};
    }

    .oval {
      box-sizing: border-box;
      height: ${toRem(41)} ;
      width: ${toRem(41)} ;
      border: ${toRem(1)}  solid ${baseVariables.color['neutral20']};
      background-color: ${baseVariables.color['base20']};
      box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .gm-style .gm-style-iw-tc{
      display:none
    }
    .gm-style .gm-style-iw-d{
      margin-top : ${toRem(-46)};
    }
    .gm-style-iw{
      padding-top: ${toRem(8)} !important;
      padding-right: 0;
      @media ${baseVariables.mediaQuery.lg} {
        padding-right: ${toRem(12)};
      }
    }

    .link-container{
      margin-bottom:${toRem(4)};
      width:${toRem(160)};
      margin-top:${toRem(4)};
      padding-bottom: ${toRem(4)};
      &:first-child {
        .link-custom:focus-visible {
          outline: 0;
        }
      }
    }
    .link-custom{
      cursor: pointer;
      padding-bottom:0;
      border-bottom: 0;
    }

    .link-custom:hover{
      text-decoration: underline;
      font-weight: ${baseVariables.font['fontWeightRegular']};
    }
    
    .gm-ui-hover-effect{
      top : ${toRem(4)} !important;
      right : ${toRem(4)} !important;
      display: flex !important;
      justify-content: end;
      margin-right: ${toRem(10)} !important;
    }

    .gm-ui-hover-effect>span {
      height: ${toRem(12)} !important;
      width: ${toRem(12)} !important;
      margin: 0 !important;
    }

    .gmnoprint {
      display : block !important;
    }

    .gm-control-active {
      &[title='Zoom in'] {
        border-radius: 50% !important;
        box-shadow: 0 ${toRem(3)} ${toRem(10)} rgb(0 0 0 / 0.2);
        height: ${toRem(30)} !important;
        width: ${toRem(30)} !important;
        margin-left: ${toRem(0.5)} !important;
        margin-bottom: ${toRem(7)} !important;
      }
      &[title='Zoom out'] {
        border-radius: 50% !important;
        box-shadow: 0 ${toRem(3)} ${toRem(10)} rgb(0 0 0 / 0.2);
        height: ${toRem(30)} !important;
        width: ${toRem(30)} !important;
        margin-left: ${toRem(0.5)} !important;
        margin-bottom: ${toRem(6)} !important;
        top: ${toRem(2)} !important;
      }
    }

    //adjust the custom height and radius of zoom control options
    div[data-control-height="81"]>div{
       border-radius: ${toRem(25)} !important;
       width: ${toRem(32)} !important;
       height:${toRem(69)} !important;
       background:white !important;
    }

    //to hide the bg of zoom control container
    div[data-control-height="81"]>div>div{
      height:0 !important
    }

    //to change size of plus minus zoom icons on mao
    .gm-control-active>img {
      width: ${toRem(12)} !important;
    }
    .row{
      margin-right: ${toRem(-16)};
      margin-left: ${toRem(-16)};
    }
    @media ${baseVariables.mediaQuery.md} {
      .row{
        margin-right: ${toRem(-8)};
        margin-left: ${toRem(-8)};
      }
    }

    .gmnoprint.gm-style-mtc-bbw {
      margin: ${toRem(20)} ${toRem(16)} 0 !important;
      @media ${baseVariables.mediaQuery.sm} {
        margin: ${toRem(20)} auto 0 !important;
        position: relative !important;
        max-width: ${toRem(524)};
      }
      @media ${baseVariables.mediaQuery.md} {
        max-width: ${toRem(704)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        max-width: ${toRem(944)};
      }
      @media ${baseVariables.mediaQuery.xl} {
        max-width: ${toRem(1124)};
      }
    }

    .map-full-view-container {
      margin-right: ${toRem(16)};
      height: ${toRem(60)};
      display: flex;
      justify-content: flex-end;
      @media ${baseVariables.mediaQuery.sm} {
        margin: auto;
        max-width: ${toRem(524)};
      }
      @media ${baseVariables.mediaQuery.md} {
        max-width: ${toRem(704)};
      }
      @media ${baseVariables.mediaQuery.lg} {
        max-width: ${toRem(944)};
      }
      @media ${baseVariables.mediaQuery.xl} {
        max-width: ${toRem(1124)};
      }
      .gm-control-active.gm-fullscreen-control {
        position: relative !important;
        margin: 0 !important;
        top: ${toRem(16)} !important;
        @media ${baseVariables.mediaQuery.md} {
          top:0 !important;
          margin: 0 ${toRem(10)} !important;
        }
        @media ${baseVariables.mediaQuery.lg} {
          top:0 !important;
        }
        max-width: ${toRem(32)};
        max-height: ${toRem(32)} ;
        color: ${baseVariables.color['base10']} !important;
      }
    }


    .gm-style-mtc {
      button:focus-visible {
        outline: ${toRem(1)} solid -webkit-focus-ring-color;
        font-weight: ${baseVariables.font['fontWeightMedium']} !important;
      }

      button {
        font-family: ${baseVariables.font['fontFamilySwiss']} !important;
        font-size: ${baseVariables.font.fontXs} !important;
        font-weight: ${baseVariables.font['fontWeightRegular']} !important;
        max-height: ${toRem(32)} !important;
        color: ${baseVariables.color['base10']} !important;
        padding: ${toRem(8)} ${toRem(15)} ${toRem(9)} ${toRem(15)} !important;
      }
    }

    div.gm-style-mtc:first-child button {
      width: ${toRem(55)};
    }

    div.gm-style-mtc button:focus {
      font-weight: ${baseVariables.font['fontWeightMedium']}  !important;
    }

    div.gm-style-mtc:last-child button {
      width: ${toRem(74)};
    }

    .gm-fullscreen-control:focus-visible {
      outline: ${toRem(1)} solid -webkit-focus-ring-color;
    }

    .sl-container {
      .image-place-loader {
        margin: 0;
        height: ${toRem(500)};
      }
    }
  `}
`;
