export const BREAKPOINT_TABLET = 767;
export const BREAKPOINT_DESKTOP = 991;
export const BREAKPOINT_DESKTOP_1023 = 1023;
export const SIZE_GUTTER_WIDTH_MOBILE = 12;
export const SIZE_GUTTER_WIDTH_TABLET = 16;
export const SIZE_MARGIN_CONTENT_MOBILE = 24;
export const SIZE_MARGIN_CONTENT_TABLET = 40;
export const DATE_FORMAT_DEFAULT = 'ddd, MMM DD'; // Wed, Jan 20
export const DATE_MOBILE_FORMAT_DEFAULT = 'MMM DD'; // Aug 22
export const DATE_MOBILE_FORMAT_DEFAULT_DE = 'DD. MMM'; // 22 Aug
export const DATE_MOBILE_FORMAT_JA = 'MMM Do';
export const DATE_MOBILE_FORMAT_FR = 'DD/MM';
export const DATE_FORMAT_YEAR_MONTH_DAY = 'YYYY-MM-DD';
export const DATE_FORMAT_VALUE = 'MM/DD/YYYY'; // 01/20/2021
export const CALENDAR_MAX_ALLOWED_DAYS = 365;
export const VARIATION_VERTICAL = 'VERTICAL';
export const VARIATION_DESKTOP = 'DESKTOP';
export const VARIATION_TABLET = 'TABLET';
export const SEARCH_SCROLL_TOP_POSITION = 131;
export const COOKIE_SESSION_ID = 'sessionID';
export const MAX_ROOM_SELECTION_LIMIT = '26+';
export const SEARCH_STATE_COOKIE = 'searchstate';
export const PUBLISH_USER_SELECTION = 'publish-user-selection';
export const TIME_OUT = 2000;
export const IS_LOCALE_ZH_TW = 'zh-TW';
export const IS_LOCALE_ZH_CN = 'zh-CN';
export const IS_LOCALE_THAI = 'th-TH';
export const IS_LOCALE_ARABIC = 'ar';
export const BUDDHIST_YEAR_VALUE = 543;
export const REWARDS_REDEMPTION_CLUSTER_CODES = ['MRW', 'P17', '39E', 'MW1'];
export const SEARCH_FORM_WIDTH = 992;
