import styled from 'styled-components';
import { theme } from '../../styles';

export const Line = styled.hr`
  box-sizing: border-box;
  width: 100%;
  height: 1px;
  border-bottom: 0px;
  &.dashed {
    border-top: 2px dashed #51555a;
  }
  &.dashed-light {
    border-top: 2px dashed ${theme.colors['close-silver']};
  }
  &.solid {
    border: 2px dashed #51555a;
  }
`;
