export enum Tile {
  TranslationDisclaimerTile = 'TranslationDisclaimerTile',
  CongratulationsTile = 'CongratulationsTile',
}

export interface TranslationDisclaimerProps {
  pageType?: string;
  componentType?: Tile;
  disclaimerText?: string;
  classname?: string;
  disableBackgroundStyling?: boolean;
}
