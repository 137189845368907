/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState, useRef, KeyboardEvent } from 'react';
import Downshift from 'downshift';
import moment from 'moment';

import { useApolloClient } from '@apollo/client';

import { phoenixShopSuggestedPlacesQuery, phoenixShopSuggestedPlacesDetails } from './queries';
import { InputTextField, DropDownModal, getValueFromDate } from '@marriott/mi-ui-library';
import { SearchFormState, useSearchFormStore } from '../../modules/store/searchFormStore';
import { useStaticDataContext } from '../../modules/context';
import CurrentLocationView from './CurrentLocation/CurrentLocation';
import { eventUtil, isRecentViewAndSearchAvailable } from '../../modules/utils';
import { LOCALE_ZH_CN } from '@marriott/mi-ui-library';

import {
  DESTINATION,
  DESTINATION_HIDDEN_FIELDS,
  MILESTOMETERS,
  ROOMS_AND_GUESTS,
  DATES,
  MEETINGS_DESTINATION,
  EVENTS_DESTINATION_HIDDEN_FIELDS,
  MEETINGS_DATES,
  EVENT_SPACE,
  EVENT_PURPOSE,
  GUEST_ROOMS,
} from '../../modules/store/store.constants';

import { StyledSearchFieldCategoriesDiv } from './SearchFieldCategories.styles';
import { SuggestedPlacesNode } from './SearchField.types';
import RecentViewAndSearch from './RecentViewAndSearch/RecentViewAndSearch';
import { AutoSuggestions } from './AutoSuggestions/AutoSuggestions';
import { CurrentLocationFeature } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import {
  searchFormClickTrackingLoc,
  meetingSearchFormClickTrackingLoc,
  tabSelect,
} from '../../constants/lib/constants';
import { CityPicklist } from '../CityPicklist';
import { isServer } from '@marriott/mi-apollo-client-utils';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { getSearchDestinationTypeAnalytics } from '../../utils';
import { CHINA_REGION_CODE } from '../../modules/constants/HotelDirectory.constants';
//TODO : to remove types with any
interface SearchFieldCategoriesProp {
  isDesktopView: boolean;
  isModalOpen?: boolean;
  changeMobileState?: (inputValue: string) => void;
  onCancel?: () => void;
  mobileModalHeading?: string;
  mobilePlaceHolderText?: string;
  hideClearCta?: boolean;
  isMeetingDestField?: boolean;
  isMobileForm?: boolean;
}
export const SearchFieldCategories: FC<SearchFieldCategoriesProp> = ({
  isDesktopView,
  isModalOpen,
  changeMobileState,
  onCancel,
  mobileModalHeading,
  mobilePlaceHolderText,
  hideClearCta = false,
  isMeetingDestField,
  isMobileForm,
}) => {
  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;
  const { ACCEPT_LANGUAGE: currentLocale, REQUEST_ID: requestID } = clientEnvVars;
  const setSearchFormState = useSearchFormStore((state: any) => state.setSearchFormState);
  const formDestination = useSearchFormStore((state: any) => state.destination);
  const EventsformDestination = useSearchFormStore((state: any) => state.meetingsDestination);
  const tabSelected = useSearchFormStore((state: SearchFormState) => state.selectedTab);
  const selectedTab = tabSelected?.selectedTab;
  const {
    placeholderTextDesktop,
    destinationModuleEyebrowText,
    nameOfRecentSearchesSection,
    nameOfRecentlyViewedSection,
    currentLocationText,
    clearDestinationLabel,
    isTabbedSearchForm,
    currentLocationLabel,
    cityPickListToggle = false,
  } = useStaticDataContext();
  const searchText =
    selectedTab === tabSelect?.meetingsTab ? EventsformDestination?.eventsDisplayText : formDestination?.displayText;
  const [inputValue, setInputValue] = useState<string>('');
  const [clearCta, setClearCta] = useState(false); // flag for clear input CTA
  const [showCityPicklist, setShowCityPicklist] = useState(false); // flag to show/hide city picklist
  const [showSearchListView, setShowSearchListView] = useState(false);
  const [isRecentViewAndSearchPresent, setIsRecentViewAndSearchPresent] = useState(false);
  const [showRecentViewed, setRecentViewed] = useState(false);
  const [openPopup, setOpenPopupState] = useState(isModalOpen ?? false);
  const [showAutoSuggest, setShowAutoSuggest] = useState(false);
  const [optionsList, setOptionsList] = useState<SuggestedPlacesNode[] | string[]>([]);
  const [selectFromAutoSuggestOrRecentSearch, setSelectFromAutoSuggestOrRecentSearch] = useState(false);
  const [timerList, setTimerList] = useState<ReturnType<typeof setTimeout>[]>([]);
  const searchFormTopPosition = useSearchFormStore((state: any) => state?.searchFormTopPosition);

  const searchFormClickTracking: string = isMeetingDestField
    ? meetingSearchFormClickTrackingLoc
    : searchFormClickTrackingLoc;
  const client = useApolloClient();
  const BAIDU_MAP_API_KEY: string | undefined = clientEnvVars?.['BAIDU_MAP_API_KEY'];
  const GOOGLE_MAP_API_KEY: string | undefined = clientEnvVars?.['GOOGLE_MAP_API_KEY'];
  const searchFieldRef = useRef<HTMLDivElement>(null);

  const inputRef = useRef<HTMLDivElement>(null);
  const inputElementRef = useRef<HTMLInputElement>(null);
  //update inputValue on change of zustand store form values
  useEffect(() => {
    setInputValue(searchText);
  }, [searchText]);
  // we are added this useEffect to avoid the background scroll in the homepage searchform
  useEffect(() => {
    if (openPopup && !isDesktopView) {
      inputElementRef.current?.focus();
      //Can't access body element without this selector.
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }
    return () => {
      //Can't access body element without this selector.
      document.getElementsByTagName('body')[0].style.overflow = 'initial';
    };
  }, [openPopup, isDesktopView]);

  useEffect(() => {
    if (isDesktopView) {
      if (!showAutoSuggest && !showRecentViewed && !showCityPicklist) {
        setOpenPopupState(false);
      } else if (showAutoSuggest || showRecentViewed || showCityPicklist) {
        setOpenPopupState(true);
      }
    }
  }, [showRecentViewed, showAutoSuggest, showCityPicklist]);

  useEffect(() => {
    if (optionsList?.length && inputValue.length) {
      setShowAutoSuggest(true);
    } else {
      setShowAutoSuggest(false);
    }
  }, [optionsList]);
  /**
   * flag to check weather user changed the input or not
   */
  const [isUserInputChanged, setIsUserInputChanged] = useState(false);

  useEffect(() => {
    if (inputValue) {
      eventUtil.dispatch('showSecondaryForm', true);
    } else {
      eventUtil.dispatch('showSecondaryForm', false);
    }
    // Delaying API call till the input value finishes updating
    if (inputValue && isUserInputChanged) {
      if (inputValue !== searchText) {
        clearTimer(timerList);
        selectedTab !== tabSelect?.meetingsTab
          ? setSearchFormState(
              [DESTINATION],
              {
                [DESTINATION]: {
                  ...formDestination,
                  displayText: inputValue?.toString(),
                  destinationAddressPlaceId: '',
                  secondaryDescription: '',
                },
              },
              false
            )
          : setSearchFormState(
              [MEETINGS_DESTINATION],
              {
                [MEETINGS_DESTINATION]: {
                  ...EventsformDestination,
                  eventsDisplayText: inputValue?.toString(),
                  eventsDestinationAddressPlaceId: '',
                  secondaryDescription: '',
                },
              },
              false
            );
        const timer = setTimeout(() => {
          getSuggestionList({
            variables: {
              query: inputValue,
            },
            context: {
              headers: {
                'x-request-id': requestID,
                'accept-language': currentLocale,
              },
            },
          });
        }, 500);
        setTimerList([...timerList, timer]);
      }
    } else {
      setOptionsList([]);
      // cancel current fetch timeout timer if user delete the input value
      clearTimer(timerList);
    }
  }, [inputValue, isUserInputChanged]);

  useEffect(() => {
    //this useeffect is used to empty the value of placeid whenever user input is empty but the uxl returns LATE response for previous calls
    if (!inputValue && optionsList?.length) {
      selectedTab !== tabSelect?.meetingsTab
        ? setSearchFormState(
            [DESTINATION],
            {
              [DESTINATION]: {
                destinationAddressPlaceId: '',
              },
            },
            false
          )
        : setSearchFormState(
            [MEETINGS_DESTINATION],
            {
              [MEETINGS_DESTINATION]: {
                eventsDestinationAddressPlaceId: '',
              },
            },
            false
          );
    }
  }, [optionsList, inputValue]);

  /**
   * UXL call functions START
   */
  async function getSuggestionList(inputValues: any) {
    const { data } = await client.query({
      query: phoenixShopSuggestedPlacesQuery,
      ...inputValues,
    });
    if (data) {
      setAutoSuggestOptions(data);
    }
  }
  async function getSuggestedPlaceDetails(inputValues: any) {
    setIsUserInputChanged(false);
    const { data } = await client.query({
      query: phoenixShopSuggestedPlacesDetails,
      ...inputValues,
    });
    if (data) {
      selectedTab !== tabSelect?.meetingsTab
        ? setSearchFormState([DESTINATION_HIDDEN_FIELDS], {
            [DESTINATION_HIDDEN_FIELDS]: {
              __typename: data?.suggestedPlaceDetails?.location?.__typename,
              latitude: data?.suggestedPlaceDetails?.location?.latitude,
              longitude: data?.suggestedPlaceDetails?.location?.longitude,
              address: data?.suggestedPlaceDetails?.location?.address,
              city: data?.suggestedPlaceDetails?.location?.city,
              state: data?.suggestedPlaceDetails?.location?.state,
              country: data?.suggestedPlaceDetails?.location?.country,
              countryName: data?.suggestedPlaceDetails?.location?.countryName,
              searchRadius: convertMetersToMiles(data?.suggestedPlaceDetails?.distance),
              destinationType: getSearchDestinationTypeAnalytics(data?.suggestedPlaceDetails?.types),
            },
          })
        : setSearchFormState([EVENTS_DESTINATION_HIDDEN_FIELDS], {
            [EVENTS_DESTINATION_HIDDEN_FIELDS]: {
              __typename: data?.suggestedPlaceDetails?.location?.__typename,
              latitude: data?.suggestedPlaceDetails?.location?.latitude,
              longitude: data?.suggestedPlaceDetails?.location?.longitude,
              address: data?.suggestedPlaceDetails?.location?.address,
              city: data?.suggestedPlaceDetails?.location?.city,
              state: data?.suggestedPlaceDetails?.location?.state,
              country: data?.suggestedPlaceDetails?.location?.country,
              countryName: data?.suggestedPlaceDetails?.location?.countryName,
              searchRadius: convertMetersToMiles(data?.suggestedPlaceDetails?.distance),
              destinationType: getSearchDestinationTypeAnalytics(data?.suggestedPlaceDetails?.types),
            },
          });
    }
  }
  /**
   * UXL call functions END
   *
   */
  /**
   * Main data selection and maniplutation functions START
   *
   */
  const setAutoSuggestOptions = (data: any) => {
    if (data && data.suggestedPlaces !== undefined) {
      if (data.suggestedPlaces.total === 0) {
        setOptionsList([]);
      } else if (data?.suggestedPlaces?.total > 0) {
        if (data?.suggestedPlaces?.edges) {
          setOptionsList(data.suggestedPlaces.edges);
          updateAutoSuggestedPlacesDetails(data.suggestedPlaces.edges);
        }
      }
    }
  };

  // This method is used to update the placeId details of the first auto suggestion so as to make the correct UXL call
  const updateAutoSuggestedPlacesDetails = (options: SuggestedPlacesNode[]) => {
    const primaryOption = options?.[0]?.node;
    const placeIDToAssign =
      primaryOption?.primaryDescription?.toLowerCase() === inputValue?.toLowerCase() ? primaryOption?.placeId : '';
    selectedTab !== tabSelect?.meetingsTab
      ? setSearchFormState(
          [DESTINATION],
          {
            [DESTINATION]: {
              destinationAddressPlaceId: placeIDToAssign,
            },
          },
          false
        )
      : setSearchFormState(
          [MEETINGS_DESTINATION],
          {
            [MEETINGS_DESTINATION]: {
              eventsDestinationAddressPlaceId: placeIDToAssign,
            },
          },
          false
        );
  };

  const selectFromAutoSuggest = (newValue: any) => {
    setSelectFromAutoSuggestOrRecentSearch(true);
    setIsUserInputChanged(false);
    if (newValue && typeof newValue === 'object') {
      const PID = newValue?.node?.placeId;
      try {
        changeMobileState?.(newValue);
        updateFormValues(
          newValue?.node?.description,
          PID,
          newValue?.node?.secondaryDescription,
          '',
          newValue?.node?.primaryDescription
        );
      } catch {
        changeMobileState?.(newValue);
        updateFormValues(newValue?.node?.description, PID);
      }
    } else if (newValue && typeof newValue === 'string') {
      changeMobileState?.(newValue);
      updateFormValues(newValue, '');
    }
    setClearCta(false);
  };

  const updateFormValues = (
    val: string | number,
    pid?: string | number,
    secondaryDescription?: string | number,
    heading?: string,
    primaryDescription?: string
  ): void => {
    setInputValue(val?.toString());
    selectedTab !== tabSelect?.meetingsTab
      ? setSearchFormState([DESTINATION, DESTINATION_HIDDEN_FIELDS], {
          [DESTINATION]: {
            displayText: val?.toString(),
            destinationAddressPlaceId: pid?.toString(),
            secondaryDescription: secondaryDescription?.toString(),
          },
          [DESTINATION_HIDDEN_FIELDS]: {
            primaryDescription: primaryDescription?.toString(),
            secondaryDescription: secondaryDescription?.toString(),
          },
        })
      : setSearchFormState([MEETINGS_DESTINATION, EVENTS_DESTINATION_HIDDEN_FIELDS], {
          [MEETINGS_DESTINATION]: {
            eventsDisplayText: val?.toString(),
            eventsDestinationAddressPlaceId: pid?.toString(),
            secondaryDescription: secondaryDescription?.toString(),
          },
          [EVENTS_DESTINATION_HIDDEN_FIELDS]: {
            primaryDescription: primaryDescription?.toString(),
            secondaryDescription: secondaryDescription?.toString(),
          },
        });
    if (heading !== nameOfRecentlyViewedSection) {
      setSearchFormState(['destinationFieldDetails'], {
        destinationFieldDetails: {
          recentlyViewedPopertyCode: '',
        },
      });
    }
    setShowAutoSuggest(false);
    //either picklist or recent view/searches will be shown at a time
    cityPickListToggle && setShowCityPicklist(false);
    setRecentViewed(false);
    if (!isDesktopView) {
      setOpenPopupState(false);
    }
  };

  const updateRecentValue = (
    recentValue: string | number,
    pid?: string | number,
    secondaryDescription?: string | number,
    item?: any,
    heading?: string,
    city?: string | number,
    state?: string | number,
    country?: string | number
  ): void => {
    setClearCta(false);
    setSelectFromAutoSuggestOrRecentSearch(true);
    if (typeof recentValue === 'string') {
      if (pid) {
        getSuggestedPlaceDetails({
          variables: {
            placeId: pid,
          },
          context: {
            headers: {
              'x-request-id': requestID,
              'accept-language': currentLocale,
            },
          },
        });
        changeMobileState?.(recentValue);
        updateFormValues(recentValue, pid, secondaryDescription, heading);
      } else {
        const isCountrySearch = !city && !state && country ? true : false;
        geoCodeAddress(recentValue, isCountrySearch)
          .then(placeId => {
            changeMobileState?.(recentValue);
            getSuggestedPlaceDetails({
              variables: {
                placeId: placeId,
              },
              context: {
                headers: {
                  'x-request-id': requestID,
                  'accept-language': currentLocale,
                },
              },
            });
            updateFormValues(recentValue, placeId, secondaryDescription, heading);
          })
          .catch(() => {
            changeMobileState?.(recentValue);
            updateFormValues(recentValue, pid, secondaryDescription, heading);
          });
      }
    }
    if (item && heading === nameOfRecentSearchesSection) {
      const numRooms = item['roomsCount'] || item['guestRooms'];
      const numAdultsPerRoom = parseInt(item?.['numberOfAdults']);
      const numChildrenPerRoom = parseInt(item?.['numberOfChildren']);
      const childrenAges = item['childrenAges'];
      let fromDate = moment(item['fromDate'] || item['startDate']);
      fromDate = fromDate.subtract(moment(fromDate).utcOffset() / 60, 'hours');
      let toDate = moment(item['toDate'] || item['endDate']);
      toDate = toDate.subtract(moment(toDate).utcOffset() / 60, 'hours');
      const flexible = item['flexible'] || item['flexibleDates'];
      const checkInDate = fromDate && (flexible === false || flexible === 'false') ? getValueFromDate(fromDate) : '';
      const checkOutDate = toDate && (flexible === false || flexible === 'false') ? getValueFromDate(toDate) : '';
      const numberOfNights = item['lengthOfStay'] || toDate.diff(fromDate, 'days');
      const lengthOfStay = item['lengthOfStay'] || toDate.diff(fromDate, 'days');
      if (selectedTab === tabSelect?.meetingsTab) {
        setSearchFormState([MEETINGS_DATES, EVENT_PURPOSE, GUEST_ROOMS, EVENT_SPACE], {
          [MEETINGS_DATES]: {
            eventsFromDate: fromDate,
            eventsToDate: toDate,
            flexible: flexible,
            numberOfNights: numberOfNights,
          },
          [EVENT_PURPOSE]: {
            purpose: item['eventType'],
          },
          [GUEST_ROOMS]: {
            noOfRooms: item['guestRooms'],
          },
          [EVENT_SPACE]: {
            noOfAttendees: item['attendees'],
          },
        });
      } else {
        setSearchFormState([ROOMS_AND_GUESTS, DATES], {
          ...[ROOMS_AND_GUESTS],
          [ROOMS_AND_GUESTS]: {
            numRooms: numRooms,
            numAdultsPerRoom: numAdultsPerRoom,
            numChildrenPerRoom: numChildrenPerRoom,
            childrenAges: childrenAges,
          },
          [DATES]: {
            fromDate: fromDate,
            toDate: toDate,
            flexible: flexible && flexible !== 'false' ? true : false,
            checkInDate: checkInDate,
            checkOutDate: checkOutDate,
            numberOfNights: numberOfNights,
            lengthOfStay: lengthOfStay,
          },
        });
      }
    }

    if (item && heading === nameOfRecentlyViewedSection) {
      setSearchFormState(['destinationFieldDetails'], {
        destinationFieldDetails: {
          recentlyViewedPopertyCode: item['marshaCode'],
        },
      });
    } else {
      setSearchFormState(['destinationFieldDetails'], {
        destinationFieldDetails: {
          recentlyViewedPopertyCode: '',
        },
      });
    }
  };

  // This function will update the hidden fields, based on the user interactions.
  const hiddenFieldsHandler = (data: Record<string, string | number>): void => {
    if (data && data['placeId']) {
      getSuggestedPlaceDetails({
        variables: {
          placeId: data['placeId'],
        },
        context: {
          headers: {
            'x-request-id': requestID,
            'accept-language': currentLocale,
          },
        },
      });
    }
  };
  /**
   * Main data selection and maniplutation functions END
   *
   */
  /**
   * Utility functions START
   *
   */

  // To call geoCoder and get placeid for destinations from recent searches
  function geoCodeAddress(location: string, isCountrySearch = false): Promise<string> {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder?.geocode({ address: location }, (results: any, status: any) => {
        if (status === 'OK') {
          let placeId;
          if (results && results.length) {
            if (results.length > 1) {
              for (let addressCount = 0; addressCount < results.length; addressCount++) {
                //if its a country search and country is CHINA , resolve placeId for CN , this helps to handle cases where we get other country code such as TW, MO, HK
                if (
                  isCountrySearch &&
                  results[addressCount]?.address_components?.[0]?.short_name === CHINA_REGION_CODE
                ) {
                  placeId = results[addressCount].place_id;
                  break;
                }
              }
            }
            placeId = placeId || results[0].place_id;
            resolve(placeId);
          } else {
            reject(new Error('No results found'));
          }
        } else {
          reject(new Error(`Geocoder failed due to: ${status}`));
        }
      });
    });
  }

  const convertMetersToMiles = (num: number | undefined | null) => {
    if (num) {
      return num / MILESTOMETERS;
    }
    return num;
  };
  const clearTimer = (timers: ReturnType<typeof setTimeout>[]): void => {
    if (timers.length) {
      for (let i = timers.length - 1; i >= 0; i--) {
        clearTimeout(timers[i]);
      }
      setTimerList([]);
    }
  };
  /**
   * Functions to handle events END
   *
   */

  /**
   * Functions to handle events START
   *
   */
  const closePopup = (): void => {
    setIsRecentViewAndSearchPresent(false);
    isDesktopView && setOpenPopupState(false); // for desktop view to close popup on clear recents
  };
  //to handle input change from TextInputField
  const handleInputChange = (returnedValue: string) => {
    setInputValue(returnedValue);
    setIsUserInputChanged(true);
    setSelectFromAutoSuggestOrRecentSearch(false);
    if (!returnedValue) {
      setInputValue('');
      selectedTab !== tabSelect?.meetingsTab
        ? setSearchFormState(
            [DESTINATION, DESTINATION_HIDDEN_FIELDS, 'destinationFieldDetails'],
            {
              [DESTINATION]: {
                ...formDestination,
                displayText: '',
                destinationAddressPlaceId: '',
                secondaryDescription: '',
              },
              [DESTINATION_HIDDEN_FIELDS]: {
                latitude: '',
                longitude: '',
                destinationAddressMainText: '',
                destinationAddressSecondaryText: '',
                city: '',
                country: '',
                searchRadius: null,
              },
              destinationFieldDetails: {
                recentlyViewedPopertyCode: '',
              },
            },
            true
          )
        : setSearchFormState(
            [MEETINGS_DESTINATION, EVENTS_DESTINATION_HIDDEN_FIELDS, 'destinationFieldDetails'],
            {
              [MEETINGS_DESTINATION]: {
                ...EventsformDestination,
                eventsDisplayText: '',
                eventsDestinationAddressPlaceId: '',
                secondaryDescription: '',
              },
              [EVENTS_DESTINATION_HIDDEN_FIELDS]: {
                latitude: '',
                longitude: '',
                destinationAddressMainText: '',
                destinationAddressSecondaryText: '',
                city: '',
                country: '',
                searchRadius: null,
              },
              destinationFieldDetails: {
                recentlyViewedPopertyCode: '',
              },
            },
            true
          );

      //either picklist or recent view/searches will be shown at a time
      if (!isRecentViewAndSearchPresent && cityPickListToggle) {
        setShowCityPicklist(true);
        setRecentViewed(false);
      } else if (isRecentViewAndSearchPresent || !cityPickListToggle) {
        setRecentViewed(true);
        setShowCityPicklist(false);
      }
      setShowAutoSuggest(false);
    } else {
      setClearCta(true);
      //either picklist or recent view/searches will be shown at a time
      setShowCityPicklist(false);
      setRecentViewed(false);
    }
  };
  const handleEnterKeyPress = () => {
    //on enter key press , if changeMobileState is undefined close the popup, else changeMobileState will redirect to Dates module - THIS IS MOBILE ONLY
    if (openPopup) {
      if (changeMobileState) {
        changeMobileState(inputValue);
        setOpenPopupState(false);
      } else {
        setOpenPopupState(false);
      }
    } else {
      !isDesktopView && setOpenPopupState(true);
    }
    selectedTab !== tabSelect?.meetingsTab
      ? setSearchFormState(
          [DESTINATION, DESTINATION_HIDDEN_FIELDS, 'destinationFieldDetails'],
          {
            [DESTINATION]: {
              ...formDestination,
              destinationAddressPlaceId: '',
              secondaryDescription: '',
              displayText: inputValue,
            },
            [DESTINATION_HIDDEN_FIELDS]: {
              latitude: '',
              longitude: '',
              destinationAddressMainText: inputValue,
              destinationAddressSecondaryText: '',
              city: '',
              country: '',
              searchRadius: null,
            },
            destinationFieldDetails: {
              recentlyViewedPopertyCode: '',
            },
          },
          true
        )
      : setSearchFormState(
          [MEETINGS_DESTINATION, EVENTS_DESTINATION_HIDDEN_FIELDS, 'destinationFieldDetails'],
          {
            [MEETINGS_DESTINATION]: {
              ...EventsformDestination,
              eventsDisplayText: inputValue,
              eventsDestinationAddressPlaceId: '',
              secondaryDescription: '',
            },
            [EVENTS_DESTINATION_HIDDEN_FIELDS]: {
              latitude: '',
              longitude: '',
              destinationAddressMainText: '',
              destinationAddressSecondaryText: '',
              city: '',
              country: '',
              searchRadius: null,
            },
            destinationFieldDetails: {
              recentlyViewedPopertyCode: '',
            },
          },
          true
        );
  };

  useEffect(() => {
    if (openPopup) {
      //Can't access this element without this selector.
      const textField = searchFieldRef?.current?.querySelector(
        `[name="input-text-Destination "][type="text"]`
      ) as HTMLElement;
      textField?.focus();
    }
    // change the height of searchform on mobile when this dropdown closes
    if (!openPopup && !isDesktopView) {
      const requiredElem = document.querySelectorAll('.m-dropdown-content');
      if (requiredElem && requiredElem.length) {
        requiredElem.forEach((node: any) => {
          if (node && node.style) {
            node.style.setProperty('height', '544px');
            node?.style.setProperty('display', 'unset');
          }
        });
      }
    }
  }, [openPopup]);
  const desktopInputClickHandler = () => {
    setIsUserInputChanged(true);
    setOpenPopupState(true);
    if (inputValue) {
      setShowAutoSuggest(true);
      //either picklist or recent view/searches will be shown at a time
      cityPickListToggle && setShowCityPicklist(false);
      setRecentViewed(false);
      setClearCta(true);
    } else {
      const recentViewAndSearchPresent = isRecentViewAndSearchAvailable(isServer, currentLocale);
      setIsRecentViewAndSearchPresent(recentViewAndSearchPresent);
      setShowAutoSuggest(false);
      //either picklist or recent view/searches will be shown at a time
      if (!recentViewAndSearchPresent && cityPickListToggle) {
        setShowCityPicklist(true);
        setRecentViewed(false);
      } else if (recentViewAndSearchPresent || !cityPickListToggle) {
        setRecentViewed(true);
        setShowCityPicklist(false);
      }
    }
    if (!isDesktopView) {
      setOpenPopupState(true);
    }
  };
  /**
   * Functions to handle events END
   *
   */
  const handleIconClick = () => {
    if (openPopup && !isDesktopView) {
      inputElementRef.current?.focus();
    }
    //either picklist or recent view/searches will be shown at a time
    if (!isRecentViewAndSearchPresent && cityPickListToggle) {
      setShowCityPicklist(true);
      setRecentViewed(false);
    } else if (isRecentViewAndSearchPresent || !cityPickListToggle) {
      setRecentViewed(true);
      setShowCityPicklist(false);
    }
    setInputValue('');
    selectedTab !== tabSelect?.meetingsTab
      ? setSearchFormState(
          [DESTINATION, DESTINATION_HIDDEN_FIELDS, 'destinationFieldDetails'],
          {
            [DESTINATION]: {
              ...formDestination,
              displayText: '',
              destinationAddressPlaceId: '',
              secondaryDescription: '',
            },
            [DESTINATION_HIDDEN_FIELDS]: {
              latitude: '',
              longitude: '',
              destinationAddressMainText: '',
              destinationAddressSecondaryText: '',
              city: '',
              country: '',
              searchRadius: null,
            },
            destinationFieldDetails: {
              recentlyViewedPopertyCode: '',
            },
          },
          true
        )
      : setSearchFormState(
          [MEETINGS_DESTINATION, EVENTS_DESTINATION_HIDDEN_FIELDS, 'destinationFieldDetails'],
          {
            [MEETINGS_DESTINATION]: {
              ...EventsformDestination,
              eventsDisplayText: '',
              eventsDestinationAddressPlaceId: '',
              secondaryDescription: '',
            },
            [EVENTS_DESTINATION_HIDDEN_FIELDS]: {
              latitude: '',
              longitude: '',
              destinationAddressMainText: '',
              destinationAddressSecondaryText: '',
              city: '',
              country: '',
              searchRadius: null,
            },
            destinationFieldDetails: {
              recentlyViewedPopertyCode: '',
            },
          },
          true
        );
  };
  const handleIconKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleIconClick();
    }
  };
  const blurTimeoutRef = useRef<any>(); //ref for timeout
  useEffect(() => {
    return () => {
      clearTimeout(blurTimeoutRef.current); // clearing timeout on component unmount
    };
  }, []);
  useEffect(() => {
    if (!isDesktopView && openPopup) {
      if (!inputValue) {
        //show recent viewed and searches on mobile/tablet popup open
        //either picklist or recent view/searches will be shown at a time
        if (!isRecentViewAndSearchPresent && cityPickListToggle) {
          setShowCityPicklist(true);
          setRecentViewed(false);
        } else if (isRecentViewAndSearchPresent || !cityPickListToggle) {
          setRecentViewed(true);
          setShowCityPicklist(false);
        }
        setShowAutoSuggest(false);
      } else {
        //show recent viewed and searches on mobile/tablet popup open
        //either picklist or recent view/searches will be shown at a time
        setRecentViewed(false);
        cityPickListToggle && setShowCityPicklist(false);
        setShowAutoSuggest(true);
      }
    }
  }, [openPopup, inputValue]);

  const handleBlurOnMobile = () => {
    //No DOm manipulation, only reading the values
    const dropdownElem = document.getElementById('search-field-dropdown');
    if (dropdownElem) {
      // Below, we are checking if the clicking is on any of the elements that are not contained
      // within 'dropdownElem' but are used to either open the dropdwon or to return to the dropdwon and closing dropdwon accordingly
      const focusoutListener = (event: FocusEvent): void => {
        if (
          event.relatedTarget &&
          !dropdownElem.contains(event.relatedTarget as Element) &&
          !dropdownElem.contains(document.activeElement)
        ) {
          setOpenPopupState(false);
        }
      };
      setTimeout(() => {
        document.addEventListener('focusout', focusoutListener); // Attaching click event to document to handle click outside of dropdown
      }, 100);
    }
  };
  useEffect(() => {
    //No DOm manipulation, only reading the values
    const dropdownElem = document.getElementById('search-field-dropdown');
    if (dropdownElem && cityPickListToggle) {
      // Below, we are checking if the clicking is on any of the elements that are not contained
      // within 'dropdownElem' but are used to either open the dropdwon or to return to the dropdwon and closing dropdwon accordingly
      const clickListener = (event: Event): void => {
        if (
          !dropdownElem.contains(event.target as Element) &&
          !searchFieldRef?.current?.contains(event.target as Element)
        ) {
          setOpenPopupState(false);
          setShowCityPicklist(false);
        }
      };
      const focusoutListener = (event: FocusEvent): void => {
        if (
          event.relatedTarget &&
          !dropdownElem.contains(event.relatedTarget as Element) &&
          !dropdownElem.contains(document.activeElement) &&
          dropdownElem.contains(event.target as Element)
        ) {
          setOpenPopupState(false);
          setShowCityPicklist(false);
        }
      };
      setTimeout(() => {
        isDesktopView && document.addEventListener('mousedown', clickListener); // Attaching click event to document to handle click outside of dropdown
        isDesktopView && dropdownElem.addEventListener('focusout', focusoutListener); // Attaching focusout event to dropdwon to handle blur event
      }, 100);
      // removing event listeners from document
      return (): void => {
        document.removeEventListener('mousedown', clickListener);
        dropdownElem.removeEventListener('focusout', focusoutListener);
      };
    }
    return;
  }, [isDesktopView]);

  useEffect(() => {
    const recentViewAndSearchPresent = isRecentViewAndSearchAvailable(isServer, currentLocale);
    setIsRecentViewAndSearchPresent(recentViewAndSearchPresent);
  }, []);

  useEffect(() => {
    if (!isDesktopView && openPopup) {
      if (cityPickListToggle && !isRecentViewAndSearchPresent) {
        setShowCityPicklist(true);
        setRecentViewed(false);
      } else if (!cityPickListToggle || isRecentViewAndSearchPresent) {
        setRecentViewed(true);
        setShowCityPicklist(false);
      }
    } else {
      setShowCityPicklist(false);
      setRecentViewed(false);
    }
  }, [isRecentViewAndSearchPresent, isDesktopView]);
  const buttonRef = useRef<HTMLButtonElement>(null); //ref to the last element of the search field, to be used in dropdown modal to identify the last element of the modal

  return (
    <StyledSearchFieldCategoriesDiv
      $isTabbedSearchForm={isTabbedSearchForm === true ? true : false}
      $cityPickListToggle={cityPickListToggle}
      ref={searchFieldRef}
      onFocus={() => {
        // If the input is focused before the timeout, cancel it
        clearTimeout(blurTimeoutRef.current);
        isDesktopView && desktopInputClickHandler();
        if (!isDesktopView && openPopup) {
          if (!inputValue) {
            //either picklist or recent view/searches will be shown at a time
            //show recent viewed and searches on mobile/tablet popup open and focuses on input
            if (!isRecentViewAndSearchPresent && cityPickListToggle) {
              setShowCityPicklist(true);
              setRecentViewed(false);
            } else if (isRecentViewAndSearchPresent || !cityPickListToggle) {
              setRecentViewed(true);
              setShowCityPicklist(false);
            }
            setShowAutoSuggest(false);
          } else {
            //either picklist or recent view/searches will be shown at a time
            //show recent viewed and searches on mobile/tablet popup open and focuses on input
            setRecentViewed(false);
            cityPickListToggle && setShowCityPicklist(false);
            setShowAutoSuggest(true);
          }
        }
      }}
      onClick={() => {
        desktopInputClickHandler();
      }}
      onBlur={event => {
        // Use a setTimeout to delay hiding the button
        blurTimeoutRef.current = setTimeout(() => {
          setClearCta(false);
        }, 100);
        const eventTargetClass = event.relatedTarget?.className;
        //adding the below code to NOT to close the popup when user navigates through tab/arrow key on dropdown elements, please check ADA functionality before changing this code
        if (
          !eventTargetClass?.includes('highlighted-item') &&
          !eventTargetClass?.includes('m-link-action') &&
          !eventTargetClass?.includes('close-button')
        ) {
          setShowAutoSuggest(false);
          //either picklist or recent view/searches will be shown at a time
          setRecentViewed(false);
          cityPickListToggle && setShowCityPicklist(false);
        }
      }}
      {...(!isDesktopView && !openPopup
        ? {
            onKeyDown: (event: any) => {
              if (event.key === 'Enter' || event.keyCode === 13) {
                handleEnterKeyPress();
              }
              !isDesktopView && handleBlurOnMobile(); // for mobile and tablet , on blur
            },
          }
        : {})}
      data-testid="search-field-categories"
    >
      <Downshift
        onSelect={(newValue: any) => {
          selectFromAutoSuggest?.(newValue);
          getSuggestedPlaceDetails({
            variables: {
              placeId: newValue?.node?.placeId,
            },
            context: {
              headers: {
                'x-request-id': requestID,
                'accept-language': currentLocale,
              },
            },
          });
        }}
        onChange={(newValue: any) => {
          selectFromAutoSuggest?.(newValue);
          getSuggestedPlaceDetails({
            variables: {
              placeId: newValue?.node?.placeId,
            },
            context: {
              headers: {
                'x-request-id': requestID,
                'accept-language': currentLocale,
              },
            },
          });
        }}
        itemToString={(item: any) => (item ? item?.node?.description : '')}
      >
        {({ getInputProps, getItemProps, highlightedIndex, selectedItem, getRootProps, getLabelProps }: any) => (
          <div
            className={clsx(
              isDesktopView && openPopup && isTabbedSearchForm !== true && !showCityPicklist
                ? 'destination-searchbar-divider'
                : ''
            )}
          >
            {(!isDesktopView && !openPopup) ||
            isDesktopView ||
            (!isDesktopView && isTabbedSearchForm && !isModalOpen) ? (
              <div
                {...getRootProps(
                  {
                    onClick: () => {
                      desktopInputClickHandler();
                    },
                    role: 'combobox',
                    'aria-controls': openPopup ? 'search-field-dropdown' : 'search-field-dropdown-sr-only',
                    'aria-labelledby': 'destination-combobox-hidden',
                  },
                  { suppressreferror: true }
                )}
                ref={inputRef}
                className={isTabbedSearchForm ? 'destination-medium-font' : ''}
              >
                {/* this label is just for combobox and wont be visible on UI */}
                <label className="sr-only" id="destination-combobox-hidden">
                  {destinationModuleEyebrowText + 'combobox'}
                </label>
                {/* this div is just for combobox aria-controls and wont be visible on UI */}
                <div className="sr-only" id="search-field-dropdown-sr-only"></div>
                <InputTextField
                  togglePlaceholder={true}
                  onBlur={() => {
                    if (!selectFromAutoSuggestOrRecentSearch && isDesktopView) {
                      selectedTab !== tabSelect?.meetingsTab
                        ? setSearchFormState(
                            [DESTINATION, DESTINATION_HIDDEN_FIELDS, 'destinationFieldDetails'],
                            {
                              [DESTINATION]: {
                                ...formDestination,
                                secondaryDescription: '',
                              },
                              [DESTINATION_HIDDEN_FIELDS]: {
                                latitude: '',
                                longitude: '',
                                destinationAddressMainText: inputValue,
                                destinationAddressSecondaryText: '',
                                city: '',
                                country: '',
                                searchRadius: null,
                              },
                              destinationFieldDetails: {
                                recentlyViewedPopertyCode: '',
                              },
                            },
                            true
                          )
                        : setSearchFormState(
                            [MEETINGS_DESTINATION, EVENTS_DESTINATION_HIDDEN_FIELDS, 'destinationFieldDetails'],
                            {
                              [MEETINGS_DESTINATION]: {
                                ...EventsformDestination,
                                secondaryDescription: '',
                              },
                              [EVENTS_DESTINATION_HIDDEN_FIELDS]: {
                                latitude: '',
                                longitude: '',
                                destinationAddressMainText: inputValue,
                                destinationAddressSecondaryText: '',
                                city: '',
                                country: '',
                                searchRadius: null,
                              },
                              destinationFieldDetails: {
                                recentlyViewedPopertyCode: '',
                              },
                            },
                            true
                          );
                    }
                  }}
                  label={destinationModuleEyebrowText}
                  iconForLabel="icon-location"
                  placeHolderText={placeholderTextDesktop}
                  inputValue={inputValue}
                  getInputValue={handleInputChange}
                  handleKeyDown={handleEnterKeyPress}
                  getInputProps={() => ({
                    ...getInputProps(),
                    readOnly: !isDesktopView,
                  })}
                  infoLabel={currentLocationText}
                  getLabelProps={() => ({
                    ...getLabelProps(),
                    className: 'disable-user-action',
                  })}
                  trailingIconProps={{ onKeyDown: handleIconKeyDown }}
                  variation={isTabbedSearchForm ? 'default' : 'line-type'}
                  showIcon={clearCta && !hideClearCta && inputValue?.length ? true : false}
                  //changing from iconOnMouseDownCapture to iconOnClick as the onclick fire's the click tracking
                  iconOnClick={handleIconClick}
                  iconClass="icon-cancel clear-input-icon custom_click_track"
                  iconAriaLabel={clearDestinationLabel}
                  withinModal={!isDesktopView}
                  showUnderline={isMobileForm}
                  custom_click_track_value={`${searchFormClickTracking} | Destination selected |internal`}
                  icon_custom_click_track_value={`${searchFormClickTracking} | Destination cleared |internal`}
                  inputTextFieldClassName={'text-truncate custom_click_track'}
                  customDownshiftLabel={true}
                />
              </div>
            ) : (
              <div></div>
            )}
            {openPopup && (
              <DropDownModal
                role="dialog"
                isAutoScrollEnabled={true}
                dropDownSpecificFlag={false}
                parentElementTopValue={searchFormTopPosition?.value}
                handleBlur={true}
                scrollDownModalCloseState={true}
                labelledBy="search-field-dropdown"
                applyDefaultHeight={isTabbedSearchForm === true ? true : false}
                mobileTakeOver={isDesktopView ? false : true}
                mobileModalHeading={mobileModalHeading}
                show={openPopup}
                className={`dropdown-container ${showCityPicklist ? 'city-picklist-dropdown-container' : ''}
                 ${isDesktopView && inputValue && !optionsList.length ? 'hide-dropdown' : ''}`}
                childrenClassName={'search-field-dropdown'}
                mobileHeaderEnabled={isDesktopView ? false : true}
                dropdownModalOpenState={openPopup}
                setDropdownModalOpenState={setOpenPopupState}
                dropdownModalOnCLoseFunc={() => {
                  setTimeout(() => {
                    onCancel?.();
                    setOpenPopupState(false);
                  }, 100);
                }}
                customLastElementRef={buttonRef}
              >
                {!isDesktopView && (
                  <div
                    className={clsx('mobile-input-field', isTabbedSearchForm ? 'destination-label-medium-font' : '')}
                    {...getRootProps({ role: 'document', suppressreferror: 'true' })}
                    onKeyDown={(event: any) => {
                      if (
                        (event.key === 'Enter' || event.keyCode === 13) &&
                        !event?.target?.classList?.contains('trailing-element')
                      ) {
                        handleEnterKeyPress();
                      }
                    }}
                  >
                    <InputTextField
                      ref={inputElementRef}
                      togglePlaceholder={true}
                      label={destinationModuleEyebrowText + ' '}
                      iconForLabel="icon-location"
                      placeHolderText={inputValue ? '' : mobilePlaceHolderText}
                      inputValue={inputValue}
                      getInputValue={handleInputChange}
                      getInputProps={() => ({
                        ...getInputProps(),
                        'aria-labelledby': 'Dropdown-' + getInputProps()['aria-labelledby'],
                        hidden: openPopup ? false : true,
                        id: 'Dropdown-' + getInputProps().id,
                      })}
                      handleKeyDown={handleEnterKeyPress}
                      getLabelProps={() => ({
                        ...getLabelProps(),
                        hidden: openPopup ? false : true,
                        readOnly: !isDesktopView ? true : false,
                        id: 'Dropdown-' + getLabelProps().id,
                      })}
                      variation={isTabbedSearchForm ? 'default' : 'line-type'}
                      iconClass="icon-cancel clear-input-icon custom_click_track"
                      showIcon={clearCta && !hideClearCta && inputValue.length ? true : false}
                      trailingIconProps={{ onKeyDown: handleIconKeyDown }}
                      //changing from iconOnMouseDownCapture to iconOnClick as the onclick fire's the click tracking
                      iconOnClick={() => {
                        setInputValue('');
                        selectedTab !== tabSelect?.meetingsTab
                          ? setSearchFormState(
                              [DESTINATION, DESTINATION_HIDDEN_FIELDS, 'destinationFieldDetails'],
                              {
                                [DESTINATION]: {
                                  ...formDestination,
                                  displayText: '',
                                  destinationAddressPlaceId: '',
                                  secondaryDescription: '',
                                },
                                [DESTINATION_HIDDEN_FIELDS]: {
                                  latitude: '',
                                  longitude: '',
                                  destinationAddressMainText: '',
                                  destinationAddressSecondaryText: '',
                                  city: '',
                                  country: '',
                                  searchRadius: null,
                                },
                                destinationFieldDetails: {
                                  recentlyViewedPopertyCode: '',
                                },
                              },
                              true
                            )
                          : setSearchFormState(
                              [MEETINGS_DESTINATION, EVENTS_DESTINATION_HIDDEN_FIELDS, 'destinationFieldDetails'],
                              {
                                [MEETINGS_DESTINATION]: {
                                  ...EventsformDestination,
                                  eventsDisplayText: '',
                                  eventsDestinationAddressPlaceId: '',
                                  secondaryDescription: '',
                                },
                                [EVENTS_DESTINATION_HIDDEN_FIELDS]: {
                                  latitude: '',
                                  longitude: '',
                                  destinationAddressMainText: '',
                                  destinationAddressSecondaryText: '',
                                  city: '',
                                  country: '',
                                  searchRadius: null,
                                },
                                destinationFieldDetails: {
                                  recentlyViewedPopertyCode: '',
                                },
                              },
                              true
                            );
                      }}
                      iconAriaLabel={clearDestinationLabel}
                      withinModal={true}
                      showUnderline={true}
                      custom_click_track_value={`${searchFormClickTracking} | Destination selected |internal`}
                      icon_custom_click_track_value={`${searchFormClickTracking} | Destination cleared |internal`}
                      inputTextFieldClassName={'text-truncate'}
                      lastElementRef={buttonRef}
                    />
                  </div>
                )}
                <div>
                  {showCityPicklist && (
                    <CityPicklist
                      currentLocationHiddenFieldHandler={hiddenFieldsHandler}
                      updateInputValue={updateRecentValue}
                      locale={currentLocale}
                      currentLocationLabel={currentLocationLabel}
                      apiKey={currentLocale === LOCALE_ZH_CN ? BAIDU_MAP_API_KEY : GOOGLE_MAP_API_KEY}
                      showSearchListView={showSearchListView}
                      setShowSearchListView={setShowSearchListView}
                      closePopup={closePopup}
                      isMeetingDestField={isMeetingDestField}
                      lastElementRef={buttonRef}
                      showListView={showSearchListView}
                    ></CityPicklist>
                  )}
                  {showRecentViewed && (
                    <div {...getItemProps()} className={clsx('standard', !isDesktopView ? 'mt-3' : '')}>
                      <CurrentLocationFeature
                        currentLocationHiddenFieldHandler={hiddenFieldsHandler}
                        inputFieldHandler={updateRecentValue}
                        locale={currentLocale}
                        currentLocationLabel={currentLocationLabel}
                        apiKey={currentLocale === LOCALE_ZH_CN ? BAIDU_MAP_API_KEY : GOOGLE_MAP_API_KEY}
                        render={CurrentLocationView}
                      />
                      <RecentViewAndSearch
                        updateInputValue={updateRecentValue}
                        updateSearchView={(val: boolean): void => setShowSearchListView(val)}
                        showListView={showSearchListView}
                        closePopup={closePopup}
                        isMeetingDestField={isMeetingDestField}
                        lastElementRef={buttonRef}
                        isCityPicklistFlow={false}
                      />
                    </div>
                  )}
                  {showAutoSuggest && (
                    <AutoSuggestions
                      optionsList={optionsList}
                      getItemProps={getItemProps}
                      highlightedIndex={highlightedIndex}
                      selectedItem={selectedItem}
                    ></AutoSuggestions>
                  )}
                </div>
              </DropDownModal>
            )}
          </div>
        )}
      </Downshift>
    </StyledSearchFieldCategoriesDiv>
  );
};
