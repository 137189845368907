import { CheckboxProps } from './Checkbox.types';
import { StyledDiv, StyledError } from './Checkbox.styles';
import DOMPurify from 'isomorphic-dompurify';

export const Checkbox = (props: CheckboxProps) => {
  const {
    label,
    subTitle,
    selectOptions = [],
    registerFn,
    id = '',
    registerOptions = {},
    isMandatoryField,
    className,
    titleClass,
    handleClick,
    selectedValue = [],
    description,
    errorsProps,
    testId = '',
    defaultChecked,
    errorId,
    describedBy,
    disabled,
    checked,
    inputClass,
    labelClass,
    trackValue,
  } = props;

  const errorMessage = errorsProps?.[id]?.message;
  const isError = errorsProps?.[id] ? 'd-block' : 'd-none';
  const isPreferredCard = id === 'preferredCard';
  const hideLabel = !label && 'd-none';

  return (
    <StyledDiv data-component-name="a-book-Checkbox" data-testid="book-Checkbox" role="group" className={className}>
      <legend id={label || id} className={['title', titleClass, hideLabel].join(' ')}>
        {label || id}
      </legend>
      {subTitle && <div className="subTitle">{subTitle}</div>}
      {selectOptions.map((option, index) => {
        const sanitizedNode = DOMPurify.sanitize(option.key, { RETURN_DOM: true });
        const fragmentString = sanitizedNode.innerHTML;
        return (
          <div key={`${option.key}_${index + 1}`} className="checkbox">
            <input
              key={`radio_${option.key}_${index + 1}`}
              type={'checkbox'}
              id={option?.id ? option?.id?.split(' ').join('') : option?.key?.split(' ').join('')}
              value={option.value}
              className={inputClass}
              onChange={handleClick}
              {...(selectedValue.length > 0
                ? { checked: selectedValue?.some(val => val.id === option.value) }
                : { checked: checked || defaultChecked })}
              {...(registerFn &&
                registerFn(id, {
                  required: isMandatoryField,
                  ...registerOptions,
                  ...{ onChange: handleClick },
                }))}
              aria-describedby={errorId === option.value ? describedBy : 'error'}
              data-testid={testId}
              disabled={disabled || option.disabled}
              data-custom_click_track_value={trackValue}
            />
            {option.isRichText ? (
              <label
                htmlFor={option?.key?.split(' ').join('')}
                key={`radiolabel_${option.key}_${index + 1}`}
                dangerouslySetInnerHTML={{ __html: fragmentString }}
              >
                {}
              </label>
            ) : (
              <label
                htmlFor={option?.id ? option?.id?.split(' ').join('') : option?.key?.split(' ').join('')}
                key={`radiolabel_${option.key}_${index + 1}`}
                aria-label={option.key}
              >
                {isPreferredCard ? (
                  option.key && <span className={labelClass}>{option.key}</span>
                ) : (
                  <span className={labelClass}>{option.key}</span>
                )}
                {option.description && <span className="description"> - {description}</span>}
                {option.link && (
                  <a className={option.className ? option.className : ''} onClick={() => option?.callBack?.()}>
                    {option.link}
                  </a>
                )}
              </label>
            )}
          </div>
        );
      })}
      <StyledError id="error" className={isError} data-testid={`${testId}_error`}>
        {errorMessage}
      </StyledError>
    </StyledDiv>
  );
};
