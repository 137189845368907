// Imports for external libraries go here.
import React from 'react';

// Imports for internal (to the monorepo) libraries go here
import { Heading } from '../Heading';
import { Text } from '../Text';
import { headingType, size } from '../../utils/enums/enums';
import { StyledPoints } from './Points.styles';
import { PointsProps } from './Points.types';
import clsx from 'clsx';
import { Types } from '@marriott/mi-ui-library';

export const PointsConfig = {
  emptyLabel: 'Points',
  isEmpty: () => true,
  resourceType: `/components/content/Points`,
};

export const Points = (props: PointsProps) => {
  const { text, variant = 'default', borderstyle, bonusLabel, isBonusPointsLabelVisible } = props;
  return (
    <StyledPoints
      data-component-name="a-ui-library-Points"
      data-testid="ui-library-Points"
      className={clsx(borderstyle === 'underlinedLarge' ? 'd-flex flex-column align-items-center' : '')}
    >
      {borderstyle === 'underlined' && (
        <>
          <Heading
            titleText={text}
            variation={headingType.title}
            fontSize={size.small}
            customClass={clsx(
              'points points-underlined d-inline-block mb-0',
              variant === 'carddetails' ? 't-numbers-s' : ''
            )}
          />
          {isBonusPointsLabelVisible && (
            <Text
              element={Types.tags.span}
              fontSize={Types.size.medium}
              copyText={bonusLabel}
              customClass="points-underlined-fontweight t-subtitle-medium pl-2"
            />
          )}
        </>
      )}
      {borderstyle === 'underlinedLarge' && (
        <>
          <Heading
            titleText={text}
            variation={headingType.title}
            fontSize={size.large}
            customClass={clsx('points mb-lg-2 mb-1', variant === 'carddetails' ? 't-numbers-s' : '')}
          />
          <div className="points-underlinedLarge"></div>
        </>
      )}
      {borderstyle === 'none' && (
        <Heading
          titleText={text}
          variation={headingType.title}
          fontSize={size.small}
          customClass={clsx('points mb-0', variant === 'carddetails' ? 't-numbers-s' : '')}
        />
      )}
      {borderstyle === 'rounded' && (
        <Heading
          titleText={text}
          variation={headingType.title}
          fontSize={size.medium}
          customClass={clsx('points points-rounded mb-0 p-4', variant === 'carddetails' ? 't-numbers-s' : '')}
        />
      )}
    </StyledPoints>
  );
};
