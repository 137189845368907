import { RequestProps } from './dataLayer/index.types';
import { isDev } from './helper';
import { axiosWrapper } from './axiosClient';
const { INTERCEPTOR_HOST_URL } = process.env;
const { INTERCEPTOR_HOST_URL_LOCAL } = process.env;

export const interceptor = async (req: { headers: RequestProps; url: string }, resolvedUrl: string) => {
  const { log, pLog } = global.loggerInstance('Interceptor');
  const headers = { ...req.headers };
  delete headers['host'];
  let interceptorHost = INTERCEPTOR_HOST_URL;
  // Dev check to run interceptor on local with interceptor mock.
  if (isDev) {
    interceptorHost = INTERCEPTOR_HOST_URL_LOCAL;
  }
  const url = `${interceptorHost}${process.env['INTERCEPTOR_URL']}`;
  log.debug(`API url: ${url}`);
  const apiStartTime = new Date().getTime();
  let response;
  try {
    response = await axiosWrapper.post(
      url,
      {
        originalRequestPath: resolvedUrl,
      },
      { headers }
    );
    log.debug(`API call success with status code: ${response.status}`);
  } catch (e) {
    log.error(`API url: ${url} | API call failed: ${e}`);
    throw new Error('Interceptor call failed');
  }
  pLog.log('API call performance timing', apiStartTime, new Date().getTime());
  return response;
};
