import React, { FC } from 'react';
import clsx from 'clsx';

export interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';

  /**
   * Additional classes to add on button
   */
  className?: string;
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;

  custom_click_track_value?: string;

  children?: React.ReactNode;
}

/**
 * Primary UI component for user interaction
 */
export const Button: FC<ButtonProps> = ({
  primary = false,
  size = 'medium',
  backgroundColor,
  label,
  className,
  children,
  ...props
}) => {
  const mode = primary ? 'button-primary' : 'button-secondary';
  return (
    <button
      type="button"
      className={clsx('button', 'custom_click_track', `button--${size}`, mode, className ? className : '')}
      style={{ backgroundColor }}
      {...props}
    >
      {children}
      {label}
    </button>
  );
};

export default Button;
