import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledDiv = styled.div`
  margin: ${toRem(32)} 0;
  position: relative;
  .validation {
    position: absolute;
    z-index: 100;
    width: 100%;
    padding: ${toRem(25)};
    padding-bottom: ${toRem(9)};
    list-style-type: none;
    background-color: ${theme.colors.white};
    border-radius: 0 0 ${toRem(14)} ${toRem(14)};
    border: 1px solid ${theme.colors['close-silver']};
    border-top: none;

    li {
      margin-bottom: ${toRem(16)};
      font-size: ${theme.fonts['fontM']};
      line-height: ${theme.fonts['lineHeightXl']};
      color: ${theme.colors['merch-green']};

      &.error {
        color: ${theme.colors.monza};
      }

      &.icon-clear::before,
      &.icon-check::before {
        margin-right: ${toRem(13)};
        font-size: ${theme.fonts['fontS']};
        font-weight: ${theme.fonts['fontWeightMedium']};
      }
    }
  }

  @media ${theme.mediaQuery.allMobiles} {
    margin-top: ${toRem(24)};
  }
`;
