import styled from 'styled-components';
import { baseVariables, toRem } from '../../styles';

const { color } = baseVariables;

export const StyledPill = styled.div`
  .pill {
    display: flex;
    flex-flow: wrap;
    padding: ${toRem(8)} ${toRem(12)};
    font-weight: 500;
    border: ${toRem(1)} solid ${color['neutral30']};
    border-radius: ${toRem(8)};
    &:hover {
      font-weight: 500;
    }
    &:focus {
      outline: ${toRem(1)} solid ${color['base10']};
    }
  }
  .active {
    background-color: ${color['neutral30']};
    font-weight: 500;
  }
  .pill-icon {
    margin: 0 ${toRem(5)} 0 0;
  }
  .disabled {
    color: ${color['neutral00']} !important;
    cursor: not-allowed !important;
    pointer-events: auto !important;
  }
`;

export const StyledPillBadge = styled.span`
  display: flex;
  height: ${toRem(20)};
  width: max-content;
  min-width: ${toRem(20)};
  margin-right: ${toRem(3)};
  padding: ${toRem(5)};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  background-color: ${color['base10']};
`;
