/* eslint-disable @typescript-eslint/consistent-type-assertions */
let observer: IntersectionObserver;

// Replace the data-src attribute with the value of the data-src attribute
const preloadImage = (element: HTMLImageElement): void => {
  if (element.dataset?.['src']) {
    element.src = element.dataset?.['src'];
    setTimeout(() => {
      element.classList.add('lazy-loaded');
      element.classList.remove('lazy');
    }, 1000);
    const parent = element.parentElement;
    // Once img element comes in viewport
    // loop through all <source> elements
    const siblings = <NodeListOf<HTMLImageElement>>parent?.childNodes ?? [];
    Array.from(siblings).forEach(sibling => {
      if (sibling.dataset?.['srcset']) {
        sibling.setAttribute('srcset', sibling.dataset['srcset']);
      }
    });
  }
};

const onIntersection: IntersectionObserverCallback = (entries): void => {
  entries.forEach((entry: IntersectionObserverEntry) => {
    if (entry.isIntersecting) {
      // Stop watching and load the image
      observer.unobserve(entry.target);
      // call our method: preloadImage
      preloadImage(entry.target as HTMLImageElement);
    }
  });
};

export const loadImages = (): void => {
  // select both source and image elements -> more on this later
  const images = window?.document?.querySelectorAll('.lazy img');
  // Some config parameters for the IntersectionObserve
  const config = {
    // If the image gets within 100px in the Y axis, start the download.
    rootMargin: '100px 0px',
    threshold: 0,
  };

  // If we don't have support for intersection observer, load the images immediately
  if (!('IntersectionObserver' in window)) {
    Array.from(<NodeListOf<HTMLImageElement>>images).forEach((image: HTMLImageElement): void => preloadImage(image));
  } else {
    // It is supported, load the images by calling our method: onIntersection
    observer = new IntersectionObserver(onIntersection, config);
    images.forEach(image => {
      observer.observe(image);
    });
  }
};
