// Styles for HotelsAndEvents go here.
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { theme } from '@marriott/mi-ui-library-shop';
import styled from 'styled-components';

export const StyledHotelsAndEvents = styled.div`
  .event-details {
    width: auto;
  }
  .eventOptionSelection {
    .fieldset {
      margin: 0 !important;
    }
  }

  .keydown-anchor {
    font-weight: ${theme.fonts['fontWeightRegular']};
  }

  .events-input-wrapper {
    width: 100%;

    .guest-room-div {
      width: 100%;
      height: ${toRem(52)};

      div {
        padding-left: 0;
      }

      .col-lg-12 {
        padding-right: 0;
      }

      .m-input-text-field {
        input::placeholder {
          color: ${baseVariables.color['neutral40']} !important;
          line-height: ${toRem(21)};
          font-weight: ${baseVariables.font['fontWeightMedium']};
        }
      }
    }

    .eventSpaceUtilityLabel {
      color: ${baseVariables.color['base10']};
      text-align: right;
      font-size: ${baseVariables.font['fontXs']};
      line-height: ${toRem(12)};
      margin-top: ${toRem(4)};
      margin-right: ${toRem(1)};
      font-weight: ${baseVariables.font['fontWeightRegular']};
    }
  }

  .eventOptionSelection {
    margin-right: auto;
    padding-left: 0;
    @media ${baseVariables.mediaQuery.lg} {
      padding-left: ${toRem(8)};
    }

    p.filter-sub-heading {
      padding-bottom: ${toRem(8)} !important;
      font-size: ${toRem(14)};
      line-height: ${toRem(21)};
      font-weight: ${baseVariables.font['fontWeightRegular']};
    }

    .radio-controls-list__radio {
      padding-bottom: 0 !important;
      padding-right: ${toRem(16)};
      padding-left: ${toRem(5)};
    }

    input[type='radio'] + label {
      padding-left: ${toRem(22)};
      padding-right: 0;

      a {
        vertical-align: middle;
      }
    }
    input[type='radio'] + label::before {
      transform: scale(0.7);
      outline: none !important;
    }
    input[type='radio'] + label::after {
      transform: scale(0.7);
      left: 0.46rem;
      top: 0.46rem;
    }
  }

  .submit-block-wrapper {
    width: 100%;
  }

  .submit-btn-wrapper {
    width: 100%;
    text-align: center;

    @media ${baseVariables.mediaQuery.md} {
      width: 50%;
    }

    .event-details-button {
      width: 100%;
      height: ${toRem(52)};
      margin: 0 auto;
      @media ${baseVariables.mediaQuery.md} {
        width: 100%;
        max-width: ${toRem(232)};
        position: absolute;
      }
      @media ${baseVariables.mediaQuery.lg} {
        width: 100%;
        max-width: ${toRem(232)};
        position: static;
      }
    }

    .icon-search:before {
      padding-right: ${toRem(2)};
      height: ${toRem(24)};
      width: ${toRem(24)};
    }
    @media ${baseVariables.mediaQuery.md} {
      .guest-room-div {
        width: 50%;
      }
      .attendees-div {
        width: 50%;
      }
    }
    @media ${baseVariables.mediaQuery.lg} {
      .guest-room-div {
        width: auto;
      }
      .attendees-div {
        width: auto;
      }
    }
  }
`;
