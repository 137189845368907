import { FC } from 'react';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { MenuProps } from './Menu.types';
import { styles, StyledMenuItem, StyledIcon, StyledDivider } from './Menu.styles';

const Menu: FC<MenuProps> = ({ showMenu, anchorEl, classes, menuItems, handleClose, handleItemClick }) => {
  return (
    <div data-component-name="m-book-Menu">
      <Popper className={classes['popper']} open={showMenu} anchorEl={anchorEl}>
        <Box className="d-md-none">
          <Paper className={classes['root']}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList>
                {menuItems.map(({ icon, text, key, clickTrackValue }, index) => (
                  <div key={index.toString() + 'menuItem'}>
                    <StyledMenuItem
                      className="custom_click_track"
                      onClick={() => handleItemClick(key)}
                      data-custom_click_track_value={clickTrackValue}
                    >
                      <StyledIcon className={icon} />
                      {text}
                    </StyledMenuItem>
                    {index !== menuItems.length - 1 && <StyledDivider />}
                  </div>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Box>
      </Popper>
    </div>
  );
};

export default withStyles(styles)(Menu);
