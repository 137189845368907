import React, { ReactNode, useEffect } from 'react';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { CalendarProps } from './index.types';
import { StyledCalendarDiv } from './index.styles';
import { getUserAgentType } from '../../styles/utils';
import { useCalendar, VARIATION_DESKTOP, VARIATION_TABLET, VARIATION_VERTICAL } from './index.controller';
import moment from 'moment';
import 'react-day-picker/lib/style.css';

const Calendar: React.FC<CalendarProps> = props => {
  const {
    variation,
    dateRef,
    getVerticalMonths,
    hoverDate,
    numberOfNightsValue,
    selectedDaysModifier,
    selectedDaysRange,
    onDayMouseLeave,
    onDayClickHandler,
    fromMonth,
    toMonth,
    renderDay,
    weeksdaysShort,
    monthsValue,
    weekdaysLongName,
    initialMonth,
    disabledDays,
    updateMouseEnterEvent,
    firstDayOfWeek,
    captionElement,
  } = useCalendar(props);

  let searchFormHoverDisable: boolean;
  //below useEffect is to assign aria-level attribute to elements having role='heading'
  useEffect(() => {
    dateRef?.current?.getElementsByClassName('DayPicker-Caption')[0]?.setAttribute('aria-level', '2');
    dateRef?.current?.getElementsByClassName('DayPicker-Caption')[1]?.setAttribute('aria-level', '2');
  }, []);

  if (
    props.fromSearchForm &&
    selectedDaysRange[0] !== undefined &&
    props.startDate &&
    moment(hoverDate) < props.startDate
  ) {
    searchFormHoverDisable = true;
  } else {
    searchFormHoverDisable = false;
  }

  return (
    <StyledCalendarDiv
      disableHover={props.disableHover}
      searchFormHoverDisable={searchFormHoverDisable}
      variation={variation}
      ref={dateRef}
      userAgentType={getUserAgentType()}
      data-testid="day-picker"
    >
      <DayPicker
        className="Selectable"
        selectedDays={selectedDaysRange}
        modifiers={selectedDaysModifier}
        month={initialMonth}
        fromMonth={fromMonth}
        toMonth={toMonth}
        weekdaysShort={weeksdaysShort}
        weekdaysLong={weekdaysLongName}
        months={monthsValue}
        disabledDays={disabledDays}
        firstDayOfWeek={Number(firstDayOfWeek) || 0}
        numberOfMonths={variation === VARIATION_VERTICAL ? getVerticalMonths() : variation === VARIATION_TABLET ? 1 : 2}
        renderDay={(day, _modifier): ReactNode => {
          return renderDay(day);
        }}
        captionElement={captionElement}
        onDayClick={(day, modifiers: DayModifiers): void => {
          onDayClickHandler(day, modifiers);
        }}
        onDayMouseLeave={(_day: Date, modifiers: DayModifiers, _event: React.MouseEvent<HTMLDivElement>): void => {
          onDayMouseLeave(modifiers);
        }}
        onMonthChange={(_month: Date): void => updateMouseEnterEvent(true)}
      />
      {variation === VARIATION_DESKTOP && hoverDate ? numberOfNightsValue() : null}
    </StyledCalendarDiv>
  );
};

export default Calendar;
