import { toRem } from './utils';

export default {
  lightGray: '#b2b2b2',
  gray: '#1c1c1c',
  darkGray: '#444054',
  background: '#f4f4f4',
  'dark-gray-1': '#1c1c1c',
  'dark-gray-2': '#231c19',
  'dark-gray-3': '#707070',
  'dark-gray-4': '#767676',
  // 'dark-gray-5': '#1e1e1e',
  'dark-gray-6': '#979797',
  'dark-gray-7': '#1d1d1d',
  'dark-gray-8': '#868686',
  'dark-gray-9': '#cccccc',
  'dark-gray-10': '#878787',
  'dark-gray-11': '#e4e7e7',
  'dark-gray-12': '#303030',
  white: '#ffffff',
  'white-smoke': '#F5F5F5',
  // 'light-gray': '#999999',
  'light-gray-1': '#d2d2d2',
  // 'light-gray-2': '#e6e6e6',
  'light-gray-3': '#eeeeee',
  // 'light-gray-4': '#c1c1c1',
  'light-gray-5': '#fcfcfc',
  'light-gray-6': '#c4c4c4',
  'light-gray-7': '#dbdbdb',
  'light-gray-9': '#c0c0c0',
  // 'light-orange': '#b84c16',
  'light-orange-2': '#ff9962',
  // 'light-blue': '#3b5998',
  // 'dark-orange': '#b84c16',
  black: '#000000',
  Terracotta: '#df8355',
  silver: '#bababa',
  alot: '#d8d8d8',
  alabaster: '#f8f8f8',
  zeus: '#231d19',
  monza: '#d0021b',
  'carousel-pink': '#fcebed',
  'dove-gray': '#6C6C6C',
  'mine-shaft': '#303030',
  'fiery-Orange': '#b84c16',
  'rose-of-sharon': '#b94600',
  'outline-blue': '#065AF6',
  'black-opacity-2': 'rgba(0, 0, 0, 0.2)',
  'cta-box-shadow': `0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07)`,
  'cta-banner-shadow': `0 ${toRem(1)} ${toRem(15)} 0 rgba(0, 0, 0, 0.12)`,
  'container-box-shadow': `0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.1)`,
};
