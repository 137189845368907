/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react';

import { getProcessEnvs, transformResponse, eventUtil, axiosWrapper } from '../../utils';
import { constants, URL_CONSTANTS } from '../../constants';
import { HotelDataMap, UniqueHotelLogoMap } from './HotelHeaderV2.schema';
import { HotelHeaderProps, Rendition } from './HotelHeaderV2.types';

const { NEXT_PUBLIC_RECENTLY_VIEWED_CONFIG } = process.env;

const { CTS_LOGO_PAYLOAD, LOGO_L } = constants;

export const parseHotelHeaderData = (
  apiResponse: unknown,
  setData: Dispatch<SetStateAction<HotelHeaderProps>>,
  locale: unknown,
  imageDomain = URL_CONSTANTS.CACHE_MARRIOTT_URL
) => {
  const {
    brandCode,
    hotelName,
    city,
    currency,
    seoNickname,
    phoneNumbers,
    hotelReviews,
    stars,
    latitude,
    longitude,
    stateProvince,
    country,
    postalCode,
    addressLine1,
    addressLine2,
    addressLine3,
    userData,
    imageUrl,
  } = transformResponse(apiResponse, HotelDataMap);

  const mainPhone = phoneNumbers?.find((val: any) => val?.type?.code === 'phone')?.number;

  setData({
    brandCode: brandCode.toLowerCase() || constants.MARRIOTT_BRAND_CODE,
    hotelName,
    city,
    currency,
    seoNickname,
    hotelReviews,
    stars,
    latitude,
    longitude,
    phone: mainPhone,
    stateProvince,
    country,
    postalCode,
    addressLine1,
    addressLine2,
    addressLine3,
    isJPLocale: locale === constants.IS_LOCALE_JP,
    suppressReviews: locale === constants.IS_LOCALE_CN,
    showReviews: stars?.count > 0 && hotelReviews?.count > 4,
    userData,
    imageUrl: `${imageDomain}${imageUrl}`,
  });
};

export const findSavedProperty = (propertyId: string, propertyList?: { [id: string]: string }[]): number => {
  if (propertyList?.length) {
    return propertyList?.findIndex(
      (list: { [id: string]: string }) => list?.['id'].toLowerCase() === propertyId.toLowerCase()
    );
  } else return -1;
};

const recentlyViewinitial = [
  {
    config: NEXT_PUBLIC_RECENTLY_VIEWED_CONFIG
      ? JSON.parse(NEXT_PUBLIC_RECENTLY_VIEWED_CONFIG)
      : constants.NEXT_PUBLIC_RECENTLY_VIEWED_CONFIG,
    recentlyViewedProperties: [],
  },
];

const daysDateDifference = (newDate: string, oldDate: string) => {
  const timeDiff = Math.abs(new Date(newDate).getTime() - new Date(oldDate).getTime());
  return Math.ceil(timeDiff / (1000 * 3600 * 24));
};

export const recentlyViewed = (recentlyViewData: any, sessionData: any) => {
  const existingLocalData = JSON.parse(window.localStorage.getItem('miRecentlyViewedProperties')!);
  const miRecentlyViewedProperties = existingLocalData || recentlyViewinitial[0];
  const filteredData = miRecentlyViewedProperties?.recentlyViewedProperties?.filter((item: any) => {
    return (
      item?.marshaCode?.toLowerCase() !== sessionData?.propertyId?.toLowerCase() &&
      daysDateDifference(new Date().toISOString(), item.createdAt) <=
        miRecentlyViewedProperties.config.maxCachedDaysLimit
    );
  });

  miRecentlyViewedProperties?.recentlyViewedProperties?.splice(
    0,
    miRecentlyViewedProperties?.recentlyViewedProperties.length
  );

  miRecentlyViewedProperties?.recentlyViewedProperties.push(...filteredData);
  recentlyViewData.name !== '' && miRecentlyViewedProperties?.recentlyViewedProperties.splice(0, 0, recentlyViewData);
  window.localStorage.setItem('miRecentlyViewedProperties', JSON.stringify(miRecentlyViewedProperties));
};

// get property logo from CTS API
export async function getPropertyLogo(
  propertyId: string,
  setUniqueHotelLogo: Dispatch<SetStateAction<string>>,
  locale: string
) {
  const { NEXT_PUBLIC_PREFIX } = getProcessEnvs();
  const url = `${NEXT_PUBLIC_PREFIX}${URL_CONSTANTS.CTS_API_URL}`;
  propertyId = propertyId?.toUpperCase();
  const prefix = propertyId?.slice(0, 1);
  const payload = {
    includeLink: true,
    locales: [locale],
    navIds: CTS_LOGO_PAYLOAD.NAVIDS,
    taxonomies: [[`/${CTS_LOGO_PAYLOAD.TAXONOMIES}/${prefix}/${propertyId}`]],
    types: CTS_LOGO_PAYLOAD.TYPES,
  };

  const configs = {
    params: {
      limit: 5,
      defaultLocale: true,
    },
  };
  const result = await axiosWrapper.post(url, payload, configs);
  const content = result?.data?.content?.find(({ name = '' }) => name?.includes(LOGO_L)) || result?.data?.content?.[0];
  const { imageUrl = '' } = transformResponse(content, UniqueHotelLogoMap);
  setUniqueHotelLogo(imageUrl);
}
export const handleClickHotelDetails = ({ e, id, key, hotelReviewUrl, hotelDetailUrl }: any) => {
  e && e.preventDefault();
  eventUtil &&
    eventUtil?.dispatch(key, {
      id,
      hotelReviewUrl,
      hotelDetailUrl,
    });
};

/**
 * Returns an array of renditions with updated paths if dynamic is true and the paths start with a forward slash.
 * @param {Rendition[]} renditions - An array of renditions.
 * @param {boolean} dynamic - A boolean indicating whether the paths should be updated dynamically.
 * @returns {Rendition[] | undefined} - An array of renditions with updated paths.
 */
export const getRenditionPath = (renditions?: Rendition[], dynamic?: boolean) => {
  if (!dynamic) {
    return renditions;
  }
  return renditions?.map(i => {
    if (i?.renditionPath?.startsWith('/')) i.renditionPath = `${URL_CONSTANTS.CACHE_MARRIOTT_URL}${i.renditionPath}`;
    if (i?.damPath?.startsWith('/')) i.damPath = `${URL_CONSTANTS.CACHE_MARRIOTT_URL}${i.damPath}`;
    return i;
  });
};
