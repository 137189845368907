/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateCreator } from 'zustand';
import { createAppStore } from '@marriott/mi-store-utils';

interface environmentVartype {
  googleMapApiKey?: string;
  submitSearchUrl?: string;
  availabilitySearchUrl?: string;
}
interface SessionDataState {
  sessionData: object;
  setSessionData: (sessionData: object) => void;
  headersData: object;
  setHeadersData: (headersData: object) => void;
  envVariablesData: environmentVartype;
  setEnvVariablesData: (envVariablesData: object) => void;
}

const initialState: SessionDataState = {
  sessionData: {},
  setSessionData: /* istanbul ignore next */ () => {},
  headersData: {},
  setHeadersData: /* istanbul ignore next */ () => {},
  envVariablesData: {},
  setEnvVariablesData: /* istanbul ignore next */ () => {},
};

export const SessionDataStore: StateCreator<SessionDataState> = set => {
  return {
    ...initialState,
    setSessionData: (data: object) => set({ sessionData: data }),
    setHeadersData: (data: object) => set({ headersData: data }),
    setEnvVariablesData: (data: object) => set({ envVariablesData: data }),
  };
};

// TODO: This store will be remove, once global store is created with session data
export const useSessionDataStore = createAppStore(SessionDataStore, {
  usePersistentStore: false,
});
