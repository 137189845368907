import React, { useEffect } from 'react';
import { SliderProps } from './index.types';
import { SliderWithStyle } from './index.styles';

const CustomSlider: React.FC<SliderProps> = props => {
  const { minValue, maxValue, valueChangeHandler, minLabel, maxLabel, value, ariaLabel, ariaValuetext } = props;
  const [sliderValue, setSliderValue] = React.useState(value);

  const sliderChangeHandler = (_: unknown, newValue: number | Array<number>): void => {
    if (typeof newValue === 'number') {
      // To communicate the changes in the value to parent component.
      valueChangeHandler(newValue);
      setSliderValue(newValue);
    }
  };

  useEffect(() => {
    setSliderValue(value || 0);
  }, [value]);

  return (
    <SliderWithStyle
      data-testid="mi-slider"
      min={minValue}
      max={maxValue}
      value={sliderValue}
      aria-valuetext={ariaValuetext}
      onChange={sliderChangeHandler}
      marks={[
        { value: minValue, label: minLabel },
        { value: maxValue, label: maxLabel },
      ]}
      aria-label={ariaLabel}
    />
  );
};

export default CustomSlider;
