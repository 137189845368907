import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const StyledSkeletonLoaderContainer = styled.div`
  ${rtl`
    background: ${theme.color.background};
    @media ${theme.mediaQuery.largeMobile} {
      padding-right: 0;
      padding-left: 0;
    }
    
  `}
`;

export const StyledSkeletonLoaderFormContainer = styled.div`
  ${rtl`
    background: ${theme.color.background};
    @media ${theme.mediaQuery.largeMobile} {
      padding-right: 0;
      padding-left: 0;
    }
    display: inline-flex;
    width: 100%;
    .search-form-div{
      margin: auto;
      width: 100%;
      height: ${toRem(160)};
      padding: ${toRem(0)};
      margin-top: ${toRem(16)};
      @media ${theme.mediaQuery.allMobiles} {
        height: ${toRem(91)};
        margin-left: ${toRem(16)};
        margin-right: ${toRem(16)};
      }
      .image-place-loader {
        @media ${theme.mediaQuery.allMobiles} {
          height: ${toRem(95)} !important;
        }
      }
    }
  `}
`;

export const StyledSkeletonLoaderDiv = styled.div`
  ${rtl`
    border-radius: ${toRem(14)};
    margin: ${toRem(10)} 0;
    font-family: ${theme.fonts['fontFamilySwiss']};
    display: flex;
    box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07);
    background-color: ${theme.color['white']};
      @media ${theme.mediaQuery.allMobiles} {
        flex-flow:wrap;
        max-height: ${toRem(399.5)};
        margin: ${toRem(15)} 0;
        overflow: hidden;
      }
    .search-form-div{
      width: 100%;
      margin: auto;
    }
    .left-side-loader {
      width:45%;
      @media ${theme.mediaQuery.allMobiles} {
        width:100%;
        min-height:${toRem(200)}
      }
      .image-place-loader {
        border-top-left-radius:${toRem(10)};
        border-bottom-left-radius:${toRem(10)};
         @media ${theme.mediaQuery.allMobiles} {
            border-radius:${toRem(10)};
            border-top-left-radius:${toRem(10)};
            border-top-right-radius:${toRem(10)};
            border-bottom-right-radius:${toRem(0)};
            border-bottom-left-radius:${toRem(0)};
          }
      }
    }
    .image-place-loader-map{
      border-radius: ${toRem(10)} ${toRem(10)} 0 0;
    }
    .skeleton-loader-composite {
      background-color: ${theme.color['light-gray-1']};
      color: white;
      -webkit-animation: fadein 2s ease-in alternate infinite;
      -moz-animation: fadein 2s ease-in alternate infinite;
      animation: fadein 2s ease-in alternate infinite;
    }
    @-webkit-keyframes fadein {
      10%   { opacity: 0.3; }
      25%   { opacity: 0.35; }
      50%   { opacity: 0.4; }
      75%   { opacity: 0.5; }
      100% { opacity: 0.6; }
    }
    @-moz-keyframes fadein {
      10%   { opacity: 0.3; }
      25%   { opacity: 0.35; }
      50%   { opacity: 0.4; }
      75%   { opacity: 0.5; }
      100% { opacity: 0.6; }
    }
    @keyframes fadein {
      10%   { opacity: 0.3; }
      25%   { opacity: 0.35; }
      50%   { opacity: 0.4; }
      75%   { opacity: 0.5; }
      100% { opacity: 0.6; }
    }
    .right-side-loader {
      padding-left: ${toRem(15)};
      padding-top: ${toRem(20)};
      padding-bottom: ${toRem(20)};
      width:55%;
      @media ${theme.mediaQuery.allMobiles} {
        width:100%;
        padding-right: ${toRem(15)};
      }
        .top-right-side-loader {
          width:90%;
          @media ${theme.mediaQuery.allMobiles} {
            width:100%;
          }
        }
        .bottom-rate-place-loader-container {
          width:90%;
          display: flex;
          justify-content: flex-end;
           @media ${theme.mediaQuery.allMobiles} {
            width:100%;
            .rate-place-loader {
              width:40%
            }
          }
        }
      }
    }
  `}
`;

export const StyledSkeletonMapViewLoaderDiv = styled.div`
  ${rtl`
    z-index: 3;
    position: fixed;
    top:0;
    height: 100%;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.6);

    .map-view-loader{
      padding: ${toRem(10)};
      border-radius: ${toRem(12)};
      display: flex;
      justify-content: center;
      align-items: center;
    }
`}
`;

export const StyledSkeletonMapViewLoaderDivForMobile = styled.div`
  ${rtl`
    width: 91.8%;
    left: ${toRem(32)};
    position: absolute;
    bottom: ${toRem(88)};
    
    @media ${theme.mediaQuery.allMobiles} {
      left: ${toRem(11)};
    }
    .skeleton-card {
      &__image {
        width: 45.5%;
        height: ${toRem(214)};
        @media ${theme.mediaQuery.allMobiles} {
          width: 33.5%;
          height: ${toRem(112)};
        }
        float: left;
      }
      &__properties {
        width: 54.5%;
        height: ${toRem(214)};
        @media ${theme.mediaQuery.allMobiles} {
          width: 66.5%;
          height: ${toRem(112)};
        }
        clear: left;
        &-first {
          width: 78.1%;
          height: ${toRem(32)};
          margin-left: ${toRem(16)};
          margin-top: ${toRem(32)};
          @media ${theme.mediaQuery.allMobiles} {
            width: 88.3%;
            margin-left: ${toRem(10)};
            margin-top: ${toRem(14)};
          }
        }
        &-second {
          width: 63%;
          height: ${toRem(16)};
          margin-left: ${toRem(16)};
          margin-top: ${toRem(12)};
          @media ${theme.mediaQuery.allMobiles} {
            width: 75.2%;
            margin-left: ${toRem(10)};
            margin-top: ${toRem(8)};
          }
        }
        &-third {
          width: 33.3%;
          height: ${toRem(16)};
          margin-left: ${toRem(16)};
          margin-top: ${toRem(12)};
          @media ${theme.mediaQuery.allMobiles} {
            width: 57.7%;
            float: right;
            margin-right: ${toRem(8)};
            margin-bottom: ${toRem(10)};
          }
        }
        &-fourth {
          width: 33.3%;
          height: ${toRem(32)};
          float: right;
          margin-left: ${toRem(94)};
          margin-top: ${toRem(54)};
          @media ${theme.mediaQuery.allMobiles} {
            width: 0;
          }
      }
      &-fifth {
        width: 33.3%;
        height: ${toRem(32)};
        border-radius:${toRem(20)};
        margin-left: ${toRem(16)};
        margin-top: ${toRem(54)};
        @media ${theme.mediaQuery.allMobiles} {
          width: 0;
        }
      }
   
`}
`;

export const StyledSkeletonMapViewLoaderContainerForMobile = styled.div`
  ${rtl`
    z-index: 3;
    position: fixed;
    height: 75vh;
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`}
`;
