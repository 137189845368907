import React, { FC } from 'react';

import { constants } from '../../constants';
import { PropertyRatingProps } from './PropertyRating.types';
import { StyledTooltipContainer } from './PropertyRating.styles';
import { getReplacedText } from '../../utils/helper';

const { CN_LOCALE, TARGET_BLANK, COUNT } = constants;

// Use named rather than default exports.
export const PropertyRating: FC<PropertyRatingProps> = ({
  locale,
  propertyReviewUrl,
  propertyReviews,
  propertyRatingStars,
  reviewsText,
  starsOutOfText,
  tooltipText,
  clickTrackValue,
  ...props
}) => {
  if (locale === CN_LOCALE) {
    return (
      <StyledTooltipContainer
        data-component-name="m-book-PropertyRating"
        data-testid="book-PropertyRating"
        // data-testid="reviews-tooltip"
        className="m-tooltip-top tooltip-container"
        data-content={tooltipText}
      >
        <span
          data-custom_click_track_value={clickTrackValue}
          aria-label={`${propertyRatingStars?.[COUNT]} ${starsOutOfText}`}
          data-testid="reviews"
          {...props}
        >
          <span className="ml-1 stars">{propertyRatingStars?.[COUNT]}</span>
        </span>
      </StyledTooltipContainer>
    );
  }

  const reviewCount = propertyReviews?.[COUNT];
  const updatedReview = reviewCount ? getReplacedText(reviewsText, String(reviewCount)) : '';
  return (
    <a
      data-component-name="m-book-PropertyRating"
      data-testid="book-PropertyRating"
      href={propertyReviewUrl}
      target={TARGET_BLANK}
      data-custom_click_track_value={clickTrackValue}
      aria-label={`${propertyRatingStars?.[COUNT]} ${starsOutOfText} ${updatedReview}`}
      // data-testid="reviews"
      {...props}
    >
      <span className="ml-1 stars">{propertyRatingStars?.[COUNT]}</span>
      <div className="d-inline-block d-lg-none reviews ml-1">{`(${propertyReviews?.[COUNT]})`}</div>
      <div data-testid="reviews-count" className="d-none d-lg-inline-block reviews ml-1">{`(${updatedReview})`}</div>
    </a>
  );
};
