import { toRem } from './utils';
import { theme } from './theme';

export const heading = (fontSize: number, lineHeight: number): string => `
    font-size: ${toRem(fontSize)};
    font-weight: 500;
    letter-spacing: 0;
    line-height: ${toRem(lineHeight)};
`;

export const bodyTxt = (fontSize: number, lineHeight: number): string => `
    font-family: ${theme.fontFamily.body};
    font-size: ${toRem(fontSize)};
    letter-spacing: 0;
    line-height: ${toRem(lineHeight)};
`;

export default `
    .h-r-label-form-field {
        display: block;
        text-transform: uppercase;
        ${heading(12, 16)}
        letter-spacing: ${toRem(1.3)};
        color: ${theme.color['dark-gray-2']};
        font-family: ${theme.fontFamily.title};
        @media ${theme.mediaQuery.tablet} {
            color: ${theme.color['dark-gray-4']};
        }
    }

    .h-r-form-field-txt {
        color: ${theme.color['dark-gray-4']};
        ${bodyTxt(18, 20)}
        @media ${theme.mediaQuery.tablet} {
            ${bodyTxt(18, 24)}
            color: ${theme.color['dark-gray-2']};
        }
        @media ${theme.mediaQuery.desktop} {
            ${bodyTxt(22, 26)}
        }
        font-family: ${theme.fontFamily.title};
    }

    .h-r-form-field-txt-small {
        ${bodyTxt(14, 24)}
    }

    .h-r-body-txt-medium {
        ${bodyTxt(14, 16)}
    }
`;
