export enum SearchFacetsList {
  brand = 'BRANDS',
  points = 'POINTS',
  amenities = 'AMENITIES',
  propertyType = 'PROPERTY_TYPES',
  price = 'PRICE',
  activities = 'ACTIVITIES',
  cities = 'CITIES',
  states = 'STATES',
  countries = 'COUNTRIES',
  distance = 'DISTANCE',
  hotelType = 'HOTEL_SERVICE_TYPES',
  meetingEvents = 'MEETINGS_EVENTS',
  transportTypes = 'TRANSPORTATION_TYPES',
  leisureRegions = 'LEISURE_REGIONS',
  // breakfastAmenities = 'BREAKFAST_AMENTITIES'
  // leisureRegions = 'LEISURE_REGIONS',
  // rewardCategories = 'REWARDS_CATEGORIES',
  // states = 'STATES',
}

export enum SearchFacetsListCodes {
  brand = 'brands',
  points = 'points',
  amenities = 'amenities',
  propertyType = 'property-types',
  price = 'price',
  activities = 'activities-on-site',
  cities = 'cities',
  states = 'states',
  countries = 'countries',
  distance = 'distance',
  hotelType = 'hotel-service-types',
  meetingEvents = 'meetings-and-events',
  transportTypes = 'transportation-types',
  leisureRegions = 'leisure-region',
  // breakfastAmenities = 'BREAKFAST_AMENTITIES',
  // leisureRegions = 'LEISURE_REGIONS',
  // rewardCategories = 'REWARDS_CATEGORIES',
  // states = 'STATES',
}

export enum SearchFacetsConstants {
  terms = 'terms',
  ranges = 'ranges',
  latitude = 'latitude',
  longitude = 'longitude',
  keyword = 'keyword',
  destination = 'destination',
  facets = 'facets',
  search = 'search',
  options = 'options',
  includeTaxes = 'includeTaxesAndFees',
  unavailableProperties = 'includeUnavailableProperties',
  limit = 'limit',
  offset = 'offset',
  goToNextPage = 'nextPage',
  sort = 'sort',
  points = 'pointsEnable',
  filter = 'filter',
  modes = 'modes',
  promote = 'promote',
  ids = 'ids',
}

export const SearchPageAlerts = {
  default: {
    code: '',
    heading: '',
    hasLink: false,
    linkUrl: '',
    linkText: '',
    errorMessage: '',
    showPageAndFilter: true,
    icon: '',
  },
  info: {
    code: '',
    heading: '',
    hasLink: false,
    linkUrl: '',
    linkText: '',
    errorMessage: "We're temporarily unable to display the information you requested. Please try again later.",
    showPageAndFilter: false,
    icon: 'icon-information',
    type: 'info',
  },
  warning: {
    code: 'queries.v2.properties.limit.range.dev.2',
    heading: '',
    hasLink: false,
    linkUrl: '',
    linkText: '',
    errorMessage:
      'There are too many hotels in your results to display pricing and availability information. Please further refine your search results.',
    showPageAndFilter: true,
    icon: 'icon-warning',
    type: 'warning',
  },
  success: {
    code: '',
    heading: 'Currency Updated',
    hasLink: false,
    linkUrl: '',
    // linkCallbackFunc: setDefaultPrice(),
    linkText: 'Show me rates in local currency',
    errorMessage: `When you reserve you will see the local currency of the hotel.`,
    showPageAndFilter: true,
    icon: 'icon-success',
    type: 'success',
  },
};

export const SearchSortFieldForCITY = ['CITY', 'BRAND', 'PROPERTY_NAME'];
export const SearchSortFieldForDISTANCE = ['DISTANCE', 'CITY', 'BRAND', 'PROPERTY_NAME'];
export const SearchSortFieldForBRAND = ['BRAND', 'PROPERTY_NAME'];

export enum SearchSortFields {
  brand = 'BRAND',
  distance = 'DISTANCE',
  price = 'PRICE',
  city = 'CITY',
  state = 'STATE',
  guest_rating = 'GUEST_RATINGS',
  no_of_reviews = 'NUM_REVIEWS',
  default = 'PROPERTY_NAME',
}

export enum SearchSortFieldsDirection {
  desc = 'DESC',
  asc = 'ASC',
}

export const SearchRangesEndPointArr = ['0', '100', '200', 'overflow'];
export const SearchDistanceRangesEndPointArr = ['0', '5000', '15000', '80000'];
export const filterList = [
  'BRANDS',
  'POINTS',
  'AMENITIES',
  'PROPERTY_TYPES',
  'PRICE',
  'ACTIVITIES',
  'CITIES',
  'STATES',
  'COUNTRIES',
  'DISTANCE',
  'HOTEL_SERVICE_TYPES',
  'MEETINGS_EVENTS',
  'TRANSPORTATION_TYPES',
];
export const SearchPointsEndPointArr = ['0', '40000', '80000', '120000', '160000', '200000', '240000', 'overflow'];
export const enableAverageNightlyRate = true;
export const RateNodeTypeToBeUsed = enableAverageNightlyRate
  ? 'lowest-average-nightly-rate'
  : 'first-night-lowest-stay';
export const unavailableCode = 'unavailable';
export const HWSBrands = ['RZ', 'BCNRZ', 'DALIL', 'LISRZ'];
export const viewHotelWebsiteLink = '/hotels/travel/';
export const maxOccupancyCode = 'closedMaxOccupancyExceeded';
export const RateNodeTypeToBeUsedInQuery = enableAverageNightlyRate
  ? 'LOWEST_AVERAGE_NIGHTLY_RATE'
  : 'FIRST_NIGHT_LOWEST_STAY';
export const PointsNodeTypeToBeUsedInQuery = 'POINTS_PER_UNIT';
export const PointsNodeTypeToBeUsed = 'points-per-unit';
export const DefaultCurrency = 'USD';
export const DefaultEURCurrency = 'EUR';
export const DefaultValue = 'default';
export const PRICE_CODE = 'standard';
export const POINTS_CODE_VALUE = 'MRW';
export const SPECIAL_CODE = 'special';
export const AVAILABLE_CODE = 'available';
export const P17_CODE = 'P17';
export const EnableCurrencyPersistence = false;
export const STRIKE_PRICE_THRESHOLD_PERCENTAGE = 3.3;
export const descriptionToDisplayOnPropertyCard = 'hotelMarketingCaption';
export const descriptionFilterToDisplayOnPropertyCard = 'HOTEL_MARKETING_CAPTION';
export const resortDescriptionFilterToDisplayOnPropertyCard = 'RESORT_FEE_DESCRIPTION';
export const destinationDescriptionFilterToDisplayOnPropertyCard = 'DESTINATION_FEE_DESCRIPTION';
export const LA_COUNTY_FEE_DESCRIPTION = 'SURCHARGE_ORDINANCE_COST_DESCRIPTION';

export const errorMessageRedirection = '../hotel-search.mi';
export enum MemberExclusiveBannerConstants {
  fourNights = 4,
  fiveNights = 5,
}

export const priceType = { code: 'standard', value: null };
export const pointsType = { code: 'special', value: 'MRW' };

export const dailyResortFeeLabel = 'Daily Resort Fee';
export const dailyDestinationFeeLabel = 'Daily Destination Amenity Fee';
export const clearAllFilterKey = 'clearAllFilters';
export const noNearAddressKey = 'noNearAddressPropertyResult';
export const noAvailabilityKey = 'noAvailabilityWithinRadius';
export const maxCapacityKey = 'noAvailabilityMaxcapacity';
export const noHotelsWithinRadiusKey = 'noHotelsWithinRadius';
export const invalidSearchPropertyResultKey = 'invalidSearchPropertResult';
export const unsuccessfulSellKey = 'unsuccessfulSellWarning';
export const showAvailabilityLabel = 'show available hotels';
export const showTaxesandFeesLabel = 'show taxes and fees';

export const fallbackImages = {
  Classic: 'https://cache.marriott.com/is/image/marriotts7prod/bonvoy-error-image-10518:Classic-Hor',
  Square: 'https://cache.marriott.com/is/image/marriotts7prod/bonvoy-error-image-10518:Square',
  Wide: 'https://cache.marriott.com/is/image/marriotts7prod/bonvoy-error-image-10518:Wide-Hor',
  altText: 'Fallback',
};

export enum GraphQlAPiResponseKey {
  FLEXI_desti = 'searchPropertiesByDestination',
  FLEXI_geo = 'searchPropertiesByGeolocation',
  LAR_geo = 'searchLowestAvailableRatesByGeolocation',
  LAR_desti = 'searchLowestAvailableRatesByDestination',
  LAR_search_input = 'searchLowestAvailableRatesByLocation',
  FLEXI_by_id = 'searchPropertiesByIds',
  LAR_by_id = 'searchLowestAvailableRatesByPropertyIds',
}

export enum GraphQlMgpAPiResponseKey {
  geo = 'searchByGeolocation',
  desti = 'searchByDestination',
  search_input = 'searchByLocation',
  id = 'searchByPropertyIds',
}

export const mandatoryFeeCodes = {
  resortFeeCode: 'resortFeeDescription',
  destinationFeeCode: 'destinationFeeDescription',
  resortFeeCheck: 'a resort fee of',
  destinationFeeCheck: 'a daily destination fee of',
  laCountyFeeCode: 'surchargeOrdinanceCostDescription',
  laCountyFeeCheck: 'Surcharge added for hotel costs to comply with LA Hotel Worker Protection Ordinance.',
};

export const amenityCodeToIconMap: Record<string, string> = {
  'EV Charging': 'ev-charging-station',
  'Whirlpool in Room': 'pool',
  'Whirlpool on Site': 'pool',
  'new-lobby': 'check',
};

export const priorityPhotoCategoryCode = 'preferred';

export enum hardCodedAllLocale {
  de = 'de-DE',
  en = 'en-US',
  zhTW = 'zh-TW',
  ko = 'ko-KR',
  ar = 'ar-AE',
}

// Image transition constants
export const croppingRatio = 6;
export const outputQuality = 90;
export const outputInterpolation = 'progressive-bilinear';

export const searchFilters = {
  amenities: 'amenities',
  transportationTypes: 'transportation',
  brands: 'marriottBrands',
  hotelTypes: 'hotelType',
  activities: 'activities',
  events: 'events',
  states: 'states',
  cities: 'cities',
  showAvailableHotels: 'showAvailableHotels',
  includeTaxes: 'showFullPrice',
  selectedCurrency: 'currency',
  view: 'view',
};

export const payloadCountryCodeForChina = 'CN, TW, MO, HK';
export const countryCodeForChina = 'CN';
export const destinationValueForChina = 'CHINA';

export const description = {
  resortFeeDescription: 'resortFeeDescription',
  destinationFeeDescription: 'destinationFeeDescription',
  surchargeOrdinanceCostDescription: 'surchargeOrdinanceCostDescription',
};

export const dataLayerLabels = {
  points: 'POINTS',
  price: 'PRICE',
  distance: 'DISTANCE',
  brand: 'BRANDS',
  states: 'STATES',
};

export const RECOMMENDED_SORT = 'recommendedSort';
export const RECOMMENDED_OPTION = 'RECOMMENDED';
export const MARRIOTT_CACHE_URL = 'https://cache.marriott.com/';
export const DEFAULT_SORT_OPTS = 'no-segment-options';

export const SESSION_SEARCH_KEYS =
  'sessionToken,rewardsId,memberLevel,name,accessToken,consumerID,propertyId,AriesRewards.savedHotelList,AriesReservation.propertyId,AriesReservation.errorMessages,AriesCommon.prop_name,AriesCommon.savedProps,AriesCommon.revisionToken,AriesCommon.memState,AriesCommon.ptsBal,AriesCommon.search_destination_city,AriesCommon.search_destination_country,AriesCommon.search_destination_state,AriesSearch.search_availability_search,AriesSearch.search_date_type,AriesSearch.search_location_or_date_change,AriesSearch.rememberedMemberLevel,AriesSearch.searchCriteria,AriesSearch.search_keyword,AriesSearch.search_date_check_out_day_of_week,AriesSearch.search_date_check_in_day_of_week,AriesSearch.search_advance_purchase_days,AriesSearch.propertyFilterCriteria,AriesSearch.hotelDirectoryFilterCriteria,AriesSearch.search_is_weekend_stay,AriesSearch.search_criteria_changed,AriesSearch.search_google_places_destination,AriesSearch.propertyRecordsCount,AriesSearch.propertyId,AriesSearch.errorMessages,AriesSearch.search_dates_flexible';

export const DEFAULT_IMAGE =
  'https://cache.marriott.com/content/dam/marriott-digital/cy/us-canada/hws/h/hpnnb/en_us/logo/internal/assets/cy-hpnnb-grey-image-placeholder-34740.png?imbypass=on';

export const ALL_INCLUSIVE_CODE = 'all-inclusive';
export const ALL_INCLUSIVE_LABEL = 'All-Inclusive';

export const COUNTRY_LIST_SHOW_FULL_PRICE_BY_DEFAULT = ['NL'];
