import { FC } from 'react';
import cssClass from 'classnames';

import { MessageProps } from './Message.types';
import { StyledSection } from './Message.styles';

export const Message: FC<MessageProps> = props => {
  const { title, inModal = true, policyMessagreArr, inCancelModal = false } = props;
  const containerClassName = cssClass({ 'p-4': inCancelModal });
  const descriptionClassName = cssClass({ description: !inCancelModal, 'description-rrd': inCancelModal });

  const policyMessage = policyMessagreArr.join(' ');
  return (
    <StyledSection
      data-component-name="a-book-Message"
      data-testid="book-Message"
      isRrdPage={!inModal}
      className={containerClassName}
    >
      <div>
        {title && <p className="title">{title}</p>}
        {policyMessagreArr.length > 0 && <p className={descriptionClassName}>{policyMessage}</p>}
      </div>
    </StyledSection>
  );
};
