import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { theme } from '../../styles-3.0/theme';
import { toRem } from '../../styles-3.0/utils';
import { StyledCalendarDivProps } from './index.types';
import { VARIATION_DESKTOP, VARIATION_VERTICAL } from './index.controller';

// eslint-disable-next-line @typescript-eslint/no-var-requires
// const reactDateCss = require('react-day-picker/lib/style.css').toString();
// console.log('reactDateCss: ', reactDateCss);
// const reactDateCssStr = reactDateCss.toString() || '';
// import reactDateCss from 'react-day-picker/dist/style.css'

// ${reactDateCss}
export const StyledCalendarDiv = styled.div<StyledCalendarDivProps>`
  ${rtl`
    /* START: custom DayPicker styles starts*/
    .Selectable .DayPicker-Day {
      border-radius: 0 !important;
      &.DayPicker-Day--endHover {
        border-top-right-radius: 50% !important;
        border-bottom-right-radius: 50% !important;
      }
    }
    .Selectable .DayPicker-Day--start:not(.DayPicker-Day--outside) {
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
     }
    .Selectable .DayPicker-Day--end:not(.DayPicker-Day--outside) {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
     }

  .DayPicker {
    width: 100%;
    font-family: ${theme.fontFamily.swiss};
    border-radius:0;
  }
  .DayPicker-Day {
    padding: 0;
    border-radius:0;
  }

  ${(props: StyledCalendarDivProps): string =>
    props.variation !== VARIATION_VERTICAL
      ? `
      .DayPicker-Day--start {
       padding-right: ${toRem(1)};
       padding-bottom: ${toRem(1)};

     }
      .DayPicker-Day--end {
        padding-left: ${toRem(1)};
        padding-top: ${toRem(1)}
     }`
      : ``}

  .DayPicker-Day-value {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
     z-index: 1;
  }

   /* START: date background color start */
  .DayPicker-Day--today {
    color: rgb(0, 0, 0);
    font-weight: 400;
  }
   /* set selected date color for range dates div */
  .DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--outside):not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
    background: ${theme.color['light-gray-7']};
    color: ${theme.color.black};
}
.DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--outside):not(.DayPicker-Day--start):not(.DayPicker-Day--end) :hover{
  background: ${theme.color['light-gray-7']};
  color: ${theme.color.black};
  border-radius:50%;
}

/* styles for start and end date range div */
  .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--start:not(.DayPicker-Day--outside),
  .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--end:not(.DayPicker-Day--outside) {
     background: ${theme.color['light-gray-7']} !important;
     color: ${theme.color.white};
     }

  /* set date color for selected start and end date */
  .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--start:not(.DayPicker-Day--outside) .DayPicker-Day-value,
  .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--end:not(.DayPicker-Day--disabled) .DayPicker-Day-value {
    background-color: ${theme.color.black} !important;
    color: ${theme.color.white};
    border-radius: 50%;
  }
  .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--start:(.DayPicker-Day--outside) .DayPicker-Day-value,
  .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--end:(.DayPicker-Day--disabled) .DayPicker-Day-value {
    background-color: ${theme.color.black} !important;
    color: ${theme.color.white};
    border-radius: 50%;
  }
 /* set date color for single selected start or end date */
  .DayPicker-Day.DayPicker-Day--select.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) .DayPicker-Day-value {
    background-color:  ${theme.color.black} !important;
    color: ${theme.color.white} !important;
    border-radius: 50% !important;
  }
  .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--start.DayPicker-Day--end:not(.DayPicker-Day--outside){
    background-color: transparent !important;
  }

  .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--start.DayPicker-Day--end:not(.DayPicker-Day--outside):hover{
    border-radius: 0 !important;
  }

  .DayPicker-Day.DayPicker-Day--selected.DayPicker-Day--start.DayPicker-Day--end {
    border-radius: 0 !important;
  }

  .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--select.DayPicker-Day--selected {
    border-radius: 50% 0 0 50% !important;
  }

  .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--end.DayPicker-Day--hoverRange.DayPicker-Day--fromFocus.DayPicker-Day--selected {
    background: ${theme.color['light-gray-7']} !important;
    border-radius: 50% 0 0 50% !important;
  }

  .DayPicker-Day.DayPicker-Day--select.DayPicker-Day--hoverRange.DayPicker-Day--fromFocus.DayPicker-Day--selected {
    border-radius: 50% 0 0 50% !important;
  }

  .DayPicker-Day.DayPicker-Day--select.DayPicker-Day--hoverRange.DayPicker-Day--endFocus.DayPicker-Day--selected {
    border-radius: 0 50% 50% 0 !important;
  }

  .DayPicker-Day.DayPicker-Day--select.DayPicker-Day--selected {
    border-radius: 50% !important;
  }

   /* set date color for single selected start or end date */
  .DayPicker-Day.DayPicker-Day--select.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):hover{
    background-color:  ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL ? theme.color['white-smoke'] : theme.color['white']} !important;
  }

  ${(props: StyledCalendarDivProps): string =>
    props.variation !== VARIATION_VERTICAL
      ? `
      .DayPicker:not(.DayPicker--interactionDisabled).DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
          background-color: ${theme.color['white-smoke']};
        }
       .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):not(.DayPicker-Day--fromHover):not(.DayPicker-Day--endHover):hover .DayPicker-Day-value{
        background-color:transparent;
        border-radius: 50% !important;
      }

      .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color:  transparent;
      }

      .DayPicker-Day.DayPicker-Day--hoverRange.DayPicker-Day--endHover .DayPicker-Day-value:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--end):not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
          border-top-right-radius: 50% !important;
          border-bottom-right-radius: 50% !important;
          background-color: ${theme.color['light-gray-7']};
      }
     .DayPicker-Day.DayPicker-Day--hoverRange.DayPicker-Day--fromHover .DayPicker-Day-value:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
          border-top-left-radius: 50% !important;
          border-bottom-left-radius: 50% !important;
          background-color: ${theme.color['light-gray-7']};
      }
      .DayPicker-Day.DayPicker-Day--hoverRange.DayPicker-Day--fromHover .DayPicker-Day-value:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
        background-color: ${theme.color['light-gray-7']} !important;
      }
      `
      : `
       /* on date hover */
      .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):not(.DayPicker-Day--fromHover):not(.DayPicker-Day--endHover):hover .DayPicker-Day-value{
        background-color: ${theme.color['light-gray-7']};
        border-radius: 50% !important;
      }

      .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color:  transparent;
      }
      .DayPicker-Day.DayPicker-Day--hoverRange.DayPicker-Day--endHover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--end):not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
          border-top-right-radius: 50% !important;
          border-bottom-right-radius: 50% !important;
          background-color: ${theme.color['light-gray-7']};
      }
     .DayPicker-Day.DayPicker-Day--hoverRange.DayPicker-Day--fromHover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
          border-top-left-radius: 50% !important;
          border-bottom-left-radius: 50% !important;
          background-color: ${theme.color['light-gray-7']};
      }
      .DayPicker-Day.DayPicker-Day--hoverRange.DayPicker-Day--fromHover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
        background-color: ${theme.color['light-gray-7']} !important;
      }
      `}

  .DayPicker-Day:focus {
     outline: none;
  }

  .DayPicker-Day:focus-visible .DayPicker-Day-value {
     outline: ${toRem(1)} solid ${theme.color.black};
  }

  .DayPicker-Day.DayPicker-Day--hoverRange:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: ${theme.color['light-gray-7']};
  }



  .DayPicker-Day.DayPicker-Day--disabled {
    color: ${theme.color['dark-gray-3']};
    &:hover {
      border: none;
      color: ${theme.color['dark-gray-3']};
    }
  }
   /* END: Date background color ends */

   /* START: Navigation icon starts */
  .DayPicker-NavButton {
    top: ${toRem(21)};
    margin-top:  ${toRem(8)};;
    color: ${theme.color['dark-gray-2']};
  }
  .DayPicker-NavButton:hover,
  .DayPicker-NavButton:focus {
    outline: none;
  }
  .DayPicker-NavButton:focus-visible {
    outline: ${toRem(1)} solid ${theme.color.black};;
  }
  .DayPicker-NavButton--prev {
    left:${(props: StyledCalendarDivProps): string => (props.variation === VARIATION_DESKTOP ? toRem(30) : toRem(-15))};
    margin-right: 0;
    background-image: none;
  }
  .DayPicker-NavButton--next {
    right:${(props: StyledCalendarDivProps): string => (props.variation === VARIATION_DESKTOP ? toRem(35) : toRem(22))};
      @media ${theme.mediaQuery.tablet} {
        right: ${toRem(-10)}
      }
      @media ${theme.mediaQuery.desktop} {
        right: ${toRem(25)}
      }
      @media ${theme.mediaQuery.largeDesktop} {
        right: ${toRem(35)}
      }
     background-image: none;
  }

  .DayPicker-NavButton.DayPicker-NavButton--next:before {
    position: absolute;
    content: '\\e922';
    left: ${toRem(-1)};
    top: ${toRem(-1)};
    height: ${toRem(12)};
    width: ${toRem(8)};
    height: 100%;
    width: 100%;
    font-family: 'MiIcons';
    font-size: ${toRem(23)};
  }

  [dir=rtl] &.DayPicker-NavButton.DayPicker-NavButton--next:before{
    content: '\\e921';
  }

  .DayPicker-NavButton.DayPicker-NavButton--prev:before {
    position: absolute;
    content: '\\e921';
    left: 0;
    top: 0;
    height:  ${toRem(12)};
    width:  ${toRem(8)};
    height: 100%;
    width: 100%;
    font-family: 'MiIcons';
    font-size: ${toRem(23)};
  }

  [dir=rtl] &.DayPicker-NavButton.DayPicker-NavButton--prev:before{
    content: '\\e922';
  }
  /*END:navigation icon ends */

   /*START: month styles start */

   .DayPicker-Months {
    ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL
        ? `
        background-color:  ${theme.color['white-smoke']}
        `
        : ``}
   }
   .DayPicker-Months {
    @media ${theme.mediaQuery.mobileOnly} {

    ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL
        ? `
       height:65vh !important;
        `
        : ``}
    }
      @media ${theme.mediaQuery.desktop} {
        padding: 0 ${toRem(32)}
      }

      @media ${theme.mediaQuery.largeDesktop} {
        padding: 0 ${toRem(40)}
      }
   }
  .DayPicker-Month {
    margin-top: 0;
    width: 100%;

    @media ${theme.mediaQuery.desktop} {
      width: 44.2%;
    }
    @media ${theme.mediaQuery.largeDesktop} {
      width: 45.65%;
    }
  }

  // below media query will target only on safari
  @media not all and (min-resolution:.001dpcm) { @media {
    .DayPicker-Month {
      display: flex;
      flex-direction: column;
      .DayPicker-Weekdays {
        margin-top: 0;
      }
      .DayPicker-Week {
        ${(props: StyledCalendarDivProps): string =>
          props.variation === VARIATION_VERTICAL
            ? ` display: flex;
                justify-content: space-around;
            `
            : ``}
        @media ${theme.mediaQuery.mobileOnly} {
          .DayPicker-Day {
            flex: 1;
          }
        }
      }
    }
  }}

  .DayPicker-Months .DayPicker-Month:nth-child(odd) {
    margin-right: 0;
    @media ${theme.mediaQuery.desktop} {
      margin-right: ${toRem(33)}
    }
    @media ${theme.mediaQuery.largeDesktop} {
      margin-right: ${toRem(29)}
    }
    margin-left: 0;
  }

  .DayPicker-Months .DayPicker-Month:nth-child(even) {
    margin-left: 0;
    @media ${theme.mediaQuery.mobileOnly} {
      border: ${toRem(1)} solid transparent;
    }
    @media ${theme.mediaQuery.desktop} {
      margin-left: ${toRem(33)}
    }
    @media ${theme.mediaQuery.largeDesktop} {
      margin-left: ${toRem(29)}
    }
     margin-right: 0;
  }
  /* month styles ends */

  /* caption styles start */
  .DayPicker-Caption {
    text-align: center;
    ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL
        ? `
        padding-left: ${toRem(28)};
        margin-top: 0;
        margin-bottom:  0;
        padding-bottom:  ${toRem(12)};
        background: ${theme.color['white-smoke']};
        `
        : ``}
  }

  ${(props: StyledCalendarDivProps): string =>
    props.variation === VARIATION_VERTICAL
      ? `
   .DayPicker-Months .DayPicker-Month:first-child {
     .DayPicker-Caption {
       margin-top: ${toRem(25)};
     }
   }
  `
      : ``}

  .DayPicker-Caption div {
    text-transform: none;
    color:  ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL ? theme.color['dark-gray-12'] : theme.color.black};
    font-size: ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL ? toRem(12) : toRem(14)};
    font-weight: 700;
    letter-spacing: ${toRem(1.2)};
    padding-top: ${toRem(25)};
    text-align: ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL ? 'start' : 'center'};
    padding-left: ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL ? toRem(4) : '0'};
    ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL
        ? `
        line-height: ${toRem(16)};
        `
        : ``}
  }
   /* caption styles ends */

  /* weekdays styles start */

  .DayPicker-Weekdays {
    ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL
        ? `
          width: 100%;
          height: ${toRem(38)};
          display: flex;
          align-items: center;
          background-color: ${theme.color.white};
          position: fixed;
          margin-top: 0;
          top: ${toRem(108)};
          padding: 0 ${toRem(24)} !important;
          font-family: ${theme.fontFamily.swiss};
          color: ${theme.color['dark-gray-2']};
          z-index: 1;
        `
        : ``}
  }
  .DayPicker-WeekdaysRow {
     ${(props: StyledCalendarDivProps): string =>
       props.variation === VARIATION_VERTICAL
         ? ` width: 100%;
             display: flex;
             justify-content: space-between;
          `
         : ``}
  }

  .DayPicker-Weekday {
    width: ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL ? 'auto' : toRem(36)} !important;
    /* START: FSD-40976 ISSUE FIX */
    @media ${theme.mediaQuery.largeDesktop} {
      width: ${(props: StyledCalendarDivProps): string =>
        props.variation === VARIATION_VERTICAL ? 'auto' : toRem(44)} !important;
    }
    /* END: FSD-40976 ISSUE FIX */
    ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL ? `margin-left: ${toRem(1)};` : ``}
    &:first-letter {
      font-size: ${(props: StyledCalendarDivProps): string =>
        props.variation === VARIATION_VERTICAL ? toRem(14) : toRem(13)} !important;

    }
  }
  /* START: IE11 fix for weekdays row */
  ${(props: StyledCalendarDivProps): string =>
    props.variation === VARIATION_VERTICAL && theme.mediaQuery.ie11Only
      ? `
          .DayPicker-Weekdays {
            padding: 0 ${toRem(12)} !important;
          }
          .DayPicker-WeekdaysRow {
            table-layout: fixed;
            display: table;
            }
            .DayPicker-Weekday {
              display: table-cell;
              width: 14.28%;
            }
        `
      : ``}
   /* END: IE11 fix for weekdays row */


  .DayPicker-Weekday abbr {
    color:  ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL ? theme.color['dark-gray-2'] : theme.color.black};
    opacity:  ${(props: StyledCalendarDivProps): string => (props.variation === VARIATION_VERTICAL ? '0.5' : '1')};
    font-weight: 800;
    font-size: ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL ? toRem(14) : toRem(13)};
    font-weight: 800;
    letter-spacing:  ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL ? '0' : toRem(1.11)};
    line-height: ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL ? toRem(20) : toRem(16)};
  }

   /* weekdays styles ends */


  .DayPicker-Day {
    position: relative;
    border: none;
    height: ${toRem(44)} !important;
    width:  ${toRem(44)} !important;
    font-size: ${toRem(14)};
    background-color: ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL ? theme.color['white-smoke'] : theme.color.white};
    color: ${theme.color.black};
    @media ${theme.mediaQuery.desktop} {
      width: ${toRem(36)} !important;
      height: ${toRem(36)} !important;;
    }

    @media ${theme.mediaQuery.largeDesktop} {
      width:${toRem(44)} !important;
      height: ${toRem(44)} !important;
    }
    &:hover{
        ${(props: StyledCalendarDivProps): string =>
          props.variation === VARIATION_VERTICAL
            ? `
          background: none;
          border: none;
        `
            : `

          border: none;
        `}
      }
  }
  ${(props: StyledCalendarDivProps): string =>
    props.variation === VARIATION_VERTICAL
      ? `.DayPicker-Day--outside.DayPicker-Day:hover {
    background: ${theme.color['white-smoke']};
  }`
      : ``}

   .DayPicker-Day.DayPicker-Day--select.DayPicker-Day--hoverRange.DayPicker-Day--selected:not(.DayPicker-Day--endFocus):not(.DayPicker-Day--outside):before,
    .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--hoverRange.DayPicker-Day--selected:not(.DayPicker-Day--endFocus):not(.DayPicker-Day--outside):before {
        width: 56%;
        height: 100%;
        content: "";
        background: ${theme.color['white-smoke']};
        position: absolute;
        left: 0;
        z-index: 0;
      }
    .DayPicker-Day.DayPicker-Day--select.DayPicker-Day--endFocus.DayPicker-Day--hoverRange.DayPicker-Day--selected:not(.DayPicker-Day--outside):before {
        width: 56%;
        height: 100%;
        content: "";
        background:  ${theme.color['white-smoke']};
        position: absolute;
        right: 0;
        z-index: 0;
      }

    ${(props: StyledCalendarDivProps): string =>
      props.variation === VARIATION_VERTICAL
        ? `
            .DayPicker-Day .DayPicker-Day-value {
              height: ${toRem(44)};
              width: ${toRem(44)};
              font-weight: 500;
              margin-right: auto;
              margin-left: auto;
            }
            .DayPicker-Day.DayPicker-Day--start:before {
              width: 56%;
              height: 100%;
              content: "";
              background: ${theme.color['white-smoke']};
              position: absolute;
              left: 0;
              z-index: 0;
            }
            .DayPicker-Day.DayPicker-Day--end:before {
              width: 56%;
              height: 100%;
              content: "";
              background: ${theme.color['white-smoke']};
              position: absolute;
              right: 0;
              z-index: 0;
            }
            .DayPicker-Day.DayPicker-Day--hoverRange.DayPicker-Day--endHover:not(.DayPicker-Day--outside):after {
              width: 56%;
              height: 100%;
              content: "";
              background: ${theme.color['light-gray-7']};
              position: absolute;
              left: 0;
              z-index: 0;
              top: 0;
            }
            `
        : `
        .DayPicker-Day.DayPicker-Day--select.DayPicker-Day--hoverRange.DayPicker-Day--selected:not(.DayPicker-Day--endFocus):not(.DayPicker-Day--outside):before,
        .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--hoverRange.DayPicker-Day--selected:not(.DayPicker-Day--endFocus):not(.DayPicker-Day--outside):before {
           background: ${theme.color.white};
          }
        .DayPicker-Day.DayPicker-Day--select.DayPicker-Day--endFocus.DayPicker-Day--hoverRange.DayPicker-Day--selected:not(.DayPicker-Day--outside):before {
          background:  ${theme.color.white};
         }

        `}
  /* END: custom DayPicker styles ends*/
  strong{
    font-weight: 500;
    font-family: ${theme.fontFamily.swiss};
  }

   .Calendar-tooltip {
     font-family: ${theme.fontFamily.swiss};
      display: unset;
      background: ${theme.color.white};
      box-shadow: 0 ${toRem(2)} ${toRem(6)} 0 rgba(0,0,0,0.2);
      color: ${theme.color.black};
      padding: ${toRem(5)} ${toRem(8)};
      font-size: ${toRem(11)};
      border-radius: ${toRem(8)};
      font-weight: 500;
      width: auto;
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -${toRem(5)};
        border-width: ${toRem(5)};
        border-style: solid;
        border-color: ${theme.color.white} transparent transparent transparent;
        }
    }

    .DayPicker-Day-Cell {
      display: block;
      height: 100%;
      width: 100%;
    }

    .DayPicker-Day-month-value,
    .DayPicker-Day-year-value {
      display: none;
      width: 0;
      height: 0;
    }
    ${(props: StyledCalendarDivProps): string =>
      props.disableHover
        ? `
      .DayPicker-Day.DayPicker-Day--hoverRange.DayPicker-Day--endHover .DayPicker-Day-value:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--end):not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
          border-top-right-radius: 50% !important;
          border-bottom-right-radius: 50% !important;
          background-color: transparent !important;
      }

      .DayPicker-Day.DayPicker-Day--hoverRange:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: transparent !important;
      }
      .DayPicker-Day.DayPicker-Day--select.DayPicker-Day--hoverRange.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background: transparent !important;
      }
      `
        : ``}
      ${(props: StyledCalendarDivProps): string =>
        props.disableHover || props.searchFormHoverDisable
          ? `
          .DayPicker-Day.DayPicker-Day--hoverRange:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
            background-color: transparent !important;
          }
          .DayPicker-Day.DayPicker-Day--select.DayPicker-Day--hoverRange.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
     background: transparent !important;
  }

}     
          `
          : ``}
          @media ${theme.mediaQuery.mobileOnly} {
            .DayPicker-Day.DayPicker-Day--select.DayPicker-Day--selected {
              .DayPicker-Day-Cell {
                background: ${theme.color['white-smoke']} !important;
              }
            }
          }
          @media ${theme.mediaQuery.mobileOnly}{
          .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--select.DayPicker-Day--selected {
            .DayPicker-Day-Cell { 
              background: ${theme.color['light-gray-7']} !important;
            }
          }
        }
`}
`;
