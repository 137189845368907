import { MapRefs } from '@uiw/react-baidu-map';
import { COORD_STANDARD, COORD_TYPE, SearchResultDataNode } from './BaiduMap.types';
/**
 * Function to recenter map
 */
export const handleBMapRecenter = (lat: number, lng: number, mapRef?: MapRefs) => {
  if (mapRef?.map && mapRef?.BMap) {
    const { BMap, map } = mapRef;
    const point = new BMap.Point(lng, lat);
    map.panTo(point, {});
  }
};

//function to set boundaries for maps based on the markers latitude and longitude
export const setBMapBounds = (uniqueMarkersState: Array<{ latitude: number; longitude: number }>, mapRef: MapRefs) => {
  if (uniqueMarkersState?.length > 0 && mapRef?.map && mapRef?.BMap) {
    const { map, BMap } = mapRef;
    const uniquePoints: Array<BMap.Point> = [];
    uniqueMarkersState?.forEach((marker: { latitude: number; longitude: number }) => {
      if (marker.latitude && marker.longitude) {
        const point = new BMap.Point(marker.longitude, marker.latitude);
        uniquePoints.push(point);
      }
    });
    map.setViewport(uniquePoints, { margins: [0, 10, 0, 10] });
  }
};

const isCurrencyCNY = (currency: string) => currency?.toUpperCase() === 'CNY';

// creating sets of 10. translate method takes maximum 10 lenght array as input as mentioned
// in baidu docs https://lbsyun.baidu.com/cms/jsapi/reference/jsapi_reference.html#a7b49
// also categorizing based on currency code
const categorizeMarkers = (searchResults: Array<SearchResultDataNode>, isFlexibleDateSearch: boolean) =>
  searchResults.reduce(
    (dataSets, marker, index) => {
      const { currency = '' } = isFlexibleDateSearch
        ? marker.node.basicInformation
        : marker.node.property.basicInformation;
      // mainland China and other places worldwide (including Hong Kong, Macau and Taiwan)
      const category = isCurrencyCNY(currency) ? 'mChinaMarkers' : 'otherMarkers';
      const top = dataSets[category].length - 1;
      const markerData = { ...marker, index };
      if (dataSets[category][top]?.length < 10) dataSets[category][top].push(markerData);
      else dataSets[category].push([markerData]);
      return dataSets;
    },
    { mChinaMarkers: [], otherMarkers: [] } as Record<string, Array<typeof searchResults>>
  );

const formatMarkersState = (
  dataSets: Array<SearchResultDataNode>,
  points: Array<{ lng: number; lat: number }>,
  isFlexibleDateSearch: boolean
) =>
  dataSets.map((marker, i) => {
    if (isFlexibleDateSearch) {
      marker.node.basicInformation.latitude = points[i].lat;
      marker.node.basicInformation.longitude = points[i].lng;
    } else {
      marker.node.property.basicInformation.latitude = points[i].lat;
      marker.node.property.basicInformation.longitude = points[i].lng;
    }
    marker.node.translated = true;
    return marker;
  });

const translateCoords = (
  convertor: BMap.Convertor,
  dataSet: Array<SearchResultDataNode>,
  coordStandard: COORD_STANDARD,
  isFlexibleDateSearch: boolean,
  callback: (data: Array<SearchResultDataNode>) => void
) => {
  const coordinates = dataSet.map(edge => ({
    lng: isFlexibleDateSearch
      ? edge?.node?.basicInformation?.longitude
      : edge?.node?.property?.basicInformation?.longitude,
    lat: isFlexibleDateSearch
      ? edge?.node?.basicInformation?.latitude
      : edge?.node?.property?.basicInformation?.latitude,
  }));
  convertor.translate(coordinates, coordStandard, COORD_STANDARD.BD09, ({ points }) => {
    callback(formatMarkersState(dataSet, points, isFlexibleDateSearch));
  });
};

// CNWEB-3532 - Conversion logic for hotel coordinates from GCJ02/WGS84 to BD09 which is used by Baidu
export const transformToBaiduCoords = async (
  searchResults: Array<SearchResultDataNode>,
  mapRef: MapRefs,
  isFlexibleDateSearch: boolean
) => {
  if (searchResults?.length && !searchResults[0].node?.translated && mapRef?.BMap) {
    const { BMap } = mapRef;
    const convertor = new BMap.Convertor();

    // getting 2 sets of data. one for mainland China other for all other pins.
    const { mChinaMarkers, otherMarkers } = categorizeMarkers(searchResults, isFlexibleDateSearch);

    return Promise.all([
      ...mChinaMarkers.map(
        dataSet =>
          new Promise<typeof searchResults>(resolve =>
            translateCoords(convertor, dataSet, COORD_STANDARD.GCJ02, isFlexibleDateSearch, resolve)
          )
      ),
      ...otherMarkers.map(
        dataSet =>
          new Promise<typeof searchResults>(resolve =>
            translateCoords(convertor, dataSet, COORD_STANDARD.WGS84, isFlexibleDateSearch, resolve)
          )
      ),
    ])
      .then(newSets => newSets.flat())
      .then(newSets => newSets.sort((first, second) => (first?.index || 0) - (second?.index || 0)));
  }
  return searchResults;
};

export const getCoordTypeFromCountryCode = (currency?: string) => {
  if (currency && isCurrencyCNY(currency)) return COORD_TYPE.GCJ02;
  return COORD_TYPE.WGS84;
};
