import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledSection = styled.section`
  padding: 0 ${toRem(18)} 0 ${toRem(40)};
  margin-right: ${toRem(10)};

  @media ${theme.mediaQuery.allTablets} {
    padding-right: ${toRem(18)};
  }

  @media ${theme.mediaQuery.allMobiles} {
    overflow-y: scroll;
    overflow-x: hidden;
    height: fit-content;
    padding: 0 ${toRem(4)} 0 ${toRem(16)};
    margin-right: ${toRem(4)};
    max-height: calc(100vh - 104px);
    height: calc(100vh - 104px);
  }

  button {
    background: none;
    position: absolute;
    top: 2.1875rem;
  }

  .icon-print {
    height: ${toRem(12)};
    width: ${toRem(13.33)};
  }

  .print-text {
    color: ${theme.colors['base-10']};
    font-size: ${toRem(12)};
    line-height: ${toRem(16)};
    margin-left: ${toRem(5)};
  }
`;

export const PrintableSection = styled.section`
  margin-top: ${toRem(16)};
  @media print {
    margin-top: ${toRem(0)};
    .printable-section {
      display: block;
      padding: ${toRem(20)};
      margin-top: ${toRem(0)};
    }

    @page {
      size: auto;
      margin: ${toRem(40)};
    }

    div {
      break-inside: avoid;
    }
  }
  .show-printable-section {
    display: block;
  }
`;
