import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledOfferCardList = styled.section`
  display: flex;
  justify-content: center;
  max-width: ${toRem(1124)};
  margin: 0 auto ${toRem(70)};
  position: relative;
  @media ${theme.mediaQuery.tabletOnly} {
    padding-bottom: ${toRem(48)};
  }

  .splide__pagination {
    bottom: ${toRem(-50)};
    button {
      pointer-events: none;
    }
    li {
      margin: 0 ${toRem(4)};
    }
    &__page.is-active {
      background-color: ${theme.colors['dark-gray-1']};
      height: ${toRem(8)};
      width: ${toRem(8)};
      transform: scale(1);
    }
  }
  .splide {
    @media ${theme.mediaQuery.mobileTablet} {
      display: flex;
      justify-content: center;
    }
  }
  .splide__arrows {
    position: absolute;
    top: 101%;
    width: ${toRem(200)};
    display: flex;
    justify-content: space-between;
    @media ${theme.mediaQuery.tabletOnly} {
      width: ${toRem(235)};
    }

    .splide__arrow {
      background: none;
      top: 102%;
      transform: none;
      &--next {
        margin-top: ${toRem(24)};
      }

      &--prev {
        margin-top: ${toRem(24)};
      }
    }
    @media ${theme.mediaQuery.desktop} {
      position: static;
      width: 104%;
      justify-content: center;
      bottom: 40% !important;
      z-index: 5;
      button {
        opacity: 1;
        box-shadow: 0 ${toRem(2)} ${toRem(2)} 0 rgba(0, 0, 0, 0.3);
        :hover {
          box-shadow: 0 ${toRem(2)} ${toRem(2)} 0 rgba(0, 0, 0, 0.7);
        }
        :disabled {
          visibility: hidden;
        }
      }
      .splide__arrow {
        height: ${toRem(40)};
        width: ${toRem(40)};
        top: 35%;
        .arrow-container {
          height: 100%;
          width: 100%;
          border-radius: ${toRem(20)};
          border: 1px solid ${theme.colors['light-gray-3']};
          background-color: ${theme.colors['base-20']};
          span {
            font-size: ${toRem(24)};
          }
        }

        &--next {
          right: ${toRem(-20)};
        }

        &--prev {
          left: ${toRem(-20)};
        }
      }
    }
  }

  .custom_splide_arrow {
    position: absolute;
    top: 111%;
    display: flex;
    justify-content: space-around;
    right: 42%;
    width: ${toRem(180)};
    button {
      background: none;
      box-shadow: none;
      :hover {
        box-shadow: none;
      }
      :disabled {
        visibility: inherit;
      }
    }
  }

  .image_carousel {
    &.single-image {
      .splide__arrows,
      .splide__pagination {
        display: none;
      }
    }

    .custom_splide_arrow {
      display: none;
    }

    .splide__pagination {
      bottom: ${toRem(10)};
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: ${toRem(20)};
      padding: ${toRem(5)};
      display: table;
      margin: 0 auto;

      &__page.is-active {
        background-color: rgba(255, 255, 255, 1);
      }
      &.splide__pagination__page {
        background-color: rgba(255, 255, 255, 0.5);
      }
      @media ${theme.mediaQuery.mobileTablet} {
        left: auto;
        right: auto;
      }
    }

    .splide__arrows {
      top: calc(50% - 40px);
      .icon-arrow-left {
        font-size: ${toRem(24)};
      }
      .icon-arrow-right {
        font-size: ${toRem(24)};
      }
      .arrow-container {
        background-color: rgba(0, 0, 0, 0.5);
        border: none;
        color: ${theme.colors.white};
      }

      .splide__arrow--prev {
        left: ${toRem(16)};
      }

      .splide__arrow--next {
        right: ${toRem(16)};
      }

      @media ${theme.mediaQuery.mobileTablet} {
        width: -webkit-fill-available;
        .icon-arrow-left {
          font-size: ${toRem(19)};
        }
        .icon-arrow-right {
          font-size: ${toRem(19)};
        }
        .arrow-container {
          height: 100%;
          width: 100%;
          border-radius: 1.25rem;
        }

        .splide__arrow--prev {
          left: ${toRem(28)};
        }

        .splide__arrow--next {
          right: ${toRem(28)};
        }
      }

      @media ${theme.mediaQuery.mobileOnly} {
        width: 100%;
        .splide__arrow--prev {
          left: ${toRem(16)};
        }

        .splide__arrow--next {
          right: ${toRem(16)};
        }
      }
    }
  }
`;
