import { FC } from 'react';

import { TitleProps } from './Title.types';
import { StyledTitle } from './Title.styles';

export const Title: FC<TitleProps> = props => {
  const { text, style, customClassName, type = 'h3' } = props;
  return (
    <StyledTitle
      data-component-name="a-book-Title"
      data-testid="book-Title"
      as={type}
      style={style}
      className={`t-font-weight-m ${customClassName}`}
    >
      {text}
    </StyledTitle>
  );
};
