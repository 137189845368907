import { createContext, useContext } from 'react';
import { SelectedInteractionsContextType } from './index.types';

export const SessionUpdateContext = createContext<SelectedInteractionsContextType>({
  interactionsSelected: {
    // destination: false,
    // checkindate: false,
    // checkoutdate: false,
    // rooms: false,
    // adults: false,
    // children: false,
    // specialRates: false,
    // usePoints: false,
    // flexibleSearchType: false,
    availability: false,
    price: false,
    points: false,
    amenities: false,
    brands: false,
    distance: false,
    transportation: false,
    hotelType: false,
    events: false,
    activities: false,
    states: false,
    city: false,
    pagination: false,
    taxesAndFees: false,
    currencyConversion: false,
    sortBy: false,
    quickFilters: false,
    savedProperties: false,
    listOrMapButton: false,
  },
  setInteractionsSelected: () => {
    //TODO : empty function
  },
});

export const useSessionUpdateContext = () => {
  return useContext(SessionUpdateContext);
};
