import { FC, useState, useEffect, useCallback } from 'react';
import cssClass from 'classnames';

import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { AccordionProps } from './CustomAccordion.types';
import { AccordionWithStyle } from './CustomAccordion.styles';

export const CustomAccordion: FC<AccordionProps> = props => {
  const {
    styles,
    header,
    details,
    customIcon,
    isExpandBtnHidden = false,
    getAccorionStateAtParent,
    isExpanded,
    collapsed,
    click_track_value,
    collapseAccordion,
    disabled = false,
    showHeader = true,
    isPaymentInfo,
    isClickTrack,
  } = props;

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (collapsed !== undefined) {
      setOpen(collapsed || false);
    }
    if (!isExpanded) {
      if (open) {
        setOpen(false);
      }
    }
  }, [collapsed, isExpanded]);

  const onClick = useCallback(() => {
    if (typeof collapseAccordion === 'function') {
      collapseAccordion(!open);
    } else {
      setOpen(prev => !prev);
    }
  }, [open, collapseAccordion]);

  const handleChangeAccordian = (_e: unknown, open: boolean) => {
    if (isExpanded && isPaymentInfo) {
      getAccorionStateAtParent && getAccorionStateAtParent(!open);
    } else {
      getAccorionStateAtParent && getAccorionStateAtParent(open);
    }
  };

  /*Generating css classname from classname module*/
  const accordionClassName = cssClass({ custom_click_track: true });
  const summaryClassName = cssClass({ 'hide-expand-button': isExpandBtnHidden && isExpanded });

  return (
    <AccordionWithStyle
      data-component-name="m-book-CustomAccordion"
      data-testid="book-CustomAccordion"
      disabled={disabled}
      expanded={isExpanded || open}
      elevation={0}
      onChange={handleChangeAccordian}
      className={accordionClassName}
      data-custom_click_track_value={isClickTrack ? click_track_value : !open ? click_track_value : ''}
    >
      {showHeader && (
        <AccordionSummary expandIcon={customIcon ?? <ExpandMore />} className={summaryClassName} onClick={onClick}>
          {header}
        </AccordionSummary>
      )}
      <AccordionDetails className={styles}>{details}</AccordionDetails>
    </AccordionWithStyle>
  );
};

export default CustomAccordion;
