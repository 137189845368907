// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// eslint-disable-next-line import/no-webpack-loader-syntax
import fonts from '!!scss-extract-loader?{"plugins": ["scss-extract-js"]}!../../../../node_modules/@marriott/global-styles/dist/scss/base/variables/_font.scss';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mediaQueries from '!!scss-extract-loader?{"plugins": ["scss-extract-js"]}!../../../../node_modules/@marriott/global-styles/dist/scss/base/variables/_mediaQueries.scss';

import { toRem } from './utils';
import colors from './colors';
import brand from './brand';

export const theme = {
  colors,
  mediaQuery: {
    largeDesktop: `(min-width: ${mediaQueries['viewportXl']})`,
    desktop: `(min-width: ${mediaQueries['viewportL']})`,
    largeTablet: ` screen and (max-width: ${toRem(1024)})`,
    mobileTablet: ` screen and (max-width: ${toRem(991)})`,
    tablet: ` screen and (min-width: ${mediaQueries['viewportM']})`,
    largeMobile: ` screen and (min-width: ${toRem(600)})`,
    // On windows machine its was breaking on 767 to fix that added extra 0.5
    mobileOnly: ` screen and (max-width: ${toRem(767.5)})`,
    mobile: ` screen and (min-width: ${toRem(320)} )`,
    smallMobile: ` screen and (max-width: ${toRem(359)})`,
    ie11Only: ` screen and (-ms-high-contrast: none), (-ms-high-contrast: active)`,
    tabletMax: ` screen and (max-width: ${toRem(1199)})`,
    minDesktop: ` screen and (min-width: ${toRem(1200)})`,
    allTablets: ` screen and (min-width: ${toRem(768)}) and (max-width: ${toRem(1025)}) `,
    allMobiles: ` screen and (max-width: ${toRem(767)}) `,
    smallerTablet: ` screen and (min-width: ${toRem(768)}) and (max-width: ${toRem(1000)}) `,
    largeScreen: ` screen and (min-width: ${toRem(1400)})`,
    tabletOnly: `screen and (min-width: ${toRem(768)}) and (max-width: ${toRem(1200)})`,
    tabletScreen: ` screen and (min-width: ${toRem(768)}) and (max-width: ${toRem(990)}) `,
  },
  fontFamily: {
    icon: 'MarriottIconsNew',
    swiss: fonts['fontFamilySwiss'],
    swissCondensed: fonts['fontSwissCondensed'],
    base: 'Swiss721-BT,Helvetica,Arial,sans-serif',
    title: 'Swiss721-BT-Medium, Helvetica,Arial,sans-serif',
    body: 'Swiss721-BT-Regular, Helvetica,Arial,sans-serif',
    heading: 'Swiss-721-Condense,Helvetica,Arial,sans-serif',
  },
  fonts,
  brand,
};
