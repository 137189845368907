import { Marker } from '@react-google-maps/api';
import React from 'react';

export const MapMarker: React.FC<{
  indexVal: number;
  positionVal: google.maps.LatLng | google.maps.LatLngLiteral;
  clickHandler: () => void;
  labelVal?: string | google.maps.MarkerLabel | undefined;
  iconVal?: string | google.maps.Icon | google.maps.Symbol | undefined;
  clickable?: boolean;
}> = ({ indexVal, positionVal, clickHandler, labelVal, iconVal, clickable = true }) => {
  return (
    <div>
      <Marker
        key={indexVal}
        position={positionVal}
        onClick={clickHandler}
        label={labelVal}
        icon={iconVal}
        clickable={clickable}
      />
    </div>
  );
};
