import { FC } from 'react';
import { InlineMessagesProps, InlineMessagesType } from './InlineMessages.types';
import { StyledInlineMessages } from './InlineMessages.styles';

export const InlineMessages: FC<InlineMessagesProps> = ({
  title,
  messageList,
  children,
  type,
  severity = '1',
  inverse = false,
}) => {
  let customType = type as string;
  if (type === InlineMessagesType.Error) {
    customType = `error-sev${severity}`;
  } else if (type === InlineMessagesType.Info && inverse) {
    customType = 'info inverse';
  }

  return (
    <StyledInlineMessages
      data-component-name="m-ui-library-InlineMessages"
      data-testid="ui-library-InlineMessages"
      className="justify-content-center"
    >
      <div className={`m-message-inline ${customType}`}>
        <div className="m-message-content-wrap">
          <div className="m-message-content">
            <span>{title}</span>
            {messageList?.length ? (
              <ul>
                {messageList.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
            ) : null}
            {children || null}
          </div>
        </div>
      </div>
    </StyledInlineMessages>
  );
};
