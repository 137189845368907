// Imports for external libraries go here.
import { FC, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import dynamic from 'next/dynamic';

// Imports for internal (to the monorepo) libraries go here,
// separated by a blank line from external imports.
// The closer the import is to the file the lower it should be in this list.
import { BaiduMapContainerProps } from './BaiduMap.types';
import { StyledMapContainer } from './BaiduMap.styles';

const BaiduMap = dynamic(() => import('./BaiduMap'), {
  loading: () => <Skeleton containerTestId="baidu-map-skeleton" height="100%" enableAnimation />,
});

// Use named rather than default exports.
export const LazyBaiduMap: FC<BaiduMapContainerProps> = ({ style = {}, ...props }) => {
  const { height = '100%' } = style;
  const [maps, setMaps] = useState<JSX.Element | null>(null);

  // used to load maps after the api is loaded
  useEffect(() => {
    setMaps(<BaiduMap style={style} {...props} />);
  }, []);

  return <StyledMapContainer style={{ height }}>{maps}</StyledMapContainer>;
};

export default LazyBaiduMap;
