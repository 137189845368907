import { constants } from '../constants';

const { BREAKPOINT_TABLET, BREAKPOINT_DESKTOP } = constants;
export const useGetBreakpoint = (): string | undefined => {
  if (typeof window !== 'undefined' && window?.screen && window?.screen?.width) {
    if (window.innerWidth > BREAKPOINT_DESKTOP) {
      return 'desktop';
    } else if (window.innerWidth > BREAKPOINT_TABLET) {
      return 'tablet';
    } else {
      return 'mobile';
    }
  } else {
    return undefined;
  }
};

export const getClassForBreakpoint = (
  breakpoint: string | undefined,
  desktopClass: string,
  tabletClass: string,
  mobileClass: string
): string => {
  if (!breakpoint) return desktopClass;
  if (desktopClass.indexOf(' ') > -1 || tabletClass.indexOf(' ') > -1 || mobileClass.indexOf(' ') > -1) {
    console.error(
      'Improper use of getClassForBreakpoint utility: To avoid mixed-purpose class assignment logic, please pass one class per breakpoint.'
    );
  }
  if (breakpoint === 'tablet') {
    return tabletClass;
  } else if (breakpoint === 'mobile') {
    return mobileClass;
  } else return desktopClass;
};
