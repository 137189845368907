import dynamic from 'next/dynamic';
import { EditableComponent } from '@adobe/aem-react-editable-components';

import { MessageProps } from './ErrorMessage.types';

export const ErrorMessage = dynamic<MessageProps>(() => import('./ErrorMessage').then(module => module.ErrorMessage));

export const ErrorMessageConfig = {
  emptyLabel: 'errorMessage',
  isEmpty: false,
  resourceType: `mcom-book-spa/components/foundation/errormessage`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ErrorMessageEditable = (props: any) => {
  return (
    <EditableComponent config={ErrorMessageConfig} {...props}>
      <ErrorMessage {...props} />
    </EditableComponent>
  );
};
