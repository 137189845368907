import { RoomImagesType } from '../RateCard/RateCard.types';
import { OffersCardProps } from '../../molecules/OfferCard/OfferCard.types';

export const parseHotelPhotos = (hotelImages: RoomImagesType[]) => {
  let imageArray: OffersCardProps[] = [];
  if (hotelImages.length) {
    imageArray = hotelImages.map(_item => {
      const formattedData = {
        offerCardImage: {
          damPath: _item.metadata?.[0].imageFile,
          dynamic: false,
          renditions: [
            {
              renditionPath: _item.metadata?.[0].imageFile,
            },
          ],
        },
        offersId: _item.altText,
      };
      return formattedData;
    });
  }
  return imageArray;
};
