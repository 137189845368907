import styled from 'styled-components';
import { toRem } from '../../styles';

export const StyledToggleControlsList = styled.div`
  .show-more-btn {
    background: none;
    .show-more-icon {
      vertical-align: top;
    }
    .icon-dropdown-down:before,
    .icon-dropdown-up:before {
      font-size: ${toRem(22)};
    }
  }
`;
