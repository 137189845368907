import { numberWithCommas, convertPriceFormat } from '../../utils';
import { upsellDataProps } from './UpSellMiniCard.types';
import { constants } from '../../constants';

export const getUpsellCardData = (props: upsellDataProps) => {
  const {
    type,
    snaStatus,
    auth,
    snaTitle,
    snaSignInMsg,
    requiredSNA,
    availableSNA,
    description,
    upsellPendingTitle,
    upsellPendingDescription,
    upsellSnaConfirmedDescription,
    upsellSnaConfirmedTitle,
    upsellSnaDeniedDescription,
    roomUpgradeData,
    title,
    upgradeTo,
  } = props;
  const {
    SNA_TYPE,
    ROOM_UPGRADE_TYPE,
    SNA_STATUS_CODE_REQUEST,
    SNA_STATUS_CODE_PENDING,
    SNA_STATUS_CODE_GUARANTEED,
    SNA_STATUS_CODE_DENIED,
  } = constants;

  const snaType = type === SNA_TYPE;
  const roomUpgradeType = type === ROOM_UPGRADE_TYPE;

  const snaPendingState = snaStatus === SNA_STATUS_CODE_PENDING;
  const snaRequestedState = snaStatus === SNA_STATUS_CODE_REQUEST;
  const snaDeniedState = snaStatus === SNA_STATUS_CODE_DENIED;
  const snaGuranteedState = snaStatus === SNA_STATUS_CODE_GUARANTEED;

  const snaContainer =
    (snaPendingState || snaRequestedState || snaGuranteedState || snaDeniedState || !auth) && snaType;
  const upsellContainer = (auth && snaType) || roomUpgradeType;

  let snaUpdatedTitle = '';
  let snaUpdatedDescription = '';
  let updatedTitle = title;
  let upsellPrice = '';
  let updatedDescription = snaType
    ? description?.replace('{requiredSna}', `${requiredSNA}`)?.replace('{availableSna}', `${availableSNA}`)
    : description;

  function safeFailValue(value: string | undefined) {
    return value ?? '';
  }

  if (snaType) {
    if (!auth) {
      snaUpdatedTitle = safeFailValue(snaTitle);
      snaUpdatedDescription = safeFailValue(snaSignInMsg);
    } else if (snaGuranteedState) {
      snaUpdatedTitle = safeFailValue(upsellSnaConfirmedTitle);
      snaUpdatedDescription = safeFailValue(upsellSnaConfirmedDescription);
    } else if (snaDeniedState) {
      snaUpdatedTitle = safeFailValue(upsellSnaDeniedDescription);
    } else if (snaPendingState || snaRequestedState) {
      snaUpdatedDescription = safeFailValue(upsellPendingDescription);
      snaUpdatedTitle = safeFailValue(upsellPendingTitle);
    }
  }

  if (roomUpgradeType) {
    const {
      propertyHeading,
      propertyDescription,
      upsellValue,
      upsellValueDecimalPoint,
      roomPriceValue,
      roomPriceValueDecimalPoint,
    } = roomUpgradeData || {};
    const titleText = propertyHeading + (propertyDescription ? ',' + propertyDescription : '');
    updatedTitle = upgradeTo?.includes('{X}') ? upgradeTo?.replace('{X}', `${titleText}`) : `${upgradeTo} ${titleText}`;
    updatedDescription = propertyDescription;
    const upgradePrice = convertPriceFormat(upsellValue, upsellValueDecimalPoint);
    const roomPrice = convertPriceFormat(roomPriceValue, roomPriceValueDecimalPoint) || 0;
    upsellPrice = upgradePrice ? numberWithCommas(Number((upgradePrice - roomPrice).toFixed(0))) : '0';
  }

  return {
    snaContainer,
    snaUpdatedTitle,
    snaUpdatedDescription,
    upsellContainer,
    snaPendingState,
    snaRequestedState,
    snaGuranteedState,
    snaDeniedState,
    updatedDescription,
    updatedTitle,
    upsellPrice,
  };
};
