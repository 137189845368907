import { FC } from 'react';
import clsx from 'clsx';

import { StyledRadioButtonDiv } from './index.styles';
import { RadioButtonProps } from './index.types';

export const RadioButton: FC<RadioButtonProps> = ({
  className,
  radiobuttonId,
  radiobuttonName,
  radiobuttonLabel,
  checked,
  value,
  onChange,
  disabled,
  setTabIndex,
  customClickClass,
  isRichText,
  labelClassName,
  tabIndexForInput,
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      onChange && onChange(e);
    }
  };
  return (
    <StyledRadioButtonDiv onKeyDown={handleKeyDown} className={className}>
      <input
        id={radiobuttonId}
        type="radio"
        data-testid={`${radiobuttonName}-testId`}
        name={radiobuttonName}
        onChange={onChange}
        checked={checked}
        value={value}
        disabled={disabled}
        aria-checked={checked}
        tabIndex={tabIndexForInput}
        onKeyDown={handleKeyDown}
        className={customClickClass}
        aria-hidden
        {...props}
      />

      <label htmlFor={radiobuttonId} className={clsx(labelClassName ? labelClassName : '')}>
        {isRichText ? (
          <a tabIndex={setTabIndex} className={clsx('keydown-anchor', disabled ? 'disabled' : '')} role="link">
            {radiobuttonLabel}
          </a>
        ) : (
          radiobuttonLabel
        )}
      </label>
    </StyledRadioButtonDiv>
  );
};
export default RadioButton;
