import styled from 'styled-components';
import { toRem, theme } from '../../styles';

export const StyledComp = styled.section`
  flex: 1;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: ${toRem(8)};
  background-color: ${theme.colors.background};
  padding: ${toRem(28)} ${toRem(17)} ${toRem(27)} ${toRem(16)};
  @media ${theme.mediaQuery.minDesktop} {
    height: ${toRem(134)};
  }

  @media ${theme.mediaQuery.allMobiles} {
    padding: ${toRem(20)} ${toRem(12)} ${toRem(20)} ${toRem(20)};
    .hotelLogo {
      margin-right: ${toRem(32)};
    }
    .headerContent {
      max-width: ${toRem(198)};
    }
  }

  @media ${theme.mediaQuery.allTablets} {
    padding: ${toRem(20)} ${toRem(2)} ${toRem(20)} ${toRem(20)};
    .hotelLogo {
      margin-right: ${toRem(25)};
    }
    .headerContent {
      max-width: ${toRem(307)};
    }
  }

  a {
    padding: 0;
    :hover {
      text-decoration: underline;
      text-decoration-thickness: ${toRem(1)};
    }
  }

  .hotelLogo {
    margin-right: ${toRem(48)};
    &.brand-logo-xe::before {
      font-size: ${toRem(48.25)};
      line-height: ${toRem(53)};
    }
  }

  .headerContent {
    display: flex;
    flex-direction: column;
    max-width: ${toRem(394)};

    .title {
      color: ${theme.colors['dark-gray-1']};
      font-size: ${theme.fonts['fontL']};
      font-weight: ${theme.fonts['fontWeightRegular']};
      letter-spacing: 0;
      line-height: ${theme.fonts['lineHeightM']};
      @media ${theme.mediaQuery.allMobiles} {
        font-size: ${theme.fonts['fontL']};
      }
    }

    .addressWrapper {
      display: inline-flex;
      position: relative;

      .icon-margin {
        margin-top: ${toRem(-4)};
        margin-right: ${toRem(6)};
        font-size: ${toRem(14)};
      }

      .address {
        color: ${theme.colors['dark-gray-3']};
        font-size: ${theme.fonts['fontXs']};
        letter-spacing: 0;
        line-height: ${theme.fonts['lineHeightXs']};
      }
    }
  }
`;

export const StyledSection = styled.section`
  @media ${theme.mediaQuery.desktop} {
    padding-left: 0;
  }
  display: flex;
  min-height: ${toRem(140)};
  align-items: center;
  .property-details {
    padding: ${toRem(24)} ${toRem(8)};
  }
  .hotel-name {
    font-size: ${toRem(22)};
    line-height: ${toRem(26)};
    text-transform: capitalize;
  }
  .logo-wrapper {
    max-width: ${toRem(200)};
    margin-right: ${toRem(72)};
    .hotel-logo {
      height: ${toRem(80)};
      width: ${toRem(120)};
      object-fit: contain;
    }
  }
  .hqvLink {
    cursor: pointer;
  }
  .address,
  .option-label,
  .phone,
  .reviews,
  .stars {
    font-family: ${theme.fontFamily.swiss};
    font-size: ${theme.fonts['fontS']};
  }
  .address,
  .phone,
  .icon-height {
    line-height: ${toRem(16)};
    font-size: ${theme.fonts['fontS']};
  }
  a:hover {
    font-weight: ${theme.fonts['fontWeightRegular']};
  }
  @media ${theme.mediaQuery.tablet} {
    .property-details {
      padding: ${toRem(32)} 0;
    }
    .address,
    .phone,
    .reviews,
    .option-label,
    .stars {
      line-height: ${toRem(20)};
    }
  }
  .disableLinks {
    pointer-events: none;
  }
`;

export const StyledLoadingSection = styled.section`
  min-height: 126px;
  .icon-loader {
    div {
      margin-bottom: ${toRem(10)};
    }
  }
  @media ${theme.mediaQuery.mobileOnly} {
    min-height: ${toRem(140)};
    .icon-loader {
      display: none;
    }
  }
`;
