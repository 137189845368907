/* eslint-disable @typescript-eslint/no-explicit-any */
import { InfoWindow } from '@react-google-maps/api';
import React from 'react';

export const MiniInfoWindow: React.FC<{
  positionVal: google.maps.LatLng | google.maps.LatLngLiteral | undefined;
  optionsVal?: google.maps.InfoWindowOptions | undefined;
  childrenVal?: any;
}> = ({ positionVal, optionsVal, childrenVal }) => {
  return (
    <div>
      <InfoWindow position={positionVal} options={optionsVal} children={childrenVal}></InfoWindow>
    </div>
  );
};
