/* eslint-disable jsx-a11y/anchor-has-content */
import React, { FC, useCallback, useEffect, useState } from 'react';
import cssClass from 'classnames';

import { LoadingSkeleton as Skeleton } from '../../atoms';
import { usePageContext, pageStoreComponentID } from '../PageContext';
import { BrandedHotelHeader } from './BrandedHotelHeader';
import gqlQuery from './queries/hotelHeaderDetail.graphql';
import gqlQueryAuth from './queries/hotelHeaderDetailAuth.graphql';
import gqlMutation from './mutations/savePropertiesToProfile.graphql';
import { findSavedProperty, parseHotelHeaderData, getPropertyLogo, recentlyViewed } from './helper';
import { SessionDataMap } from './HotelHeaderV2.schema';
import { HotelHeaderProps, ComponentProps } from './HotelHeaderV2.types';
import { StyledComp, StyledLoadingSection } from './HotelHeaderV2.styles';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';

import {
  parseContactDetails,
  isMobileViewPort,
  getWindowSession,
  useQueryFetch,
  useMutationFetch,
  transformResponse,
  gql,
  addSubDirectoryPrefix,
  getHotelDetailURL,
  updateBookPrintModalUXLStatus,
} from '../../utils';
import { constants, GQL_OPT_NAME, URL_CONSTANTS, LOADER_CONFIGS, ENV_CONSTANTS } from '../../constants';

const { HotelHeaderImageLoadingConfig, HotelHeaderDetailsLoadingConfig } = LOADER_CONFIGS;

export const HotelHeaderLoader = (props: { inModal: boolean }) => {
  return props.inModal ? (
    <div data-component-name="o-book-HotelHeader-Loading">
      <Skeleton count={1} height={isMobileViewPort() ? 152 : 126} />
    </div>
  ) : (
    <StyledLoadingSection data-component-name="o-HotelHeaderV2" className="d-flex align-items-center container">
      <div className="mr-3 icon-loader">
        <Skeleton customSkeletonConfig={HotelHeaderImageLoadingConfig} style={{ borderRadius: '25px' }} />
      </div>
      <div className="py-5">
        <Skeleton customSkeletonConfig={HotelHeaderDetailsLoadingConfig} style={{ borderRadius: '25px' }} />
      </div>
    </StyledLoadingSection>
  );
};

export const HotelHeader: FC<ComponentProps> = props => {
  const initialState = {
    country: '',
    city: '',
    phone: '',
    postalCode: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    stateProvince: '',
    brandCode: '',
    hotelName: '',
    seoNickname: '',
    userData: {},
    imageUrl: '',
  };

  const {
    isInEditor,
    inModal,
    reviews,
    componentId,
    fileReference,
    hotelDetailsLabel,
    pageType,
    savedLabel,
    saveLabel,
    phoneText,
    starsOutOfText,
    inCancelModal,
    reviewUrl,
    overridePropertyCountry,
    overridePropertyCountryText,
    disableHotelHeaderAndReviewLinks,
    disableHotelReviewLinks,
    reviewsLabel,
    isAccountPage = false,
    disableReviews = false,
  } = props;
  const {
    REMEMBERED_STATE,
    RLM_PAGE,
    ACCESS_TYPE,
    REMEMBER_ME_STATE,
    RLM_AND_POSTBOOKING_PAGES,
    BRAND_ICON_HH_CONFIG,
  } = constants;
  const { PROPERTY_URL, PROPERTY_LOCATION_URL, HOTEL_MAP_POST_URL } = URL_CONSTANTS;
  const [hotelHeaderData, setHotelHeaderData] = useState<HotelHeaderProps>(initialState);
  const [uniqueHotelLogo, setUniqueHotelLogo] = useState('');
  const GET_HOTEL_HEADER = gql`
    ${gqlQuery}
  `;
  const GET_HOTEL_HEADER_AUTH = gql`
    ${gqlQueryAuth}
  `;
  const { hotelHeaderAuth, hotelHeader } = GQL_OPT_NAME;
  const UPDATE_CUSTOMER_MUTATION = gql`
    ${gqlMutation}
  `;
  const mutationOperationName = GQL_OPT_NAME.customerSavedProperties;

  const { updateComponentState = () => '' } = usePageContext(state => state.hotelHeader);

  const sessionObject = getWindowSession();
  const isAuth = sessionObject?.authenticated;
  const isRlmPage = pageType === RLM_PAGE;
  const isRlmOrPostBookingPage = (!!pageType && RLM_AND_POSTBOOKING_PAGES.includes(pageType)) || isAccountPage;
  const { envVarsObject } = useClientEnvVarsStore();
  const imageDomain = envVarsObject?.[ENV_CONSTANTS.uxlImageCacheDomain] ?? URL_CONSTANTS.CACHE_MARRIOTT_URL;

  const updateHotelHeaderContext = useCallback(
    (dataObj: Record<string, string | number | boolean>) => {
      if (isRlmPage) {
        sessionStorage.removeItem('insuranceData');
        sessionStorage.removeItem('insurancePurchased');
        sessionStorage.removeItem('insuranceShown');
      }
      updateComponentState({
        componentName: pageStoreComponentID.HOTEL_HEADER,
        newState: {
          data: dataObj,
        },
      });
    },
    [updateComponentState]
  );

  const { tripsXRequestedByHeader, locale, consumerID, userState, isMax } = transformResponse(
    sessionObject,
    SessionDataMap
  );

  let { propertyId = '' } = transformResponse(sessionObject, SessionDataMap);

  const urlParams = new URLSearchParams(window.location.search);
  const propertyCode = urlParams?.get('propertyCode') || '';
  if (propertyCode) {
    propertyId = propertyCode;
  }

  const isRememberedUser = userState?.toLowerCase() === REMEMBERED_STATE && consumerID;

  const [getHotelHeaderData, { loading, data, refetch }] = useQueryFetch(
    (isAuth || isRememberedUser) && isRlmOrPostBookingPage ? GET_HOTEL_HEADER_AUTH : GET_HOTEL_HEADER,
    (isAuth || isRememberedUser) && isRlmOrPostBookingPage ? hotelHeaderAuth : hotelHeader,
    tripsXRequestedByHeader,
    false,
    undefined,
    isRememberedUser ? { [ACCESS_TYPE]: REMEMBER_ME_STATE } : {},
    () => updateBookPrintModalUXLStatus(hotelHeader),
    () => updateBookPrintModalUXLStatus(hotelHeader)
  );
  const [savePropertyToProfile] = useMutationFetch(
    UPDATE_CUSTOMER_MUTATION,
    mutationOperationName,
    '',
    isRememberedUser ? { [ACCESS_TYPE]: REMEMBER_ME_STATE } : {}
  );

  let isLoading = loading || !data;

  if (isInEditor) {
    isLoading = false;
  }

  const isSession = sessionObject?.cacheData?.data?.AriesReservation;
  const showSaveProperty = isAuth || isRememberedUser;
  const containerClassName = cssClass({ hide: isLoading, 'top-container': inCancelModal });

  const {
    brandCode,
    hotelName,
    city,
    currency,
    seoNickname,
    phone,
    stateProvince,
    country,
    postalCode,
    addressLine1,
    addressLine2,
    addressLine3,
    latitude,
    longitude,
    stars,
    hotelReviews,
    isJPLocale,
    suppressReviews,
    showReviews,
    userData,
    imageUrl,
  } = hotelHeaderData;

  const brandSize = BRAND_ICON_HH_CONFIG[brandCode?.toLowerCase() as keyof typeof BRAND_ICON_HH_CONFIG];

  const hotelAddress = parseContactDetails({
    city,
    stateProvince,
    country,
    postalCode,
    addressLine1,
    addressLine2,
    addressLine3,
    isJPLocale,
    inModal,
    overridePropertyCountry,
    overridePropertyCountryText,
    locale,
  });

  useEffect(() => {
    if (isSession) {
      getHotelHeaderData({
        variables: {
          propertyId: propertyId || propertyCode,
          customerId: consumerID ?? '',
        },
      });
    }
  }, [isSession, propertyId, getHotelHeaderData]);

  useEffect(() => {
    if (data?.property) {
      parseHotelHeaderData({ data }, setHotelHeaderData, locale, imageDomain);
      updateHotelHeaderContext(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //Method to update unique hotel logo in Property Header
  useEffect(() => {
    if (propertyId && !inModal) {
      getPropertyLogo(propertyId, setUniqueHotelLogo, locale);
    }
  }, [propertyId, inModal, getPropertyLogo]);

  const sessionData = transformResponse(sessionObject, SessionDataMap);

  const recentlyViewData = {
    name: hotelName || '',
    address: hotelAddress || '',
    marshaCode: sessionData?.propertyId || '',
    imageUrl: imageUrl || '',
    latitude: latitude || '',
    longitude: longitude || '',
    marshaBrandCode: brandCode || '',
    numberOfReviewsCount: hotelReviews?.['count'] || '',
    starsCount: stars?.['count'] || '',
    postalAddress: postalCode || '',
    state: stateProvince || '',
    country: country || '',
    createdAt: new Date().toISOString(),
  };

  useEffect(() => {
    if (isRlmPage) {
      recentlyViewed(recentlyViewData, sessionData);
    }
  }, [recentlyViewData]);

  const handleSaveHotel = async (
    propertyList: { [id: string]: string }[],
    setHotelSave: React.Dispatch<React.SetStateAction<{ addClass: string; showSavedLabel: boolean }>>,
    hotelSave: Record<string, string | boolean>
  ) => {
    if (userData?.savedProperties && propertyList?.length <= 20) {
      const index = findSavedProperty(propertyId, propertyList);
      index > -1 ? propertyList.splice(index, 1) : propertyList.push({ id: propertyId.toUpperCase() });
      let status = constants.ERROR;
      const variables = {
        input: {
          id: consumerID,
          savedProperties: propertyList,
          revisionToken: userData?.revisionToken,
        },
      };
      status = await savePropertyToProfile({
        variables: variables,
      })
        .then(result => {
          return result.data.updateCustomerSavedProperties.status[0].code;
        })
        .catch(err => console.log(err, 'Error while updating properties'));
      if (status?.toLowerCase() === constants.SUCCESS) {
        refetch({
          variables: {
            propertyId: propertyId,
            customerId: consumerID ?? '',
          },
        });
        if (index > -1) {
          setHotelSave({ ...hotelSave, addClass: 'icon-heart-outline', showSavedLabel: false });
        } else {
          setHotelSave({ ...hotelSave, addClass: 'icon-heart-fill', showSavedLabel: true });
        }
      }
    }
  };

  const brandedHeaderData = {
    componentId,
    seoNickname,
    hotelName,
    fileReference,
    latitude,
    longitude,
    currency,
    hotelAddress,
    phone,
    showReviews,
    suppressReviews,
    stars,
    hotelReviews,
    reviews,
    hotelDetailsLabel,
    savedLabel,
    saveLabel,
    brandCode,
    propertyId,
    userData,
    showSaveProperty,
    consumerID,
    imageUrl,
    phoneText,
    starsOutOfText,
    reviewUrl,
    isRlmOrPostBookingPage,
    isMax,
    disableHotelHeaderAndReviewLinks,
    disableHotelReviewLinks,
    locale,
    reviewsLabel,
    disableReviews,
    city,
  };

  let hotelDetailUrl = getHotelDetailURL({
    brandCode,
    locale,
    marshaCode: propertyId,
    location: city?.toLocaleLowerCase(),
  });
  hotelDetailUrl = hotelDetailUrl || addSubDirectoryPrefix(`${PROPERTY_URL}${seoNickname}`);

  hotelDetailUrl = disableHotelHeaderAndReviewLinks ? '' : hotelDetailUrl;
  return (
    <>
      {isLoading && <HotelHeaderLoader inModal={Boolean(inModal)} />}
      {inModal ? (
        <StyledComp
          data-component-name="o-book-HotelHeader"
          className={containerClassName}
          brandCode={brandCode}
          brandSize={brandSize}
        >
          <span className={`t-brand-logo brand-logo-${brandCode} hotelLogo t-brand-logo-s`}></span>
          <div className="headerContent">
            <a
              href={hotelDetailUrl}
              aria-label={hotelDetailUrl}
              className={disableHotelHeaderAndReviewLinks ? 'disableLinks' : ''}
            >
              <span className="title">{hotelName}</span>
            </a>
            {/*  Show hotel header data only when hotel header data is received */}
            {hotelName && (
              <div className="addressWrapper">
                <a
                  className="icon-location icon-margin"
                  href={addSubDirectoryPrefix(
                    `${PROPERTY_LOCATION_URL}${seoNickname}?${HOTEL_MAP_POST_URL}${seoNickname}`
                  )}
                  aria-label={hotelAddress}
                />
                <p className="address">{hotelAddress}</p>
              </div>
            )}
          </div>
        </StyledComp>
      ) : (
        <BrandedHotelHeader
          {...brandedHeaderData}
          uniqueHotelLogo={uniqueHotelLogo}
          isLoading={isLoading}
          handleClick={handleSaveHotel}
        />
      )}
    </>
  );
};
export default React.memo(HotelHeader);
