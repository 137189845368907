import styled from 'styled-components';
import { theme } from '../../styles-3.0/theme';
import { toRem } from '../../styles-3.0/utils';

export const StyledPaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .shop-pagination {
    list-style-type: none;
    padding-left: ${toRem(0)};
    display: flex;
    align-items: center;
    justify-content: center;

    .shop-pagination-item {
      margin: 0 ${toRem(1)};
      padding: ${toRem(4)} ${toRem(8)};
      border-radius: 50%;
      border: ${toRem(1)} solid transparent;
      height: ${toRem(24)};
      width: ${toRem(24)};
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        border-color: ${theme.color['dark-gray-13']};
      }
      &.active {
        background: ${theme.color['dark-gray-13']};
        color: ${theme.color.white};
        &:hover {
          border: none;
          cursor: default;
        }
      }
    }
    .shop-pagination-item.shop-pagination-dots {
      width: ${toRem(14)} !important;
      margin: 0;
      padding: 0;
      &:hover {
        border: none;
        cursor: default;
      }
    }
    .shop-pagination-arrow {
      font-size: ${toRem(27)};
      width: ${toRem(24)};
      height: ${toRem(24)};
      padding: ${toRem(2)} 0;
      &.disabled {
        pointer-events: none;
        color: ${theme.color['close-silver']};
      }

      .icon-arrow-right,
      .icon-arrow-left {
        &:before {
          width: ${toRem(24)};
          height: ${toRem(24)};
        }
      }
    }
  }
`;
