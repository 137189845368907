/* eslint-disable @typescript-eslint/no-empty-function */
import { StateCreator } from 'zustand';
import { createAppStore } from '../zustand';

/** Used for managing enrollment-related API success/failure messages. Message is set from OneClickJoinForm component.
 * The message to be displayed can be accessed by other teams such as Book, Contactless etc. 
 *  Returns a new object spreading the initial state and providing a functional implementation for setMessage
 * Usage:
 *   const { message, setMessage } = useEnrollmentStore(state => state);
     setMessage('enrollment_success'); // Example key for success message from OneClickJoinForm component
 */

export interface EnrollmentMessageState {
  message: {
    key: string;
  };
  tripIdEnrolled: string;
  setMessage: (key: string) => void;
  setTripIdEnrolled: (id: string) => void;
}

export const initialState: EnrollmentMessageState = {
  message: {
    key: '',
  },
  tripIdEnrolled: '',
  setMessage: () => {},
  setTripIdEnrolled: () => '',
};

const enrollmentStore: StateCreator<EnrollmentMessageState> = set => {
  return {
    ...initialState,
    setMessage: (key: string) => {
      set({ message: { key } });
    },
    setTripIdEnrolled: (id: string) => {
      set({ tripIdEnrolled: id });
    },
  };
};

export const useEnrollmentStore = createAppStore(enrollmentStore, {
  usePersistentStore: true,
  persistentStoreName: 'EnrollmentMessageState',
});
