/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ComponentProps } from './HotelGalleryTitle.types';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import SmHotelGalleryTitleComp from './HotelGalleryTitleComp';

export const SmHotelGalleryTitle: React.FC<ComponentProps> = pageProps => {
  return (
    <React.Suspense>
      <SmHotelGalleryTitleComp {...pageProps} />
    </React.Suspense>
  );
};

export const HotelGalleryTitleConfig = {
  emptyLabel: 'SmHotelGalleryTitle',
  isEmpty: false,
  resourceType: `mi-aem-shop-spa/components/content/hotelgallerytitle`,
};

export const HotelGalleryTitleEditable = (props: any) =>
  props.cqPath.includes('experiencefragment') ? (
    <SmHotelGalleryTitle {...props} />
  ) : (
    <EditableComponent config={HotelGalleryTitleConfig} {...props}>
      <SmHotelGalleryTitle {...props} />
    </EditableComponent>
  );
