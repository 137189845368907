import { FC, Fragment } from 'react';
import {
  Map,
  APILoader,
  NavigationControl,
  PanoramaControl,
  MapTypeControl,
  useMapContext,
  Provider,
} from '@uiw/react-baidu-map';
import { BaiduMapProps, MapComponentsProps } from './BaiduMap.types';

const NAVIGATION_OFFSET = { width: 10, height: 10 };
const MAP_TYPE_OFFSET = { width: 10, height: 10 };

const MapComponents: FC<MapComponentsProps> = ({
  navigationProps,
  mapControlProps,
  children,
  mapTypeControl = true,
  navigationControl = true,
  panoramaControl = false,
}) => {
  const { BMap } = useMapContext();
  // Checking if the map api is loaded by the APILoader
  if (BMap)
    return (
      <>
        <MapTypeControl
          visiable={mapTypeControl}
          anchor={BMAP_ANCHOR_TOP_LEFT}
          offset={MAP_TYPE_OFFSET}
          {...mapControlProps}
        />
        {panoramaControl && <PanoramaControl />}
        <NavigationControl
          anchor={BMAP_ANCHOR_BOTTOM_RIGHT}
          type={BMAP_NAVIGATION_CONTROL_ZOOM}
          offset={NAVIGATION_OFFSET}
          visiable={navigationControl}
          {...navigationProps}
        />
        {children}
      </>
    );
  return <Fragment />;
};

const BaiduMap: FC<BaiduMapProps> = ({ apiKey, center, zoom, style, getMapRef, mapProps, ...props }) => (
  <APILoader akay={apiKey}>
    <Provider>
      <>
        <Map
          ref={props => {
            if (getMapRef && props?.map) {
              getMapRef(props);
            }
          }}
          center={center}
          zoom={zoom}
          style={style}
          enableScrollWheelZoom
          enableDoubleClickZoom
          enablePinchToZoom
          enableDragging
          enableAutoResize
          {...mapProps}
        >
          <MapComponents {...props} />
        </Map>
      </>
    </Provider>
  </APILoader>
);

export default BaiduMap;
