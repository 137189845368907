import styled from 'styled-components';
import { toRem, theme } from '../../styles';

export const StyledTabs = styled.ul`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0;
  background-color: ${theme.colors['light-gray-2']};
  margin-bottom: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    padding: ${toRem(16)} ${toRem(24)} ${toRem(8)} ${toRem(24)};
    cursor: pointer;
    background-color: ${theme.colors['light-gray-2']};
    list-style-type: none;

    .title {
      font-size: ${theme.fonts['fontM']};
      color: ${theme.colors['dark-gray-12']};
      line-height: ${toRem(20)};
      font-weight: ${theme.fonts['fontWeightMedium']};
    }

    .subTitle {
      font-size: ${theme.fonts['fontXs']};
      color: ${theme.colors['dark-gray-12']};
      line-height: ${toRem(20)};
    }

    &:hover,
    &:focus-visible {
      .title {
        color: ${theme.colors['gray']};
      }
      background: linear-gradient(
          270deg,
          ${theme.colors['base-20']} 0%,
          rgba(255, 255, 255, 0) 42.81%,
          rgba(255, 255, 255, 0) 75.52%,
          ${theme.colors['base-20']} 100%
        ),
        linear-gradient(180deg, ${theme.colors['base-20']} 0%, ${theme.colors['light-gray-2']} 100%);
      border-radius: ${toRem(4)} ${toRem(4)} 0 0;
      background-color: ${theme.colors['base-20']};
      box-shadow: 0 ${toRem(4)} ${toRem(20)} 0 rgba(0, 0, 0, 0.07);
    }
  }

  .selected {
    .title {
      color: ${theme.colors['dark-gray-12']};
    }
    background: ${theme.colors['base-20']} !important;
    box-shadow: 0 0 0 0 !important;
    border-radius: ${toRem(4)} ${toRem(4)} 0 0;
    border-top: ${toRem(3)} solid black;
  }

  @media ${theme.mediaQuery.allTablets} {
    li {
      padding: ${toRem(16)} ${toRem(16)} ${toRem(8)} ${toRem(16)};
    }
  }
  @media ${theme.mediaQuery.allMobiles} {
    li {
      padding: ${toRem(16)} ${toRem(16)} ${toRem(8)} ${toRem(16)};
    }
  }
`;
