import axios from 'axios';

import { RequestProps } from './dataLayer/index.types';
import { DEFAULT_ACCEPT_LANGUAGE } from './constants';

export const interceptor = async (req: { headers: RequestProps; originalUrl: string }, resolvedUrl: string) => {
  const { log } = global.loggerInstance('Interceptor');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const headers: any = {
    Cookie: req.headers['cookie'],
    // Adding process.env['NEXT_PUBLIC_URL'] for localhost and x-host for higher envs.
    'X-Host': req.headers['x-host'] ?? process.env['NEXT_PUBLIC_URL'],
    'Content-Type': 'application/json',
    // do not split/update accept language as interceptor should identify correct localeKey, when splitting locale from string could result in localeKey response from interceptor which is not available in AEM
    'Accept-Language': req.headers['accept-language'] ?? DEFAULT_ACCEPT_LANGUAGE,
  };
  log.debug(`Preprocessor Interceptor headers: ${JSON.stringify(headers)}`);
  const url = `${process.env['INTERCEPTOR_HOST_URL']}${process.env['INTERCEPTOR_URL']}`;
  log.debug(`Preprocessor Interceptor API url: ${url}`);
  log.debug(`Preprocessor Interceptor req.originalUrl: ${req.originalUrl}`);
  log.debug(`Preprocessor Interceptor resolvedUrl: ${resolvedUrl}`);
  const originalRequestPath = req.originalUrl ?? resolvedUrl;
  log.debug(`Preprocessor Interceptor originalRequestPath: ${originalRequestPath}`);
  const appId = process.env['NEXT_PUBLIC_APP_INTERCEPTOR_ID']; // add extra optionl params for account
  log.debug(`Preprocessor Interceptor appID: ${appId}`);

  let response;
  try {
    response = await axios.post(
      url,
      {
        originalRequestPath,
        ...(appId ? { appId: appId } : {}), // add extra optionl params for account
      },
      { headers }
    );
  } catch (e) {
    log.error(`Interceptor API call failed: ${e}`);
    throw new Error('Interceptor call failed');
  }
  log.debug('Interceptor data loaded');
  return response;
};
