import dynamic from 'next/dynamic';

import { CancelAllRoomsCTAProps, CancelRoomModalProps } from './CancelAllRoomsCTA.types';
// import { CancelRoomModalLoading } from './CancelRoomModalLoader';

export const CancelAllRoomsCTA = dynamic<CancelAllRoomsCTAProps>(() =>
  import('./CancelAllRoomsCTA').then(module => module.CancelAllRoomsCTA)
);

export const CancelRoomModalDynamic = dynamic<CancelRoomModalProps>(
  () => import('./CancelRoomModal').then(module => module.CancelRoomModal),
  {
    loading: () => <p>Loading...</p>,
  }
);
