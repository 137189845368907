// Imports for external libraries go here.
import React, { FC } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import { MapComponentProps } from './MapComponent.types';
import { StyledMapComponent } from './MapComponent.styles';

export const MapComponent: FC<MapComponentProps> = ({ googleMapsApiKey, mapId, mapHeight, ...props }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleMapsApiKey,
    mapIds: [mapId],
  });
  const elemHeight = { height: mapHeight };

  return isLoaded ? (
    <StyledMapComponent
      data-component-name="a-ui-library-MapComponent"
      data-testid="ui-library-MapComponent"
      style={elemHeight}
    >
      <GoogleMap {...props}></GoogleMap>
    </StyledMapComponent>
  ) : (
    <></>
  );
};
