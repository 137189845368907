import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, theme } from '@marriott/mi-ui-library-shop';

export const Styled = styled.div`
  ${rtl`
.overview-and-location{
  max-width: ${toRem(1440)};
  margin: auto;
}
.location-box__accordian:focus-visible:not(:active) {
  outline: -webkit-focus-ring-color auto 1px;
  }
  .location-box__accordian:focus { outline: none;}
  .hotel-overview-container {
    padding: ${toRem(0)} ${toRem(158)} ${toRem(24)};
    .overview-title-container {
      padding: 0 ${toRem(117)};
      text-align: center;
      margin-bottom: ${toRem(80)};
      border-bottom: ${toRem(1.5)} solid #eee;
      .overview-title {
        font-size: ${toRem(30)};
        line-height: ${toRem(32)};
        font-family: ${theme.fonts['fontFamilySwiss']};
        font-weight: ${theme.fonts['fontWeightMedium']};
        margin-bottom: ${toRem(8)};
      }
      .overview-description {
        font-size: ${toRem(16)};
        line-height: ${toRem(20)};
        font-family: ${theme.fonts['fontFamilySwiss']};
        padding-bottom: ${toRem(37)};
        margin-bottom: 0;
      }
    }
  }

  @media only ${theme.mediaQuery.allTablets} {
    .hotel-overview-container {
      padding: ${toRem(15)} 0 0 0;
      .overview-title-container {
        padding: 0 ${toRem(97)};
        .overview-title {
          font-size: ${toRem(22)};
        }
        .overview-description {
          font-size: ${toRem(14)};
          padding-bottom: ${toRem(27)};
        }
      }
    }
  }

  @media only ${theme.mediaQuery.allMobiles} {
    .location-box-header {
      padding-top: ${toRem(10)};
    }
    .hotel-overview-container {
      padding: 0;
      .overview-title-container {
        padding: 0 ${toRem(16)};
        margin-top: ${toRem(-45)};
        border-bottom: 0;
        .overview-title {
          font-size: ${toRem(22)};
          text-align: left;
          padding-top: ${toRem(20)};
          border-top: ${toRem(1.5)} solid ${theme.color.gallery};
        }
        .overview-description {
          font-size: ${toRem(14)};
          text-align: left;
          padding-bottom: ${toRem(22)};
          border-bottom: ${toRem(1.5)} solid ${theme.color.gallery};
        }
      }
      .location-box {
        display: block;
        padding: 0;

        &__container {
          border-bottom: 1px solid #d5d5d5;
          padding-bottom: 30px;
          display: inline-flex;
          width: 100%;
        }

        &__col1 {
          width: 40%;
          display: inline-block;
        }

        &__col2 {
          width: 60%;
          display: inline-block;
        }

        #location-box__map-box-mobile {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          background-color: #d5d5d5;
        }
        #location-box__map-box-desktop {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          background-color: #d5d5d5;
        }
        .desktop-map {
          display: block;
        }
        .mobile-map {
          display: none;
        }

        &__desc {
          width: 60%;
        }

        &__tel {
          color: #000;
          text-decoration: none;
        }

        &__contactNumber {
          text-decoration: none;
        }

        &__cn-box {
          text-decoration: none;
          margin-top: 15px;
          display: block;
        }

        &__accordian {
          margin: 20px;
          width: 80%;
          border-bottom: 1px solid #d5d5d5;
          padding-bottom: 15px;
        }

        &__location-name {
          width: 60%;
          display: inline-block;
        }

        &__accordian-container {
          .location-box__accordian:last-child {
            border: none;
          }
        }

        &__col2-mobile {
          display: none;
        }

        .panel {
          padding: 0 18px;
          background-color: white;
          overflow: hidden;
        }

        .showaccordian {
          display: none;
        }

        .labels {
          color: #e93d76;
          font-size: 14px;
          max-width: 200px;
          max-height: 60px;
          text-shadow: 1px 1px #fff;

          .label-container {
            .label-text {
              position: absolute;
              top: 13px;
              left: 13px;
              text-shadow: none;
              display: inline-block;
              font-size: 5px;
              width: 6px;
              white-space: pre-wrap;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      .hotel-overview-container {
        padding: 0;
        .location-box {
          padding: 5% 5% 0;

          &__container {
            display: block;
          }

          &__location-name {
            width: 80%;
          }

          &__col1 {
            width: 100%;
          }

          &__col2 {
            display: none;
          }

          &__col2-mobile {
            display: block;
            width: 100%;
            height: 213px;
          }
          .desktop-map {
            display: none;
          }
          .mobile-map {
            display: block;
          }
        }
      }
    }
  }`}
`;
