/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { inspect } from 'util';

import { DATALAYER_CONSTANT, MOCK_SESSION_ID, PPV5_URL_FORMAT } from '../constants';
import { canUseDOM, getProcessEnvs } from '../helpers';
import { DataLayerProps, elmType, indexType, KeysRequest, RequestProps } from './index.types';
import { logger } from '../logger';

const { DATALAYER_ENDPOINT } = process.env;

export const getDataLayer = (
  req: {
    cookies?: { sessionID: string; UserIdToken: string };
    headers: RequestProps;
    query?: Record<string, unknown>;
    originalUrl: string;
  },
  resolvedUrl: string,
  locale: string,
  query?: Record<string, string>,
  ignoreMVPOffers = false
): Promise<DataLayerProps> => {
  return new Promise((resolve, reject) => {
    // logger initialization
    const { log, pLog } = canUseDOM
      ? logger({ requestID: '', sessionID: '' })('Adobe DataLayer')
      : global.loggerInstance('Datalayer');

    const headers = canUseDOM ? ({} as RequestProps) : req.headers;
    const cookies = canUseDOM ? document.cookie : req.headers.cookie;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const adobeDataLayer: Array<{ [key: string]: any }> = [];

    // decrypting cookies and merging with header object
    decodeURIComponent(cookies)
      .split(';')
      .forEach(item => {
        const keyValuePair = item.split('=');
        if (keyValuePair.length >= 2) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: Unreachable code error
          headers[keyValuePair[0].trim().toLowerCase()] = keyValuePair[1];
        }
      });

    // extracting all the required keys from header
    const {
      sessionid,
      useridtoken,
      // authorization,
      mi_site,
      mi_cn_site,
      mi_visitor,
      remembermeflag = false,
      remembermeuserid = '',
      remembermealternateid = '',
      dtm_token = '',
      dtm_user_id = '',
    } = headers;
    const ENV_PLATFORM = 'env_platform';

    pLog.log(JSON.stringify({ mi_site, mi_visitor, mi_cn_site }));
    const getPageURI = resolvedUrl ? resolvedUrl.split('?')[0] : '';
    const absolutePageURL = `${getPageURI}.mi`;
    const localeKeyValue = locale;
    const channelValue = process.env['NEXT_PUBLIC_DATALAYER_SITENAME']?.split('.')[0];
    const requestBody: any = {
      context: {
        localeKey: localeKeyValue ?? 'en_US',
        programFlag: '',
        siteName: process.env['NEXT_PUBLIC_DATALAYER_SITENAME'],
        mobileAuthEnabled: 'false',
        productSiteId: process.env['NEXT_PUBLIC_DATALAYER_PRODUCT'],
        channel: channelValue,
        pageContent: [],
        pageURI: getPageURI,
        Referer: req?.headers?.referer,
        absolutePageURL: absolutePageURL,
        applicationName: process.env['NEXT_PUBLIC_DATALAYER_APPLICATION'],
        products: process.env['NEXT_PUBLIC_DATALAYER_PRODUCT'],
        template: process.env['NEXT_PUBLIC_DATALAYER_TEMPLATE'],
        seoQueryParams: req.query ?? query,
        ignoreMVPOffers,
      },
      sessionToken: req?.cookies?.sessionID ?? MOCK_SESSION_ID,
      sourceURI: absolutePageURL,
      variation: process.env['NEXT_PUBLIC_DATALAYER_VARIATION'],
    };

    if (absolutePageURL?.toLowerCase()?.includes(PPV5_URL_FORMAT)) {
      requestBody.context['ppv5SeoQueryParams'] = req.query ?? query;
    }

    const headersKey: Record<string, string | undefined> = {};

    // creating headers for datalayer call
    DATALAYER_CONSTANT.headerKeys.forEach(name => {
      if (name && headers[name as KeysRequest['keys']]) {
        headersKey[name] = headers[name as KeysRequest['keys']];
      }
    });

    // modifying headers
    const getHeaders = (): string => {
      let cookieValues = '';
      if (mi_site) {
        cookieValues = cookieValues + ` MI_SITE=${mi_site};`;
      }
      if (mi_visitor) {
        cookieValues = cookieValues + ` MI_VISITOR=${mi_visitor};`;
      }
      if (mi_cn_site) {
        cookieValues = cookieValues + ` MI_CN_SITE=${mi_cn_site};`;
      }
      if (remembermeuserid) {
        cookieValues = cookieValues + ` RememberMeUserID=${encodeURIComponent(remembermeuserid)};`;
      }
      if (remembermealternateid) {
        cookieValues = cookieValues + ` RememberMeAlternateID=${encodeURIComponent(remembermealternateid)};`;
      }
      if (useridtoken) {
        cookieValues = cookieValues + ` UserIdToken=${encodeURIComponent(useridtoken)};`;
      }
      if (dtm_token) {
        cookieValues = cookieValues + ` dtm_token=${encodeURIComponent(dtm_token)};`;
      }
      if (dtm_user_id) {
        cookieValues = cookieValues + ` dtm_user_id=${encodeURIComponent(dtm_user_id)};`;
      }
      return cookieValues;
    };

    // API call
    const endpoint = DATALAYER_ENDPOINT ?? '';

    const perfApiStartTime = new Date().getTime();
    pLog.log(`Before API call:`, perfApiStartTime, new Date().getTime());
    let response;
    log.debug(`API call to ${endpoint}: ${JSON.stringify(requestBody)}`);
    const reqHeaders = {
      cookie: `sessionID=${sessionid}; RememberMeFlag=${remembermeflag}; ${getHeaders()}`,
      ...headersKey,
    };
    log.debug(`Headers: ${JSON.stringify(reqHeaders)}`);
    axios
      .post(endpoint, requestBody, {
        headers: {
          // please make sure these coookies are in single line. Else there will be `TypeError [ERR_INVALID_CHAR]`
          cookie: `sessionID=${sessionid}; RememberMeFlag=${remembermeflag}; ${getHeaders()}`,
          // ...authorizationHeader,
          ...headersKey,
        },
      })
      .then(({ data, status }) => {
        log.debug(`Aries API Call Success: ${status}`);
        response = data.component?.data;
        pLog.log(`Aries API Response Time:`, perfApiStartTime, new Date().getTime());

        log.debug(`Response Data: ${JSON.stringify(data)}`);

        const dataLayerObj: indexType = {};
        let mvpOffers = '';
        const envProcessURL = canUseDOM
          ? getProcessEnvs()['PHOENIX_ENV_PLATFORM']
          : process.env['PHOENIX_ENV_PLATFORM'];

        /*
  manipulating some of the response data
  */
        if (typeof response.dataProperties !== 'undefined') {
          response.dataProperties.forEach((elm: elmType) => {
            if (elm.value && elm.value !== 'undefined' && elm.value !== null) {
              dataLayerObj[elm.key] = elm.value;
            }
          });
        }

        // To set platform like "AEM-prod, AEM-stage, AEM-dev"
        if (envProcessURL) {
          dataLayerObj[ENV_PLATFORM] = envProcessURL;
        }

        if (typeof dataLayerObj['mr_nights_to_renew'] === 'string') {
          const nightsToRenew = Number(dataLayerObj['mr_nights_to_renew']);
          if (!isNaN(nightsToRenew)) {
            dataLayerObj['mr_nights_to_renew'] = nightsToRenew;
          }
        }

        if (typeof dataLayerObj['mr_nights_to_next_level'] === 'string') {
          const nightsNextLevel = Number(dataLayerObj['mr_nights_to_next_level']);
          if (!isNaN(nightsNextLevel)) {
            dataLayerObj['mr_nights_to_next_level'] = nightsNextLevel;
          }
        }

        // To identify OTA reservations in check-in flow
        if (typeof dataLayerObj['page_url_query_string'] === 'string') {
          const otaParam = dataLayerObj['page_url_query_string']
            .split('&')
            .filter(val => val.includes('OTA='))
            .join();
          if (otaParam) {
            dataLayerObj['ota_flag'] = otaParam.substring(otaParam.lastIndexOf('=') + 1);
          }
        }

        // Start: manually adding these item if Maken does not provide
        if (typeof dataLayerObj['page_data_layer_ready'] === 'undefined') {
          dataLayerObj['page_data_layer_ready'] = 'false';
        }

        if (typeof dataLayerObj['request_id'] === 'undefined') {
          dataLayerObj['request_id'] = headersKey['x-request-id'] ?? '';
        }
        // End

        if (typeof response.mvpOfferList !== 'undefined') {
          mvpOffers = JSON.stringify(response.mvpOfferList);
        }

        // logging
        const isLogError = canUseDOM
          ? getProcessEnvs()['LOG_DATA_LAYER'] === 'true'
          : process.env['LOG_DATA_LAYER'] === 'true';
        if (isLogError) {
          log.debug(
            `Datalayer endpoint: ${endpoint}, datalayer env. value ${
              canUseDOM ? getProcessEnvs()['DATALAYER_ENDPOINT'] : process.env['DATALAYER_ENDPOINT']
            }`
          );
          log.debug(`Datalayer request headers: ${inspect(headers, { breakLength: Infinity })}`);
          log.debug(`Datalayer axios request headers: ${inspect(headersKey, { breakLength: Infinity })}`);
          log.debug(`Datalayer axios post data: ${inspect(requestBody, { breakLength: Infinity })}`);
          if (sessionid !== dataLayerObj['sessionId']) {
            log.error('Session IDs are different');
          } else {
            log.error('Session IDs are same');
          }
          log.debug(`Datalayer request sessionID= ${sessionid}, datalayerSessionId= ${dataLayerObj['sessionId']}`);
          if (!useridtoken && dataLayerObj['memState'] === 'authenticated') {
            log.error('User token does not exist but data layer is authenticated');
          }
          log.debug(`Datalayer request User ID= ${useridtoken}, memState= ${dataLayerObj['memState']}`);
          if (!useridtoken && dataLayerObj['mr_id_alternate']) {
            log.error('mr_id_alternate found but no user id token');
          }
        }

        adobeDataLayer.push(dataLayerObj);

        resolve({
          data: adobeDataLayer,
          mvpOffersData: mvpOffers,
        });
      })
      .catch(err => {
        log.error(`Aries API Failed: ${err?.response?.status} [message]: ${err?.response?.data}`);
        reject(err);
      });
  });
};
