export const DESTINATION_STATE = 'destination';
export const CALENDAR_STATE = 'calendar';
export const ROOM_GUEST_STATE = 'roomGuest';
export const SPECIAL_RATES_STATE = 'specialRates';
export const FORM_STATE = 'form';
export const CLOSED_STATE = 'closed';
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const SPECIAL_RATES_DONE_CLICK_TRACK = 'Search Form|Special Rates done button|internal';
export const SPECIAL_RATES_CLOSE_CLICK_TRACK = 'Search Form|CORP code clear button|internal';
export const SPECIAL_RATES_EDIT_CLICK_TRACK = 'Search Form|CORP code edit button|internal';

export const RateRequestTypeOptions = {
  // used to apply discount in api call
  s9r: 'CLUSTER',
  corp: 'CLUSTER',
  gov: 'GOV',
  aaa: 'AAA',
};
export const hardCodedAllLocale = {
  de: 'de-DE',
  en: 'en-US',
  ja: 'ja-JP',
  fr: 'fr-FR',
};
