import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledSection = styled.section`
  font-family: ${theme.fontFamily.swiss};
  font-weight: ${theme.fonts['fontWeightRegular']};
  font-size: ${toRem(14)};
  margin-top: ${toRem(32)};
  p {
    margin: 0;
  }
  color: ${theme.colors['base-10']};
  letter-spacing: 0;
  line-height: ${toRem(20)};
  a {
    border-bottom: 1px solid ${theme.colors['base-10']};
  }
`;
