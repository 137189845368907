/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef, useCallback, ReactNode } from 'react';
import clsx from 'clsx';
import Glide from '@glidejs/glide';
import { Heading, Button, Link, Image, Icon, Types, Text, Modal, eventUtil } from '@marriott/mi-ui-library';
import { useGetBreakpoint, getClassForBreakpoint } from '../../../src/modules/utils/helpers';
import { PropertyCardProps, ImageProps } from './PropertyCard.types';
import { StyledPropertyCard, StyledOverlayContainer } from './PropertyCard.styles';
import { addSubDirectoryPrefix } from '../../utils/src/subDirectoryFunctions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { isEmpty } from '@marriott/shared/mi-helper-utils';
import { Tooltip } from '@marriott/mi-ui-library-shop';
import { formatNumberByLocale } from '../../utils/src/utils';

const { size, tags } = Types;

export interface StyledOverlayProps {
  iconPath?: boolean;
}

export const PropertyCard: React.FC<PropertyCardProps> = ({
  titleDetails,
  eyebrow,
  reviewsDetails,
  description,
  tertiaryLinkDetails,
  footerLinkDetails,
  mandatoryFeeObj,
  images = [],
  ribbonDetails,
  favIconDetails,
  brandDetails,
  carouselDotsPosition,
  overlayText = '',
  overlayIcon = '',
  showIcon,
  iconTitle,
  address,
  secondaryLinkDetails,
  glideClass,
  innerGlideList,
  slideIndex,
  isMBOP = false,
  loader,
  addScrimToImage,
  isVerticalCard,
  showMobileVariantOnly = false,
  trackingProperties = {
    clickTrack: false,
  },
  propertyName,
  styleClass = '',
  variation = '',
  showDescriptionAlways = false,
  iconPathDining,
  iconPathBar,
}) => {
  const glideRef = useRef<HTMLDivElement | any>(null);
  const [breakpoint, setBreakpoint] = useState<string | undefined>(useGetBreakpoint());
  const WrapperElement = overlayText !== '' ? StyledOverlayContainer : 'div';
  const WrapperElementProps: StyledOverlayProps = overlayText !== '' ? { iconPath: !!overlayIcon } : {};
  const isTaxesAndFeesEnabled = false;
  const {
    amountPlusMandatoryFee,
    mandatoryFee,
    mandatoryFeeDescription,
    title,
    feeDescription1,
    feeDescription2,
    viewRatesLabel,
  } = mandatoryFeeObj ?? {};

  const isMandatoryFeesEnabled =
    mandatoryFee && mandatoryFee.value
      ? parseInt(mandatoryFee?.value) !== 0 && mandatoryFeeDescription?.length && mandatoryFeeDescription?.length > 0
        ? true
        : false
      : false;
  const toolTipList = mandatoryFeeDescription?.length ? mandatoryFeeDescription[0].text?.split('|') : []; // ['test test', 'sample'];

  useEffect(() => {
    eventUtil.on('openTooltipPopup', data => {
      setPopupOpenState(data);
    });
  }, []);

  // Reset breakpoint on window resize.
  useEffect(() => {
    if (showMobileVariantOnly) {
      setBreakpoint('mobile');
      return;
    } else {
      const handleResize = () => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        setBreakpoint(useGetBreakpoint());
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [showMobileVariantOnly]);
  const [popupOpenState, setPopupOpenState] = useState(false);
  const handleTooltipClick = (event: any): void => {
    if (event.key === 'Enter' || event.type === 'click' || event.keyCode === 13) {
      setPopupOpenState(true);
    }
  };
  const toolTipTitle = title; //'Surcharge For Ordinance Cost';

  const styleforbuttonMobileTab = breakpoint === 'mobile' || breakpoint === 'tablet' ? 'book-button' : '';
  // let isLAFeeProperty = false;
  useEffect(() => {
    if (images?.length > 1) {
      const isRtl = document.body.getAttribute('dir') === 'rtl';
      const lang = document.body.getAttribute('lang');
      const isArabic = lang === 'ar-AE' || lang === 'he-IL';
      const direction = isRtl && isArabic ? 'rtl' : 'ltr';

      const glide = new Glide(glideClass ? `.${glideClass}` : glideRef.current, {
        type: 'slider',
        gap: 0,
        startAt: 0,
        focusAt: 'center',
        autoplay: false,
        bound: false,
        rewind: true,
        direction: direction,
        dragThreshold: false,
        //  swipeThreshold: false,
        perView: 1,
        peek: {
          before: 0,
          after: 0,
        },
        keyboard: false,
      });

      const glideOnRun = () => {
        const index = Number(
          glideRef.current?.querySelector('.glide__slide--active')?.getAttribute('data-glide-index')
        );

        if (index !== null && images) {
          const nextIndex = (index + 1) % images.length;
          const prevIndex = (index - 1 + images.length) % images.length;
          const nextImg = glideRef.current?.querySelector(`[data-glide-index='${nextIndex}'] img`);
          const prevImg = glideRef.current?.querySelector(`[data-glide-index='${prevIndex}'] img`);

          if (nextImg) nextImg.setAttribute('loading', 'eager');
          if (prevImg) prevImg.setAttribute('loading', 'eager');
        }
      };

      glide.on('run.after', glideOnRun);
      if (innerGlideList && slideIndex) {
        innerGlideList.current[slideIndex - 1] = glide;
      } else {
        glide.mount();
      }
    }
  }, [innerGlideList, glideClass, images, slideIndex]);

  const renderImages = useCallback(
    (images: ImageProps[]) => {
      return (
        <div className="img-carousel__container">
          <div className={`glide ${glideClass}`} ref={glideRef}>
            <div className="glide__track" data-glide-el="track">
              <ul className="glide__slides d-flex m-0 p-0">
                {images?.map((img: ImageProps, index: number) => (
                  <li className="glide__slide" key={`property-card-list-item-${index}`} data-glide-index={index}>
                    <Image
                      title=""
                      defaultImageURL={`${img.defaultImageUrl}`}
                      altText={`Slide ${index + 1}`}
                      customClass="property-card__img-container--img"
                      loading={index === 0 || index === 1 || index === images.length - 1 ? 'eager' : 'lazy'}
                    />
                    {addScrimToImage && <div className="property-card__img-container--scrim-component" />}
                  </li>
                ))}
              </ul>

              <div className="glide__arrows" data-glide-el="controls">
                <button
                  className={`glide__arrow glide__arrow--left left-arrow carousal-arrow`}
                  data-glide-dir="<"
                  aria-label="Previous"
                >
                  <span className="icon-arrow-left"></span>
                </button>
              </div>
              <div
                data-glide-el="controls[nav]"
                className={clsx(
                  'glide-bullet-container d-flex align-items-center px-2',
                  carouselDotsPosition ? 'center' : ''
                )}
              >
                {images?.map((_: any, index: number) => (
                  <button
                    className="glide__bullet carousal-bullet p-0"
                    aria-label={`glide__bullet-${index}`}
                    data-glide-dir={`=${index}`}
                    key={`propery-card-bullet-${index}`}
                  ></button>
                ))}
              </div>

              <div className="glide__arrows" data-glide-el="controls">
                <button
                  className={`glide__arrow glide__arrow--right right-arrow carousal-arrow`}
                  data-glide-dir=">"
                  aria-label="Next"
                >
                  <span className="icon-arrow-right"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    },
    [glideClass]
  );
  // const currentViewIsMobile = CommonUtils.isOnlyMobileView();
  // const currentViewIsTab = CommonUtils.isTabletView();
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onCancel = () => {
    setPopupOpenState(false);
  };

  return (
    <StyledPropertyCard
      data-component-name="m-shop-PropertyCard"
      data-testid="shop-PropertyCard"
      className={clsx(
        'property-card container d-flex p-0 mt-3',
        isVerticalCard ? 'flex-column' : 'flex-row',
        getClassForBreakpoint(breakpoint, 'flex-nowrap', 'flex-nowrap', 'flex-column flex-nowrap'),
        showMobileVariantOnly ? 'mobile-card flex-wrap' : '',
        styleClass
      )}
      variation={variation}
    >
      {popupOpenState && (
        <Modal
          secondaryClassName={clsx('popup-width', 'custom-position')}
          show={true}
          handleBlur={true}
          popupOpenState={popupOpenState}
          setPopupOpenState={setPopupOpenState}
          role="dialog"
          labelledBy="tooltip-modal"
          modalId="tooltip-modal"
        >
          <div className="tooltip-popup-wrapper">
            <Modal.Header
              className="custom-header-font"
              labelText={toolTipTitle ?? ''}
              popupHeaderOnCLoseFunc={onCancel}
              popupCloseClassName="popup-close-button"
            ></Modal.Header>
            <Modal.Body className="custom-heights">
              <div className="tooltip-main-wrapper" tabIndex={0} role="document">
                {feeDescription1}:
                <div className="tooltip-main-content">
                  <ul className="custom-ul-props">
                    {toolTipList?.map((item: ReactNode) => {
                      return <li className="custom-list">{item}</li>;
                    })}
                  </ul>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer
              setFeatureSelected={undefined}
              viewRateLabel={viewRatesLabel}
              viewRatesURL={footerLinkDetails?.href || ''}
              className="color-scheme3 rate-container row-reverse"
              fromToolTip={true}
            ></Modal.Footer>
          </div>
        </Modal>
      )}
      <div className={clsx('property-card__img-container')}>
        {loader ? (
          <div className=" property-card__skeleton d-flex d-md-block d-lg-flex flex-column align-items-center align-items-lg-start offset-md-1 offset-lg-0">
            <div className="skeleton-loader mx-sm-auto mx-md-0 property-card__skeleton__img"></div>
          </div>
        ) : (
          <>
            {favIconDetails && Object.keys(favIconDetails).length && (
              <div
                tabIndex={0}
                className="inverse icon-container custom_click_track property-card__img-container--favorite d-flex align-items-center justify-content-center"
                onClick={favIconDetails?.favCallback}
                onKeyDown={favIconDetails?.favCallback}
                {...{ custom_click_track_value: favIconDetails?.clickTrackVal || '' }}
                aria-label={favIconDetails?.label || ''}
                role="button"
              >
                <span className={`icon-heart-outline icon-inverse` + (favIconDetails?.isFavorite ? ` d-none` : ``)} />
                <span className={`icon-heart-fill icon-inverse` + (favIconDetails?.isFavorite ? `` : ` d-none`)} />
              </div>
            )}
            {ribbonDetails && Object.keys(ribbonDetails).length && (
              <div className="property-card__img-container--ribbon">
                <div className="ribbons-container">
                  <div
                    className={clsx(
                      'ribbon d-flex flex-row align-items-center justify-content-center',
                      ribbonDetails?.ribbonClass || ''
                    )}
                  >
                    {' '}
                    <div className="t-label-alt-xs ribbon-text ml-1">{ribbonDetails?.ribbonText || ''}</div>{' '}
                  </div>
                </div>
              </div>
            )}
            {isMBOP
              ? brandDetails &&
                Object.keys(brandDetails).length &&
                brandDetails?.hasUniquePropertyLogo === false && (
                  <div className="property-card__img-container--logo d-flex align-items-end">
                    <Icon
                      iconClass={clsx(
                        `icon-inverse brand-logo-container brand-logo-${brandDetails?.brandId?.toUpperCase()}`
                      )}
                      ariaLabel={brandDetails?.label || ''}
                    ></Icon>
                  </div>
                )
              : brandDetails &&
                Object.keys(brandDetails).length && (
                  <div className="property-card__img-container--logo d-flex align-items-end">
                    <Icon
                      iconClass={clsx(
                        `icon-inverse brand-logo-container brand-logo-${brandDetails?.brandId?.toUpperCase()}`
                      )}
                      ariaLabel={brandDetails?.label || ''}
                    ></Icon>
                  </div>
                )}

            {images?.length === 1 ? (
              <WrapperElement {...WrapperElementProps}>
                <Image
                  title=""
                  defaultImageURL={`${images[0].defaultImageUrl}`}
                  altText={`Slide 1`}
                  customClass="property-card__img-container--img"
                  loading={'eager'}
                />
                <div className="overlay-content d-flex justify-content-center align-items-center flex-column">
                  {!!overlayIcon && <span className={clsx('icon-inverse mb-4 d-inline-flex', `${overlayIcon}`)}></span>}
                  {!!overlayText && <div className="t-subtitle-inverse-l">{overlayText}</div>}
                </div>
              </WrapperElement>
            ) : (
              renderImages(images)
            )}
          </>
        )}
      </div>

      <div
        className={clsx(
          'property-card__details-container d-flex flex-column justify-content-between',
          isVerticalCard ? 'verticalCardHeight' : '',
          getClassForBreakpoint(
            breakpoint,
            'pb-3 pt-4',
            isVerticalCard ? 'pb-3 pt-4' : 'pb-3 pt-3',
            isVerticalCard ? 'pb-3 pt-3' : variation === 'culinaryView' ? 'pb-0 pt-2 ' : 'pb-2 pt-2'
          )
        )}
      >
        <div
          className={clsx(
            'property-card__details-container_top',
            variation === 'culinaryView' ? 'px-md-5 px-lg-5 pt-md-5 pt-lg-3 pt-xl-4 pb-0' : ''
          )}
        >
          {loader ? (
            <div className=" property-card__skeleton d-flex d-md-block d-lg-flex flex-column align-items-center align-items-lg-start offset-md-1 offset-lg-1">
              <div className="skeleton-loader mt-2 mx-sm-auto mx-md-0 property-card__skeleton__desc"></div>
            </div>
          ) : (
            <>
              {eyebrow && (
                <Heading
                  variation={Types.headingType.subtitle}
                  titleText={eyebrow}
                  element={Types.tags.h3}
                  customClass={getClassForBreakpoint(
                    breakpoint,
                    variation === 'culinaryView'
                      ? 'property-card__eyebrow mb-0 t-overline-normal pl-3 pr-4'
                      : 'property-card__eyebrow pb-2 mb-0 t-overline-normal pl-3 pr-4',
                    isVerticalCard
                      ? variation === 'culinaryView'
                        ? 'property-card__eyebrow mb-0 t-overline-normal pl-3 pr-4'
                        : 'property-card__eyebrow pb-2 mb-0 t-overline-normal pl-3 pr-4'
                      : variation === 'culinaryView'
                      ? 'property-card__eyebrow mb-0 t-overline-normal pl-3 pr-4'
                      : 'property-card__eyebrow pb-1 mb-0 t-overline-normal pl-3 pr-4',
                    variation === 'culinaryView'
                      ? 'property-card__eyebrow mb-0 t-overline-normal pl-3 pr-4'
                      : 'property-card__eyebrow pb-1 mb-0 t-overline-normal pl-3 pr-4'
                  )}
                ></Heading>
              )}
              {titleDetails?.isTitleLink ? (
                <div className={clsx('d-flex flex-row align-items-start', breakpoint === 'mobile' ? 'mb-n1' : '')}>
                  {(showIcon || iconTitle) && (
                    <Icon
                      iconClass={clsx(
                        'icon-decorative',
                        'pl-3',
                        'pt-1',
                        'pr-0',
                        iconTitle ? 'icon-dining' : 'icon-onsite-bar'
                      )}
                    ></Icon>
                  )}

                  <h2
                    className={clsx(
                      'property-card__title mb-0',
                      getClassForBreakpoint(
                        breakpoint,
                        titleDetails.titleClassNames ? titleDetails.titleClassNames[0] : 't-subtitle-xl pl-3 pr-4',
                        titleDetails.titleClassNames ? titleDetails.titleClassNames[1] : 't-subtitle-l pl-3 pr-4',
                        titleDetails.titleClassNames ? titleDetails.titleClassNames[2] : 't-subtitle-m pl-3 pr-3'
                      )
                    )}
                  >
                    {isMBOP ? (
                      <Link
                        linkHref={titleDetails?.titleLink ?? ''}
                        linkClassName={'custom_click_track p-0 text-left link-header'}
                        custom_click_track_value={titleDetails?.titleClickTrackVal || ''}
                        trackingProperties={trackingProperties}
                      >
                        {titleDetails?.title || ''}
                      </Link>
                    ) : (
                      <Button
                        type={Types.ButtonTypeVariation.Button}
                        callback={titleDetails?.titleClickCallback}
                        className={'p-0 text-left'}
                        isLink={false}
                        custom_click_track_value={titleDetails?.titleClickTrackVal || ''}
                        {...(titleDetails.titleButtonChildNode
                          ? {
                              ariaLabel: titleDetails?.title,
                            }
                          : {
                              buttonCopy: titleDetails?.title,
                            })}
                      >
                        {titleDetails && titleDetails.titleButtonChildNode && titleDetails.titleButtonChildNode}
                      </Button>
                    )}
                  </h2>
                </div>
              ) : (
                <>
                  <div className="d-flex flex-row align-items-baseline">
                    {(showIcon || iconTitle) && (
                      <Icon
                        iconClass={clsx(
                          'icon-decorative',
                          'pl-3',
                          'pr-0',
                          'pt-1',
                          iconTitle ? iconPathDining || 'icon-dining' : iconPathBar || 'icon-onsite-bar'
                        )}
                      ></Icon>
                    )}

                    <Heading
                      variation={Types.headingType.subtitle}
                      titleText={titleDetails.title}
                      fontSize={
                        breakpoint === 'desktop'
                          ? Types.size.extraLarge
                          : breakpoint === 'tablet'
                          ? Types.size.large
                          : Types.size.medium
                      }
                      element={Types.tags.h2}
                      customClass={`property-card__title mb-0 pl-1 ${breakpoint === 'mobile' ? 'pr-3' : 'pr-4'}`}
                    ></Heading>
                  </div>

                  {propertyName && (
                    <Text
                      copyText={propertyName}
                      customClass="property-card__subTitle px-3 mt-1 t-font-alt-s"
                      fontSize={size.small}
                      element={tags.span}
                    />
                  )}

                  <div className="pl-3 property-card__address">
                    {address && (
                      <Text
                        copyText={'' + address}
                        customClass="t-font-alt-s"
                        fontSize={size.small}
                        element={tags.span}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          )}
          {tertiaryLinkDetails &&
            tertiaryLinkDetails.placeLinkOnTopSection &&
            Object.keys(tertiaryLinkDetails).length && (
              <Button
                type={Types.ButtonTypeVariation.Button}
                href={addSubDirectoryPrefix(tertiaryLinkDetails?.href) || ''}
                target={tertiaryLinkDetails?.target}
                buttonCopy={tertiaryLinkDetails?.buttonCopy || ''}
                className={clsx(tertiaryLinkDetails?.className || '', 'property-card__tertiary-link pt-3 ml-3 pr-4')}
                linkAriaLabelOpenNewWindow={tertiaryLinkDetails?.linkAriaLabelOpenNewWindow || ''}
                isLink={tertiaryLinkDetails?.isLink}
                isTextBeforeChildren={tertiaryLinkDetails?.isTextBeforeChildren}
                custom_click_track_value={tertiaryLinkDetails?.custom_click_track_value || ''}
              ></Button>
            )}
          {loader ? (
            <div className=" property-card__skeleton d-flex d-md-block d-lg-flex flex-column align-items-center align-items-lg-start offset-md-1 offset-lg-1">
              <div className="skeleton-loader mt-3 mx-sm-auto mx-md-0 property-card__skeleton__label"></div>
            </div>
          ) : isMBOP && brandDetails?.hasUniquePropertyLogo === true ? (
            ''
          ) : (
            reviewsDetails &&
            Object.keys(reviewsDetails).length &&
            reviewsDetails?.reviewsLink && (
              <div
                className={clsx(
                  'property-card__reviews-miles pl-3 d-flex',
                  getClassForBreakpoint(breakpoint, 'pr-4', 'pr-4', 'pr-3')
                )}
              >
                {reviewsDetails?.reviewsText ? (
                  <Link
                    text={''}
                    linkClassName={'custom_click_track ratings-container d-flex'}
                    ariaLabel={reviewsDetails?.reviewsLabel || ''}
                    custom_click_track_value={reviewsDetails?.reviewsClickTrackValue || ''}
                    linkHref={reviewsDetails?.reviewsLink}
                    trackingProperties={trackingProperties}
                  >
                    <div className="star-container">
                      <div className="m-ratings ratings-value-container align-items-center">
                        <div className="m-ratings-stars mr-1">
                          <span className="star-icon-container icon-alt icon-star-fill"></span>
                        </div>
                        <span
                          className={clsx(
                            'star-number-container mr-2',
                            getClassForBreakpoint(breakpoint, 't-font-alt-s', 't-font-alt-xs', 't-font-alt-xs')
                          )}
                        >
                          {reviewsDetails?.reviewsAvg}
                        </span>
                        <span
                          className={clsx(
                            'review-number-container mr-2',
                            getClassForBreakpoint(breakpoint, 't-font-alt-s', 't-font-alt-xs', 't-font-alt-xs')
                          )}
                        >
                          {reviewsDetails?.reviewsText}
                        </span>
                      </div>
                    </div>
                  </Link>
                ) : null}
                {reviewsDetails?.milesText && (
                  <div className="distance-section d-flex">
                    {reviewsDetails?.reviewsText ? (
                      <div
                        className={clsx(
                          'divider-section d-flex mr-1 align-items-center',
                          getClassForBreakpoint(breakpoint, 't-font-alt-s', 't-font-alt-xs', 't-font-alt-xs')
                        )}
                      >
                        {' '}
                        |
                      </div>
                    ) : null}
                    <div className="m-icon-text distance-container">
                      <span className="icon-xs icon-location icon-xs"></span>
                      <span
                        className={clsx(
                          'distance-value ml-1',
                          getClassForBreakpoint(breakpoint, 't-font-alt-s', 't-font-alt-xs', 't-font-alt-xs')
                        )}
                      >
                        {reviewsDetails?.milesText}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )
          )}
          {(breakpoint === 'desktop' ||
            breakpoint === 'tablet' ||
            (breakpoint === 'mobile' && variation === 'culinaryView') ||
            (breakpoint === 'mobile' && showDescriptionAlways)) &&
            description && (
              <p
                className={clsx(
                  'property-card__details-container_desc pl-3 pr-4 mb-0',
                  getClassForBreakpoint(
                    breakpoint,
                    isVerticalCard ? 't-font-alt-s mt-4' : 't-font-alt-s mt-3',
                    variation === 'culinaryView' ? 't-font-alt-s mt-2' : 't-font-alt-xs mt-2',
                    variation === 'culinaryView' ? 't-font-alt-s mt-2' : 't-font-alt-xs mt-2'
                  )
                )}
              >
                {description}
              </p>
            )}
        </div>
        <div className="property-card__details-container_bottom pt-0">
          {tertiaryLinkDetails &&
            !tertiaryLinkDetails.placeLinkOnTopSection &&
            Object.keys(tertiaryLinkDetails).length && (
              <Button
                type={Types.ButtonTypeVariation.Button}
                href={addSubDirectoryPrefix(tertiaryLinkDetails?.href) || ''}
                target={tertiaryLinkDetails?.target}
                buttonCopy={tertiaryLinkDetails?.buttonCopy || ''}
                className={clsx(
                  tertiaryLinkDetails?.className || '',
                  'property-card__tertiary-link ',
                  variation === 'culinaryView' ? 'reserve-culinary-link' : 'pt-3 ml-3 pr-4'
                )}
                linkAriaLabelOpenNewWindow={tertiaryLinkDetails?.linkAriaLabelOpenNewWindow || ''}
                isLink={tertiaryLinkDetails?.isLink}
                callback={tertiaryLinkDetails?.onClick}
                isTextBeforeChildren={tertiaryLinkDetails?.isTextBeforeChildren}
                custom_click_track_value={tertiaryLinkDetails?.custom_click_track_value || ''}
              ></Button>
            )}
          <div
            className={clsx(
              getClassForBreakpoint(
                breakpoint,
                variation === 'culinaryView' ? 'mb-3 separator culinary-view-separator' : 'mb-3 separator mt-3',
                variation === 'culinaryView'
                  ? 'mb-3 separator culinary-view-separator'
                  : isMBOP
                  ? 'mb-2 separator mt-2'
                  : 'mb-3 separator mt-3',
                variation === 'culinaryView' ? 'mb-3 separator mt-3' : 'mb-2 separator mt-2'
              )
            )}
          />
          <div
            className={clsx(
              'property-card__details-container_footer d-flex pl-3 justify-content-end',
              getClassForBreakpoint(breakpoint, 'pr-4', 'pr-4', 'pr-3')
            )}
          >
            <div className="footer-container d-flex">
              {loader ? (
                <div className=" property-card__skeleton d-flex d-md-block d-lg-flex flex-column align-items-center align-items-lg-start offset-md-1 offset-lg-1">
                  <div className="skeleton-loader mx-sm-auto mx-md-0 property-card__skeleton__btn"></div>
                </div>
              ) : (
                <>
                  {footerLinkDetails?.hasPrice && footerLinkDetails?.points ? (
                    <Link
                      text={''}
                      linkClassName={clsx(
                        `points-section non-saver custom_click_track price-value d-flex flex-end ${
                          footerLinkDetails?.isPointSaver ? 'points-saver' : 'non-saver'
                        }`
                      )}
                      ariaLabel={footerLinkDetails?.priceAriaLabel || ''}
                      custom_click_track_value={footerLinkDetails?.priceClickTrackValue || ''}
                      linkHref={footerLinkDetails?.href || ''}
                      target={footerLinkDetails?.target || ''}
                      trackingProperties={trackingProperties}
                    >
                      {footerLinkDetails?.isPointSaver && (
                        <div className={clsx('t-label-alt-xs points-saver-label')}>PointSavers</div>
                      )}

                      <div className={clsx('price-section')}>
                        <div
                          className={clsx(
                            `m-price points-value ${footerLinkDetails?.isPointSaver ? 'points-saver' : 'non-saver'}`
                          )}
                        >
                          {footerLinkDetails?.points}
                        </div>
                        <div className={clsx(`t-label-alt-xs`, 'points-currency-label')} role="button">
                          {footerLinkDetails?.pointsType}
                        </div>
                      </div>

                      <div className={clsx('currency-section', 'm-price-currency-s')}>
                        <div className={clsx('t-font-alt-xs', 'price-value', 'm-price-currency-s')}>
                          {footerLinkDetails?.priceValue}
                        </div>
                        <div className={clsx('t-font-alt-xs', 'currency-label', 'm-price-currency-s')} role="button">
                          {footerLinkDetails?.priceType}
                        </div>
                      </div>
                    </Link>
                  ) : footerLinkDetails?.hasPrice ? (
                    <div
                      className={clsx(
                        breakpoint !== 'desktop' && secondaryLinkDetails && 'hide-price',
                        'price-container'
                      )}
                    >
                      <Link
                        text={''}
                        linkClassName={clsx(
                          footerLinkDetails?.priceClassName || '',
                          'custom_click_track price-value d-flex flex-end'
                        )}
                        ariaLabel={footerLinkDetails?.priceAriaLabel || ''}
                        custom_click_track_value={footerLinkDetails?.priceClickTrackValue || ''}
                        linkHref={footerLinkDetails?.href || ''}
                        target={footerLinkDetails?.target || ''}
                        trackingProperties={trackingProperties}
                      >
                        <div className="rate-container">
                          <span className="price-value currency-value">
                            <span className="price-value">
                              <span
                                className="m-price"
                                tabIndex={0}
                                role="button"
                                aria-label={footerLinkDetails?.priceValue || ''}
                              >
                                {isMBOP && isMandatoryFeesEnabled && amountPlusMandatoryFee
                                  ? amountPlusMandatoryFee
                                  : footerLinkDetails?.priceValue || ''}
                              </span>
                            </span>
                            <span
                              className="currency-label t-label-xs"
                              tabIndex={0}
                              role="button"
                              aria-label={footerLinkDetails?.priceType || ''}
                            >
                              {' '}
                              {footerLinkDetails?.priceType || ''}
                            </span>
                          </span>
                        </div>
                      </Link>
                      {/** Code to display LA ordinance fee */}
                      {breakpoint === 'mobile' || breakpoint === 'tablet'
                        ? isMBOP &&
                          mandatoryFee &&
                          !isTaxesAndFeesEnabled &&
                          isMandatoryFeesEnabled && (
                            //mandatoryFeesBlock?.value &&mandatoryFee
                            <div className="mandatory-fee-section-list-view-sm">
                              <div
                                data-testid="includes-fee"
                                className="includes-fee"
                                onKeyDown={(e): void => handleTooltipClick(e)}
                              >
                                {feeDescription1}{' '}
                                {formatNumberByLocale(
                                  Math.floor(
                                    Math.floor(
                                      parseInt(mandatoryFee?.value) /
                                        Math.pow(10, parseInt(mandatoryFee?.valueDecimalPoint))
                                    ) * 1
                                  ),
                                  'en-US'
                                )}{' '}
                                {mandatoryFee.currency}
                              </div>
                              <div>
                                <span
                                  data-component-name="a-mandatoryfee"
                                  data-testId="mandatoryfee"
                                  onClick={(e): void => handleTooltipClick(e)}
                                  className={clsx(
                                    'm-link-icon-button-info',
                                    'custom-info-map-sm',
                                    !toolTipList?.length ? 'remove-info-icon' : ''
                                  )}
                                  {...{
                                    custom_click_track_value: `Mandatory Fee Tooltip Button |internal`,
                                  }}
                                >
                                  {' '}
                                  {feeDescription2}{' '}
                                </span>
                              </div>
                            </div>
                          )
                        : isMBOP &&
                          mandatoryFee &&
                          !isTaxesAndFeesEnabled &&
                          isMandatoryFeesEnabled && (
                            <div className="mandatory-fee-section-list-view">
                              <div
                                data-testid="shop-mandatoryfee-tooltip"
                                onKeyDown={(e): void => handleTooltipClick(e)}
                              >
                                <div className="includes-fee">
                                  <span
                                    data-testid="shop-PropertyCard-tooltip"
                                    onClick={(e): void => handleTooltipClick(e)}
                                    className={clsx(
                                      'm-link-icon-button-info',
                                      'custom-info-map',
                                      !toolTipList?.length ? 'remove-info-icon' : ''
                                    )}
                                    {...{
                                      custom_click_track_value: `Mandatory Fee Tooltip Button |internal`,
                                    }}
                                  >
                                    {feeDescription1}{' '}
                                    {formatNumberByLocale(
                                      Math.floor(
                                        Math.floor(
                                          parseInt(mandatoryFee?.value) /
                                            Math.pow(10, parseInt(mandatoryFee?.valueDecimalPoint))
                                        ) * 1
                                      ),
                                      'en-US'
                                    )}{' '}
                                    {mandatoryFee.currency} {feeDescription2}{' '}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                    </div>
                  ) : null}

                  {secondaryLinkDetails && !isEmpty(secondaryLinkDetails) && (
                    <Button
                      type={Types.ButtonTypeVariation.Button}
                      href={addSubDirectoryPrefix(secondaryLinkDetails?.href) || ''}
                      target={secondaryLinkDetails?.target}
                      buttonCopy={secondaryLinkDetails?.buttonCopy || ''}
                      className={clsx(
                        secondaryLinkDetails?.className || '',
                        'property-card__details-container_footer_link ml-2 text-center'
                      )}
                      callback={secondaryLinkDetails?.onClick}
                      linkAriaLabelOpenNewWindow={secondaryLinkDetails?.linkAriaLabelOpenNewWindow || ''}
                      isLink={secondaryLinkDetails?.isLink}
                      isTextBeforeChildren={secondaryLinkDetails?.isTextBeforeChildren}
                      custom_click_track_value={secondaryLinkDetails?.custom_click_track_value || ''}
                    />
                  )}
                  {!isMBOP || (isMBOP && (footerLinkDetails?.priceValue || footerLinkDetails?.points)) ? (
                    <Link
                      text={footerLinkDetails?.text || ''}
                      linkClassName={clsx(
                        footerLinkDetails?.className || '',
                        `property-card__details-container_footer_link ${
                          secondaryLinkDetails ? `ml-2` : `ml-4 `
                        } text-center py-2 ${styleforbuttonMobileTab}`
                      )}
                      ariaLabel={footerLinkDetails?.linkAriaLabelOpenNewWindow || ''}
                      custom_click_track_value={footerLinkDetails?.ratesClickTrackValue || ''}
                      linkHref={footerLinkDetails?.href || ''}
                      target={footerLinkDetails?.target || ''}
                      callback={e => footerLinkDetails?.onClick && footerLinkDetails?.onClick(e)}
                      trackingProperties={trackingProperties}
                    ></Link>
                  ) : (
                    <span className={'rate-unavailable-text'}>{footerLinkDetails?.unavailableRateText}</span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </StyledPropertyCard>
  );
};
