// Styles for ExploreDestinations go here.
import styled from 'styled-components';
import rtl from 'styled-components-rtl';
import { toRem, baseVariables } from '@marriott/mi-ui-library';

export const StyledExploreDestinations = styled.div`
  ${rtl`
     background: ${baseVariables.color['neutral10']};
     padding: ${toRem(24)} ${toRem(16)} ${toRem(40)};

    @media ${baseVariables.mediaQuery.md} {
      padding: ${toRem(24)} ${toRem(32)} ${toRem(40)};
    }

    @media ${baseVariables.mediaQuery.lg} {
      padding-top: ${toRem(40)};
      padding-bottom: ${toRem(40)};
    }

    .destinations-container {
      .content-2 {
        width: 100%;
        max-width: ${toRem(554)};
      }

      .content-1 {
        width: 100%;
        max-width: ${toRem(554)};
        margin-right: ${toRem(16)};
      }

      .destination-item {
        padding-top: ${toRem(6)};
        border-bottom: ${toRem(1)} solid ${baseVariables.color['neutral40']};

        .destination-item-content-link {
          font-size: ${baseVariables.font['fontM']};
          font-weight: ${baseVariables.font['fontWeightRegular']};
          line-height: ${baseVariables.font['lineHeightXxxl']};
          width: fit-content;

          &:hover {
            text-decoration: underline;
          }
        }

        @media ${baseVariables.mediaQuery.sm} {
          max-width: ${toRem(704)};
        }

        @media ${baseVariables.mediaQuery.lg} {
          max-width: ${toRem(554)};
        }
      }

      .destination-item:nth-child(1) {
        border-top: ${toRem(1)} solid ${baseVariables.color['neutral40']};
      }

      .destination-item:nth-last-child(1) {
        border-bottom: none;
      }

      .destinations-subheading {
        margin-bottom: ${toRem(28)};
      }

      .destination-title {
        font-size: ${baseVariables.font['fontM']};
        font-weight: ${baseVariables.font['fontWeightRegular']};
        line-height: ${baseVariables.font['lineHeightM']};
        margin-bottom: ${toRem(7)};
        margin-top: ${toRem(4)};
      }
    }
  `}
`;
