import React, { FC } from 'react';
import { PillProps } from './Pill.types';
import { StyledPill, StyledPillBadge } from './Pill.styles';
import { Button } from '../Button';
import { Icon } from '../Icon';
import clsx from 'clsx';

export const Pill: FC<PillProps> = ({
  label,
  value,
  count,
  ariaLabel,
  className,
  isActive,
  isDisabled = false,
  showIcon,
  iconClass,
  iconAriaLabel,
  custom_click_track_value,
  buttonClassName,
  onClick,
  id,
  ...rest
}) => {
  const pillIcon = (
    <span className="pill-icon">
      <Icon ariaLabel={iconAriaLabel} iconClass={iconClass}></Icon>
    </span>
  );

  return (
    <StyledPill data-component-name="a-ui-library-Pill" data-testid="ui-library-Pill" className={className}>
      <Button
        className={clsx(
          'pill',
          'm-button-primary-inverse',
          isActive ? 'active' : '',
          isDisabled ? 'disabled' : '',
          className,
          buttonClassName
        )}
        linkAriaLabelOpenNewWindow={ariaLabel}
        isDisabled={isDisabled}
        callback={() => onClick && onClick(value)}
        custom_click_track_value={custom_click_track_value}
        id={id}
        {...rest}
      >
        {showIcon && pillIcon}
        {count !== undefined && count > 0 && <StyledPillBadge className="t-font-xs">{count}</StyledPillBadge>}
        {label}
      </Button>
    </StyledPill>
  );
};
