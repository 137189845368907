import { useEffect, useState } from 'react';
import cssClass from 'classnames';

import { Input } from '../../atoms';
import { PasswordProps } from './Password.types';
import { StyledDiv } from './Password.styles';

import { REGEX_CONSTANTS } from '../../constants';

export const Password = (props: PasswordProps) => {
  const {
    label,
    name,
    pwdLengthText,
    pwdLowerCase,
    pwdUpperCase,
    pwdSpecialChar,
    setIsValid,
    showError,
    pwdRequiredErrorMsg,
    pwdLengthErrorMsg,
    pwdLowerCaseErrorMsg,
    pwdUpperCaseErrorMsg,
    pwdSpecialCharErrorMsg,
    watch,
  } = props;
  const initialState = {
    length: false,
    lowerCase: false,
    upperCase: false,
    specialChar: false,
  };
  const [showValidations, setShowValidations] = useState(false);
  const [validations, setValidations] = useState(initialState);
  const [errors, setErrors] = useState<string[]>([]);
  const { length, upperCase, lowerCase, specialChar } = validations;
  const { LOWER_CASE_PATTERN, UPPER_CASE_PATTER, SPECIAL_CHAR_PATTERN, LENGTH_PATTERN, ALLOWED_CHAR_PATTERN } =
    REGEX_CONSTANTS;
  const pwd_value = watch(name);

  /*Generating css classname from classname module*/
  const getPwdErrorsClassName = (isErrorChecked: boolean) => {
    return cssClass({ 'icon-check': isErrorChecked, 'icon-clear error': !isErrorChecked });
  };

  const validatePassword = (value: string) => {
    const obj = { ...validations };
    const error = [];
    obj.length = new RegExp(LENGTH_PATTERN).test(value);
    if (value && !obj.length) {
      error.push(pwdLengthErrorMsg);
    }
    obj.lowerCase = new RegExp(LOWER_CASE_PATTERN).test(value);
    if (value && !obj.lowerCase) {
      error.push(pwdLowerCaseErrorMsg);
    }
    obj.upperCase = new RegExp(UPPER_CASE_PATTER).test(value);
    if (value && !obj.upperCase) {
      error.push(pwdUpperCaseErrorMsg);
    }
    obj.specialChar = Boolean(
      value && new RegExp(SPECIAL_CHAR_PATTERN).test(value) && new RegExp(ALLOWED_CHAR_PATTERN).test(value)
    );
    if (value && !obj.specialChar) {
      error.push(pwdSpecialCharErrorMsg);
    }

    setErrors([...error]);
    setValidations({ ...obj });
  };

  useEffect(() => {
    if (!pwd_value && errors?.length) {
      setErrors([]);
      setValidations(initialState);
    }
  }, [pwd_value]);

  return (
    <StyledDiv data-component-name="m-book-Password" data-testid="book-Password">
      <Input
        {...props}
        className="pwd"
        id={name}
        name={name}
        label={label}
        type="password"
        varient="outlined"
        isErrorIcon={false}
        onFocus={() => setShowValidations(true)}
        onBlur={() => {
          setIsValid?.(length && lowerCase && upperCase && specialChar);
          setShowValidations(false);
        }}
        isValid={length && lowerCase && upperCase && specialChar}
        showError={showError}
        isMandatoryField={true}
        registerOptions={{
          required: pwdRequiredErrorMsg,
          validate: (value: string) => validatePassword(value),
        }}
        errors={errors}
        testId={name}
      />
      {showValidations && (
        <ul className="validation">
          <li className={getPwdErrorsClassName(length)}>{pwdLengthText}</li>
          <li className={getPwdErrorsClassName(lowerCase)}>{pwdLowerCase}</li>
          <li className={getPwdErrorsClassName(upperCase)}>{pwdUpperCase}</li>
          <li className={getPwdErrorsClassName(specialChar)}>{pwdSpecialChar}</li>
        </ul>
      )}
    </StyledDiv>
  );
};
