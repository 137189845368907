import { PropertyRatingProps } from './PropertyRating.types';

export const PropertyRating = ({ className, children }: PropertyRatingProps) => {
  return (
    <span
      data-component-name="m-ui-library-PropertyRating"
      data-testid="ui-library-PropertyRating"
      className={className}
      // data-testid="ratings"
    >
      {children}
    </span>
  );
};
