import { createContext, Dispatch } from 'react';
import { getInitialSearchMockState } from '../store/store.action';
import { InitialSearchResult } from '../store/store.reducer';
import { UpdateSearchStateActionType, SearchStateStoreDataType, SearchResultDataType } from '../store/store.type';
import { SearchResultErrorType, SearchResultAlertType } from './index.types';
import { hardCodedAllLocale } from '../../SearchForm/component/index.constants';

interface SearchFilterContextType {
  searchQueryState: SearchStateStoreDataType;
  setSearchQuery: Dispatch<UpdateSearchStateActionType>;
}

interface SearchResultContextType {
  searchResult: SearchResultDataType;
  setSearchResult: Dispatch<UpdateSearchStateActionType>;
}

interface SearchResultErrorContextType {
  searchResultErrors: Array<SearchResultErrorType> | [];
  setSearchResultErrors: Dispatch<Array<SearchResultErrorType>>;
}

interface SearchResultContextType {
  searchResult: SearchResultDataType;
  setSearchResult: Dispatch<UpdateSearchStateActionType>;
}

export const SearchContentContext = createContext<Record<string, unknown>>({});

export const ErrorSearchResultContext = createContext<SearchResultErrorContextType>({
  searchResultErrors: [],
  setSearchResultErrors: () => {
    //TODO : empty function
  },
});

export const SearchFilterContext = createContext<SearchFilterContextType>({
  searchQueryState: getInitialSearchMockState({}, 'false', hardCodedAllLocale.en, '', false),
  setSearchQuery: () => {
    //TODO : empty function
  },
});

export const SearchResultContext = createContext<SearchResultContextType>({
  searchResult: InitialSearchResult,
  setSearchResult: () => {
    //TODO : empty function
  },
});

interface SearchResultAlertContextType {
  searchResultAlerts: SearchResultAlertType;
  setSearchResultAlerts: Dispatch<SearchResultAlertType>;
}
export const SearchResultAlertContext = createContext<SearchResultAlertContextType>({
  searchResultAlerts: {},
  setSearchResultAlerts: () => {
    //TODO : empty function
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MapViewContext = createContext<any>({
  selectedHotel: [],
  setSelectedHotel: () => {
    //TODO : empty function
  },

  open: false,
  setOpen: () => {
    //TODO : empty function
  },
});
