import styled from 'styled-components';
import { toRem, baseVariables as theme, baseVariables } from '@marriott/mi-ui-library';

export const StyledTab = styled.div<{ $isMeetingsTab?: boolean }>`
  position: relative;
  border-radius: ${toRem(12)};
  .t-subtitle-m {
    display: inline-block;
  }
  .hide-image {
    display: none;
    @media ${baseVariables.mediaQuery.md} {
      visibility: hidden;
      display: initial;
    }
  }
  .tab-wrapper {
    padding: ${toRem(24)} ${toRem(0)} ${toRem(0)} ${toRem(0)};
    @media ${baseVariables.mediaQuery.md} {
      padding: ${toRem(32)} ${toRem(0)} ${toRem(0)} ${toRem(0)};
    }
    .document_search_form_container {
      border-bottom-left-radius: ${toRem(20)};
      border-bottom-right-radius: ${toRem(20)};
    }
  }
  .search-container-top {
    @media ${theme.mediaQuery.desktop} {
      padding-top: ${toRem(4)} !important;
    }
  }
  .m-icon-tab-wrapper .tabs-sub-heading {
    text-transform: none;
    font-family: ${baseVariables.font['fontFamilySwiss']};
    line-height: 125%;
  }
  .m-icon-tab-wrapper .m-icon-tab-list .m-icon-tab-list-item .icon:before {
    width: ${toRem(24)};
    font-size: ${toRem(24)};
  }
  .icon-tabs-container {
    box-shadow: none;
    z-index: 2;
  }
  .accomdation {
    padding-top: ${toRem(16)};
    @media ${baseVariables.mediaQuery.md} {
      padding-top: 0;
      padding-left: ${toRem(16)};
      padding-right: ${toRem(8)};
    }
    font-weight: 500;
    font-size: ${toRem(14)};
    font-family: ${baseVariables.font['fontFamilySwiss']};

    .m-accordion__list {
      gap: ${toRem(16)};
    }
  }
  .vacations {
    padding-top: ${toRem(16)};
    padding-bottom: ${toRem(40)};
    @media ${baseVariables.mediaQuery.md} {
      padding-bottom: 0;
    }
    line-height: ${toRem(21)};
    a {
      padding-top: ${toRem(0)};
    }
  }
  .empty-div {
    padding-top: ${toRem(78)};
  }
  .m-input-text-field
    input[type='text']:not([type='submit']):not([type='button']):not([type='checkbox']):not([type='radio']) {
    border-color: ${theme.color.neutral40};
  }
  .m-input-text-field > label {
    padding-left: ${toRem(16)};
    padding-top: ${toRem(8.5)};
  }
  .vacation-text {
    font-size: ${toRem(14)};
    font-weight: 500;
    height: ${toRem(21)};
    font-family: ${baseVariables.font['fontFamilySwiss']};
    color: ${theme.color.neutral40};
  }

  .tab-list-mob {
    width: 100% !important;
  }
  .cl__list-item {
    height: ${toRem(21)};
    padding-right: ${toRem(32)} !important;
  }
  .cl__item-link.icon-arrow-right-external::before {
    font-size: ${toRem(16)} !important;
    line-height: ${toRem(16)};
  }
  .m-standard-tab-list {
    margin: 0 ${toRem(32)};
    .m-standard-tab-list-item {
      width: ${toRem(180)};
      text-align: center;
      margin-right: 0;
      font-family: Swiss721 BT;
      font-size: ${toRem(13)};
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
    }
  }
  .m-standard-tab-list-item:first-child {
    margin-right: ${toRem(17)};
  }
  .image-container {
    height: ${toRem(125)};
    overflow: hidden;
    img {
      width: 100vw;
      display: block;
    }
  }
  @media ${baseVariables.mediaQuery.md} {
    padding: ${toRem(32)} ${toRem(0)} ${toRem(80)} ${toRem(0)};
    .image-container {
      height: ${toRem(256)};
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 0;
    }
    .tabbed-container {
      margin-top: ${toRem(136)};
    }
  }

  @media ${baseVariables.mediaQuery.lg} {
    .primary-wrapper {
      padding: 0 !important;
      height: ${toRem(68)} !important;
    }
    .image-container {
      height: ${toRem(480)};
    }
    .tabbed-container {
      margin-top: ${toRem(256)};
    }
  }

  .m-icon-tab-list-item-heading {
    span {
      font-size: ${toRem(13)};
      line-height: ${toRem(13)};
    }
  }
  .tabs-sub-heading {
    margin-bottom: 0;
  }
  .m-icon-tab-list-item-heading {
    margin-top: 0 !important;
  }
  .m-icon-tab-list {
    height: ${toRem(57)};
    @media ${baseVariables.mediaQuery.md} {
      margin-top: ${props => (props.$isMeetingsTab === true ? 0 : `${toRem(-2)}`)};
    }
    @media ${baseVariables.mediaQuery.lg} {
      margin-top: 0;
    }
  }
  .inactive-tab {
    opacity: 1;
  }
  .inactive-tab .m-icon-tab-list-item-heading {
    span {
      color: ${baseVariables.color.neutral40};
    }
  }
`;
