import { FC } from 'react';
import { constants } from '../../constants/constants';
import { SaveReservationAries } from './SaveReservationAries';
import { SaveReservationPhoenix } from './SaveReservationPHX';
import { ReservationModalProps } from './SaveReservation.types';
import { getOCJModalPlatform } from '../../utils/helper';

export const SaveReservation: FC<ReservationModalProps> = props => {
  const { modalType = '' } = props;
  const platform = getOCJModalPlatform(modalType);
  if (platform === constants.PHOENIX) {
    return <SaveReservationPhoenix {...props} />;
  }
  return <SaveReservationAries {...props} />;
};
