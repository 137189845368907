// This file need to be removed or changed once we get global styles
// for switch checkbox from lakshmi and team
import styled from 'styled-components';
import { toRem, baseVariables } from '../../styles';

export const StyledSwitchDiv = styled.div`
  width: fit-content;
  input:focus-visible + label {
    outline: ${toRem(1)} solid ${baseVariables.color['neutral00']};
  }
  .toggle-switch {
    width: ${toRem(52)};
    height: ${toRem(32)};
    position: relative;
  }

  .toggle-switch + input {
    opacity: 0;
  }

  .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${baseVariables.color['neutral30']};
    transition: 0.4s;
    border-radius: ${toRem(34)};
    border: ${toRem(1)} solid ${baseVariables.color['neutral20']};
  }

  .toggle-slider:before {
    position: absolute;
    content: '';
    height: ${toRem(28)};
    width: ${toRem(28)};
    left: ${toRem(0.5)};
    top: ${toRem(1)};
    background-color: ${baseVariables.color['neutral20']};
    transition: 0.4s;
    border-radius: ${toRem(34)};
    border: none;
  }

  input[type='checkbox']:checked + .toggle-slider {
    background-color: ${baseVariables.color['merch10']};
    border-color: ${baseVariables.color['merch10']};
  }

  input[type='checkbox']:checked + .toggle-slider:before {
    background-color: ${baseVariables.color['neutral20']};
    transform: translateX(${toRem(19)});
    border-radius: 50%;
    left: ${toRem(2)};
  }
`;
