import { FC } from 'react';
import { StyledTitleWrapper } from './index.styles';

const TitleDescComponent: FC<{ bannerTitle?: string; bannerDesc: string }> = ({ bannerTitle, bannerDesc }) => (
  <StyledTitleWrapper>
    <h4 className="hvmi-banner-title mb-0">{bannerTitle}</h4>
    <div className="t-font-alt-s pt-md-2 pt-1 hvmi-banner-desc" dangerouslySetInnerHTML={{ __html: bannerDesc }} />
  </StyledTitleWrapper>
);
export default TitleDescComponent;
