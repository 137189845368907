import { FC } from 'react';
import cssClass from 'classnames';

import { DropdownProps, SelectOptions } from './Dropdown.types';
import { StyledDropdown, StyledError } from './Dropdown.styles';

export const Dropdown: FC<DropdownProps> = ({
  id,
  label,
  name,
  testId,
  code = '',
  description = '',
  className,
  placeholder,
  registerFn,
  errorsProps,
  inputClassName,
  isMandatoryField,
  selectOptions = [],
  registerOptions = {},
  varient = 'standard',
  defaultValue,
  onChange,
  isErrorIcon = true,
  readonly = false,
}) => {
  const isError = errorsProps?.[id] && 'is-error';
  const errorMessage = errorsProps?.[id]?.message;
  const errorClassName = isErrorIcon ? 'icon-information' : 'information';
  const outlineVariant = varient === 'outlined';

  const fieldClassName = cssClass({
    outlined: outlineVariant,
    'no-border': !outlineVariant,
    'read-only-field': readonly,
  });

  const renderOptions = (optionList: SelectOptions[]) => {
    return optionList.map(({ label, value }, index) => (
      <option
        key={`Option${index}`}
        value={value}
        selected={description ? value === code && label === description : value === code}
      >
        {label}
      </option>
    ));
  };
  return (
    <StyledDropdown data-component-name="a-book-Dropdown" data-testid="book-Dropdown" className={className}>
      <fieldset className={[fieldClassName, isError].join(' ')}>
        {label ? (
          varient === 'outlined' ? (
            <legend className="outlined-label">{label}</legend>
          ) : (
            <label htmlFor={id}>{label}</label>
          )
        ) : null}
        <div className="select-box" aria-live={isError ? 'polite' : 'off'}>
          <select
            id={id}
            name={name}
            data-testid={testId}
            aria-label={placeholder ?? label}
            aria-describedby={errorMessage && `error${id}`}
            aria-invalid={isError ? true : false}
            className={[inputClassName, isError].join(' ')}
            value={defaultValue}
            {...(registerFn &&
              registerFn(id, { required: isMandatoryField, ...registerOptions, ...{ onChange: onChange } }))}
          >
            {placeholder && (
              <option key={'placeholder'} selected hidden value={code}>
                {placeholder}
              </option>
            )}
            {renderOptions(selectOptions)}
          </select>
        </div>
      </fieldset>
      {errorMessage && (
        <StyledError className={errorClassName} id={`error${id}`}>
          {errorMessage}
        </StyledError>
      )}
    </StyledDropdown>
  );
};
