/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { ApolloProvider } from '@apollo/client';
import { useNextMiApolloClient, getDeployedEnvType } from '@marriott/mi-apollo-client-utils';
import { Modal } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import axios from 'axios';
import { useGetBreakpoint } from '../../utils/layout';
const { IS_LOCAL_DEV } = process.env;

export interface PageonModalProps {
  templateURL?: string;
  modalOpenState?: boolean;
  modelReqBody?: any;
  hotelQuickViewCacheConfig?: any;
  closeModal?: any;
  operationSignatures?: any;
  enableApolloUtility?: boolean;
  aemPath?: string;
  itemPath?: string;
  pageName?: string;
  getMockPageModel?: any;
  getItemFromPageModel?: any;
  respGridUtil?: any;
  ErrorHandlerAlert?: any;
}

let modelTemplate: any = {};
let responsiveGridModelHQV: any = {};
let responsiveGridModelPropsHQV: any = {};

const PageonModal: React.FC<PageonModalProps> = ({
  templateURL,
  modalOpenState,
  modelReqBody,
  hotelQuickViewCacheConfig,
  closeModal,
  operationSignatures,
  enableApolloUtility,
  aemPath,
  itemPath,
  pageName,
  getMockPageModel,
  getItemFromPageModel,
  respGridUtil,
  ErrorHandlerAlert,
}) => {
  /**
   * useEffect -> It checks for local development and decides to use mock data or api call for AEM model
   * sets AEM modal(mock or API response) on responsiveGridModelHQV variable based
   */
  useEffect(() => {
    (async function () {
      if (IS_LOCAL_DEV === 'true') {
        modelTemplate = await getMockPageModel(pageName);
      } else {
        modelTemplate = await getPageModel(modelReqBody, templateURL as string);
      }
      responsiveGridModelHQV = getItemFromPageModel(modelTemplate, itemPath);
      responsiveGridModelPropsHQV = respGridUtil(responsiveGridModelHQV);
    })();
  }, []);

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsmobilePort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const deployedEnvType = getDeployedEnvType();
  const HQVMiApolloClient = useNextMiApolloClient(operationSignatures, deployedEnvType, hotelQuickViewCacheConfig);
  const [isMobilePort, setIsmobilePort] = useState(useGetBreakpoint() === 'mobile');

  /**
   * making an Page modal call on component load based on modelReqBody value
   * sets AEM modal on responsiveGridModelHQV variable based on AEM response
   * @param modelReqBody: Request body for hybrid config app in order to provide model
   * @param templateURL: hybrid config app URL from where we get AEM model
   */
  const getPageModel = async (modelReqBody: any, templateURL: string) => {
    let pageModel = {};
    try {
      const response = await axios.post(`${templateURL}`, modelReqBody);
      if (response) {
        pageModel = response?.data?.data;
      } else {
        pageModel = {};
      }
    } catch (err) {
      // log.error(`HQV_MODEL_CALL : API call failed: ${err}`);
      //returing page model and logging error
      return pageModel;
    }
    return pageModel;
  };

  return (
    <ErrorHandlerAlert alertMessage={modelTemplate?.['componentErrorMessage']}>
      {modalOpenState && (
        <Modal
          className={`custom-scrollbar  ${modalOpenState ? 'hqv-modal-main-class' : 'close-popup-header'}`}
          show={true}
          handleBlur={true}
          popupOpenState={modalOpenState as boolean}
          setPopupOpenState={closeModal}
          allFilterPopUp={true}
          onClose={closeModal}
          modalId={'hqv-modal'}
          role="dialog"
          labelledBy="hqv-modal"
        >
          <Modal.Header
            className={clsx('hqv-modal-content-header')}
            labelText={' '}
            popupHeaderOnCLoseFunc={closeModal}
          />
          <Modal.Body className={clsx('custom-scrollbar', 'hqv-modal-content', 'modal-content-loader')}>
            <div className={clsx('m-spinner-m')}></div>
          </Modal.Body>
          <Modal.Body
            className={clsx(
              isMobilePort ? '' : 'custom-scrollbar',
              'hqv-modal-content',
              'hqv-modal-data',
              'hqv-modal-component-data'
            )}
          >
            {enableApolloUtility ? (
              <ApolloProvider client={HQVMiApolloClient}>
                <ResponsiveGrid
                  {...responsiveGridModelPropsHQV}
                  model={responsiveGridModelHQV}
                  pagePath={aemPath}
                  itemPath={itemPath}
                />
              </ApolloProvider>
            ) : (
              <ResponsiveGrid
                {...responsiveGridModelPropsHQV}
                model={responsiveGridModelHQV}
                pagePath={aemPath}
                itemPath={itemPath}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
    </ErrorHandlerAlert>
  );
};

export default PageonModal;
