import { toRem } from './utils';
import { theme } from './theme';

const ctaTypography = `
    font-family: ${theme.fontFamily.base};
    font-size: ${toRem(18)};
    font-weight: 500;
    letter-spacing: 0;
    line-height: ${toRem(20)};
    @media ${theme.mediaQuery.tablet} {
        font-family: ${theme.fontFamily.title};
    }
    @media ${theme.mediaQuery.desktop} {
        font-size: ${toRem(14)};
        line-height: ${toRem(16)};
    }
`;

const cta = `
    ${ctaTypography}
    text-align: center;
    border-radius: ${toRem(26)};
    box-shadow: ${theme.color['cta-box-shadow']};
    text-decoration: none;
    display: inline-block;
    :hover {
      text-decoration: none;
    }
    @media ${theme.mediaQuery.tablet} {
      border-radius: ${toRem(23)};
    }
`;

export default `
    .h-r-cta-primary-black {
        ${cta}
        box-shadow: none;
        color: ${theme.color['white']};
        background-color: ${theme.color['dark-gray-2']};
        padding:${toRem(16)} ${toRem(20)};
        border: none;
        @media ${theme.mediaQuery.tablet} {
          background: ${theme.color['black']};
          padding: ${toRem(14)} ${toRem(12)};
          font-size: ${toRem(18)};
          line-height: ${toRem(18)};
        }
    }

    .h-r-cta-primary-white {
        ${cta}
        box-shadow: none;
        color: ${theme.color['black']};
        background: ${theme.color['white']};
        width: 100%;
        padding: ${toRem(16)} ${toRem(12)} ${toRem(16)};
        border: none;
    }

    .h-r-cta-secondary-white {
        ${cta}
        box-shadow: none;
        color: ${theme.color['black']};
        background: ${theme.color['light-gray-5']};
        border: ${toRem(1.5)} solid ${theme.color['black']};
        padding: ${toRem(11)} ${toRem(12)} ${toRem(13)};
        width: auto;
        @media ${theme.mediaQuery.tablet} {
            font-size: ${toRem(14)};
            line-height: ${toRem(16)};
        }
    }

    .link-white {
        color: ${theme.color.white};
        font-size: ${toRem(13)};
        line-height: ${toRem(16)};
        font-family: ${theme.fontFamily.title};
        background: none;
        border: none;
        :hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
`;
