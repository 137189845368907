import React, { useState, FC, useEffect } from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';
import { StyledComp } from './HQVModal.styles';
import { ModalUI } from '../../molecules';
import { HotelGalleryPhotoViewer } from '../HotelGalleryPhotoViewer';
import { HotelInformation } from '../HotelInformation';
import { HotelOverview } from '../HotelOverview';
import { HotelGalleryTitle } from '../HotelGalleryTitle';
import clsx from 'clsx';
import { eventUtil } from '../../utils';
import { constants, CUSTOM_EVENTS_CONSTANTS } from '../../constants';
import { HQVProps } from './HQVModal.types';
const { HOTEL_DETAILS_ID } = constants;
const { ON_SHOW_HOTEL_DETAIL } = CUSTOM_EVENTS_CONSTANTS;

export const HQVModal: FC<HQVProps> = props => {
  const [showModal, setshowModal] = useState<boolean>(false);
  const [hideLoading, setHideLoading] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [hotelReviewUrl, setHotelReviewURL] = useState<string>('');
  const [hotelDetailUrl, setHotelDetailUrl] = useState<string>('');
  const [disableReviews, setDisableReviews] = useState<boolean>(false);

  useEffect(() => {
    if (dataLoaded) {
      setHideLoading(true);
      setDataLoaded(false);
    }
  }, [dataLoaded]);

  const closeModal = () => {
    setshowModal(false);
    setHideLoading(false);
  };

  const handleLoading = (isDataLoaded: boolean) => {
    setDataLoaded(isDataLoaded);
  };
  eventUtil &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventUtil?.on(ON_SHOW_HOTEL_DETAIL, function (eventData: any) {
      if (eventData.id === HOTEL_DETAILS_ID) {
        setHotelReviewURL(eventData.hotelReviewUrl);
        setHotelDetailUrl(eventData.hotelDetailUrl);
        setDisableReviews(eventData?.disableReviews);
        setshowModal(true);
      }
    });
  return (
    <ModalUI
      open={showModal}
      onClose={closeModal}
      customCss="hqv-modal"
      keepMounted={false}
      ariaDescribedby="hqv-modal"
      data-component-name="o-book-HQVModal"
    >
      <StyledComp>
        <div className={clsx(hideLoading ? 'display-none' : 'display-content', 'hqv-modal-loader')}>
          <div className="m-spinner-m"></div>
        </div>
        <div className={clsx(hideLoading ? 'display-content' : 'display-none', 'border')}></div>
        <div className={clsx(hideLoading ? 'display-content' : 'display-none', 'hqv-modal-content custom-scrollbar')}>
          <HotelGalleryTitle
            {...props.hotelgallerytitle}
            handleLoading={handleLoading}
            bookHotelHeaderHQV={props.bookHotelHeaderHQV}
            somethingWentWrong={props.somethingWentWrong}
            hotelReviewUrl={hotelReviewUrl}
            hotelDetailUrl={hotelDetailUrl}
            disableHotelHeaderAndReviewLinks={props.disableHotelHeaderAndReviewLinks}
            disableHotelReviewLinks={props.disableHotelReviewLinks}
            disableReviews={disableReviews}
          />
          <HotelGalleryPhotoViewer
            {...props.hotelgalleryphotoviewer}
            bookHotelHeaderHQV={props.bookHotelHeaderHQV}
            somethingWentWrong={props.somethingWentWrong}
          />
          <HotelOverview {...props.hotelgalleryoverview} somethingWentWrong={props.somethingWentWrong} />
          <HotelInformation model={props.hotelinformation} somethingWentWrong={props.somethingWentWrong} />
        </div>
      </StyledComp>
    </ModalUI>
  );
};

export const HQVModalConfig = {
  emptyLabel: 'hqvmodal',
  isEmpty: false,
  resourceType: `mcom-book-spa/components/hoteloverlay/hqvmodal`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const HQVModalEditable = (props: any) => {
  return (
    <EditableComponent config={HQVModalConfig} {...props}>
      <HQVModal {...props} />
    </EditableComponent>
  );
};
